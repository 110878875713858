import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { Table } from 'primeng/table';
import { OfflineBillsService } from '../offline-bills.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MultiSelect } from 'primeng/multiselect';

@Component({
  selector: 'backoffice-offline-bills-grid',
  templateUrl: './offline-bills-grid.component.html',
  styleUrls: ['./offline-bills-grid.component.scss']
})
export class OfflineBillsGridComponent implements OnInit {

  breadcrumbList = [
    { label: `Offline Bills`, routerLink: [] },
  ];

  homeicon = { icon: `pi pi-home` };

  searchCompanyPopUp: boolean = false;
  filterDisplay: boolean = true;
  loadingStatus: boolean = false; 
  apiFail: boolean = false; 
  showFailureModel: boolean = false; 
  filterDisplayRow: boolean = true;
  emptyMsgFlag: boolean = false;
  showEntityLoader: boolean = false;
  gridLoader: boolean = false;

  companyNameSearchValue : any;
  globalStartDate: any;
  globalEndDate: any;

  emptyMsgVal: string = 'No Company Found';

  statusList: any[] = [{name:"Pending"},{name:"Approved"},{name:"Rejected"}];

  gridDataList = [];
  companyNameList = [];

  minGlobalStartTime = new Date();
  maxGlobalEndTime = new Date();

  billStatus:any;

  billForm = this.fb.group({
    bdManagerFilter:[null as any,[]],
    costCenterFilter:[null as any,[]],
    billTypeFilter:[null as any,[]],
    statusFilter:[null as any,[]]
  });

  bdManagerList:any;
  costCenterList:any;
  billTypeList:any;

  @ViewChild('globalCalendar') globalCalendar: any;

  // grid related variables - start
  @ViewChildren('mainGrid') mainGrid;
  @ViewChild('mainGrid') mainGrid1 : Table;
  selectAllBdManager: boolean = false;
  @ViewChild('oppBdManager') oppBdManager: MultiSelect;
  selectAllCostCenter: boolean = false;
  @ViewChild('oppCostCenter') oppCostCenter: MultiSelect;
  selectAllBillType: boolean = false;
  @ViewChild('oppBillType') oppBillType: MultiSelect;
  
  constructor(private fb: FormBuilder,private cd: ChangeDetectorRef,private billService: OfflineBillsService,private router: Router) { }

  ngOnInit(): void {
    this.billStatus = "Pending";
    let billStatusFilter = {name:"Pending"};
    this.billForm.patchValue({statusFilter:"Pending"});
    this.getGridDetails(this.billStatus);
  }

  datePicker(clear?){

  }

  loadCompanyList(event) {

  }

  showSearchCompanyPopUp() {
    console.log(this.gridLoader, this.loadingStatus, 'ssss')
    if(!this.gridLoader && !this.loadingStatus){
      this.searchCompanyPopUp = true;
    }
  }

  clearFilter(table : Table) {
    table.reset();
    this.cd.detectChanges();
    
    
    if(this.mainGrid?.first?.filters) { // reset all filters
      Object.values(this.mainGrid.first.filters).map(item => {
        (<any>item).value = null;
        return item;
      });
    }

    this.billForm.controls.bdManagerFilter.reset();
    this.billForm.controls.costCenterFilter.reset();
    this.billForm.controls.billTypeFilter.reset();

    this.filterDisplayRow = false;
    this.filterDisplay = false;
    this.checkBdManagerAllSelection(this.billForm.get('bdManagerFilter')?.value);
    this.checkCostCenterAllSelection(this.billForm.get('costCenterFilter')?.value);
    this.checkBillTypeAllSelection(this.billForm.get('billTypeFilter')?.value);
  }

  showFilter(){
    this.filterDisplayRow = true;
    this.filterDisplay = true;
    this.cd.detectChanges();
    this.checkBdManagerAllSelection(this.billForm.get('bdManagerFilter')?.value);
    this.checkCostCenterAllSelection(this.billForm.get('costCenterFilter')?.value);
    this.checkBillTypeAllSelection(this.billForm.get('billTypeFilter')?.value);

  }

  backBtn(){
    sessionStorage.removeItem('urlRedirectedTo');
    sessionStorage.setItem('dashboard', 'true');
    window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
  } 

  homeClicked(event) {
    if(event.item?.icon == 'pi pi-home'){
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }
  }

  statusChange(event){
    console.log("Statussss ",event);
    this.billStatus = event;
    this.getGridDetails(event);
  }

  getGridDetails(status:any){
    let payload = {"status":status};
    this.billService.getOfflineBillDetails(payload).subscribe((res)=>{
      this.setGridDetails(res);
    });
  }

  setGridDetails(gridData){
    this.gridDataList = <[]>gridData;
    this.bdManagerList = new Set();
    this.costCenterList = new Set();
    this.billTypeList = new Set();

    this.gridDataList.forEach(item=>{
      if(item.bdManager){
        this.bdManagerList.add(item.bdManager);
        item.bdManagerListColumn = {name:item.bdManager};
      }

      if(item.costCenter){
        this.costCenterList.add(item.costCenter);
        item.costCenterListColumn = {name:item.costCenter};
      }

      if(item.billType){
        this.billTypeList.add(item.billType);
        item.billTypeListColumn = {name:item.billType};
      }

      item.billingDate = this.formatDate(item.billingDate);
    });

    this.bdManagerList = Array.from(this.bdManagerList).map(item => ({name: item}));
    this.costCenterList = Array.from(this.costCenterList).map(item => ({name: item}));
    this.billTypeList = Array.from(this.billTypeList).map(item => ({name: item}));
  }

  formatDate(billingDate: string){
    return billingDate.split("-")?.reverse()?.join("-");
  }

  openBillform(rowData:any){
    this.router.navigate(['/offline-bills/offlinebillform', rowData?.offlineBillId]);
  }

  applyFilterBdManager(event: any) {
    console.log("event", event);
    if (event.checked) {
      this.selectAllBdManager = event.checked;
      this.billForm.get('bdManagerFilter').reset();
      this.billForm.patchValue({ bdManagerFilter: this.bdManagerList });
      if (this.mainGrid1) {
        this.mainGrid1.filter(this.billForm.get('bdManagerFilter')?.value, 'bdManagerListColumn', 'in');
      }
    } else {
      this.selectAllBdManager = event.checked;
      this.billForm.get('bdManagerFilter').reset();
      this.billForm.patchValue({ bdManagerFilter: [] });
      this.oppBdManager.onChange.emit({ originalEvent: null, value: [], itemValue: null });
    }
  }

  checkBdManagerAllSelection(eValue) {
    console.log("eValue", eValue);
    console.log("this.bdManagerList", this.bdManagerList);

    if(eValue !== null || eValue !== undefined){
      this.selectAllBdManager = false;
    }else if (eValue.length == this.bdManagerList.length) {
      this.selectAllBdManager = true;
    } else {
      this.selectAllBdManager = false;
    }
  }

  applyFilterCostCenter(event: any) {
    console.log("event", event);
    if (event.checked) {
      this.selectAllCostCenter = event.checked;
      this.billForm.get('costCenterFilter').reset();
      this.billForm.patchValue({ costCenterFilter: this.costCenterList });
      if (this.mainGrid1) {
        this.mainGrid1.filter(this.billForm.get('costCenterFilter')?.value, 'costCenterListColumn', 'in');
      }
    } else {
      this.selectAllCostCenter = event.checked;
      this.billForm.get('costCenterFilter').reset();
      this.billForm.patchValue({ costCenterFilter: [] });
      this.oppCostCenter.onChange.emit({ originalEvent: null, value: [], itemValue: null });
    }
  }

  checkCostCenterAllSelection(eValue) {
    console.log("eValue", eValue);
    console.log("this.costCenterList", this.costCenterList);

    if(eValue !== null || eValue !== undefined){
      this.selectAllCostCenter = false;
    }else if (eValue.length == this.costCenterList.length) {
      this.selectAllCostCenter = true;
    } else {
      this.selectAllCostCenter = false;
    }
  }

  applyFilterBillType(event: any) {
    console.log("event", event);
    if (event.checked) {
      this.selectAllBillType = event.checked;
      this.billForm.get('billTypeFilter').reset();
      this.billForm.patchValue({ billTypeFilter: this.billTypeList });
      if (this.mainGrid1) {
        this.mainGrid1.filter(this.billForm.get('billTypeFilter')?.value, 'billTypeListColumn', 'in');
      }
    } else {
      this.selectAllBillType = event.checked;
      this.billForm.get('billTypeFilter').reset();
      this.billForm.patchValue({ billTypeFilter: [] });
      this.oppBillType.onChange.emit({ originalEvent: null, value: [], itemValue: null });
    }
  }

  checkBillTypeAllSelection(eValue) {
    console.log("eValue", eValue);
    console.log("this.billTypeList", this.billTypeList);

    if(eValue !== null || eValue !== undefined){
      this.selectAllBillType = false;
    }else if (eValue.length == this.billTypeList.length) {
      this.selectAllBillType = true;
    } else {
      this.selectAllBillType = false;
    }
  }
}
