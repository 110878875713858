import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'qc-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentsDetailsComponent implements OnInit {

  listItems;
  comboListItems;
  totalListItems;
  paymentForm : FormGroup;
  isApproveAllEnabled = true;
  primaryOpportunityName: any;
  secondaryOpportunityName: any;

  constructor(private fb : FormBuilder) { }
  
  @Input('form') set paymentsDetailsForm(formVal : FormGroup) {
    this.paymentForm = formVal;
    this.listItems = (<FormGroup>formVal.get('list')).controls;
    this.comboListItems = (<FormGroup>formVal.get('comboList')).controls;
    this.totalListItems = [...this.listItems,this.comboListItems];
    this.primaryOpportunityName = (<FormGroup>formVal.get('primaryOpportunityName')).value;
    this.secondaryOpportunityName = (<FormGroup>formVal.get('secondaryOpportunityName')).value;
  } 
  
  @Input('disabled') disabled;

  ngOnInit(): void {
    this.setApproveAll();
  }

  approveAllPayments() {
    this.listItems.forEach((item, index) => {
      item.patchValue({['paymentApprove' + index] : 'Approved'});
      item.patchValue({['chequeApprove' + index] : 'Approved'});
      item.patchValue({['depositApprove' + index] : 'Approved'});
      
      item.get('paymentComment').removeValidators([Validators.required, Validators.minLength(3)]);
      item.get('chequeAttachmentComment').removeValidators([Validators.required, Validators.minLength(3)]);
      item.get('depositSlipComment').removeValidators([Validators.required, Validators.minLength(3)]);
      
      
      item.get('paymentComment').updateValueAndValidity();
      item.get('chequeAttachmentComment').updateValueAndValidity();
      item.get('depositSlipComment').updateValueAndValidity();
      
    });

    this.comboListItems.forEach((item, index) => {
      item.patchValue({['paymentApprove0' + index] : 'Approved'});
      item.patchValue({['chequeApprove0' + index] : 'Approved'});
      item.patchValue({['depositApprove0' + index] : 'Approved'});
      
      item.get('paymentComment0').removeValidators([Validators.required, Validators.minLength(3)]);
      item.get('chequeAttachmentComment0').removeValidators([Validators.required, Validators.minLength(3)]);
      item.get('depositSlipComment0').removeValidators([Validators.required, Validators.minLength(3)]);
      
      
      item.get('paymentComment0').updateValueAndValidity();
      item.get('chequeAttachmentComment0').updateValueAndValidity();
      item.get('depositSlipComment0').updateValueAndValidity();
      
    });
  }

  setApproveAll() {
    // if(this.disabled) { // all fields disabled
      // check if atleast 1 field is enabled, i.e. isDisable must be false for same
      this.isApproveAllEnabled = (this.listItems.some(item => !item?.value?.isDisabled) || this.comboListItems.some(item => !item?.value?.isDisabled));
      this.listItems.forEach((item, index) => {
        // console.log("-->", item.get(`chequeApprove${index}`), "-->", item.get(`chequeApprove${index}`).value);
        if(item.get(`chequeApprove${index}`).value === "Rejected")
          item.get('chequeAttachmentComment').addValidators([Validators.required, Validators.minLength(3)]);
        if(item.get(`depositApprove${index}`).value === "Rejected")
          item.get('depositSlipComment').addValidators([Validators.required, Validators.minLength(3)]);
        if(item.get(`paymentApprove${index}`).value === "Rejected")
          item.get('paymentComment').addValidators([Validators.required, Validators.minLength(3)]);
      });
      this.comboListItems.forEach((item, index) => {
        // console.log("-->", item.get(`chequeApprove${index}`), "-->", item.get(`chequeApprove${index}`).value);
        if(item.get(`chequeApprove0${index}`).value === "Rejected")
          item.get('chequeAttachmentComment').addValidators([Validators.required, Validators.minLength(3)]);
        if(item.get(`depositApprove0${index}`).value === "Rejected")
          item.get('depositSlipComment').addValidators([Validators.required, Validators.minLength(3)]);
        if(item.get(`paymentApprove0${index}`).value === "Rejected")
          item.get('paymentComment').addValidators([Validators.required, Validators.minLength(3)]);
      })
    // }
  }
  onApprove(value, index, radioButtonkey, commentKey) {
    
    if(!value) {
      this.listItems[index].get(commentKey).addValidators([Validators.required, Validators.minLength(3)]);
    }
    else {
      this.listItems[index].get(commentKey).removeValidators([Validators.required, Validators.minLength(3)]);
    }
    this.listItems[index].get(commentKey).updateValueAndValidity();
  }

  trackChange(index, item) {
    return item?.value?.data?.id
  }

  onApproveCombo(value, index, radioButtonkey, commentKey) {
    
    if(!value) {
      this.comboListItems[index].get(commentKey).addValidators([Validators.required, Validators.minLength(3)]);
    }
    else {
      this.comboListItems[index].get(commentKey).removeValidators([Validators.required, Validators.minLength(3)]);
    }
    this.comboListItems[index].get(commentKey).updateValueAndValidity();
  }
}
