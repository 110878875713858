import { DatePipe } from '@angular/common';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MappeduserService } from '../mappeduser.service';
import { PagesService } from '../pages.service';

@Component({
  selector: 'backoffice-userhierarchyotheruser',
  templateUrl: './userhierarchyotheruser.component.html',
  styleUrls: ['./userhierarchyotheruser.component.scss']
})
export class UserhierarchyotheruserComponent implements OnInit {

  OtherUSerForm = this.fb.group({
    employeeId: [null as any,[Validators.required]],
    employeeName: [null as any,[Validators.required,Validators.minLength(1)]],
    dateOfJoining: [null as any,[Validators.required]],
    windowsId: [null as any,[Validators.required]],
    emailId: [null as any,[Validators.required]],
    mobileNumber: [null as any,[Validators.required,Validators.minLength(10)]]
  });

  loggedInEmployeeId:any;
  constructor(private encryptDecryptInterceptor: EncryptDecryptInterceptor, private renderer: Renderer2, private router: Router, protected activatedRoute: ActivatedRoute, private fb: FormBuilder, private pagesService: PagesService, private dialogService: DialogService, private messageService: MessageService, private mappeduserService: MappeduserService, private primengConfig: PrimeNGConfig, private datePipe: DatePipe,public ref: DynamicDialogRef) {}
  
  ngOnInit(): void {
    this.loggedInEmployeeId = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('userName'));
  }

  onCancel($event: FormGroup): void {
    setTimeout(() => {
      this.ref.close();
    }, 50);
  }

  saveOtherDepartmentUser(){
    let payload = {
      "employeeId": this.OtherUSerForm.value?.employeeId,
      "dateOfJoining": this.datePipe.transform(this.OtherUSerForm.value?.dateOfJoining, 'dd-MM-yyyy'),
      "fullName": this.OtherUSerForm.value?.employeeName,
      "windowId": this.OtherUSerForm.value?.windowsId,
      "email": this.OtherUSerForm.value?.emailId,
      "mobileNumber": this.OtherUSerForm.value?.mobileNumber,
      "loggedInUserWindowId": this.loggedInEmployeeId
    }

    console.log("Payload for other department save: ",payload);
    let message = {};

    this.pagesService.saveOtherDepartmentUsers(payload).subscribe((results: any) => {
      message = {
        showMsg:results?.Status,
        status:"success"
      }
      setTimeout(() => {
        this.ref.close(message);
      }, 500);
    },(err: any) => {
      let decodederr = this.encryptDecryptInterceptor.decrypt(err.error.data);
      const errorDecoded = JSON.parse(decodederr);
      if (errorDecoded.statusCodeValue == 400) {

        message = {
          showMsg:errorDecoded.body,
          status:"error"
        }
        setTimeout(() => {
          this.ref.close(message);
        }, 500);
      }
    });
  }

}
