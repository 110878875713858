import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { PagesService } from '../pages.service';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ViewMappedUserComponent } from '../ViewMappedUser/ViewMappedUser.component';
import { UsermappingComponent } from '../usermapping/usermapping.component';
import { MessageService } from 'primeng/api';
import { MappeduserService } from '../mappeduser.service';
import { DOMAIN_ROUTE } from '../../app.constants'
import { PrimeNGConfig } from "primeng/api";
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import * as FileSaver from 'file-saver';
import { UserhierarchyotheruserComponent } from '../userhierarchyotheruser/userhierarchyotheruser.component';

@Component({
  selector: 'jhi-userhierarchylist',
  templateUrl: './userhierarchylist.component.html',
  styleUrls: ['./userhierarchylist.component.scss'],
  providers: [DialogService, MessageService]
})
export class UserhierarchylistComponent implements OnInit {

  userhierarchylistForm = this.fb.group({
    tabview: [0],
    search1: [null as any, []],
  });

  pageloadvalues: any;
  ccprimebreadcrumbList = [
    {
      label: `User Hierarchy`, routerLink: ['/pages/userhierarchylist']
    },
  ]
  homeiconccprimebreadcrumb = { icon: 'pi pi-home' };

  htmlSrc: any;
  search1Response: any = {};
  search1List: any[] = [];
  search1LoadAtOnce: any[] = [];
  userhierarchyList: any[] = [];
  userhierarchycols: any[] = [];
  unmappeduserList: any[] = [];
  unmappedusercols: any[] = [];
  shadowPortfolioList: any[] = [];
  ExportList: boolean = false;
  refreshMappedUsers: string;
  forFrozen: boolean = false;
  forUnfrozen: boolean = false
  MappingFrozen: boolean = false;
  apiFail: boolean = false;
  mappedUserData: boolean = false;
  unmappedUserData: boolean = false;
  exportURL: any;
  createScope: boolean = false;
  readScope: boolean = false;
  updateScope: boolean = false;
  scopes: any;
  dynamicAggridDialogNAMERef: DynamicDialogRef = new DynamicDialogRef;
  dynamicAggridDialogACTIONRef: DynamicDialogRef = new DynamicDialogRef;
  dynamicTableDialogNAMERef: DynamicDialogRef = new DynamicDialogRef;
  dynamicTableDialogACTIONRef: DynamicDialogRef = new DynamicDialogRef;
  fetchedExportData: any;
  addOtherUserButton: boolean = false;
  userRole: any;

  constructor(private encryptDecryptInterceptor: EncryptDecryptInterceptor, private renderer: Renderer2, private router: Router, private _location: Location, protected activatedRoute: ActivatedRoute, private fb: FormBuilder, private pagesService: PagesService, private dialogService: DialogService, private messageService: MessageService, private mappeduserService: MappeduserService, private primengConfig: PrimeNGConfig, private datePipe: DatePipe) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.userhierarchylistForm.patchValue(JSON.parse(data));
    }
    this.getData();
  }

  ngOnInit(): void {
    this.renderer.removeClass(document.body, 'overflow-scroll');
    this.htmlSrc = '';
    this.getuserhierarchyList();
    this.getunmappeduserList();
    this.scopes = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('scopes'));
    this.createScope = this.scopes.indexOf('USER HIERERCHY_CREATE') != -1 ? true : false;
    this.readScope = this.scopes.indexOf('USER HIERERCHY_READ') != -1 ? true : false;
    this.updateScope = this.scopes.indexOf('USER HIERERCHY_UPDATE') != -1 ? true : false;
    this.userRole = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('role'));
  }

  handleChangetabview(e: any): void {
    this.searchClear = '';
    this.forFrozen = false;
    this.forUnfrozen = false
    this.userhierarchylistForm.patchValue({ "tabview": e.index });
    if (e.index == 1) {
      this.ExportList = true;
      this.MappingFrozen = false;
      if(this.userRole == 'ADMIN'){
        this.addOtherUserButton = true;
      }
      
    } else {
      this.addOtherUserButton = false;
      this.ExportList = false;
    }
    console.log('indexxx: ',e.index);
  }

  dataRecieve: boolean;
  private getData() {

    this.mappeduserService.change.subscribe(value => {
      let dataRecieve = value;
      if (dataRecieve == true) {
        this.RefreshUserHierarchyList();
        this.dataRecieve = false;
      }
      console.log(this.dataRecieve + " userHierarchylist refresh");
    });

    this.mappeduserService.messageChanged.subscribe(datamessage => {
      let dataRecieveMessage = datamessage;
      if(dataRecieveMessage){
        this.messageService.add({ severity: dataRecieveMessage.msgSeverity, detail: dataRecieveMessage.datamessage });
        setTimeout(() => {
          this.messageService.clear();
        }, 4000);
      }
    });
  }


  isMppedEmpty(event) {
    console.log("Mappedevent-----> " + event.filteredValue.length);
    if (event.filteredValue.length == 0) {
      this.forFrozen = true;
    }
    else {
      this.forFrozen = false;
    }
  }

  isUnMppedEmpty(event) {
    console.log("UnMappedevent-----> " + event.filteredValue.length);
    if (event.filteredValue.length == 0) {
      this.forUnfrozen = true;
    }
    else {
      this.forUnfrozen = false;
    }
  }

  itemClicked(event) {
    if (event.item?.icon == 'pi pi-home') {
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }
  }
  backFunction() {
    sessionStorage.removeItem('urlRedirectedTo');
    sessionStorage.setItem('dashboard', 'true');
    window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
  }
  getsearch1List(): void {
    this.pagesService.getsearch1Datauserhierarchylist().subscribe((results: any) => {
      const responseData = results.userSearchList;
      this.search1List = [];
      if (responseData && responseData.length > 0) {
        this.search1LoadAtOnce = responseData;
      }
    }, () => this.search1List = []);
  }
  getuserhierarchyList(): void {
    this.mappedUserData = true;
    this.pagesService.getuserhierarchyDatauserhierarchylist().subscribe(results => {
      const responseData = results.employeeDetails;
      if (responseData && responseData.length > 0) {
        this.userhierarchyList = responseData;
        console.log('user hierarchy list')

        this.userhierarchyList.forEach(element => {
          let shadowPortfolioList: any[] = [];
          let opsSpocList: any[] = [];
          if (element.shadowPortfolioUsersList != null) {

            element.shadowPortfolioUsersList.forEach(element1 => {
              shadowPortfolioList.push(element1.shadowPortfolioEmployeeNameAndDesignation);
            })
          }
          if (element.opSpocUsersList != null) {
            element.opSpocUsersList.forEach(element => {
              opsSpocList.push(element.employeeName);
            })
          }
          element.shadowPortfolioEmployeeNameAndDesignation = shadowPortfolioList;
          element.opsSpocName = opsSpocList;
        })
        console.log(this.userhierarchyList)
        this.makeRowsSameHeight();
      }
      this.mappedUserData = false;
      this.apiFail = false;
    }, (_error) => {
      this.mappedUserData = false;
      this.apiFail = true;
    });
  }

  clearTable(table1, table2) {
    this.searchClear = '';
    this.userhierarchylistForm.value.search1 = '';
    table1.filters.global.value = '';
    table2.filters.global.value = '';
    table1.clear();
    table2.clear();
  }

  searchClear: any;
  onNAMEdialog(rowData: any, index: any): void {
    this.searchClear = '';
    this.dynamicTableDialogNAMERef = this.dialogService.open(ViewMappedUserComponent, {
      contentStyle: { "max-height": "500px", "overflow": "hidden" },
      header: 'User Hierarchy',
      data: rowData,
      width: '70%'
    });
    this.dynamicTableDialogNAMERef.onClose.subscribe((response: any) => {

    });
  }
  getunmappeduserList(): void {
    this.unmappedUserData = true;
    this.pagesService.getunmappeduserDatauserhierarchylist().subscribe(results => {
      const responseData = results.employeeDetails;
      if (responseData && responseData.length > 0) {
        this.unmappeduserList = responseData;
      }
      this.unmappedUserData = false;
      this.apiFail = false;
    }, (_error) => {
      this.unmappedUserData = false;
      this.apiFail = true;
    });
  }

  onACTIONdialog(rowData: any, index: any): void {
    if (!this.createScope) {
      return;
    }
    this.searchClear = '';
    this.dynamicTableDialogACTIONRef = this.dialogService.open(UsermappingComponent, {
      contentStyle: { "max-height": "500px", "overflow": "auto", 'padding-bottom': '180px' },
      header: 'Add User Hierarchy',
      closable: true,
      data: rowData,
      // width: '70%',
      styleClass: 'reponsivePopup'
    });
    this.dynamicTableDialogACTIONRef.onClose.subscribe((response: any) => {
    });
  }

  RefreshUserHierarchyList() {
    this.getsearch1List();
    this.getuserhierarchyList();
    this.getunmappeduserList();
  }

  makeRowsSameHeight() {
    setTimeout(() => {
      const len = document.getElementsByClassName('p-datatable-scrollable-wrapper').length;
      if (len > 0) {
        let wrapper = document.getElementsByClassName('p-datatable-scrollable-wrapper');
        for (let i = 0; i < wrapper.length; i++) {
          let w = wrapper.item(i) as HTMLElement;
          let frozen_rows: any = w.querySelectorAll('.p-datatable-frozen-view tr');
          let unfrozen_rows: any = w.querySelectorAll('.p-datatable-unfrozen-view tr');
          for (let j = 0; j < frozen_rows.length; j++) {
            if (frozen_rows[j].clientHeight < unfrozen_rows[j].clientHeight) {
              let height = unfrozen_rows[j].clientHeight + "px";
              for (let k = 0; k < frozen_rows.length; k++) {
                frozen_rows[k].style.height = height;
                unfrozen_rows[k].style.height = height;
              }
            } else if (frozen_rows[j].clientHeight > unfrozen_rows[j].clientHeight) {
              unfrozen_rows[j].style.height = frozen_rows[j].clientHeight + "px";
            }
          }
        }
      }
    });
  }

  exportExcel() {
    let fileName;
    let date = new Date();
    let formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    let formattedTime = this.datePipe.transform(date, 'HH_mm_ss');
    if (this.ExportList == true) {
      fileName = `UnmappedUsers_${formattedDate}_${formattedTime}.xlsx`;
    } else if (this.ExportList == false) {
      fileName = `MappedUsers_${formattedDate}_${formattedTime}.xlsx`;
    } else {
      fileName = '';
    }
    this.pagesService.excelExport(this.ExportList).subscribe((res) => {
      /** src = `data:application/vnd.ms-excel;base64,${res.base64}`;
      const link = document.createElement('a');
      link.href = src;
      link.download = fileName;
      link.click(); **/
      const byteCharacters = atob(res.Base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "text/xlsx" });
      FileSaver.saveAs(blob, fileName);
    });
  }

  onAddOtherEmployeedialog(): void {
    
    this.dynamicTableDialogNAMERef = this.dialogService.open(UserhierarchyotheruserComponent, {
      contentStyle: { "max-height": "550px", "overflow": "hidden" },
      header: 'Add Other Department User Details',
      data: null,
      width: '70%'
    });
    this.dynamicTableDialogNAMERef.onClose.subscribe((response: any) => {
      if(response?.status == "success"){
        this.messageService.add({ severity: 'success', detail: response?.showMsg });
      } 
      if(response?.status == "error"){
        this.messageService.add({ severity: 'error', detail: response?.showMsg });
      }
      setTimeout(() => {
        this.messageService.clear();
      }, 3000);
    });
  }
}


