import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AccountService } from 'app/core/auth/account.service';
import { BACKOFFICE_SERVER_API_URL, SERVER_API_URL, SERVER_API_INTEGRATION_URL, REPORTING_SERVER_API_URL_Allocation, REPORTING_SERVER_API_URL, REPORTING_SERVER_API_URL_Entity, REPORTING_SERVER_API_URL_CRMIntegaration, USERMANAGEMENT_SERVER_API_URL, NEXERA_SERVER_API_URL, FILEUPLOAD_URL, NEXERA_SERVER_API_URL_OPPORTUNITY, SFINITIATION_URL, SFREALIZATION_URL, SFPUSHGRID_URL, MASTER_SERVICE_URL } from 'app/app.constants';
import * as CryptoJS from 'crypto-js';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { delay, take, retryWhen, switchMap, debounceTime } from 'rxjs/operators';
import { BYPASS_LOADER } from 'app/blocks/interceptor/request-loader.interceptor';
@Injectable({ providedIn: 'root' })
export class APIService {
  
  key: any;
  iv: any;
  private userId: any;
  errorMessageOnSaveService: string;
  
  public empId = this.encDec.decrypt(localStorage.getItem('empid'));
  public empName = this.encDec.decrypt(localStorage.getItem('empName'));
  public scopes = this.encDec.decrypt(localStorage.getItem('scopes'));

  constructor(private accountService: AccountService, public http: HttpClient, public encDec: EncryptDecryptInterceptor) {
    this.key = CryptoJS.enc.Latin1.parse('Y3Jpc2lsQDIwMjE=');
    this.iv = CryptoJS.enc.Latin1.parse('fedcba9876543210');
    this.accountService.identity().subscribe(account => {
      if (account) {
        this.userId = account.login;
      }
    });
  }

  downloadFile(item: any, urlSafe) {
    const link = document.createElement('a');
    link.href = urlSafe;
    link.download = item?.fileName;
    link.click();
  }

  // get master data
  getMasterData(agreementId) {
    let encryptAgrId = this.encDec.encrypt(agreementId);
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/irfqc/actions?agreementId=${encryptAgrId}`)
  }

  // get nexera related opportunity details
  getQCDetailsOpportunity(opprId: any, includeFields?: any) {
    return this.http.get(NEXERA_SERVER_API_URL + `api/v1/case/details`, { params: { opportunityId: opprId, includeFields : includeFields || "" }});
  }

  // get backoffice related opportunity details
  getQCDetailsAgreement(agreementId: any) {
    let payload = this.encDec.encryptWith(agreementId)
    
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/mandateDetails/findByAgreementId/${payload}`);
  }

  // get client classification data
  getClientClassificationData(companyCode) {
    let encCompanyCode = this.encDec.encryptWith(companyCode);
    return this.http.get(NEXERA_SERVER_API_URL + `api/v1/reporting/findClientTypeByCompanyCode?companyCode=${encCompanyCode}`);
  }

  // get client classification data
  getAllClientType() {
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/irfinitiation/getAllclientType`);
  }
  

  // get triParty entity details
  getEntityDetails(data: any, userId: any): Observable<any> {
    data = this.encDec.encryptWith(data);
    const headersList = new HttpHeaders();
    return this.http.get(SERVER_API_URL + `api/v1/entity/findById/${data}`, {
      params: { userId: userId,visibleToOwner: 'Y' },
      headers: headersList,
    });
  }

  // submit QC data
  postQCData(data) {
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/irfqc/submit`, data);
  }

  // get limit data
  getLimitData(companyCode, masterService, subService, ratableUnit, agreemntId, instrumentId = 0) {
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/ROWIntegration/limits/details`, { params: { companyCode, masterService, subService, ratableUnit, agreemntId, instrumentId }});
  }

  // get attachment listing
  getAttachmentList(opprId, entityId) {
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/mandateDetails/findAttachmentData/${this.encDec.encryptWith(opprId)}/${this.encDec.encryptWith(entityId)}`)
  }

  getRowFeedBack(agreementId: string,companyCode:String): Observable<any> {
    const encrypteagreementId= this.encDec.encryptWith(agreementId);
    const encryptecompanyCode= this.encDec.encryptWith(companyCode);
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/ROWIntegration/mandate/details?agreementId=${encrypteagreementId}&companyCode=${encryptecompanyCode}`)
  }

  mandateValidity(): Observable<any>{
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/getSingleMasterData/MstSFMandateValidity');
  }

  pushDataToRow(payload:any): Observable<any>{
    return this.http.post(BACKOFFICE_SERVER_API_URL+`api/v1/irfqc/resubmitMandateDataToRow`,payload);
  }
}


