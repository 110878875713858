<div class="entityDetailsModal">
    <p-toast class="custom-toast"></p-toast>
    <div class="p-formgrid p-grid">
        <div class="p-col-12 p-px-0 p-py-0">
            <div class="ui-fluid p-formgrid p-grid">
                <div class="p-col-12 p-px-0 p-py-0">
                    <div class="ui-fluid p-formgrid">
                        <div class="p-col-12 p-px-0 p-py-0">
                            <div class="ui-fluid p-formgrid p-grid p-mb-2 entityDetailsHeader">
                                <div class="p-col-12 p-md-3 p-px-0 p-text-left">
                                    <div class="p-d-flex p-ai-center"> 
                                        <label class="p-mr-2 label_light p-mb-0">Company Code:</label>
                                        <h1 class="p-medium display-4 p-mb-0">{{initiationData?.companyCode}}</h1>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-3 p-px-0 p-text-left">
                                    <div class="p-d-flex p-ai-center"> 
                                        <label class="p-mr-2 label_light p-mb-0">Bill Id:</label>
                                        <h1 class="p-medium display-4 p-mb-0">{{initiationData?.billId}}</h1>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-3 p-px-0 p-text-left">
                                    <div class="p-d-flex p-ai-start "> 
                                        <label class="p-mr-2 label_light p-mb-0">Current Status:</label>
                                        <h1 class="p-medium display-4 p-mb-0">{{initiationData?.currentStatusLong || '-'}} |
                                            {{initiationData?.currentStatusShort || '-'}}</h1>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-3 p-px-0 p-text-left">
                                    <div class="p-d-flex p-ai-center"> 
                                        <label class="p-mr-2 label_light p-mb-0">Reference No:</label>
                                        <h1 class="p-medium display-4 p-mb-0">{{otaDetails?.referenceNo || '-'}}</h1>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-3 p-px-0 p-text-left">
                                    <div class="p-d-flex p-ai-center"> 
                                        <label class="p-mr-2 label_light p-mb-0">RPT In CRM:</label>
                                        <h1 class="p-medium display-4 p-mb-0">{{rptInCrm || '-'}}</h1>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-3 p-px-0 p-text-left">
                                    <div class="p-d-flex p-ai-center"> 
                                        <label class="p-mr-2 label_light p-mb-0">RPT In CC:</label>
                                        <h1 class="p-medium display-4 p-mb-0">{{rptInCC || '-'}}</h1>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-3 p-px-0 p-text-left">
                                    <div class="p-d-flex p-ai-center"> 
                                        <label class="p-mr-2 label_light p-mb-0">RPT Status Overall:</label>
                                        <h1 class="p-medium display-4 p-mb-0">{{rptoverall}}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="ui-fluid p-formgrid p-mb-5">
                        <div class="p-col-12 verticalTabContainer p-p-0">
                            <p-tabView [(activeIndex)]="activeIndex" (onChange)="tabChange($event)"
                                styleClass="p-tabview-vertical">
                                <p-tabPanel>
                                    <ng-template pTemplate="header">
                                        <span>Portfolio</span>
                                    </ng-template>
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div class="p-col-12">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <!-- <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                    <div class="p-fluid p-field">
                                                        <label>
                                                            GST Contact Name
                                                        </label>
                                                        <input type="text" pInputText placeholder="Enter " disabled
                                                            [(ngModel)]="gstContact" class="p-inputtext" />
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label>
                                                            GST Type
                                                        </label>
                                                        <input type="text" pInputText placeholder="Enter " disabled
                                                            [(ngModel)]="gstType" class="p-inputtext" />
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label>
                                                            GST Number
                                                        </label>
                                                        <input type="text" pInputText placeholder="Enter " disabled
                                                            [(ngModel)]="gstNumber" class="p-inputtext" />
                                                    </div>
                                                </div> -->
                                                <div class="p-col-12">
                                                    <h1 class="p-medium display-3">Portfolio Details</h1>
                                                </div>
                                                <div class="p-col-4 p-mb-3">
                                                    <label for="bdCase">
                                                        BD For Case
                                                    </label>
                                                    <p-dropdown placeholder="Select" [options]="nexeraUsersList"
                                                        [autoDisplayFirst]="false" (onChange)="bdForCaseClicked($event)"
                                                        resetFilterOnHide="true" optionLabel="name" optionValue="id"
                                                        [disabled]="true">
                                                    </p-dropdown>
                                                </div>
                                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 left">
                                                    <p>Mapping</p>
                                                    <p class="bdCase">{{bdHierarchy | formatBdHierarchy}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-tabPanel>
                                <p-tabPanel>
                                    <ng-template pTemplate="header"><span
                                            [ngClass]="{'errorTab': errorContactDetails}">Contact Details</span>
                                    </ng-template>
                                    <form [formGroup]="contactDetailsform">
                                        <div class="p-fluid p-formgrid p-grid">
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div>
                                                            <h1 class="p-medium display-3">Basic Info</h1>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-d-flex p-jc-end">
                                                        <div class="btnwrap">
                                                            <button pButton style="height: 28px"
                                                                class="p-button-secondary p-button-sm" type="submit"
                                                                label="Get CRM Data" (click)="getCrmData('initiation')"
                                                                [disabled]="initiationReadScope || data?.isNexeraOpportunity.toLowerCase() != 'non-nexera' || (initiationData?.crmContactId)">
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>Salutation</label>
                                                            <p-dropdown placeholder="Select"
                                                                formControlName="salutation" [options]="salutationList"
                                                                resetFilterOnHide="true" optionLabel="name"
                                                                optionValue="name" [autoDisplayFirst]="false">
                                                            </p-dropdown>
                                                            <ng-container
                                                                *ngIf="initiationCrmContact?.salutation; else salutation">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.salutation?.toLowerCase() == initiationCrmContact?.salutation?.toLowerCase()}">
                                                                    {{initiationCrmContact?.salutation}}
                                                                </div>
                                                            </ng-container>
                                                            <ng-template #salutation>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>First Name</label>
                                                            <input type="text" pInputText placeholder="Enter First Name"
                                                                formControlName="firstName" class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="initiationCrmContact?.firstName; else firstname">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.firstName?.toLowerCase() == initiationCrmContact?.firstName?.toLowerCase()}">
                                                                    {{initiationCrmContact?.firstName}}</div>
                                                            </ng-container>
                                                            <ng-template #firstname>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>Last Name</label>
                                                            <input type="text" pInputText placeholder="Enter Last Name"
                                                                formControlName="lastName" class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="initiationCrmContact?.lastName; else lastname">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.lastName?.toLowerCase() == initiationCrmContact?.lastName?.toLowerCase()}">
                                                                    {{initiationCrmContact?.lastName}}</div>
                                                            </ng-container>
                                                            <ng-template #lastname>-</ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>Designation</label>
                                                            <p-dropdown name="reasonselect" placeholder="Select"
                                                                [options]="designationList" resetFilterOnHide="true"
                                                                optionLabel="name" optionValue="name"
                                                                [autoDisplayFirst]="false"
                                                                formControlName="designation">
                                                            </p-dropdown>
                                                            <ng-container
                                                                *ngIf="initiationCrmContact?.designation; else designation">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.designation?.toLowerCase() == initiationCrmContact?.designation?.toLowerCase()}">
                                                                    {{initiationCrmContact?.designation}}</div>
                                                            </ng-container>
                                                            <ng-template #designation>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>Mobile No</label>
                                                            <input type="text" pInputText placeholder="Enter Mobile No"
                                                                formControlName="mobileNo" class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="initiationCrmContact?.mobileNum; else mobile">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.mobileNo == initiationCrmContact?.mobileNum}">
                                                                    {{initiationCrmContact?.mobileNum}}</div>
                                                            </ng-container>
                                                            <ng-template #mobile>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>Landline No</label>
                                                            <input type="text" pInputText placeholder="Enter Landline No"
                                                                formControlName="landlineNo" class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="initiationCrmContact?.phoneNum; else landline">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.landlineNo == initiationCrmContact?.phoneNum}">
                                                                    {{initiationCrmContact?.phoneNum}}</div>
                                                            </ng-container>
                                                            <ng-template #landline>-</ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>Email 1</label>
                                                            <input type="text" pInputText placeholder="Enter Email 1"
                                                                formControlName="email1" class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="initiationCrmContact?.emails && initiationCrmContact?.emails.length && initiationCrmContact.emails[0]; else email_1">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform.get('email1').value == initiationCrmContact.emails[0]}">
                                                                    {{initiationCrmContact.emails[0]}}</div>
                                                            </ng-container>
                                                            <ng-template #email_1>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>Email 2</label>
                                                            <input type="text" pInputText placeholder="Enter Email 2"
                                                                formControlName="email2" class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="initiationCrmContact?.emails && initiationCrmContact?.emails.length && initiationCrmContact.emails[1]; else email_2">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform.get('email2').value == initiationCrmContact.emails[1]}">
                                                                    {{initiationCrmContact.emails[1]}}</div>
                                                            </ng-container>
                                                            <ng-template #email_2>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>Email 3</label>
                                                            <input type="text" pInputText placeholder="Enter Email 3"
                                                                formControlName="email3" class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="initiationCrmContact?.emails && initiationCrmContact?.emails.length && initiationCrmContact.emails[2]; else email_3">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform.get('email3').value == initiationCrmContact.emails[2]}">
                                                                    {{initiationCrmContact.emails[2]}}</div>
                                                            </ng-container>
                                                            <ng-template #email_3>-</ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-mt-1">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                                        <h1 class="p-medium display-3">GST &amp; PAN Details</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>GST Type</label>
                                                            <p-dropdown name="reasonselect" placeholder="Select"
                                                                [options]="gstTypeList" resetFilterOnHide="true"
                                                                optionLabel="property" optionValue="property"
                                                                [autoDisplayFirst]="false" formControlName="gstType">
                                                            </p-dropdown>
                                                            <ng-container
                                                                *ngIf="initiationCrmContact?.gstType; else gsttype">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.gstType?.toLowerCase() == initiationCrmContact?.gstType?.toLowerCase()}">
                                                                    {{initiationCrmContact?.gstType}}</div>
                                                            </ng-container>
                                                            <ng-template #gsttype>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>GST No</label>
                                                            <input type="text" pInputText placeholder="Enter GST No"
                                                                formControlName="gstNo" class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="initiationCrmContact?.gstNumber; else gstno">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.gstNo?.toLowerCase() == initiationCrmContact?.gstNumber?.toLowerCase()}">
                                                                    {{initiationCrmContact?.gstNumber}}</div>
                                                            </ng-container>
                                                            <ng-template #gstno>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label> PAN Number </label>
                                                            <input type="text" pInputText placeholder="Enter PAN Number"
                                                                formControlName="panNo" class="p-inputtext" />
                                                            <ng-container *ngIf="initiationCrmContact?.pan; else panno">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.panNo?.toLowerCase() == initiationCrmContact?.pan?.toLowerCase()}">
                                                                    {{initiationCrmContact?.pan}}</div>
                                                            </ng-container>
                                                            <ng-template #panno>-</ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-mt-1">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                                        <h1 class="p-medium display-3">Address Details</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>Pin Code</label>
                                                            <input type="text" pInputText placeholder="Enter Pin Code"
                                                                formControlName="pincode" class="p-inputtext" />
                                                            <ng-container *ngIf="initiationCrmContact?.pin; else pin">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.pincode == initiationCrmContact?.pin}">
                                                                    {{initiationCrmContact?.pin}}</div>
                                                            </ng-container>
                                                            <ng-template #pin>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>City</label>
                                                            <input type="text" pInputText placeholder="Enter City"
                                                                formControlName="city" class="p-inputtext" />
                                                            <ng-container *ngIf="initiationCrmContact?.city; else city">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.city?.toLowerCase() == initiationCrmContact?.city?.toLowerCase()}">
                                                                    {{initiationCrmContact?.city}}</div>
                                                            </ng-container>
                                                            <ng-template #city>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>State</label>
                                                            <input type="text" pInputText placeholder="Enter State"
                                                                formControlName="state" class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="initiationCrmContact?.state; else state">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.state?.toLowerCase() == initiationCrmContact?.state?.toLowerCase()}">
                                                                    {{initiationCrmContact?.state}}</div>
                                                            </ng-container>
                                                            <ng-template #state>-</ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>Address Line 1</label>
                                                            <input type="text" pInputText placeholder="Enter Address Line 1"
                                                                formControlName="address1" class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="initiationCrmContact?.address1; else address1">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.address1?.toLowerCase() == initiationCrmContact?.address1?.toLowerCase()}">
                                                                    {{initiationCrmContact?.address1}}</div>
                                                            </ng-container>
                                                            <ng-template #address1>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>Address Line 2</label>
                                                            <input type="text" pInputText placeholder="Enter Address Line 2"
                                                                formControlName="address2" class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="initiationCrmContact?.address2; else address2">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.address2?.toLowerCase() == initiationCrmContact?.address2?.toLowerCase()}">
                                                                    {{initiationCrmContact?.address2}}</div>
                                                            </ng-container>
                                                            <ng-template #address2>-</ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field">
                                                            <label>Address Line 3</label>
                                                            <input type="text" pInputText placeholder="Enter Address Line 3"
                                                                formControlName="address3" class="p-inputtext" />
                                                            <ng-container
                                                                *ngIf="initiationCrmContact?.address3; else address3">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.address3?.toLowerCase() == initiationCrmContact?.address3?.toLowerCase()}">
                                                                    {{initiationCrmContact?.address3}}</div>
                                                            </ng-container>
                                                            <ng-template #address3>-</ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>Address Line 4</label>
                                                            <input type="text" pInputText placeholder="Enter Address Line 4"
                                                                formControlName="address4" class="p-inputtext6" />
                                                            <ng-container
                                                                *ngIf="initiationCrmContact?.address4; else address4">
                                                                <div class="p-d-flex p-jc-start invalid-feedback"
                                                                    [ngClass]="{'black-color': contactDetailsform?.value?.address4?.toLowerCase() == initiationCrmContact?.address4?.toLowerCase()}">
                                                                    {{initiationCrmContact?.address4}}</div>
                                                            </ng-container>
                                                            <ng-template #address4>-</ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </p-tabPanel>
                                <p-tabPanel [disabled]="disablePaymentTab && !disabledPaymentMsg">
                                    <ng-template pTemplate="header"><span
                                            [ngClass]="{'errorTab': errorPaymentInvoice}">Payment & Invoice</span>
                                    </ng-template>
                                    <div class="payment-tab" #paymentTab>

                                        <p-toast key="revisionAckMsg" class="custom-toast"></p-toast>


                                        <div class="p-col-12 p-pr-0">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-pr-2">
                                                    <div class="btnwrap p-d-flex p-jc-end">
                                                        <span *ngIf="invoiceDocDetails"
                                                            class="attachmentName p-px-2 p-d-flex p-ai-center"
                                                            (click)="showInvoiceModal(false)"><a>New Invoice</a></span>
                                                        <span *ngIf="invoiceDocDetails"
                                                            class="attachmentName p-px-2 p-d-flex p-ai-center"
                                                            (click)="showInvoiceModal(true)"><a>Old Invoices</a></span>
                                                        <span *ngIf="pcnDocDetails" class="attachmentName p-px-2 p-d-flex p-ai-center"
                                                                (click)="showCreditNote(true)"><a>Credit Note</a></span> 
                                                                

                                                        <button pButton style="height: 28px"
                                                            class="p-button-secondary p-button-sm" type="submit"
                                                            label="Add Payment"
                                                            [disabled]="disableAddBtn || initiationReadScope"
                                                            (click)="paymentPopup()">
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <form [formGroup]="invoiceDetailsform">
                                            <div class="p-fluid p-formgrid p-grid">
                                                <div class="p-col-12 p-pr-0">
                                                    <div class="ui-fluid p-formgrid p-grid">
                                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 ">
                                                            <div class="">
                                                                <h1 class="p-medium display-3">Invoice Details</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-pr-0">
                                                    <div class="ui-fluid p-formgrid p-grid">
                                                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                            <div class="p-fluid p-field">
                                                                <label>Financial Year</label>
                                                                <input type="text" pInputText placeholder="Enter Financial Year" disabled
                                                                    formControlName="financialYear"
                                                                    class="p-inputtext" />
                                                            </div>
                                                        </div>
                                                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                            <div class="p-fluid p-field">
                                                                <label>Invoice Number</label>
                                                                <input type="text" pInputText placeholder="Enter Invoice Number" disabled
                                                                    formControlName="invoiceNumber"
                                                                    class="p-inputtext" />
                                                            </div>
                                                        </div>
                                                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                            <div class="p-fluid p-field">
                                                                <label>Invoice Date</label>
                                                                <input type="text" pInputText placeholder="Enter Invoice Date" disabled
                                                                    formControlName="invoiceDate" class="p-inputtext" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-pr-0">
                                                    <div class="ui-fluid p-formgrid p-grid">
                                                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                            <div class="p-fluid p-field">
                                                                <label>Original Bill Amount</label>
                                                                <input type="text" pInputText placeholder="Enter Original Bill Amount" disabled
                                                                    formControlName="originalBillAmt"
                                                                    class="p-inputtext" />
                                                            </div>
                                                        </div>
                                                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                            <div class="p-fluid p-field">
                                                                <label>SF Actual Expected</label>
                                                                <input type="text" pInputText placeholder="Enter SF Actual Expected"
                                                                    (keypress)="numberOnly($event)"
                                                                    formControlName="sfActualExpected"
                                                                    (input)="sfActualEntered($event)"
                                                                    class="p-inputtext"
                                                                    [readonly]="disableActualExpected || initiationReadScope" />
                                                                <span style="color: #424242;"
                                                                    *ngIf="sfShowMessage">{{sFDiscountMessage}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                            <div class="p-fluid p-field">
                                                                <label>SF Discount / Premium Amount</label>
                                                                <p-inputNumber id="sfDiscount1_id"
                                                                    name="sfDiscount1_name"
                                                                    formControlName="sfDiscountAmount" class="  "
                                                                    buttonLayout="stacked" mode="decimal"
                                                                    [useGrouping]="false" [disabled]="true"
                                                                    placeholder="Enter SF Discount / Premium Amount"></p-inputNumber>

                                                            </div>
                                                        </div>
                                                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                            <div class=" p-fluid   p-field    ">
                                                                <label>
                                                                    SF Discount / Premium %
                                                                </label>
                                                                <p-inputNumber id="sfDiscountPercentageID"
                                                                    name="sfDiscountPercentage_name"
                                                                    formControlName="sfDiscountPercentage" class="  "
                                                                    buttonLayout="stacked" mode="decimal"
                                                                    [useGrouping]="false" [disabled]="true"
                                                                    placeholder="Enter SF Discount / Premium %" [maxFractionDigits]="2">
                                                                </p-inputNumber>
                                                            </div>

                                                        </div>
                                                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4  left">
                                                            <div class=" p-fluid   p-field    ">
                                                                <label>
                                                                    SF Discount Reason
                                                                    <span class='invalid-feedback-astrik'
                                                                        *ngIf="!disableSfDiscountReason">*</span>
                                                                </label>
                                                                <p-dropdown id="sfDiscountReason1id"
                                                                    name="sfDiscountReason1"
                                                                    [panelStyle]="{ width: '250px' }" placeholder="Select"
                                                                    [options]="sfDiscountReasonList"
                                                                    [autoDisplayFirst]="false" appendTo="body"
                                                                    formControlName="sfDiscountReason"
                                                                    [showClear]="true" optionLabel="name"
                                                                    optionValue="name"
                                                                    [disabled]="disableSfDiscountReason"
                                                                    [ngClass]="{'error': (!disableSfDiscountReason && (!invoiceDetailsform.value.sfDiscountReason) && (invoiceDetailsform.get('sfDiscountReason').dirty || invoiceDetailsform.get('sfDiscountReason').touched))}">
                                                                </p-dropdown>
                                                                <div class="invalid-feedback"
                                                                    *ngIf=" (!disableSfDiscountReason && (!invoiceDetailsform.value.sfDiscountReason) && (invoiceDetailsform.get('sfDiscountReason').dirty || invoiceDetailsform.get('sfDiscountReason').touched))">
                                                                    SF Discount Reason is required.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                            <div class="p-fluid p-field">
                                                                <label>Collected Pending Realization</label>
                                                                <input type="text" pInputText placeholder="Enter Collected Pending Realization" disabled
                                                                    formControlName="collectedPendingRealz"
                                                                    class="p-inputtext" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-pr-0">
                                                    <div class="ui-fluid p-formgrid p-grid">
                                                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                            <div class="p-fluid p-field">
                                                                <label>Realized</label>
                                                                <input type="text" pInputText placeholder="Enter Realized" disabled
                                                                    formControlName="realized" class="p-inputtext" />
                                                            </div>
                                                        </div>
                                                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                            <div class="p-fluid p-field">
                                                                <label>Rejected / Bounced</label>
                                                                <input type="text" pInputText placeholder="Enter Rejected / Bounced" disabled
                                                                    formControlName="rejectedBounced"
                                                                    class="p-inputtext" />
                                                            </div>
                                                        </div>
                                                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                            <div class="p-fluid p-field">
                                                                <label>Pending Collected</label>
                                                                <input type="text" pInputText placeholder="Enter Pending Collected" disabled
                                                                    formControlName="pendingCollected"
                                                                    class="p-inputtext" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div class="p-col-12 p-pr-0">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                                    <div>
                                                        <h1 class="p-medium display-3">Payment Details</h1>
                                                        <p class="invalid-feedback" *ngIf="payDetailsMissing">Few
                                                            Details are missing in payments.</p>
                                                        <div>
                                                            <label>Balance Amount: </label>
                                                            <span *ngIf="balanceAmount > 100"
                                                                class="text_brand_color p-mr-2"
                                                                style="font-weight:bold;">&#8377;
                                                                {{balanceAmount}}</span>
                                                            <span *ngIf="balanceAmount <= 100"
                                                                class="text_brand_color p-mr-2"
                                                                style="font-weight:bold;">&#8377; 0</span>

                                                            <label>CN Amount: </label>
                                                            <span *ngIf="partialCnAmount"
                                                                class="text_brand_color p-mr-2"
                                                                style="font-weight:bold;">&#8377;
                                                                {{partialCnAmount}}</span>
                                                            <span *ngIf="!partialCnAmount"
                                                                class="text_brand_color p-mr-2"
                                                                style="font-weight:bold;">&#8377; 0</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-pr-0">
                                            <div class="p-col-12 paymentAccordion p-mt-2">
                                                <p-accordion id="p_accordion" (onOpen)="tabOpened($event)" collapseIcon="pi pi-minus">
                                                    <p-accordionTab class="p-accordiantab"
                                                        *ngFor="let singleForm of payments.controls; let i = index"
                                                        header="Payment Mode {{i+1}}">
                                                        <form [formGroup]="singleForm" autocomplete="off">
                                                            <div class="p-fluid p-grid">
                                                                <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex">
                                                                    <div class="p-col-4 p-d-flex p-flex-column">
                                                                        <div class="p-fluid p-field">
                                                                            <label>Realization Status</label>
                                                                            <input type="text" pInputText placeholder="Enter Realization Status"
                                                                                disabled formControlName="paymentStatus"
                                                                                class="p-inputtext"
                                                                                [ngClass]="{green: singleForm.value.paymentStatus == 'Realized', red: singleForm.value.paymentStatus == 'Rejected' || singleForm.value.paymentStatus == 'Bounced'}" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-d-flex p-flex-column">
                                                                        <div class="p-fluid p-field">
                                                                            <label>Bank Clearing Date</label>
                                                                            <input type="text" pInputText placeholder="Enter Bank Clearing Date"
                                                                                disabled
                                                                                formControlName="bankClearingDate"
                                                                                class="p-inputtext" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-d-flex p-flex-column">
                                                                        <div class="p-fluid p-field">
                                                                            <label>Payment Mode <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>
                                                                            <p-dropdown id="paymentMode_{{i}}"
                                                                                name="paymentMode_{{i}}"
                                                                                placeholder="Select"
                                                                                [options]="paymentModeList"
                                                                                [disabled]="true"
                                                                                [autoDisplayFirst]="false"
                                                                                formControlName="paymentMode"
                                                                                optionLabel="property"
                                                                                optionValue="property"
                                                                                [ngClass]="{'error': (singleForm.value.paymentMode == null || singleForm.value.paymentMode == '') && (singleForm.get('paymentMode').dirty || singleForm.get('paymentMode').touched)}">
                                                                            </p-dropdown>
                                                                            <div class="invalid-feedback"
                                                                                *ngIf="(singleForm.value.paymentMode == null || singleForm.value.paymentMode == '') && (singleForm.get('paymentMode').dirty || singleForm.get('paymentMode').touched)">
                                                                                Payment Mode is Required.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="p-fluid p-grid p-mt-3">
                                                                <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex">
                                                                    <div class="p-col-4 p-d-flex p-flex-column">
                                                                        <div class="p-fluid p-field">
                                                                            <label>NEFT/Cheque No <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>
                                                                            <input pInputText type="text"
                                                                                id="neftCheque_{{i}}" name="neftCheque"
                                                                                class="textUppercase" maxlength="100"
                                                                                [readonly]="(singleForm.value.isInitiated == 'Yes' ? true : false)||initiationReadScope"
                                                                                (keypress)="numberOnlyCheque($event)"
                                                                                formControlName="neftCheque" placeholder="Enter NEFT/Cheque No"
                                                                                [ngClass]="{'error': (singleForm.value.neftCheque == null || singleForm.value.neftCheque == '') && (singleForm.get('neftCheque').dirty || singleForm.get('neftCheque').touched)}" />
                                                                            <div class="invalid-feedback"
                                                                                *ngIf="(singleForm.value.neftCheque == null || singleForm.value.neftCheque == '') && (singleForm.get('neftCheque').dirty || singleForm.get('neftCheque').touched)">
                                                                                Neft/Cheque No is required.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-d-flex p-flex-column">
                                                                        <div class="p-fluid p-field">
                                                                            <label>Bank <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>
                                                                            <p-dropdown id="bankId_{{i}}" name="bank"
                                                                                appendTo="body"
                                                                                [panelStyle]="{ width: '250px' }"
                                                                                placeholder="Select"
                                                                                [options]="bankList" [filter]="true"
                                                                                [autoDisplayFirst]="false"
                                                                                formControlName="bank"
                                                                                [disabled]="(singleForm.value.isInitiated == 'Yes' ? true : false)||initiationReadScope"
                                                                                optionLabel="name" optionValue="name"
                                                                                [ngClass]="{'error': (singleForm.value.bank == null || singleForm.value.bank == '') && (singleForm.get('bank').dirty || singleForm.get('bank').touched)}">
                                                                            </p-dropdown>
                                                                            <div class="invalid-feedback"
                                                                                *ngIf="(singleForm.value.bank == null || singleForm.value.bank == '') && (singleForm.get('bank').dirty || singleForm.get('bank').touched)">
                                                                                Bank is required.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-d-flex p-flex-column">
                                                                        <div class="p-fluid p-field">
                                                                            <label>Payment Date <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>
                                                                            <p-calendar [showIcon]="true"
                                                                                id="paymentDateId"
                                                                                [floatElement]="formDiv"
                                                                                [disabled]="disableNoPayment"
                                                                                formControlName="paymentDate"
                                                                                placeholder="Select Date" yearRange="2000:2030"
                                                                                dateFormat="dd-mm-yy"
                                                                                [disabled]="(singleForm.value.isInitiated == 'Yes' ? true : false)|| initiationReadScope"
                                                                                hourFormat="12" [readonlyInput]="true"
                                                                                name="paymentDate"
                                                                                [hideOnDateTimeSelect]="true"
                                                                                [stepHour]="1" [stepMinute]="1"
                                                                                [showButtonBar]="true"
                                                                                [ngClass]="{'error': (singleForm.value.paymentDate == null || singleForm.value.paymentDate == '') && (singleForm.get('paymentDate').dirty || singleForm.get('paymentDate').touched)}">
                                                                            </p-calendar>
                                                                            <div class="invalid-feedback"
                                                                                *ngIf="(singleForm.value.paymentDate == null || singleForm.value.paymentDate == '') && (singleForm.get('paymentDate').dirty || singleForm.get('paymentDate').touched)">
                                                                                Payment Date is required.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="p-fluid p-grid p-mt-3">
                                                                <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex">
                                                                    <div class="p-col-4 p-d-flex p-flex-column">
                                                                        <div class="p-fluid p-field">
                                                                            <label>Payment Type <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>
                                                                            <p-dropdown id="paymentTypeId_{{i}}"
                                                                                name="paymentType" placeholder="Select"
                                                                                [options]="paymentTypeListFiltered"
                                                                                [autoDisplayFirst]="false"
                                                                                [disabled]="true"
                                                                                formControlName="paymentType"
                                                                                optionLabel="property"
                                                                                optionValue="property">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-d-flex p-flex-column">
                                                                        <div class="p-fluid p-field">
                                                                            <label>TDS Type <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>
                                                                            <p-dropdown id="tdsType_{{i}}"
                                                                                name="tdsType" placeholder="Select"
                                                                                [disabled]="true"
                                                                                [options]="tdsTypeList"
                                                                                [autoDisplayFirst]="false"
                                                                                formControlName="tdsType"
                                                                                optionLabel="property"
                                                                                optionValue="property">
                                                                            </p-dropdown>
                                                                            <div class="invalid-feedback"
                                                                                *ngIf="(singleForm.value.tdsType == null || singleForm.value.tdsType == '') && (singleForm.get('tdsType').dirty || singleForm.get('tdsType').touched)">
                                                                                Tds Type is required.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-d-flex p-flex-column">
                                                                        <div class="p-fluid p-field">
                                                                            <label>TDS Amount <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>
                                                                            <input type="text" id="tdsAmount_{{i}}"
                                                                                pInputText name="tdsAmount" disabled
                                                                                placeholder="Enter TDS Amount"
                                                                                formControlName="tdsAmount"
                                                                                class="p-inputtext"
                                                                                [ngClass]="{error:(singleForm.get('tdsAmount').errors?.required || singleForm.get('tdsAmount').errors?.invalid) && (singleForm.get('tdsAmount').dirty || singleForm.get('tdsAmount').touched)}" />
                                                                            <div class="invalid-feedback"
                                                                                *ngIf="(singleForm.get('tdsAmount').errors?.required || singleForm.get('tdsAmount').errors?.invalid) && (singleForm.get('tdsAmount').dirty || singleForm.get('tdsAmount').touched)">
                                                                                TDS Amount is required
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="p-fluid p-grid p-mt-3">
                                                                <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex">
                                                                    <div class="p-col-4 p-d-flex p-flex-column">
                                                                        <div class="p-fluid p-field">
                                                                            <label>Amount Collected <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>
                                                                            <input type="text"
                                                                                id="amountCollected_{{i}}" disabled
                                                                                pInputText name="amountCollected"
                                                                                placeholder="Enter Amount Collected"
                                                                                formControlName="amountCollected"
                                                                                class="p-inputtext"
                                                                                [ngClass]="{error:(singleForm.get('amountCollected').errors?.required || singleForm.get('amountCollected').errors?.invalid) && (singleForm.get('amountCollected').dirty || singleForm.get('amountCollected').touched)}" />
                                                                            <div class="invalid-feedback"
                                                                                *ngIf="(singleForm.get('amountCollected').errors?.required || singleForm.get('amountCollected').errors?.invalid) && (singleForm.get('amountCollected').dirty || singleForm.get('amountCollected').touched)">
                                                                                Amount Collected is required
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-d-flex p-flex-column">
                                                                        <div class="p-fluid p-field">
                                                                            <label>Gross Amount</label>
                                                                            <input type="text" pInputText placeholder="Enter Gross Amount"
                                                                                disabled formControlName="grossAmount"
                                                                                class="p-inputtext" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-4 p-d-flex p-flex-column">
                                                                        <div class="p-fluid p-field">
                                                                            <label>Fin Remark</label>
                                                                            <input type="text" pInputText placeholder="Enter Fin Remark"
                                                                                disabled formControlName="finRemark"
                                                                                class="p-inputtext" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="p-fluid p-grid p-mt-3">
                                                                <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex">
                                                                    <div class="p-col-4 p-d-flex p-flex-column">
                                                                        <div class=" p-fluid   p-field    ">
                                                                            <label>Comments</label>
                                                                            <textarea pInputTextarea id="comments_id"
                                                                                name="comments_name"
                                                                                formControlName="comment" rows="2"
                                                                                placeholder="Enter Comments" [maxLength]="300"
                                                                                disabled></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </p-accordionTab>
                                                </p-accordion>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-pr-0">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                                    <h1 class="p-medium display-3">3rd Party Details</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <form [formGroup]="thirdPartyForm">
                                            <div class="p-col-12 p-pr-0 p-mb-6">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4">
                                                        <div class="p-fluid p-field">
                                                            <label>3rd Party Payment Approval</label>
                                                            <p-dropdown placeholder="Select"
                                                                formControlName="thirdPartyApproval"
                                                                [options]="partyApprovalList" resetFilterOnHide="true"
                                                                (onChange)="partyApprovalChanged($event)"
                                                                optionLabel="name" optionValue="name"
                                                                [disabled]="oppStatus == 'Report Only' || oppStatus == 'Report & Invoice' || initiationReadScope || (payments.value.length > 0 && initiationData?.thirdPartyApproval != null)"
                                                                [autoDisplayFirst]="false">
                                                            </p-dropdown>
                                                        </div>
                                                        <!-- <div class="invalid-feedback"
                                                            *ngIf="(thirdPartyForm.get('thirdPartyApproval')?.errors?.required || thirdPartyForm.get('thirdPartyApproval')?.errors?.touched || thirdPartyPayMissing) && !partyApprovalSelected">
                                                            3rd Party Payment Approval is required.
                                                        </div> -->
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                                        <div class="p-fluid p-field p-mb-0">
                                                            <label>3rd Party Approval Comment<span
                                                                    class='invalid-feedback-astrik'
                                                                    *ngIf="partyCommentM">*</span></label>
                                                            <textarea pInputTextarea name="actionComment_name" rows="3"
                                                                [readonly]="oppStatus == 'Report Only' || oppStatus == 'Report & Invoice' || disablethirdPartyComment || initiationReadScope"
                                                                placeholder="Enter 3rd Party Approval Comment" maxlength="200" id="thirdPartyComment"
                                                                formControlName="thirdPartyApprovalComment"
                                                                [ngClass]="{error:(partyCommentM && thirdPartyForm.get('thirdPartyApprovalComment')?.errors?.required) && (thirdPartyForm.get('thirdPartyApprovalComment').invalid || thirdPartyForm.get('thirdPartyApprovalComment').dirty || thirdPartyForm.get('thirdPartyApprovalComment').touched),
                                                                    disablethirdPartyComment: disablethirdPartyComment || paymentAdded}">
                                                                </textarea>
                                                        </div>
                                                        <div class="invalid-feedback"
                                                            *ngIf="(partyCommentM && thirdPartyForm.get('thirdPartyApprovalComment')?.errors?.required) && (thirdPartyForm.get('thirdPartyApprovalComment').invalid || thirdPartyForm.get('thirdPartyApprovalComment').dirty || thirdPartyForm.get('thirdPartyApprovalComment').touched)">
                                                            3rd Party Approval Comment is required.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </p-tabPanel>
                                <p-tabPanel pName="Attachment">
                                    <ng-template pTemplate="header">
                                        <span [ngClass]="{'errorTab': errorAttachments}">Attachments</span>
                                    </ng-template>
                                    <div class="p-fluid p-formgrid p-grid attachmentSectionReporting">
                                        <div class=" p-field p-col-12 p-px-3 horizontalTabView">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6">
                                                    <div class="invalid-feedback" *ngIf="chequeNeeded">
                                                        Please add attachment for Cheque.
                                                    </div>
                                                    <div class="invalid-feedback"
                                                        *ngIf="!thirdPartyAttachPresent && thirdPartyForm.controls.thirdPartyApproval?.value == 'Yes'">
                                                        Please add attachment for 3rd Party Approval.
                                                    </div>
                                                    <div class="invalid-feedback"
                                                        *ngIf="isSaveClicked && !rptAttchmentPresent && rptoverall=='Yes'">
                                                        RPT Case. Seek Approval.
                                                    </div>
                                                    <div class="invalid-feedback"
                                                        *ngIf="ibpMandatory && !ibpAttachPresent">
                                                        Please add attachment for Invoice before payment approval.
                                                    </div>
                                                </div>
                                                <div
                                                    class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6 p-px-0 p-d-flex p-jc-end">
                                                    <div class="btnwrap">
                                                        <button pButton style="height: 28px"
                                                            [disabled]="initiationReadScope"
                                                            class="p-button-secondary p-button-sm" type="submit"
                                                            label="Add Attachment" (click)="addAttachment()">
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-fluid p-formgrid p-grid">
                                                <div class=" p-col-12 p-pl-0 p-pr-0">
                                                    <div class="ui-fluid p-formgrid p-grid">
                                                        <div
                                                            class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-left p-pl-0 p-pr-0">
                                                            <div class="p-d-flex p-flex-wrap p-ai-center"
                                                                style="justify-content: center;">
                                                                <div class="p-mt-3 p-mb-3 absolute-img"
                                                                    *ngIf="documents?.length == 0">
                                                                    <div class="p-mb-1 p-d-flex p-jc-center">
                                                                        <svg width="204" height="124"
                                                                            class="illustrationLight"
                                                                            viewBox="0 0 204 124" fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M194.286 18.2354C198.98 18.2354 202.786 22.0453 202.786 26.7452C202.786 31.445 198.98 35.255 194.286 35.255H145.714C150.409 35.255 154.214 39.0649 154.214 43.7648C154.214 48.4646 150.409 52.2746 145.714 52.2746H172.429C177.123 52.2746 180.929 56.0845 180.929 60.7844C180.929 65.4842 177.123 69.2942 172.429 69.2942H160.075C154.155 69.2942 149.357 73.1041 149.357 77.804C149.357 80.9372 151.786 83.7738 156.643 86.3138C161.337 86.3138 165.143 90.1237 165.143 94.8236C165.143 99.5234 161.337 103.333 156.643 103.333H55.8571C51.1627 103.333 47.3571 99.5234 47.3571 94.8236C47.3571 90.1237 51.1627 86.3138 55.8571 86.3138H8.5C3.80558 86.3138 0 82.5038 0 77.804C0 73.1041 3.80558 69.2942 8.5 69.2942H57.0714C61.7659 69.2942 65.5714 65.4842 65.5714 60.7844C65.5714 56.0845 61.7659 52.2746 57.0714 52.2746H26.7143C22.0199 52.2746 18.2143 48.4646 18.2143 43.7648C18.2143 39.0649 22.0199 35.255 26.7143 35.255H75.2857C70.5913 35.255 66.7857 31.445 66.7857 26.7452C66.7857 22.0453 70.5913 18.2354 75.2857 18.2354H194.286ZM194.286 52.2746C198.98 52.2746 202.786 56.0845 202.786 60.7844C202.786 65.4842 198.98 69.2942 194.286 69.2942C189.591 69.2942 185.786 65.4842 185.786 60.7844C185.786 56.0845 189.591 52.2746 194.286 52.2746Z"
                                                                                fill="#EDEFF6" />
                                                                            <path fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M129.68 17.02L140.988 99.4955L142.004 107.77C142.331 110.435 140.436 112.862 137.77 113.189L66.5791 121.93C63.9135 122.257 61.4873 120.362 61.16 117.696L50.1996 28.4313C50.036 27.0985 50.9838 25.8854 52.3166 25.7218C52.325 25.7207 52.3334 25.7197 52.3418 25.7188L74.7648 23.0984"
                                                                                fill="white" />
                                                                            <path
                                                                                d="M130.884 16.8548C130.793 16.1896 130.18 15.7243 129.515 15.8155C128.849 15.9068 128.384 16.5199 128.475 17.1851L130.884 16.8548ZM140.988 99.4955L142.195 99.3473C142.194 99.3416 142.193 99.336 142.192 99.3303L140.988 99.4955ZM142.004 107.77L143.21 107.622L142.004 107.77ZM137.77 113.189L137.918 114.396L137.77 113.189ZM66.5791 121.93L66.7273 123.137L66.5791 121.93ZM61.16 117.696L62.3666 117.548L61.16 117.696ZM50.1996 28.4313L51.4063 28.2832L50.1996 28.4313ZM52.3418 25.7188L52.4774 26.9269L52.483 26.9263L52.3418 25.7188ZM74.9059 24.3059C75.5727 24.2279 76.0502 23.6241 75.9722 22.9573C75.8943 22.2904 75.2905 21.813 74.6237 21.8909L74.9059 24.3059ZM128.475 17.1851L139.783 99.6606L142.192 99.3303L130.884 16.8548L128.475 17.1851ZM139.781 99.6436L140.797 107.918L143.21 107.622L142.195 99.3473L139.781 99.6436ZM140.797 107.918C141.043 109.917 139.621 111.737 137.622 111.982L137.918 114.396C141.25 113.986 143.62 110.954 143.21 107.622L140.797 107.918ZM137.622 111.982L66.4309 120.723L66.7273 123.137L137.918 114.396L137.622 111.982ZM66.4309 120.723C64.4317 120.969 62.6121 119.547 62.3666 117.548L59.9534 117.844C60.3625 121.176 63.3952 123.546 66.7273 123.137L66.4309 120.723ZM62.3666 117.548L51.4063 28.2832L48.993 28.5795L59.9534 117.844L62.3666 117.548ZM51.4063 28.2832C51.3244 27.6168 51.7983 27.0102 52.4647 26.9284L52.1684 24.5151C50.1692 24.7606 48.7475 26.5803 48.993 28.5795L51.4063 28.2832ZM52.4647 26.9284C52.4689 26.9279 52.4731 26.9274 52.4774 26.9269L52.2063 24.5107C52.1937 24.5121 52.181 24.5136 52.1684 24.5151L52.4647 26.9284ZM52.483 26.9263L74.9059 24.3059L74.6237 21.8909L52.2007 24.5113L52.483 26.9263Z"
                                                                                fill="#9DC0FB" />
                                                                            <path fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M126.601 22.2093L136.837 96.9542L137.757 104.453C138.054 106.869 136.359 109.064 133.972 109.358L70.2192 117.185C67.8321 117.479 65.6565 115.758 65.3599 113.342L55.7484 35.0623C55.469 32.7871 55.3293 31.6495 55.9494 30.8559C56.5694 30.0623 57.707 29.9226 59.9823 29.6432L65.4559 28.9711"
                                                                                fill="#EBF3FE" />
                                                                            <path
                                                                                d="M73.0013 4.86274C73.0013 2.84853 74.6342 1.21569 76.6484 1.21569H132.031C132.998 1.21569 133.925 1.59962 134.609 2.28309L135.469 1.4232L134.609 2.28309L150.952 18.6157C151.636 19.2998 152.021 20.2277 152.021 21.1954V97.2549C152.021 99.2691 150.388 100.902 148.374 100.902H76.6484C74.6342 100.902 73.0013 99.2691 73.0013 97.2549V4.86274Z"
                                                                                fill="white" stroke="#76A7F9"
                                                                                stroke-width="2.43137" />
                                                                            <path
                                                                                d="M133.327 2.43115V17.0194C133.327 19.0336 134.96 20.6664 136.974 20.6664H151.353"
                                                                                stroke="#76A7F9" stroke-width="2.43137"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <path d="M86.3735 21.8823H117.981"
                                                                                stroke="#9DC0FB" stroke-width="2.43137"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <path d="M86.3735 80.2354H117.981"
                                                                                stroke="#9DC0FB" stroke-width="2.43137"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <path d="M86.3735 36.4707H138.648"
                                                                                stroke="#9DC0FB" stroke-width="2.43137"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <path d="M86.3735 51.0586H138.648"
                                                                                stroke="#9DC0FB" stroke-width="2.43137"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <path d="M86.3735 65.647H138.648"
                                                                                stroke="#9DC0FB" stroke-width="2.43137"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <path
                                                                                d="M139.793 107.688C149.265 107.688 156.943 99.9097 156.943 90.3147C156.943 80.7197 149.265 72.9414 139.793 72.9414C130.321 72.9414 122.643 80.7197 122.643 90.3147C122.643 99.9097 130.321 107.688 139.793 107.688Z"
                                                                                fill="#F5F9FF" stroke="#76A7F9"
                                                                                stroke-width="1.45882" />
                                                                            <path fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44"
                                                                                fill="white" />
                                                                            <path
                                                                                d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44C130.686 100.631 133.618 103.171 137.045 103.795Z"
                                                                                stroke="#76A7F9" stroke-width="1.45882"
                                                                                stroke-linecap="round" />
                                                                            <path d="M152.335 102.501L156.943 107.169"
                                                                                stroke="#76A7F9"
                                                                                stroke-width="1.45882" />
                                                                            <path fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M156.447 106.666C155.478 107.648 155.478 109.239 156.447 110.221L162.122 115.97C163.091 116.951 164.662 116.951 165.631 115.97C166.6 114.988 166.6 113.397 165.631 112.415L159.956 106.666C158.987 105.685 157.416 105.685 156.447 106.666Z"
                                                                                fill="#EBF3FE" stroke="#76A7F9"
                                                                                stroke-width="1.45882" />
                                                                            <path d="M158.991 107.688L164.623 113.393"
                                                                                stroke="white" stroke-width="1.31692"
                                                                                stroke-linecap="round" />
                                                                            <path fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M136.466 84.3505C136.466 90.3653 141.279 95.2412 147.216 95.2412C148.383 95.2412 149.506 95.053 150.557 94.705C148.841 99.0203 144.668 102.066 139.793 102.066C133.386 102.066 128.192 96.8049 128.192 90.3145C128.192 84.4332 132.457 79.561 138.025 78.6982C137.035 80.3465 136.466 82.2811 136.466 84.3505Z"
                                                                                fill="#EBF3FE" />
                                                                            <path
                                                                                d="M136.284 81.4858C132.782 82.9767 130.323 86.4843 130.323 90.5738"
                                                                                stroke="#9DC0FB" stroke-width="1.45882"
                                                                                stroke-linecap="round" />
                                                                        </svg>
                                                                        <svg width="204" height="124"
                                                                            class="illustrationDark"
                                                                            viewBox="0 0 204 124" fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M194.286 18.2354C198.98 18.2354 202.786 22.0453 202.786 26.7452C202.786 31.445 198.98 35.255 194.286 35.255H145.714C150.409 35.255 154.214 39.0649 154.214 43.7648C154.214 48.4646 150.409 52.2746 145.714 52.2746H172.429C177.123 52.2746 180.929 56.0845 180.929 60.7844C180.929 65.4842 177.123 69.2942 172.429 69.2942H160.075C154.155 69.2942 149.357 73.1041 149.357 77.804C149.357 80.9372 151.786 83.7738 156.643 86.3138C161.337 86.3138 165.143 90.1237 165.143 94.8236C165.143 99.5234 161.337 103.333 156.643 103.333H55.8571C51.1627 103.333 47.3571 99.5234 47.3571 94.8236C47.3571 90.1237 51.1627 86.3138 55.8571 86.3138H8.5C3.80558 86.3138 0 82.5038 0 77.804C0 73.1041 3.80558 69.2942 8.5 69.2942H57.0714C61.7659 69.2942 65.5714 65.4842 65.5714 60.7844C65.5714 56.0845 61.7659 52.2746 57.0714 52.2746H26.7143C22.0199 52.2746 18.2143 48.4646 18.2143 43.7648C18.2143 39.0649 22.0199 35.255 26.7143 35.255H75.2857C70.5913 35.255 66.7857 31.445 66.7857 26.7452C66.7857 22.0453 70.5913 18.2354 75.2857 18.2354H194.286ZM194.286 52.2746C198.98 52.2746 202.786 56.0845 202.786 60.7844C202.786 65.4842 198.98 69.2942 194.286 69.2942C189.591 69.2942 185.786 65.4842 185.786 60.7844C185.786 56.0845 189.591 52.2746 194.286 52.2746Z"
                                                                                fill="#2C2C2C" />
                                                                            <path fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M129.68 17.02L140.988 99.4955L142.004 107.77C142.331 110.435 140.436 112.862 137.77 113.189L66.5791 121.93C63.9135 122.257 61.4873 120.362 61.16 117.696L50.1996 28.4313C50.036 27.0985 50.9838 25.8854 52.3166 25.7218C52.325 25.7207 52.3334 25.7197 52.3418 25.7188L74.7648 23.0984"
                                                                                fill="#141414" />
                                                                            <path
                                                                                d="M130.884 16.8548C130.793 16.1896 130.18 15.7243 129.515 15.8155C128.849 15.9068 128.384 16.5199 128.475 17.1851L130.884 16.8548ZM140.988 99.4955L142.195 99.3473C142.194 99.3416 142.193 99.336 142.192 99.3303L140.988 99.4955ZM142.004 107.77L143.21 107.622L142.004 107.77ZM137.77 113.189L137.918 114.396L137.77 113.189ZM66.5791 121.93L66.7273 123.137L66.5791 121.93ZM61.16 117.696L62.3666 117.548L61.16 117.696ZM52.3418 25.7188L52.4774 26.9269L52.483 26.9263L52.3418 25.7188ZM74.9059 24.3059C75.5727 24.2279 76.0502 23.6241 75.9722 22.9573C75.8943 22.2904 75.2905 21.813 74.6237 21.8909L74.9059 24.3059ZM128.475 17.1851L139.783 99.6606L142.192 99.3303L130.884 16.8548L128.475 17.1851ZM139.781 99.6436L140.797 107.918L143.21 107.622L142.195 99.3473L139.781 99.6436ZM140.797 107.918C141.043 109.917 139.621 111.737 137.622 111.982L137.918 114.396C141.25 113.986 143.62 110.954 143.21 107.622L140.797 107.918ZM137.622 111.982L66.4309 120.723L66.7273 123.137L137.918 114.396L137.622 111.982ZM66.4309 120.723C64.4317 120.969 62.6121 119.547 62.3666 117.548L59.9534 117.844C60.3625 121.176 63.3952 123.546 66.7273 123.137L66.4309 120.723ZM62.3666 117.548L51.4063 28.2832L48.993 28.5795L59.9534 117.844L62.3666 117.548ZM51.4063 28.2832C51.3244 27.6168 51.7983 27.0102 52.4647 26.9284L52.1684 24.5151C50.1692 24.7606 48.7475 26.5803 48.993 28.5795L51.4063 28.2832ZM52.4647 26.9284C52.4689 26.9279 52.4731 26.9274 52.4774 26.9269L52.2063 24.5107C52.1937 24.5121 52.181 24.5136 52.1684 24.5151L52.4647 26.9284ZM52.483 26.9263L74.9059 24.3059L74.6237 21.8909L52.2007 24.5113L52.483 26.9263Z"
                                                                                fill="#9DC0FB" />
                                                                            <path fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M126.601 22.2093L136.837 96.9542L137.757 104.453C138.054 106.869 136.359 109.064 133.972 109.358L70.2192 117.185C67.8321 117.479 65.6565 115.758 65.3599 113.342L55.7484 35.0623C55.469 32.7871 55.3293 31.6495 55.9494 30.8559C56.5694 30.0623 57.707 29.9226 59.9823 29.6432L65.4559 28.9711"
                                                                                fill="#233655" />
                                                                            <path
                                                                                d="M73.0013 4.86274C73.0013 2.84853 74.6342 1.21569 76.6484 1.21569H132.031C132.998 1.21569 133.925 1.59962 134.609 2.28309L135.469 1.4232L134.609 2.28309L150.952 18.6157C151.636 19.2998 152.021 20.2277 152.021 21.1954V97.2549C152.021 99.2691 150.388 100.902 148.374 100.902H76.6484C74.6342 100.902 73.0013 99.2691 73.0013 97.2549V4.86274Z"
                                                                                fill="#141414" stroke="#76A7F9"
                                                                                stroke-width="2.43137" />
                                                                            <path
                                                                                d="M133.327 2.43115V17.0194C133.327 19.0336 134.96 20.6664 136.974 20.6664H151.353"
                                                                                stroke="#76A7F9" stroke-width="2.43137"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <path d="M86.3735 21.8823H117.981"
                                                                                stroke="#9DC0FB" stroke-width="2.43137"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <path d="M86.3735 80.2354H117.981"
                                                                                stroke="#9DC0FB" stroke-width="2.43137"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <path d="M86.3735 36.4707H138.648"
                                                                                stroke="#9DC0FB" stroke-width="2.43137"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <path d="M86.3735 51.0586H138.648"
                                                                                stroke="#9DC0FB" stroke-width="2.43137"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <path d="M86.3735 65.647H138.648"
                                                                                stroke="#9DC0FB" stroke-width="2.43137"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" />
                                                                            <path
                                                                                d="M139.793 107.688C149.265 107.688 156.943 99.9097 156.943 90.3147C156.943 80.7197 149.265 72.9414 139.793 72.9414C130.321 72.9414 122.643 80.7197 122.643 90.3147C122.643 99.9097 130.321 107.688 139.793 107.688Z"
                                                                                fill="#233655" stroke="#76A7F9"
                                                                                stroke-width="1.45882" />
                                                                            <path fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44"
                                                                                fill="#141414" />
                                                                            <path
                                                                                d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44C130.686 100.631 133.618 103.171 137.045 103.795Z"
                                                                                stroke="#76A7F9" stroke-width="1.45882"
                                                                                stroke-linecap="round" />
                                                                            <path d="M152.335 102.501L156.943 107.169"
                                                                                stroke="#76A7F9"
                                                                                stroke-width="1.45882" />
                                                                            <path fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M156.447 106.666C155.478 107.648 155.478 109.239 156.447 110.221L162.122 115.97C163.091 116.951 164.662 116.951 165.631 115.97C166.6 114.988 166.6 113.397 165.631 112.415L159.956 106.666C158.987 105.685 157.416 105.685 156.447 106.666Z"
                                                                                fill="#233655" stroke="#76A7F9"
                                                                                stroke-width="1.45882" />
                                                                            <path d="M158.991 107.688L164.623 113.393"
                                                                                stroke="#141414" stroke-width="1.31692"
                                                                                stroke-linecap="round" />
                                                                            <path fill-rule="evenodd"
                                                                                clip-rule="evenodd"
                                                                                d="M136.466 84.3505C136.466 90.3653 141.279 95.2412 147.216 95.2412C148.383 95.2412 149.506 95.053 150.557 94.705C148.841 99.0203 144.668 102.066 139.793 102.066C133.386 102.066 128.192 96.8049 128.192 90.3145C128.192 84.4332 132.457 79.561 138.025 78.6982C137.035 80.3465 136.466 82.2811 136.466 84.3505Z"
                                                                                fill="#233655" />
                                                                            <path
                                                                                d="M136.284 81.4858C132.782 82.9767 130.323 86.4843 130.323 90.5738"
                                                                                stroke="#9DC0FB" stroke-width="1.45882"
                                                                                stroke-linecap="round" />
                                                                        </svg>
                                                                    </div>
                                                                    <p class="p-mt-3 p-mb-0">No document available</p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-flex-wrap displayEditTypeModal">
                                                                <div class="uploadedDocumentAttachment p-col-12 p-sm-4 p-pl-0 p-d-flex p-ai-center p-flex-wrap p-pt-1 attachmentContainer"
                                                                    *ngFor="let item of documents; let i = index">
                                                                    <p-card class="attachmentCard">
                                                                        <div
                                                                            class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                                                            <div class="p-col-10 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                                                                <div class="p-col-2" style="padding-right: 9px;">
                                                                                    <img class="uploadedThumbnail"
                                                                                        *ngIf="item.fileContentType == 'application/pdf'"
                                                                                        [src]="pdfThumb" alt="">
                                                                                    <img class="uploadedThumbnail"
                                                                                        [src]="imgThumb"
                                                                                        *ngIf="item.fileContentType == 'image/png' || item.fileContentType == 'image/jpg' || item.fileContentType == 'image/jpeg'"
                                                                                        alt="">
                                                                                    <img class="uploadedThumbnail"
                                                                                        [src]="wordThumb"
                                                                                        *ngIf="item.fileContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || item.fileContentType == 'application/msword' "
                                                                                        alt="">
                                                                                    <img class="uploadedThumbnail"
                                                                                        *ngIf="item.fileContentType == ''"
                                                                                        [src]="mailThumb" alt="">
                                                                                        <svg class="uploadedThumbnail"
                                                                                        *ngIf="item?.fileContentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                                                                                        width="24" height="32" viewBox="0 0 24 32" fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clip-path="url(#clip0_1779_24618)">
                                                                                            <path d="M18.8944 0H0V32H24V5.33267L18.8944 0Z"
                                                                                                fill="#F4F5F6" />
                                                                                            <path
                                                                                                d="M5.82828 25.2306L6.5404 26.5866L7.2702 25.2306H8.37374L7.25 27.17L8.40404 29.1094H7.30556L6.5404 27.7685L5.78788 29.1094H4.67677L5.82828 27.17L4.71717 25.2306H5.82828ZM10.0884 23.9377V29.1094H9.01263V23.9377H10.0884ZM14.1774 26.3366L13.1926 26.3973C13.1757 26.3131 13.1395 26.2373 13.084 26.17C13.0284 26.101 12.9552 26.0462 12.8643 26.0058C12.775 25.9638 12.6681 25.9427 12.5436 25.9427C12.3769 25.9427 12.2363 25.9781 12.1218 26.0488C12.0074 26.1178 11.9501 26.2104 11.9501 26.3265C11.9501 26.4191 11.9872 26.4974 12.0612 26.5614C12.1353 26.6254 12.2624 26.6767 12.4426 26.7154L13.1446 26.8568C13.5217 26.9343 13.8028 27.0589 13.988 27.2306C14.1732 27.4023 14.2658 27.6279 14.2658 27.9074C14.2658 28.1616 14.1909 28.3846 14.041 28.5765C13.8929 28.7685 13.6892 28.9183 13.4299 29.026C13.1723 29.1321 12.8752 29.1851 12.5385 29.1851C12.025 29.1851 11.616 29.0782 11.3112 28.8644C11.0082 28.6489 10.8306 28.356 10.7784 27.9856L11.8365 27.9301C11.8685 28.0866 11.9459 28.2062 12.0688 28.2887C12.1917 28.3695 12.3491 28.4099 12.541 28.4099C12.7296 28.4099 12.8811 28.3737 12.9956 28.3013C13.1117 28.2272 13.1707 28.1321 13.1723 28.0159C13.1707 27.9183 13.1294 27.8383 13.0486 27.776C12.9678 27.7121 12.8432 27.6632 12.6749 27.6296L12.0032 27.4957C11.6244 27.42 11.3424 27.2887 11.1572 27.1018C10.9737 26.9149 10.8819 26.6767 10.8819 26.3872C10.8819 26.138 10.9493 25.9233 11.084 25.7432C11.2203 25.5631 11.4114 25.4242 11.6572 25.3265C11.9047 25.2289 12.1942 25.1801 12.5259 25.1801C13.0158 25.1801 13.4013 25.2836 13.6824 25.4907C13.9653 25.6978 14.1303 25.9797 14.1774 26.3366ZM15.8422 25.2306L16.5543 26.5866L17.2841 25.2306H18.3876L17.2639 27.17L18.4179 29.1094H17.3194L16.5543 27.7685L15.8018 29.1094H14.6907L15.8422 27.17L14.7311 25.2306H15.8422Z"
                                                                                                fill="#A7ABB7" />
                                                                                            <path d="M6.44444 19.1111V8.44444H16.6984V19.1111H6.44444Z"
                                                                                                stroke="#298E51" stroke-width="0.888889" />
                                                                                            <path
                                                                                                d="M9 16.8839L10.8635 13.7651L9.15659 10.6641H10.5273L11.4586 12.6498C11.5447 12.8377 11.6071 12.9774 11.6353 13.0706H11.6479C11.7087 12.9196 11.7733 12.7743 11.8408 12.6321L12.8367 10.6673H14.0983L12.3477 13.7507L14.1429 16.8863H12.8004L11.7243 14.7094C11.6743 14.6158 11.6319 14.5178 11.5974 14.4163H11.5796C11.5482 14.5152 11.5064 14.6098 11.4549 14.6982L10.3469 16.8839H9Z"
                                                                                                fill="#298E51" />
                                                                                            <path d="M19.7143 2.66667H0V0H18.923L19.7143 2.66667Z"
                                                                                                fill="#298E51" />
                                                                                            <path d="M18.9438 5.33267H24L18.9438 0V5.33267Z"
                                                                                                fill="#D3D5DB" />
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_1779_24618">
                                                                                                <rect width="24" height="32" rx="1" fill="white" />
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                    <svg class="uploadedThumbnail"
                                                                                    *ngIf="item?.fileContentType == 'application/vnd.ms-excel'" width="26" height="35" viewBox="0 0 21 28" fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clip-path="url(#clip0_12_294)">
                                                                                            <path d="M17 0H0V28H21V5L17 0Z" fill="#F3F3F3" />
                                                                                            <path
                                                                                                d="M8.27158 22.6503L8.70296 23.4718L9.14505 22.6503H9.81354L9.13281 23.8252L9.83189 25H9.16647L8.70296 24.1877L8.2471 25H7.57403L8.27158 23.8252L7.5985 22.6503H8.27158ZM10.8522 21.8671V25H10.2006V21.8671H10.8522ZM13.3292 23.3204L12.7326 23.3571C12.7224 23.3061 12.7005 23.2602 12.6668 23.2194C12.6332 23.1776 12.5888 23.1444 12.5338 23.12C12.4797 23.0945 12.415 23.0817 12.3395 23.0817C12.2385 23.0817 12.1534 23.1031 12.084 23.146C12.0147 23.1878 11.98 23.2439 11.98 23.3142C11.98 23.3703 12.0024 23.4178 12.0473 23.4565C12.0922 23.4953 12.1692 23.5264 12.2783 23.5498L12.7036 23.6355C12.932 23.6824 13.1023 23.7579 13.2145 23.8619C13.3267 23.9659 13.3828 24.1026 13.3828 24.2719C13.3828 24.4258 13.3374 24.561 13.2466 24.6772C13.1569 24.7935 13.0335 24.8843 12.8764 24.9495C12.7204 25.0138 12.5404 25.0459 12.3364 25.0459C12.0254 25.0459 11.7776 24.9811 11.593 24.8516C11.4094 24.7211 11.3018 24.5436 11.2702 24.3193L11.9112 24.2856C11.9305 24.3805 11.9775 24.4529 12.0519 24.5028C12.1263 24.5518 12.2217 24.5763 12.338 24.5763C12.4522 24.5763 12.544 24.5543 12.6133 24.5105C12.6837 24.4656 12.7194 24.408 12.7204 24.3376C12.7194 24.2785 12.6944 24.23 12.6454 24.1923C12.5965 24.1536 12.521 24.124 12.419 24.1036L12.0121 24.0225C11.7827 23.9766 11.6119 23.8971 11.4997 23.7839C11.3885 23.6707 11.3329 23.5264 11.3329 23.351C11.3329 23.2 11.3737 23.07 11.4553 22.9609C11.5379 22.8518 11.6537 22.7676 11.8026 22.7085C11.9525 22.6493 12.1279 22.6198 12.3288 22.6198C12.6255 22.6198 12.8591 22.6825 13.0294 22.8079C13.2007 22.9333 13.3007 23.1042 13.3292 23.3204Z"
                                                                                                fill="#858585" />
                                                                                            <path d="M5.78167 7.39886H14.984V16.7122H5.78167V7.39886Z"
                                                                                                stroke="#298E51" stroke-width="0.797721" />
                                                                                            <path
                                                                                                d="M8.07422 14.7705L9.74655 12.0416L8.21474 9.32812H9.44485L10.2807 11.0657C10.3579 11.2301 10.4139 11.3523 10.4392 11.4338H10.4505C10.5051 11.3017 10.5631 11.1746 10.6237 11.0502L11.5174 9.33094H12.6496L11.0785 12.0289L12.6896 14.7726H11.4848L10.5191 12.8678C10.4743 12.7859 10.4362 12.7001 10.4052 12.6114H10.3892C10.3611 12.6979 10.3235 12.7806 10.2773 12.858L9.28301 14.7705H8.07422Z"
                                                                                                fill="#298E51" />
                                                                                            <path d="M17.6923 2.33333H0V0H17L17.6923 2.33333Z"
                                                                                                fill="#298E51" />
                                                                                            <path d="M17 5H21L17 0V5Z" fill="#CECECE" />
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_12_294">
                                                                                                <rect width="21" height="28" rx="1" fill="white" />
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                </div>
                                                                                <div class="p-col-10 p-pr-0 p-pl-0 p-d-flex p-flex-column p-flex-wrap">
                                                                                    <a [ngClass]="{'attachmentName ' : item.fileId}" class="limit-text-length cursor-pointer"
                                                                                        (click)="showAttachmentPreview(item)">
                                                                                        {{item.fileName}}
                                                                                    </a>
                                                                                    <label class="attachmentFileType">Type:
                                                                                        {{item.fileTypeName}}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="p-col-2 p-pr-0 p-d-flex p-jc-end p-ai-center">
                                                                                <i (click)="editAttachment(item, i)"
                                                                                    *ngIf="paymentAdded || (notInitiated && !item.fileId)"
                                                                                    class="icon-edit cursor-pointer attachmentIcon p-mr-2"
                                                                                    aria-hidden="true"></i>
                                                                                <i class="pi pi-trash cursor-pointer attachmentIcon"
                                                                                    *ngIf="item.deleteId"
                                                                                    aria-hidden="true"
                                                                                    (click)="onDeletePopup(item)"></i>
                                                                            </div>
                                                                        </div>
                                                                    </p-card>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-tabPanel>
                                <p-tabPanel [disabled]="disableRevisionTab">
                                    <ng-template pTemplate="header">
                                        <span>Revisions</span>
                                    </ng-template>
                                    <div class="revision_tab">
                                        <p-toast key="revisionTabAckMsg" class="custom-toast"></p-toast>

                                        <div class="p-fluid p-grid">
                                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                                <h1 class="p-medium display-3"> Amount </h1>
                                            </div>
                                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-mt-2">
                                                <div class="field-radiobutton" style="width: 5%">
                                                </div>
                                                <div class="p-d-flex">
                                                    <div class="radio_container  field-radiobutton">
                                                        <p-radioButton name="amount" [(ngModel)]="discountType"
                                                            (onClick)="discountSelected()"
                                                            [disabled]="isOtaPending || disableAll" value="cancel">
                                                        </p-radioButton>
                                                        <label for="">Cancel Invoice</label>
                                                    </div>
                                                    <div class="radio_container  field-radiobutton p-ml-4">
                                                        <p-radioButton name="amount" [(ngModel)]="discountType"
                                                            (onClick)="discountSelected()"
                                                            [disabled]="isOtaPending || disableAll" value="overall">
                                                        </p-radioButton>
                                                        <label for="">Overall Discount</label>
                                                    </div>
                                                    <div class="radio_container  field-radiobutton p-ml-4">
                                                        <p-radioButton name="amount" [(ngModel)]="discountType"
                                                            (onClick)="discountSelected()"
                                                            [disabled]="isOtaPending || disableAll" value="agreement">
                                                        </p-radioButton>
                                                        <label for="">Agreement Discount</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-fluid p-grid">
                                            <div class="p-col-3 p-d-flex p-flex-column p-mt-3">
                                                <div class="p-fluid p-field">
                                                    <label>Current Invoice Amount</label>
                                                    <input type="text" pInputText placeholder="Enter Current Invoice Amount"
                                                        [(ngModel)]="currentInvAmt" disabled class="p-inputtext" />
                                                </div>
                                            </div>
                                            <div class="p-col-3 p-d-flex p-flex-column p-mt-3">
                                                <div class="p-fluid p-field">
                                                    <label>Revised Invoice Amount</label>
                                                    <input type="text" pInputText placeholder="Enter Revised Invoice Amount"
                                                        [ngClass]="{error: invAmtEmpty}"
                                                        (blur)="calculateAgreementDisc()"
                                                        (input)="fixRevisedAmt($event)" [(ngModel)]="revisedInvAmt"
                                                        class="p-inputtext"
                                                        [disabled]="disableActual || disableAll || isOtaPending" />
                                                    <div class="invalid-feedback" *ngIf="invAmtEmpty">
                                                        Field cannot be blank.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-fluid p-formgrid p-grid">
                                            <div class="p-p-0 p-ml-2" style="max-width: 950px;" *ngIf="showTable">
                                                <p-table [value]="agreementDetails" [scrollable]="true"
                                                    scrollHeight="160px" scrollDirection="both">
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th scope="col" style="max-width:110px" pFrozenColumn>Agreement
                                                                No</th>
                                                            <th scope="col" style="max-width:110px" pFrozenColumn>Product
                                                            </th>
                                                            <th scope="col" style="max-width:110px" pFrozenColumn>Instrument
                                                            </th>
                                                            <th scope="col" style="max-width:110px" pFrozenColumn>Quantum
                                                                (Cr)
                                                            </th>
                                                            <th scope="col" style="max-width:110px">SF Start Date</th>
                                                            <th scope="col" style="max-width:100px">SF End Date</th>
                                                            <th scope="col" style="max-width:100px">Method Amount</th>
                                                            <th scope="col" style="max-width:100px">SF Percent</th>
                                                            <th scope="col" style="max-width:120px">Surveillance Fees</th>
                                                            <th scope="col" style="max-width:130px" *ngIf="showSfCell">
                                                                Revised Surveillance Fees</th>
                                                            <th scope="col" style="max-width:130px" *ngIf="!showSfCell">
                                                                Revised Surveillance Fees</th>
                                                            <th scope="col" style="max-width:110px">Discount Amount</th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-agreement>
                                                        <tr>
                                                            <td style="max-width:110px" pFrozenColumn>
                                                                {{agreement.agreementId}}</td>
                                                            <td style="max-width:110px" pFrozenColumn>
                                                                {{agreement.productName}}
                                                            </td>
                                                            <td style="max-width:110px" pFrozenColumn>
                                                                {{agreement.instrumentName}}</td>
                                                            <td style="max-width:110px" pFrozenColumn>
                                                                {{agreement.outstandingQuantum}}</td>
                                                            <td style="max-width:110px">{{agreement.sfStartDate | date:
                                                                'dd-MM-yy'}}</td>
                                                            <td style="max-width:100px">{{agreement.sfEndDate | date:
                                                                'dd-MM-yy'}}</td>
                                                            <td style="max-width:100px">{{agreement.sfMethodAmount}}</td>
                                                            <td style="max-width:100px">{{agreement.sfTemplateRuleId}}</td>
                                                            <td style="max-width:120px">{{agreement.oldSurveillanceFee}}
                                                            </td>
                                                            <td style="max-width:130px" pEditableColumn *ngIf="showSfCell">
                                                                <p-cellEditor>
                                                                    <ng-template pTemplate="input">
                                                                        <input pInputText type="text"
                                                                            (input)="revisedSfEntered($event, agreement.id)"
                                                                            (blur)="fixSf()"
                                                                            [(ngModel)]="agreement.surveillanceFee">
                                                                    </ng-template>
                                                                    <ng-template pTemplate="output">
                                                                        {{agreement.surveillanceFee}}
                                                                    </ng-template>
                                                                </p-cellEditor>
                                                            </td>
                                                            <td style="max-width:130px" *ngIf="!showSfCell">
                                                                {{agreement.surveillanceFee}}</td>
                                                            <td style="max-width:110px">{{agreement.discountAmount}}</td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </div>
                                        </div>
                                        <div class="p-fluid p-grid">
                                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-mt-3">
                                                <h1 class="p-medium display-3"> Invoice Details </h1>
                                            </div>
                                            <div class="p-col-4 p-d-flex p-flex-column p-mt-2">
                                                <div class="p-fluid p-field">
                                                    <label>Invoice Date</label>
                                                    <input type="text" pInputText placeholder="Enter Invoice Date" disabled
                                                        [(ngModel)]="revInvoiceDate" class="p-inputtext" />
                                                </div>
                                            </div>
                                            <div class="p-col-4 p-d-flex p-ai-center p-mt-4 p-ml-2">
                                                <p-checkbox binary="true" (onChange)="dateChanged($event)"
                                                    [disabled]="cancelInvoiceSelected || isOtaPending || disableAll"
                                                    [(ngModel)]="dateChecked" inputId="binary"></p-checkbox>
                                                <span class="p-ml-2">Request Date Change</span>
                                            </div>
                                        </div>
                                        <div class="ui-fluid p-formgrid p-grid">
                                            <div class="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                                                <h1 class="p-medium display-3"> Contact Details </h1>
                                            </div>
                                            <div class="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-d-flex p-jc-end">
                                                <div class="btnwrap">
                                                    <button pButton style="height: 28px"
                                                        [disabled]="cancelInvoiceSelected || isOtaPending || disableAll"
                                                        class="p-button-secondary p-button-sm" type="submit"
                                                        label="Get CRM Data" (click)="getCrmData('revision')">
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-mt-2">
                                            <div class="p-fluid p-grid contact-container">
                                                <div class="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6 contact_1">
                                                    <div class="p-mb-1">
                                                        Name: <span>{{invoiceContact?.salutation}}
                                                            {{invoiceContact?.firstName}}
                                                            {{invoiceContact?.lastName}}</span>
                                                    </div>
                                                    <div class="p-mb-1">
                                                        Designation: <span>{{invoiceContact?.designation}}</span>
                                                    </div>
                                                    <div class="p-mb-1">
                                                        Address Line 1: <span>{{invoiceContact?.addressLine1}}</span>
                                                    </div>
                                                    <div class="p-mb-1">
                                                        Address Line 2: <span>{{invoiceContact?.addressLine2}}</span>
                                                    </div>
                                                    <div class="p-mb-1">
                                                        Pin Code: <span>{{invoiceContact?.pincode}}</span>
                                                    </div>
                                                    <div class="p-mb-1">
                                                        City: <span>{{invoiceContact?.city}}</span>
                                                    </div>
                                                    <div class="p-mb-1">
                                                        State: <span>{{invoiceContact?.state}}</span>
                                                    </div>
                                                    <div class="p-mb-1">
                                                        GST Number: <span>{{invoiceContact?.gstNo}}</span>
                                                    </div>
                                                    <div class="p-mb-1">
                                                        GST Type: <span>{{invoiceContact?.gstType}}</span>
                                                    </div>
                                                </div>
                                                <div class="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                                                    <div class="p-mb-1 p-ml-1">
                                                        Name: <span>{{crmSingleContact?.salutation}}
                                                            {{crmSingleContact?.firstName}}
                                                            {{crmSingleContact?.lastName}}</span>
                                                    </div>
                                                    <div class="p-mb-1 p-ml-1">
                                                        Designation: <span>{{crmSingleContact?.designation}}</span>
                                                    </div>
                                                    <div class="p-mb-1 p-ml-1">
                                                        Address Line 1: <span>{{crmSingleContact?.address1}}</span>
                                                    </div>
                                                    <div class="p-mb-1 p-ml-1">
                                                        Address Line 2: <span>{{crmSingleContact?.address2}}</span>
                                                    </div>
                                                    <div class="p-mb-1 p-ml-1">
                                                        Pin Code: <span>{{crmSingleContact?.pin}}</span>
                                                    </div>
                                                    <div class="p-mb-1 p-ml-1">
                                                        City: <span>{{crmSingleContact?.city}}</span>
                                                    </div>
                                                    <div class="p-mb-1 p-ml-1">
                                                        State: <span>{{crmSingleContact?.state}}</span>
                                                    </div>
                                                    <div class="p-mb-1 p-ml-1">
                                                        GST Number: <span>{{crmSingleContact?.gstNumber}}</span>
                                                    </div>
                                                    <div class="p-mb-1 p-ml-1">
                                                        GST Type: <span>{{crmSingleContact?.gstType}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-fluid p-grid">
                                            <!-- <div class="p-col-8 p-d-flex p-flex-column p-mt-2">
                                                <div class="p-fluid p-field">
                                                    <label>Note to Manager <span
                                                            class='invalid-feedback-astrik'>*</span></label>
                                                    <textarea pInputTextarea placeholder="Enter " rows="3"
                                                        [(ngModel)]="noteToManager"
                                                        [disabled]="isOtaPending || disableAll" maxlength="300"
                                                        (input)="noteToManagerEntered()"></textarea>
                                                </div>
                                            </div> -->
                                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-mt-2">
                                                <div class="p-fluid p-field">
                                                    <label>Reason for Revisions <span
                                                            class='invalid-feedback-astrik'>*</span></label>
                                                    <p-dropdown placeholder="Select" [(ngModel)]="noteToManager"
                                                        appendTo="body" [options]="revisionReasons"
                                                        (onChange)="noteToManagerEntered()" resetFilterOnHide="true"
                                                        optionLabel="reason_description"
                                                        optionValue="reason_description" [autoDisplayFirst]="false"
                                                        [disabled]="isOtaPending || disableAll">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="p-col-4 p-d-flex p-flex-column p-mt-2" *ngIf="isOtaPending">
                                                <div class="p-fluid p-field">
                                                    <label>OTA <span class='invalid-feedback-astrik'>*</span></label>
                                                    <p-inputMask placeholder="Enter OTA" [ngClass]="{error: invalidOta}"
                                                        (onInput)="otaEntered()" slotChar="" [(ngModel)]="otaValue"
                                                        mask="999999"></p-inputMask>
                                                    <div class='invalid-feedback' *ngIf="invalidOta">
                                                        Invalid OTA.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-tabPanel>
                            </p-tabView>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 popupFooterBtnContainer">
                        <div
                            class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-jc-end p-ai-center popupFooterBtnContent p-pr-3 p-mt-2 p-mb-2">
                            <div class="btnwrap">
                                <button pButton class="p-button-brand2 p-button p-mr-2" type="button" label="Cancel"
                                    (click)="closeInitationPopup()" *ngIf="showInitiationBtn"></button>
                                <button *ngIf="showRevisionBtn" pButton class="p-button-brand2 p-button" type="button"
                                    label="Cancel All Changes" (click)="showAllChangesPopup()"
                                    [disabled]="!isOtaPending"></button>
                                <button *ngIf="showRevisionBtn && !isOtaPending" pButton class="p-button-secondary"
                                    (click)="openApproverPopup('send')" type="button" label="Send OTA"
                                    [disabled]="disableSendOta"></button>
                                <button *ngIf="showRevisionBtn && isOtaPending" pButton class="p-button-secondary"
                                    (click)="openApproverPopup('resend')" type="button" label="Resend OTA"></button>
                            </div>
                            <div class="btnwrap">
                                <button *ngIf="showRevisionBtn" pButton class="p-button-brand2 p-button p-mr-0"
                                    (click)="verifyOta()" type="submit" [disabled]="verifySaveBtn"
                                    label="Verify & Save"></button>
                                <button *ngIf="showInitiationBtn" pButton class="p-button-primary p-button p-mr-0"
                                    type="submit" [disabled]="isFullyInitiated"
                                    [disabled]="initiationReadScope || disabledSaveBtn || (!disableSfDiscountReason && !invoiceDetailsform.value.sfDiscountReason) || initiationSubmited"
                                    (click)="validations()" label="Save"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="no_data_div" *ngIf="pageLoader">
        <div class="app-loading" id="loader">
            <div class="loadbg"></div>
            <div class="bdload">
                <div class="spinner">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                    <span>Loading...</span>
                </div>
            </div>
        </div>
    </div> -->
</div>

<p-dialog header="Payment Details" [(visible)]="paymentModel" *ngIf="paymentModel" [modal]="true"
    [style]="{width: '70vw'}" [draggable]="false" [resizable]="false">
    <form [formGroup]="addpaymentForm" autocomplete="off" #formDiv>
        <div class="p-formgrid p-grid">
            <div class="p-col-12">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 left p-mb-1">
                        <label>Balance Amount :</label>
                        <span class="text_brand_color" style="font-weight:bold;font-size: 13px;">&#8377;
                            {{balanceAmount}}</span>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 left">
                        <label class="text_brand_color" *ngIf="paymentErrorMsg">{{erroMsg}}</label>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 left">
                        <label class="text_brand_color" *ngIf="fullPayNeeded">The Payment applied is Full Payment -
                            Please check Payment Type.</label>
                    </div>

                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 left">
                        <p class="text_brand_color p-mb-2" *ngIf="sfActualFeesMissing">
                            Cannot add Payment as SF Actual Fee is not defined. Please Add SF Actual Fee first.
                        </p>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 left">
                        <p class="text_brand_color p-mb-2" *ngIf="thirdPartyPayMissing">
                            Cannot add Payment as 3rd Party Payment Approval is mandatory.
                        </p>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                        <div class="p-fluid p-field">
                            <label>
                                Payment Mode<span class='invalid-feedback-astrik'>*</span>
                            </label>
                            <p-dropdown id="paymentModeid" name="paymentMode" placeholder="Select" appendTo="body"
                                [options]="paymentModeList" [disabled]="disableNoPayment" [autoDisplayFirst]="false"
                                (onChange)="onPayModeChange($event)" formControlName="paymentMode"
                                optionLabel="property" optionValue="property"
                                [ngClass]="{'error': (addpaymentForm.value.paymentMode == null || addpaymentForm.value.paymentMode == '') && (addpaymentForm.get('paymentMode').dirty || addpaymentForm.get('paymentMode').touched)}">
                            </p-dropdown>
                            <div class="invalid-feedback"
                                *ngIf="(addpaymentForm.value.paymentMode == null || addpaymentForm.value.paymentMode == '') && (addpaymentForm.get('paymentMode').dirty || addpaymentForm.get('paymentMode').touched)">
                                Payment Mode is required.
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                        <div class="p-fluid p-field">
                            <label>
                                NEFT / Cheque No<span class='invalid-feedback-astrik'>*</span>
                            </label>
                            <input pInputText type="text" [readonly]="disableNoPayment" name="neftCheque"
                                id="chequeNo_id" maxlength="100" (keypress)="numberOnlyCheque($event)"
                                formControlName="neftCheque" class="textUppercase" placeholder="Enter NEFT / Cheque No"
                                [ngClass]="{'error': (addpaymentForm.value.neftCheque == null || addpaymentForm.value.neftCheque == '') && (addpaymentForm.get('neftCheque').dirty || addpaymentForm.get('neftCheque').touched)}" />
                            <div class="invalid-feedback"
                                *ngIf="(addpaymentForm.value.neftCheque == null || addpaymentForm.value.neftCheque == '') && (addpaymentForm.get('neftCheque').dirty || addpaymentForm.get('neftCheque').touched)">
                                Neft/Cheque No is required.
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                        <div class="p-fluid p-field">
                            <label>
                                Bank<span class='invalid-feedback-astrik'>*</span>
                            </label>
                            <p-dropdown id="bankId" name="bank" [disabled]="disableNoPayment" placeholder="Select"
                                appendTo="body" [panelStyle]="{ width: '250px' }" [options]="bankList" [filter]="true"
                                [autoDisplayFirst]="false" formControlName="bank" optionLabel="name" optionValue="name"
                                [ngClass]="{'error': (addpaymentForm.value.bank == null || addpaymentForm.value.bank == '') && (addpaymentForm.get('bank').dirty || addpaymentForm.get('bank').touched)}">
                            </p-dropdown>
                            <div class="invalid-feedback"
                                *ngIf="(addpaymentForm.value.bank == null || addpaymentForm.value.bank == '') && (addpaymentForm.get('bank').dirty || addpaymentForm.get('bank').touched)">
                                Bank is required.
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                        <div class=" p-fluid   p-field    ">
                            <label>
                                Payment Date<span class='invalid-feedback-astrik'>*</span>
                            </label>
                            <p-calendar [showIcon]="true" id="paymentDateId" [disabled]="disableNoPayment"
                                [floatElement]="formDiv" formControlName="paymentDate" placeholder="Date"
                                yearRange="2000:2030" dateFormat="dd-mm-yy" hourFormat="12" [readonlyInput]="true"
                                name="paymentDate" [hideOnDateTimeSelect]="true" [stepHour]="1" [stepMinute]="1"
                                [showButtonBar]="true"
                                [ngClass]="{'error': (addpaymentForm.value.paymentDate == null || addpaymentForm.value.paymentDate == '') && (addpaymentForm.get('paymentDate').dirty || addpaymentForm.get('paymentDate').touched)}">
                            </p-calendar>
                            <div class="invalid-feedback"
                                *ngIf="(addpaymentForm.value.paymentDate == null || addpaymentForm.value.paymentDate == '') && (addpaymentForm.get('paymentDate').dirty || addpaymentForm.get('paymentDate').touched)">
                                Payment Date is required.
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                        <div class="p-fluid p-field">
                            <label>
                                Contact<span class='invalid-feedback-astrik'>*</span>
                            </label>
                            <p-dropdown appendTo="body" id="contact" name="contact" placeholder="Select"
                                [options]="paymentContactDisplayList" [disabled]="disableContact" [filter]="true"
                                [autoDisplayFirst]="false" (onChange)="contactSelected($event)"
                                formControlName="contact" optionLabel="name"
                                [ngClass]="{'error': (addpaymentForm.value.contact == null || addpaymentForm.value.contact == '') && (addpaymentForm.get('contact').dirty || addpaymentForm.get('contact').touched)}">
                            </p-dropdown>
                            <div class="invalid-feedback"
                                *ngIf="(addpaymentForm.value.contact == null || addpaymentForm.value.contact == '') && (addpaymentForm.get('contact').dirty || addpaymentForm.get('contact').touched)">
                                Contact is required.
                            </div>
                            <div class="invalid-feedback" *ngIf="contactDetailsMissing">
                                Some details are missing in contact.
                            </div>
                            <div style="text-align: right;" *ngIf="gstType">
                                <label>{{gstType}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                        <div class="p-fluid p-field">
                            <label>
                                Payment Type<span class='invalid-feedback-astrik'>*</span>
                            </label>
                            <p-dropdown id="paymentTypeId" name="paymentType" placeholder="Select" appendTo="body"
                                [options]="paymentTypeListFiltered2" [autoDisplayFirst]="false"
                                (onChange)="paymentTypeChanged($event)" formControlName="paymentType"
                                optionLabel="property" optionValue="property"
                                [ngClass]="{'error': (addpaymentForm.value.paymentType == null || addpaymentForm.value.paymentType == '') && (addpaymentForm.get('paymentType').dirty || addpaymentForm.get('paymentType').touched)}">
                            </p-dropdown>
                            <div class="invalid-feedback"
                                *ngIf="(addpaymentForm.value.paymentType == null || addpaymentForm.value.paymentType == '') && (addpaymentForm.get('paymentType').dirty || addpaymentForm.get('paymentType').touched)">
                                Payment Type is required.
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                        <div class="p-fluid p-field">
                            <label>
                                TDS Type<span class='invalid-feedback-astrik'>*</span>
                            </label>
                            <p-dropdown id="tdsTypeId" name="tdsType" placeholder="Select" [options]="filterTdsTypeList"
                                appendTo="body" (onChange)="tdsSelected($event)" [autoDisplayFirst]="false"
                                formControlName="tdsType" optionLabel="property" optionValue="property"
                                [disabled]="disableNoPayment || disableTdsType"
                                [ngClass]="{'error': (addpaymentForm.value.tdsType == null || addpaymentForm.value.tdsType == '') && (addpaymentForm.get('tdsType').dirty || addpaymentForm.get('tdsType').touched)}">
                            </p-dropdown>
                            <div class="invalid-feedback"
                                *ngIf="(addpaymentForm.value.tdsType == null || addpaymentForm.value.tdsType == '') && (addpaymentForm.get('tdsType').dirty || addpaymentForm.get('tdsType').touched)">
                                TDS Type is required.
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                        <div class="p-fluid p-field">
                            <label>
                                Amount Collected<span class='invalid-feedback-astrik'>*</span>
                            </label>
                            <input type="text" pInputText name="amountCollected" [readonly]="disableNoPayment"
                                placeholder="Enter Amount Collected" (input)="amountCollectedEntered($event)" (keypress)="numberOnly($event)"
                                formControlName="amountCollected" class="p-inputtext"
                                [ngClass]="{error:(addpaymentForm.get('amountCollected').errors?.required || addpaymentForm.get('amountCollected').errors?.invalid) && (addpaymentForm.get('amountCollected').dirty || addpaymentForm.get('amountCollected').touched)}" />
                            <div class="invalid-feedback"
                                *ngIf="(addpaymentForm.get('amountCollected').errors?.required || addpaymentForm.get('amountCollected').errors?.invalid) && (addpaymentForm.get('amountCollected').dirty || addpaymentForm.get('amountCollected').touched)">
                                Amount Collected is required
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                        <div class="p-fluid p-field">
                            <label> Gross Amount </label>
                            <input type="text" pInputText name="grossAmount" [readonly]="disableNoPayment"
                                placeholder="Enter Gross Amount" disabled formControlName="grossAmount" class="p-inputtext" />
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left p-mb-3">
                        <div class="p-fluid p-field">
                            <label>
                                TDS Amount<span class='invalid-feedback-astrik'>*</span>
                            </label>
                            <input type="text" pInputText name="tdsAmount"
                                [readonly]="disabledTdsAmount || disableNoPayment"
                                (input)="tdsEntered($event.target.value)" placeholder="Enter TDS Amount" formControlName="tdsAmount"
                                class="p-inputtext"
                                [ngClass]="{error:(addpaymentForm.get('tdsAmount').errors?.required || addpaymentForm.get('tdsAmount').errors?.invalid) && (addpaymentForm.get('tdsAmount').dirty || addpaymentForm.get('tdsAmount').touched)}" />
                            <div class="invalid-feedback"
                                *ngIf="(addpaymentForm.get('tdsAmount').errors?.required || addpaymentForm.get('tdsAmount').errors?.invalid) && (addpaymentForm.get('tdsAmount').dirty || addpaymentForm.get('tdsAmount').touched)">
                                TDS Amount is required
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                        <div class="p-fluid p-field">
                            <label>Comment</label>
                            <input type="text" pInputText name="comment" placeholder="Enter Comment" formControlName="comment"
                                class="p-inputtext" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <ng-template pTemplate="footer">
        <button pButton (click)="paymentModel=false" label="Cancel" class="p-button-brand2"></button>
        <button pButton label="Save" (click)="addPayment()"
            [disabled]="addpaymentForm.invalid || contactDetailsMissing || sfActualFeesMissing || thirdPartyPayMissing"
            class="p-button-primary"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Error" [(visible)]="gstMisMatch" [modal]="true" [style]="{width: '27vw'}" [draggable]="false"
    [resizable]="false" [closable]="false">
    <div class="p-fluid p-grid">
        <div class="p-col-12 p-p-0">
            <p>Contact (GST Type) and TDS Type mismatch.
                Edit Contact from Entity or Change your Contact!</p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton label="Ok" (click)="gstMisMatch=false;" class="p-button-primary"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Info" class="previewModal mConfirmationPopup modal" [(visible)]="apiFail" [modal]="true"
    [style]="{width: '55vw', height: '20vh'}" [draggable]="false" [resizable]="false">
    <p class="p-ml-3">Failed to connect with the server, Sorry for the inconvenience</p>
</p-dialog>

<!-- <p-dialog header="Alert" [(visible)]="rptPopup" [modal]="true" [style]="{width: '40vw', height: '35vh'}"
    [draggable]="false" [resizable]="false">
    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-text-center" id="center" style="overflow-y: hidden;">
        <p>RPT Case. Seek Approval.</p>
    </div>
    <ng-template pTemplate="footer">
        <div class="btnwrap">
            <button pButton id="save" class="p-button-primary p-button" (click)="rptPopup=false" type="button"  label="Ok">
            </button>
        </div>
    </ng-template>
</p-dialog> -->

<p-dialog [header]="isPdfInvoice && currentPdf ? currentPdf?.invoiceName : 'Header'" [(visible)]="displayPreview"
    class="docPreview previewModal modal" [modal]="true" [style]="{width: '95vw', height: '100vh'}" [draggable]="false"
    [resizable]="false">
    <div class="no_data_div tableLoader" *ngIf="previewLoader">
        <div class="app-loading " id="loader">
            <div class="loadbg"></div>
            <div class="bdload">
                <div class="spinner">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                    <span>Loading...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="p-mb-2 p-d-flex p-flex-row zoomContainer p-px-2">
        <label style="margin-left:0px;">Zoom : </label>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-search-plus" (click)="zoom(1)"></button>
        <button type="button" (click)="zoom(-1)" pButton icon="pi pi-search-minus"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" (click)="rotateFile(1)" pButton icon="pi pi-refresh"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-replay" (click)="rotateFile(-1)"></button>
    </div>
    <pdf-viewer class="p-px-2" id="pdf_viewer" *ngIf="isPdf" [src]="urlSafe" [rotation]="rotate" [original-size]="false"
        [show-all]="true" [fit-to-page]="false" [zoom]="zoomValue" [zoom-scale]="'page-width'" [stick-to-page]="false"
        [render-text]="true" [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
        style="width: 100%; height:100%;"></pdf-viewer>
    <div class="imagePreviewContainer p-px-2 p-d-flex p-jc-center p-ai-center">
        <img *ngIf="!isPdf" src="{{urlSafe}}" id="imagePrev" alt="Image">
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" (click)="displayPreview=false" class="p-button-brand2"></button>
        <button pButton type="button" label="Download" class="p-button-secondary"
            (click)="isPdfInvoice && currentPdf ? downloadInvoice(currentPdf.invoiceNumber) : downloadFile()"></button>
    </ng-template>
</p-dialog>


<p-dialog header="Alert" [(visible)]="validationPopup" [modal]="true" [style]="{width: '30vw', height: '20vh'}"
    [draggable]="false" [resizable]="false">
    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-text-left">
        <p class="p-mb-0">There are few details are missing in {{erroMsg}}. <br>
            Please enter in order to save.</p>
    </div>
    <ng-template pTemplate="footer">
        <div class="btnwrap">
            <button pButton id="save" class="p-button-primary p-button" (click)="validationPopup=false" type="button"
                label="Ok">
            </button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog header="Alert" [(visible)]="crmContactNotSelectedPopup" [modal]="true"
    [style]="{width: '40vw', height: '40vh'}" [draggable]="false" [resizable]="false">
    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-text-center" id="center">
        <p>CRM Contact not Selected. <br>
            Please select in order to save.</p>
    </div>
    <ng-template pTemplate="footer">
        <div class="btnwrap">
            <button pButton id="save" class="p-button-primary p-button" (click)="crmContactNotSelectedPopup=false"
                type="button" label="Ok">
            </button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="previewFailed" [modal]="true"
    [style]="{width: '55vw', height: '20vh'}" [draggable]="false" [resizable]="false">
    <p>Unable to preview attachment, Please try again.</p>
</p-dialog>

<p-dialog header="CRM Contacts" [(visible)]="crmContactPopup" class="crmContact" [modal]="true"
    [style]="{width: '45vw'}" [draggable]="false" [resizable]="false">
    <ng-container *ngIf="crmContacts?.length > 0; else empty" style="overflow: auto;">
        <div class="p-col-12 p-px-3 p-py-0 p-d-flex p-ml-2" *ngFor="let item of crmContacts">
            <div class="field-radiobutton p-ml-2" style="width: 5%">
                <p-radioButton name="crmcontacts" [value]="item.contactId" [(ngModel)]="selectedCrmContact"
                    (onClick)="crmContactClicked()"></p-radioButton>
            </div>
            <p-card class="p-ml-1 p-col-11 p-p-0 p-mb-2">
                <h1 class="p-medium display-4 ">{{item?.firstName}} {{item?.lastName}}</h1>
                <div class="p-grid p-nogutter">
                    <div class="p-col-6">
                        <div class="p-grid p-nogutter">
                            <div class="p-col-3">
                                <label>GST No: </label>
                            </div>
                            <div class="p-col-8">
                                <span>{{item?.gstNumber}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-6">
                        <div class="p-grid p-nogutter">
                            <div class="p-col-4">
                                <label> Contact ID: </label>
                            </div>
                            <div class="p-col-8">
                                <span> {{item?.contactId}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
    </ng-container>
    <ng-template #empty>
        <div class="p-col-12 p-ml-2">
            No contacts available.
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton (click)="crmContactPopup=false;crmContactPopupOpeningFor='';" label="Cancel"
            class="p-button-brand2"></button>
        <button pButton (click)="crmContactSelected()" [disabled]="disabledCrmApplyBtn" label="Apply"
            class="p-button-primary p-mr-1"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Send OTA" [(visible)]="approverPopup" class="crmContact" [modal]="true" [style]="{width: '30vw'}"
    [draggable]="false" [resizable]="false">
    <ng-container>
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-3">
            <div class="p-fluid p-field">
                <label>
                    Approver Name<span class='invalid-feedback-astrik'>*</span>
                </label>
                <p-dropdown name="approver" placeholder="Select" appendTo="body" [autoDisplayFirst]="false"
                    [(ngModel)]="approverName" [options]="hierarchyList" optionLabel="employeeName"
                    optionValue="employeeid">
                </p-dropdown>
            </div>
        </div>
    </ng-container>
    <ng-template pTemplate="footer">
        <button pButton (click)="approverPopup=false" label="Cancel" class="p-button-brand2"></button>
        <button pButton (click)="sendOta()" label="Send" [disabled]="!approverName"
            class="p-button-primary p-mr-0"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Alert" [(visible)]="allChangesPopup" class="crmContact" [modal]="true"
    [style]="{width: '30vw', height:'34vh'}" [draggable]="false" [resizable]="false">
    <p class="p-mb-0 p-ml-2">This will cancel all changes including pending OTA.</p>
    <p class="p-mb-0 p-ml-2">Are you sure want to cancel all changes? </p>
    <ng-template pTemplate="footer">
        <button pButton (click)="allChangesPopup=false" label="No" class="p-button-brand2"></button>
        <button pButton (click)="cancelAllChange()" label="Yes" class="p-button-primary"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Delete" [(visible)]="deleteFile" class="DeleteFileModal" [modal]="true" [style]="{width: '27vw'}"
    [draggable]="false" [resizable]="false">
    <div class="p-fluid p-grid">
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-ml-2">
            <p>Are you sure you want to delete?</p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton (click)="deleteFile=false" label="No" class="p-button-secondary"></button>
        <button pButton label="Yes" (click)="onDelete()" class="p-button-primary"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Alert" [(visible)]="emptyAgreement" [modal]="true" [style]="{width: '27vw'}" [draggable]="false"
    [resizable]="false">
    <div class="p-fluid p-grid">
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-ml-2">
            <p>Please enter revised surveillance fees.</p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton label="Ok" (click)="emptyAgreement=false;" class="p-button-primary"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Alert" [(visible)]="selectDefaultContact" [modal]="true" [style]="{width: '27vw'}" [draggable]="false"
    [resizable]="false">
    <div class="p-fluid p-grid">
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-ml-2">
            <p>Please Select Default Contact.</p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton label="Ok" (click)="selectDefaultContact=false;" class="p-button-primary"></button>
    </ng-template>
</p-dialog>

<p-dialog [header]="showOldInvoice ? 'Old Invoices' : 'New Invoice'" class="previewModal mConfirmationPopup"
    [(visible)]="showInvoiceListModal" [modal]="true" [style]="{width: '75vw', height: '60vh'}" [draggable]="false"
    [resizable]="false">
    <p-toast key="invoiceAckMsg" class="custom-toast"></p-toast>
    <div class="p-fluid p-formgrid p-grid">
        <div class=" p-col-12">
            <div class="ui-fluid p-formgrid p-grid">
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12  p-left attachmentScroll">
                    <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-flex-wrap" *ngIf="!showEmptyInvoiceState">
                        <ng-container *ngFor="let attachData of invoiceDocDetails">

                            <div class="p-col-12 p-sm-4 p-pl-0 p-d-flex p-ai-center p-flex-wrap p-pt-1 attachmentContainer"
                                *ngIf="(showOldInvoice && !attachData.isCurrentInvoice) || (!showOldInvoice && attachData.isCurrentInvoice)">
                                <p-card>
                                    <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                        <div class="p-col-11 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                            <div style="padding-right: 10px;">
                                                <img alt="" width="50" height="64"
                                                    (click)="previewAttachment(attachData)" class="cursor-pointer"
                                                    [src]="pdfThumb" />
                                            </div>
                                            <div
                                                class="p-col-9 p-lg-9 p-xl-10 p-md-9 p-sm-7 p-pr-0 p-pl-0 p-d-flex p-flex-column p-flex-wrap contentContainer">
                                                <a class="cursor-pointer limit-text-length attachmentName"
                                                    (click)="previewAttachment(attachData)">
                                                    {{attachData.fileName}}</a>
                                                <label class="attachmentFileType">Type:
                                                    {{attachData.invoiceDate}}</label>
                                            </div>
                                        </div>
                                        <div class="p-col-1 p-pr-0 p-d-flex p-jc-end p-ai-center">
                                            <i (click)="downloadInvoice(attachData.invoiceNumber)"
                                                class="icon-download cursor-pointer attachmentIcon p-mr-2"
                                                aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </p-card>
                            </div>
                        </ng-container>
                    </div>

                    <div class="p-mt-3 p-col-12 p-pl-0 p-pr-0 p-d-flex p-flex-wrap p-flex-column p-jc-center p-ai-center"
                        *ngIf="showEmptyInvoiceState">
                        <div class="p-mb-5 p-d-flex p-jc-center">
                            <svg width="204" height="124" class="illustrationLight" viewBox="0 0 204 124" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M194.286 18.2354C198.98 18.2354 202.786 22.0453 202.786 26.7452C202.786 31.445 198.98 35.255 194.286 35.255H145.714C150.409 35.255 154.214 39.0649 154.214 43.7648C154.214 48.4646 150.409 52.2746 145.714 52.2746H172.429C177.123 52.2746 180.929 56.0845 180.929 60.7844C180.929 65.4842 177.123 69.2942 172.429 69.2942H160.075C154.155 69.2942 149.357 73.1041 149.357 77.804C149.357 80.9372 151.786 83.7738 156.643 86.3138C161.337 86.3138 165.143 90.1237 165.143 94.8236C165.143 99.5234 161.337 103.333 156.643 103.333H55.8571C51.1627 103.333 47.3571 99.5234 47.3571 94.8236C47.3571 90.1237 51.1627 86.3138 55.8571 86.3138H8.5C3.80558 86.3138 0 82.5038 0 77.804C0 73.1041 3.80558 69.2942 8.5 69.2942H57.0714C61.7659 69.2942 65.5714 65.4842 65.5714 60.7844C65.5714 56.0845 61.7659 52.2746 57.0714 52.2746H26.7143C22.0199 52.2746 18.2143 48.4646 18.2143 43.7648C18.2143 39.0649 22.0199 35.255 26.7143 35.255H75.2857C70.5913 35.255 66.7857 31.445 66.7857 26.7452C66.7857 22.0453 70.5913 18.2354 75.2857 18.2354H194.286ZM194.286 52.2746C198.98 52.2746 202.786 56.0845 202.786 60.7844C202.786 65.4842 198.98 69.2942 194.286 69.2942C189.591 69.2942 185.786 65.4842 185.786 60.7844C185.786 56.0845 189.591 52.2746 194.286 52.2746Z"
                                    fill="#EDEFF6" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M129.68 17.02L140.988 99.4955L142.004 107.77C142.331 110.435 140.436 112.862 137.77 113.189L66.5791 121.93C63.9135 122.257 61.4873 120.362 61.16 117.696L50.1996 28.4313C50.036 27.0985 50.9838 25.8854 52.3166 25.7218C52.325 25.7207 52.3334 25.7197 52.3418 25.7188L74.7648 23.0984"
                                    fill="white" />
                                <path
                                    d="M130.884 16.8548C130.793 16.1896 130.18 15.7243 129.515 15.8155C128.849 15.9068 128.384 16.5199 128.475 17.1851L130.884 16.8548ZM140.988 99.4955L142.195 99.3473C142.194 99.3416 142.193 99.336 142.192 99.3303L140.988 99.4955ZM142.004 107.77L143.21 107.622L142.004 107.77ZM137.77 113.189L137.918 114.396L137.77 113.189ZM66.5791 121.93L66.7273 123.137L66.5791 121.93ZM61.16 117.696L62.3666 117.548L61.16 117.696ZM50.1996 28.4313L51.4063 28.2832L50.1996 28.4313ZM52.3418 25.7188L52.4774 26.9269L52.483 26.9263L52.3418 25.7188ZM74.9059 24.3059C75.5727 24.2279 76.0502 23.6241 75.9722 22.9573C75.8943 22.2904 75.2905 21.813 74.6237 21.8909L74.9059 24.3059ZM128.475 17.1851L139.783 99.6606L142.192 99.3303L130.884 16.8548L128.475 17.1851ZM139.781 99.6436L140.797 107.918L143.21 107.622L142.195 99.3473L139.781 99.6436ZM140.797 107.918C141.043 109.917 139.621 111.737 137.622 111.982L137.918 114.396C141.25 113.986 143.62 110.954 143.21 107.622L140.797 107.918ZM137.622 111.982L66.4309 120.723L66.7273 123.137L137.918 114.396L137.622 111.982ZM66.4309 120.723C64.4317 120.969 62.6121 119.547 62.3666 117.548L59.9534 117.844C60.3625 121.176 63.3952 123.546 66.7273 123.137L66.4309 120.723ZM62.3666 117.548L51.4063 28.2832L48.993 28.5795L59.9534 117.844L62.3666 117.548ZM51.4063 28.2832C51.3244 27.6168 51.7983 27.0102 52.4647 26.9284L52.1684 24.5151C50.1692 24.7606 48.7475 26.5803 48.993 28.5795L51.4063 28.2832ZM52.4647 26.9284C52.4689 26.9279 52.4731 26.9274 52.4774 26.9269L52.2063 24.5107C52.1937 24.5121 52.181 24.5136 52.1684 24.5151L52.4647 26.9284ZM52.483 26.9263L74.9059 24.3059L74.6237 21.8909L52.2007 24.5113L52.483 26.9263Z"
                                    fill="#9DC0FB" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M126.601 22.2093L136.837 96.9542L137.757 104.453C138.054 106.869 136.359 109.064 133.972 109.358L70.2192 117.185C67.8321 117.479 65.6565 115.758 65.3599 113.342L55.7484 35.0623C55.469 32.7871 55.3293 31.6495 55.9494 30.8559C56.5694 30.0623 57.707 29.9226 59.9823 29.6432L65.4559 28.9711"
                                    fill="#EBF3FE" />
                                <path
                                    d="M73.0013 4.86274C73.0013 2.84853 74.6342 1.21569 76.6484 1.21569H132.031C132.998 1.21569 133.925 1.59962 134.609 2.28309L135.469 1.4232L134.609 2.28309L150.952 18.6157C151.636 19.2998 152.021 20.2277 152.021 21.1954V97.2549C152.021 99.2691 150.388 100.902 148.374 100.902H76.6484C74.6342 100.902 73.0013 99.2691 73.0013 97.2549V4.86274Z"
                                    fill="white" stroke="#76A7F9" stroke-width="2.43137" />
                                <path
                                    d="M133.327 2.43115V17.0194C133.327 19.0336 134.96 20.6664 136.974 20.6664H151.353"
                                    stroke="#76A7F9" stroke-width="2.43137" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path d="M86.3735 21.8823H117.981" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M86.3735 80.2354H117.981" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M86.3735 36.4707H138.648" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M86.3735 51.0586H138.648" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M86.3735 65.647H138.648" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path
                                    d="M139.793 107.688C149.265 107.688 156.943 99.9097 156.943 90.3147C156.943 80.7197 149.265 72.9414 139.793 72.9414C130.321 72.9414 122.643 80.7197 122.643 90.3147C122.643 99.9097 130.321 107.688 139.793 107.688Z"
                                    fill="#F5F9FF" stroke="#76A7F9" stroke-width="1.45882" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44"
                                    fill="white" />
                                <path
                                    d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44C130.686 100.631 133.618 103.171 137.045 103.795Z"
                                    stroke="#76A7F9" stroke-width="1.45882" stroke-linecap="round" />
                                <path d="M152.335 102.501L156.943 107.169" stroke="#76A7F9" stroke-width="1.45882" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M156.447 106.666C155.478 107.648 155.478 109.239 156.447 110.221L162.122 115.97C163.091 116.951 164.662 116.951 165.631 115.97C166.6 114.988 166.6 113.397 165.631 112.415L159.956 106.666C158.987 105.685 157.416 105.685 156.447 106.666Z"
                                    fill="#EBF3FE" stroke="#76A7F9" stroke-width="1.45882" />
                                <path d="M158.991 107.688L164.623 113.393" stroke="white" stroke-width="1.31692"
                                    stroke-linecap="round" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M136.466 84.3505C136.466 90.3653 141.279 95.2412 147.216 95.2412C148.383 95.2412 149.506 95.053 150.557 94.705C148.841 99.0203 144.668 102.066 139.793 102.066C133.386 102.066 128.192 96.8049 128.192 90.3145C128.192 84.4332 132.457 79.561 138.025 78.6982C137.035 80.3465 136.466 82.2811 136.466 84.3505Z"
                                    fill="#EBF3FE" />
                                <path d="M136.284 81.4858C132.782 82.9767 130.323 86.4843 130.323 90.5738"
                                    stroke="#9DC0FB" stroke-width="1.45882" stroke-linecap="round" />
                            </svg>

                            <svg width="204" height="124" class="illustrationDark" viewBox="0 0 204 124" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M194.286 18.2354C198.98 18.2354 202.786 22.0453 202.786 26.7452C202.786 31.445 198.98 35.255 194.286 35.255H145.714C150.409 35.255 154.214 39.0649 154.214 43.7648C154.214 48.4646 150.409 52.2746 145.714 52.2746H172.429C177.123 52.2746 180.929 56.0845 180.929 60.7844C180.929 65.4842 177.123 69.2942 172.429 69.2942H160.075C154.155 69.2942 149.357 73.1041 149.357 77.804C149.357 80.9372 151.786 83.7738 156.643 86.3138C161.337 86.3138 165.143 90.1237 165.143 94.8236C165.143 99.5234 161.337 103.333 156.643 103.333H55.8571C51.1627 103.333 47.3571 99.5234 47.3571 94.8236C47.3571 90.1237 51.1627 86.3138 55.8571 86.3138H8.5C3.80558 86.3138 0 82.5038 0 77.804C0 73.1041 3.80558 69.2942 8.5 69.2942H57.0714C61.7659 69.2942 65.5714 65.4842 65.5714 60.7844C65.5714 56.0845 61.7659 52.2746 57.0714 52.2746H26.7143C22.0199 52.2746 18.2143 48.4646 18.2143 43.7648C18.2143 39.0649 22.0199 35.255 26.7143 35.255H75.2857C70.5913 35.255 66.7857 31.445 66.7857 26.7452C66.7857 22.0453 70.5913 18.2354 75.2857 18.2354H194.286ZM194.286 52.2746C198.98 52.2746 202.786 56.0845 202.786 60.7844C202.786 65.4842 198.98 69.2942 194.286 69.2942C189.591 69.2942 185.786 65.4842 185.786 60.7844C185.786 56.0845 189.591 52.2746 194.286 52.2746Z"
                                    fill="#2C2C2C" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M129.68 17.02L140.988 99.4955L142.004 107.77C142.331 110.435 140.436 112.862 137.77 113.189L66.5791 121.93C63.9135 122.257 61.4873 120.362 61.16 117.696L50.1996 28.4313C50.036 27.0985 50.9838 25.8854 52.3166 25.7218C52.325 25.7207 52.3334 25.7197 52.3418 25.7188L74.7648 23.0984"
                                    fill="#141414" />
                                <path
                                    d="M130.884 16.8548C130.793 16.1896 130.18 15.7243 129.515 15.8155C128.849 15.9068 128.384 16.5199 128.475 17.1851L130.884 16.8548ZM140.988 99.4955L142.195 99.3473C142.194 99.3416 142.193 99.336 142.192 99.3303L140.988 99.4955ZM142.004 107.77L143.21 107.622L142.004 107.77ZM137.77 113.189L137.918 114.396L137.77 113.189ZM66.5791 121.93L66.7273 123.137L66.5791 121.93ZM61.16 117.696L62.3666 117.548L61.16 117.696ZM52.3418 25.7188L52.4774 26.9269L52.483 26.9263L52.3418 25.7188ZM74.9059 24.3059C75.5727 24.2279 76.0502 23.6241 75.9722 22.9573C75.8943 22.2904 75.2905 21.813 74.6237 21.8909L74.9059 24.3059ZM128.475 17.1851L139.783 99.6606L142.192 99.3303L130.884 16.8548L128.475 17.1851ZM139.781 99.6436L140.797 107.918L143.21 107.622L142.195 99.3473L139.781 99.6436ZM140.797 107.918C141.043 109.917 139.621 111.737 137.622 111.982L137.918 114.396C141.25 113.986 143.62 110.954 143.21 107.622L140.797 107.918ZM137.622 111.982L66.4309 120.723L66.7273 123.137L137.918 114.396L137.622 111.982ZM66.4309 120.723C64.4317 120.969 62.6121 119.547 62.3666 117.548L59.9534 117.844C60.3625 121.176 63.3952 123.546 66.7273 123.137L66.4309 120.723ZM62.3666 117.548L51.4063 28.2832L48.993 28.5795L59.9534 117.844L62.3666 117.548ZM51.4063 28.2832C51.3244 27.6168 51.7983 27.0102 52.4647 26.9284L52.1684 24.5151C50.1692 24.7606 48.7475 26.5803 48.993 28.5795L51.4063 28.2832ZM52.4647 26.9284C52.4689 26.9279 52.4731 26.9274 52.4774 26.9269L52.2063 24.5107C52.1937 24.5121 52.181 24.5136 52.1684 24.5151L52.4647 26.9284ZM52.483 26.9263L74.9059 24.3059L74.6237 21.8909L52.2007 24.5113L52.483 26.9263Z"
                                    fill="#9DC0FB" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M126.601 22.2093L136.837 96.9542L137.757 104.453C138.054 106.869 136.359 109.064 133.972 109.358L70.2192 117.185C67.8321 117.479 65.6565 115.758 65.3599 113.342L55.7484 35.0623C55.469 32.7871 55.3293 31.6495 55.9494 30.8559C56.5694 30.0623 57.707 29.9226 59.9823 29.6432L65.4559 28.9711"
                                    fill="#233655" />
                                <path
                                    d="M73.0013 4.86274C73.0013 2.84853 74.6342 1.21569 76.6484 1.21569H132.031C132.998 1.21569 133.925 1.59962 134.609 2.28309L135.469 1.4232L134.609 2.28309L150.952 18.6157C151.636 19.2998 152.021 20.2277 152.021 21.1954V97.2549C152.021 99.2691 150.388 100.902 148.374 100.902H76.6484C74.6342 100.902 73.0013 99.2691 73.0013 97.2549V4.86274Z"
                                    fill="#141414" stroke="#76A7F9" stroke-width="2.43137" />
                                <path
                                    d="M133.327 2.43115V17.0194C133.327 19.0336 134.96 20.6664 136.974 20.6664H151.353"
                                    stroke="#76A7F9" stroke-width="2.43137" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path d="M86.3735 21.8823H117.981" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M86.3735 80.2354H117.981" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M86.3735 36.4707H138.648" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M86.3735 51.0586H138.648" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M86.3735 65.647H138.648" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path
                                    d="M139.793 107.688C149.265 107.688 156.943 99.9097 156.943 90.3147C156.943 80.7197 149.265 72.9414 139.793 72.9414C130.321 72.9414 122.643 80.7197 122.643 90.3147C122.643 99.9097 130.321 107.688 139.793 107.688Z"
                                    fill="#233655" stroke="#76A7F9" stroke-width="1.45882" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44"
                                    fill="#141414" />
                                <path
                                    d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44C130.686 100.631 133.618 103.171 137.045 103.795Z"
                                    stroke="#76A7F9" stroke-width="1.45882" stroke-linecap="round" />
                                <path d="M152.335 102.501L156.943 107.169" stroke="#76A7F9" stroke-width="1.45882" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M156.447 106.666C155.478 107.648 155.478 109.239 156.447 110.221L162.122 115.97C163.091 116.951 164.662 116.951 165.631 115.97C166.6 114.988 166.6 113.397 165.631 112.415L159.956 106.666C158.987 105.685 157.416 105.685 156.447 106.666Z"
                                    fill="#233655" stroke="#76A7F9" stroke-width="1.45882" />
                                <path d="M158.991 107.688L164.623 113.393" stroke="#141414" stroke-width="1.31692"
                                    stroke-linecap="round" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M136.466 84.3505C136.466 90.3653 141.279 95.2412 147.216 95.2412C148.383 95.2412 149.506 95.053 150.557 94.705C148.841 99.0203 144.668 102.066 139.793 102.066C133.386 102.066 128.192 96.8049 128.192 90.3145C128.192 84.4332 132.457 79.561 138.025 78.6982C137.035 80.3465 136.466 82.2811 136.466 84.3505Z"
                                    fill="#233655" />
                                <path d="M136.284 81.4858C132.782 82.9767 130.323 86.4843 130.323 90.5738"
                                    stroke="#9DC0FB" stroke-width="1.45882" stroke-linecap="round" />
                            </svg>
                        </div>
                        <p>No invoice available</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>


<p-dialog [header]="partialCnModel ? 'Credit Note':''" class="previewModal mConfirmationPopup"
    [(visible)]="partialCnModel" [modal]="true" [style]="{width: '75vw', height: '60vh'}" [draggable]="false"
    [resizable]="false">
    <p-toast key="invoiceAckMsg" class="custom-toast"></p-toast>
    <div class="p-fluid p-formgrid p-grid">
        <div class=" p-col-12">
            <div class="ui-fluid p-formgrid p-grid">
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12  p-left attachmentScroll">
                    <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-flex-wrap" *ngIf="showPcnEmpty">
                        <ng-container *ngFor="let attachData of pcnDocDetails">

                            <div class="p-col-12 p-sm-4 p-pl-0 p-d-flex p-ai-center p-flex-wrap p-pt-1 attachmentContainer"
                                *ngIf="partialCnModel">
                                <p-card>
                                    <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                        <div class="p-col-11 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                            <div style="padding-right: 10px;">
                                                <img alt="" width="50" height="64"
                                                    (click)="previewAttachment(attachData)" class="cursor-pointer"
                                                    [src]="pdfThumb" />
                                            </div>
                                            <div
                                                class="p-col-9 p-lg-9 p-xl-10 p-md-9 p-sm-7 p-pr-0 p-pl-0 p-d-flex p-flex-column p-flex-wrap contentContainer">
                                                <a class="cursor-pointer limit-text-length attachmentName"
                                                    (click)="previewAttachment(attachData)">
                                                    {{attachData.fileName}}</a>
                                                <label class="attachmentFileType">Type:
                                                    {{attachData.invoiceDate}}</label>
                                            </div>
                                        </div>
                                        <div class="p-col-1 p-pr-0 p-d-flex p-jc-end p-ai-center">
                                            <i (click)="downloadInvoice(attachData.invoiceNumber)"
                                                class="icon-download cursor-pointer attachmentIcon p-mr-2"
                                                aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </p-card>
                            </div>
                        </ng-container>
                    </div>

                    <div class="p-mt-3 p-col-12 p-pl-0 p-pr-0 p-d-flex p-flex-wrap p-flex-column p-jc-center p-ai-center"
                        *ngIf="!showPcnEmpty">
                        <div class="p-mb-5 p-d-flex p-jc-center">
                            <svg width="204" height="124" class="illustrationLight" viewBox="0 0 204 124" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M194.286 18.2354C198.98 18.2354 202.786 22.0453 202.786 26.7452C202.786 31.445 198.98 35.255 194.286 35.255H145.714C150.409 35.255 154.214 39.0649 154.214 43.7648C154.214 48.4646 150.409 52.2746 145.714 52.2746H172.429C177.123 52.2746 180.929 56.0845 180.929 60.7844C180.929 65.4842 177.123 69.2942 172.429 69.2942H160.075C154.155 69.2942 149.357 73.1041 149.357 77.804C149.357 80.9372 151.786 83.7738 156.643 86.3138C161.337 86.3138 165.143 90.1237 165.143 94.8236C165.143 99.5234 161.337 103.333 156.643 103.333H55.8571C51.1627 103.333 47.3571 99.5234 47.3571 94.8236C47.3571 90.1237 51.1627 86.3138 55.8571 86.3138H8.5C3.80558 86.3138 0 82.5038 0 77.804C0 73.1041 3.80558 69.2942 8.5 69.2942H57.0714C61.7659 69.2942 65.5714 65.4842 65.5714 60.7844C65.5714 56.0845 61.7659 52.2746 57.0714 52.2746H26.7143C22.0199 52.2746 18.2143 48.4646 18.2143 43.7648C18.2143 39.0649 22.0199 35.255 26.7143 35.255H75.2857C70.5913 35.255 66.7857 31.445 66.7857 26.7452C66.7857 22.0453 70.5913 18.2354 75.2857 18.2354H194.286ZM194.286 52.2746C198.98 52.2746 202.786 56.0845 202.786 60.7844C202.786 65.4842 198.98 69.2942 194.286 69.2942C189.591 69.2942 185.786 65.4842 185.786 60.7844C185.786 56.0845 189.591 52.2746 194.286 52.2746Z"
                                    fill="#EDEFF6" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M129.68 17.02L140.988 99.4955L142.004 107.77C142.331 110.435 140.436 112.862 137.77 113.189L66.5791 121.93C63.9135 122.257 61.4873 120.362 61.16 117.696L50.1996 28.4313C50.036 27.0985 50.9838 25.8854 52.3166 25.7218C52.325 25.7207 52.3334 25.7197 52.3418 25.7188L74.7648 23.0984"
                                    fill="white" />
                                <path
                                    d="M130.884 16.8548C130.793 16.1896 130.18 15.7243 129.515 15.8155C128.849 15.9068 128.384 16.5199 128.475 17.1851L130.884 16.8548ZM140.988 99.4955L142.195 99.3473C142.194 99.3416 142.193 99.336 142.192 99.3303L140.988 99.4955ZM142.004 107.77L143.21 107.622L142.004 107.77ZM137.77 113.189L137.918 114.396L137.77 113.189ZM66.5791 121.93L66.7273 123.137L66.5791 121.93ZM61.16 117.696L62.3666 117.548L61.16 117.696ZM50.1996 28.4313L51.4063 28.2832L50.1996 28.4313ZM52.3418 25.7188L52.4774 26.9269L52.483 26.9263L52.3418 25.7188ZM74.9059 24.3059C75.5727 24.2279 76.0502 23.6241 75.9722 22.9573C75.8943 22.2904 75.2905 21.813 74.6237 21.8909L74.9059 24.3059ZM128.475 17.1851L139.783 99.6606L142.192 99.3303L130.884 16.8548L128.475 17.1851ZM139.781 99.6436L140.797 107.918L143.21 107.622L142.195 99.3473L139.781 99.6436ZM140.797 107.918C141.043 109.917 139.621 111.737 137.622 111.982L137.918 114.396C141.25 113.986 143.62 110.954 143.21 107.622L140.797 107.918ZM137.622 111.982L66.4309 120.723L66.7273 123.137L137.918 114.396L137.622 111.982ZM66.4309 120.723C64.4317 120.969 62.6121 119.547 62.3666 117.548L59.9534 117.844C60.3625 121.176 63.3952 123.546 66.7273 123.137L66.4309 120.723ZM62.3666 117.548L51.4063 28.2832L48.993 28.5795L59.9534 117.844L62.3666 117.548ZM51.4063 28.2832C51.3244 27.6168 51.7983 27.0102 52.4647 26.9284L52.1684 24.5151C50.1692 24.7606 48.7475 26.5803 48.993 28.5795L51.4063 28.2832ZM52.4647 26.9284C52.4689 26.9279 52.4731 26.9274 52.4774 26.9269L52.2063 24.5107C52.1937 24.5121 52.181 24.5136 52.1684 24.5151L52.4647 26.9284ZM52.483 26.9263L74.9059 24.3059L74.6237 21.8909L52.2007 24.5113L52.483 26.9263Z"
                                    fill="#9DC0FB" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M126.601 22.2093L136.837 96.9542L137.757 104.453C138.054 106.869 136.359 109.064 133.972 109.358L70.2192 117.185C67.8321 117.479 65.6565 115.758 65.3599 113.342L55.7484 35.0623C55.469 32.7871 55.3293 31.6495 55.9494 30.8559C56.5694 30.0623 57.707 29.9226 59.9823 29.6432L65.4559 28.9711"
                                    fill="#EBF3FE" />
                                <path
                                    d="M73.0013 4.86274C73.0013 2.84853 74.6342 1.21569 76.6484 1.21569H132.031C132.998 1.21569 133.925 1.59962 134.609 2.28309L135.469 1.4232L134.609 2.28309L150.952 18.6157C151.636 19.2998 152.021 20.2277 152.021 21.1954V97.2549C152.021 99.2691 150.388 100.902 148.374 100.902H76.6484C74.6342 100.902 73.0013 99.2691 73.0013 97.2549V4.86274Z"
                                    fill="white" stroke="#76A7F9" stroke-width="2.43137" />
                                <path
                                    d="M133.327 2.43115V17.0194C133.327 19.0336 134.96 20.6664 136.974 20.6664H151.353"
                                    stroke="#76A7F9" stroke-width="2.43137" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path d="M86.3735 21.8823H117.981" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M86.3735 80.2354H117.981" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M86.3735 36.4707H138.648" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M86.3735 51.0586H138.648" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M86.3735 65.647H138.648" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path
                                    d="M139.793 107.688C149.265 107.688 156.943 99.9097 156.943 90.3147C156.943 80.7197 149.265 72.9414 139.793 72.9414C130.321 72.9414 122.643 80.7197 122.643 90.3147C122.643 99.9097 130.321 107.688 139.793 107.688Z"
                                    fill="#F5F9FF" stroke="#76A7F9" stroke-width="1.45882" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44"
                                    fill="white" />
                                <path
                                    d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44C130.686 100.631 133.618 103.171 137.045 103.795Z"
                                    stroke="#76A7F9" stroke-width="1.45882" stroke-linecap="round" />
                                <path d="M152.335 102.501L156.943 107.169" stroke="#76A7F9" stroke-width="1.45882" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M156.447 106.666C155.478 107.648 155.478 109.239 156.447 110.221L162.122 115.97C163.091 116.951 164.662 116.951 165.631 115.97C166.6 114.988 166.6 113.397 165.631 112.415L159.956 106.666C158.987 105.685 157.416 105.685 156.447 106.666Z"
                                    fill="#EBF3FE" stroke="#76A7F9" stroke-width="1.45882" />
                                <path d="M158.991 107.688L164.623 113.393" stroke="white" stroke-width="1.31692"
                                    stroke-linecap="round" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M136.466 84.3505C136.466 90.3653 141.279 95.2412 147.216 95.2412C148.383 95.2412 149.506 95.053 150.557 94.705C148.841 99.0203 144.668 102.066 139.793 102.066C133.386 102.066 128.192 96.8049 128.192 90.3145C128.192 84.4332 132.457 79.561 138.025 78.6982C137.035 80.3465 136.466 82.2811 136.466 84.3505Z"
                                    fill="#EBF3FE" />
                                <path d="M136.284 81.4858C132.782 82.9767 130.323 86.4843 130.323 90.5738"
                                    stroke="#9DC0FB" stroke-width="1.45882" stroke-linecap="round" />
                            </svg>

                            <svg width="204" height="124" class="illustrationDark" viewBox="0 0 204 124" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M194.286 18.2354C198.98 18.2354 202.786 22.0453 202.786 26.7452C202.786 31.445 198.98 35.255 194.286 35.255H145.714C150.409 35.255 154.214 39.0649 154.214 43.7648C154.214 48.4646 150.409 52.2746 145.714 52.2746H172.429C177.123 52.2746 180.929 56.0845 180.929 60.7844C180.929 65.4842 177.123 69.2942 172.429 69.2942H160.075C154.155 69.2942 149.357 73.1041 149.357 77.804C149.357 80.9372 151.786 83.7738 156.643 86.3138C161.337 86.3138 165.143 90.1237 165.143 94.8236C165.143 99.5234 161.337 103.333 156.643 103.333H55.8571C51.1627 103.333 47.3571 99.5234 47.3571 94.8236C47.3571 90.1237 51.1627 86.3138 55.8571 86.3138H8.5C3.80558 86.3138 0 82.5038 0 77.804C0 73.1041 3.80558 69.2942 8.5 69.2942H57.0714C61.7659 69.2942 65.5714 65.4842 65.5714 60.7844C65.5714 56.0845 61.7659 52.2746 57.0714 52.2746H26.7143C22.0199 52.2746 18.2143 48.4646 18.2143 43.7648C18.2143 39.0649 22.0199 35.255 26.7143 35.255H75.2857C70.5913 35.255 66.7857 31.445 66.7857 26.7452C66.7857 22.0453 70.5913 18.2354 75.2857 18.2354H194.286ZM194.286 52.2746C198.98 52.2746 202.786 56.0845 202.786 60.7844C202.786 65.4842 198.98 69.2942 194.286 69.2942C189.591 69.2942 185.786 65.4842 185.786 60.7844C185.786 56.0845 189.591 52.2746 194.286 52.2746Z"
                                    fill="#2C2C2C" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M129.68 17.02L140.988 99.4955L142.004 107.77C142.331 110.435 140.436 112.862 137.77 113.189L66.5791 121.93C63.9135 122.257 61.4873 120.362 61.16 117.696L50.1996 28.4313C50.036 27.0985 50.9838 25.8854 52.3166 25.7218C52.325 25.7207 52.3334 25.7197 52.3418 25.7188L74.7648 23.0984"
                                    fill="#141414" />
                                <path
                                    d="M130.884 16.8548C130.793 16.1896 130.18 15.7243 129.515 15.8155C128.849 15.9068 128.384 16.5199 128.475 17.1851L130.884 16.8548ZM140.988 99.4955L142.195 99.3473C142.194 99.3416 142.193 99.336 142.192 99.3303L140.988 99.4955ZM142.004 107.77L143.21 107.622L142.004 107.77ZM137.77 113.189L137.918 114.396L137.77 113.189ZM66.5791 121.93L66.7273 123.137L66.5791 121.93ZM61.16 117.696L62.3666 117.548L61.16 117.696ZM52.3418 25.7188L52.4774 26.9269L52.483 26.9263L52.3418 25.7188ZM74.9059 24.3059C75.5727 24.2279 76.0502 23.6241 75.9722 22.9573C75.8943 22.2904 75.2905 21.813 74.6237 21.8909L74.9059 24.3059ZM128.475 17.1851L139.783 99.6606L142.192 99.3303L130.884 16.8548L128.475 17.1851ZM139.781 99.6436L140.797 107.918L143.21 107.622L142.195 99.3473L139.781 99.6436ZM140.797 107.918C141.043 109.917 139.621 111.737 137.622 111.982L137.918 114.396C141.25 113.986 143.62 110.954 143.21 107.622L140.797 107.918ZM137.622 111.982L66.4309 120.723L66.7273 123.137L137.918 114.396L137.622 111.982ZM66.4309 120.723C64.4317 120.969 62.6121 119.547 62.3666 117.548L59.9534 117.844C60.3625 121.176 63.3952 123.546 66.7273 123.137L66.4309 120.723ZM62.3666 117.548L51.4063 28.2832L48.993 28.5795L59.9534 117.844L62.3666 117.548ZM51.4063 28.2832C51.3244 27.6168 51.7983 27.0102 52.4647 26.9284L52.1684 24.5151C50.1692 24.7606 48.7475 26.5803 48.993 28.5795L51.4063 28.2832ZM52.4647 26.9284C52.4689 26.9279 52.4731 26.9274 52.4774 26.9269L52.2063 24.5107C52.1937 24.5121 52.181 24.5136 52.1684 24.5151L52.4647 26.9284ZM52.483 26.9263L74.9059 24.3059L74.6237 21.8909L52.2007 24.5113L52.483 26.9263Z"
                                    fill="#9DC0FB" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M126.601 22.2093L136.837 96.9542L137.757 104.453C138.054 106.869 136.359 109.064 133.972 109.358L70.2192 117.185C67.8321 117.479 65.6565 115.758 65.3599 113.342L55.7484 35.0623C55.469 32.7871 55.3293 31.6495 55.9494 30.8559C56.5694 30.0623 57.707 29.9226 59.9823 29.6432L65.4559 28.9711"
                                    fill="#233655" />
                                <path
                                    d="M73.0013 4.86274C73.0013 2.84853 74.6342 1.21569 76.6484 1.21569H132.031C132.998 1.21569 133.925 1.59962 134.609 2.28309L135.469 1.4232L134.609 2.28309L150.952 18.6157C151.636 19.2998 152.021 20.2277 152.021 21.1954V97.2549C152.021 99.2691 150.388 100.902 148.374 100.902H76.6484C74.6342 100.902 73.0013 99.2691 73.0013 97.2549V4.86274Z"
                                    fill="#141414" stroke="#76A7F9" stroke-width="2.43137" />
                                <path
                                    d="M133.327 2.43115V17.0194C133.327 19.0336 134.96 20.6664 136.974 20.6664H151.353"
                                    stroke="#76A7F9" stroke-width="2.43137" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path d="M86.3735 21.8823H117.981" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M86.3735 80.2354H117.981" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M86.3735 36.4707H138.648" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M86.3735 51.0586H138.648" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M86.3735 65.647H138.648" stroke="#9DC0FB" stroke-width="2.43137"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path
                                    d="M139.793 107.688C149.265 107.688 156.943 99.9097 156.943 90.3147C156.943 80.7197 149.265 72.9414 139.793 72.9414C130.321 72.9414 122.643 80.7197 122.643 90.3147C122.643 99.9097 130.321 107.688 139.793 107.688Z"
                                    fill="#233655" stroke="#76A7F9" stroke-width="1.45882" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44"
                                    fill="#141414" />
                                <path
                                    d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44C130.686 100.631 133.618 103.171 137.045 103.795Z"
                                    stroke="#76A7F9" stroke-width="1.45882" stroke-linecap="round" />
                                <path d="M152.335 102.501L156.943 107.169" stroke="#76A7F9" stroke-width="1.45882" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M156.447 106.666C155.478 107.648 155.478 109.239 156.447 110.221L162.122 115.97C163.091 116.951 164.662 116.951 165.631 115.97C166.6 114.988 166.6 113.397 165.631 112.415L159.956 106.666C158.987 105.685 157.416 105.685 156.447 106.666Z"
                                    fill="#233655" stroke="#76A7F9" stroke-width="1.45882" />
                                <path d="M158.991 107.688L164.623 113.393" stroke="#141414" stroke-width="1.31692"
                                    stroke-linecap="round" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M136.466 84.3505C136.466 90.3653 141.279 95.2412 147.216 95.2412C148.383 95.2412 149.506 95.053 150.557 94.705C148.841 99.0203 144.668 102.066 139.793 102.066C133.386 102.066 128.192 96.8049 128.192 90.3145C128.192 84.4332 132.457 79.561 138.025 78.6982C137.035 80.3465 136.466 82.2811 136.466 84.3505Z"
                                    fill="#233655" />
                                <path d="M136.284 81.4858C132.782 82.9767 130.323 86.4843 130.323 90.5738"
                                    stroke="#9DC0FB" stroke-width="1.45882" stroke-linecap="round" />
                            </svg>
                        </div>
                        <p>No invoice available</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>