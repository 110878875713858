import { Component, OnInit } from '@angular/core';
import { PagesService } from '../pages.service';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { log } from 'console';
import { InitiationService } from 'app/initiation/initiation.service';
import { APIService } from 'app/quality-check/api.service';
// import { ChipModule } from 'primeng/chip';
@Component({
  selector: 'backoffice-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  previewDetails: any;
  additionalPreviewDetails: any;
  agreementId: any;
  OppId: any;
  feeCap: any;
  rowData: any;
  combo: any;
  combieMasterApi: any;
  combineMasterData: any = [];
  currencyList: any = [];
  reasonList: any = [];
  hierarchyList: any = [];
  businessTypeList: any = [];
  opeTypeList: any = [];
  mandateTypeList: any = [];
  priorityQcList: any = [];
  caseClassList: any = [];
  proposedSplitList: any = [];
  allUserList: any = [];
  errorMessageSave: any = [];
  productServiceList: any = [];
  initiationTypeList: any = [];
  sfFeeRuleList: any = [];
  caseClassificationList: any = [];
  opeType1: any;
  priorityForQc1: any;
  mandateTypeList1: any;
  currency1: any;
  sfFeeRule1: any;
  opeTypeData: any;
  priorityForQcData: any;
  currencyData: any;
  mandateTypeData: any;
  caseClassification1: any;
  caseClassificatioData: any;
  ownerData: any;
  companyCode: any;
  initType:any;
  initTypeData: any;
  initType1: any;
  products:any;
  proposedSplitData: any;
  comboDetails: any;
  proposedSplitData1: any;
  qcStatusList: any;
  proposedSplitData2: any;
  qcStatus: any;
  SfFeeRule: any;
  qcStatus1: any;
  qcStatusData: any;
  paymentCollected: any;
  userId: any;
  bdHierarchyData: any;
  bdHierarchy: any;
  team2Hierarchy: any;
  team2HierarchyData: any;
  owner:any;
  teamHierarchy2: any;
  team2Owner: any;
  prposedSplitTeam1: any;
  actualIrf: any;
  prposedSplitTeam2:any;
  contact: any;
  isIrfInitiated:boolean;
  limitDetails:any;
  masterServiceName: any;
  subServiceName: any;
  ratableUnitName: any;
  productId:any;
  paymentStatusList:any[];
  paymentStatusData: any;
  demoqcdetailsData: any;
  previousId: any;
  agreementId1: any;
  previousOpprtunityId: any;
  constructor(private pageService: PagesService, private encryptDecryptInterceptor: EncryptDecryptInterceptor, public initiationService: InitiationService,private apiService: APIService,) {
  }

  ngOnInit(): void {
    localStorage.getItem("previewData");

    if (localStorage.getItem("previewData")) {
      this.previewDetails = JSON.parse(this.encryptDecryptInterceptor.decrypt(localStorage.getItem("previewData")));
      console.log("cvbnm :", this.previewDetails);
      console.log("opprtunity id  from backoffice :", this.previewDetails.opportunityId);
      this.OppId = this.previewDetails.opportunityId;
      console.log("oppId :", this.OppId);
      console.log("this.userId",this.userId);
      localStorage.removeItem( "previewData");
    }
    this.getIRFPreviwDetails();
  
    this.feeCap = this.previewDetails?.feeCapData;
    this.ownerData = this.previewDetails?.Owner;
    this.ownerData= this.previewDetails?.Owner?this.previewDetails?.Owner:this.previewDetails?.initiationDetails
    console.log("alternate nexera user" ,this.ownerData);
    this.SfFeeRule=this.previewDetails?.sfFeeRule;
  }
  
  getRowFeedBack() {
    console.log("row feedback called",this.agreementId,this.companyCode);
    this.pageService.getRowFeedBack("" + this.agreementId ,this.companyCode).subscribe(response => {
      console.log("Row feedback data: ", response);
      if(response.statusCode==200) {
        this.rowData = response.data;
      }
      console.log("Preview details row feedback data :", this.rowData);
    });
  }
  

  getIRFPreviwDetails() {
    this.getCombineMasterDetails(); 
    
    this.pageService.getIRFPreviwDetails("" + this.OppId).subscribe(response => {
      console.log("backend data IrfPreviewDetails: ", response);
      this.additionalPreviewDetails = response;
      console.log("this.previewDetails?.contact",this.previewDetails?.contact);
      console.log("this.additionalPreviewDetails?.previousAgreementId",this.additionalPreviewDetails?.previousAgreementId);
      
      // this.demoqcdetailsData =this.apiService.getQCDetailsAgreement1(this.agreementId1);
  // console.log("********demoqcdetailsData**********",this.demoqcdetailsData);

      // this.previousId=this.additionalPreviewDetails?.previousAgreementId;
      if(this.previewDetails?.contact){
 
         this.contact=this.previewDetails?.contact;
 
         console.log("this.contact 1",this.contact);
      }else{
       if(this.additionalPreviewDetails?.contact){
         this.contact=this.additionalPreviewDetails?.contact;
         console.log("this.contact 2",this.contact);
        }else{
         this.contact=this.additionalPreviewDetails?.triContact;
         console.log("this.contact 3",this.contact);
        }
      }
      this.userId= this.additionalPreviewDetails?.splitTeamOwnerId1;
      this.owner=this.additionalPreviewDetails?.splitTeamOwnerId2;
      if (this.previewDetails?.bdHierarchy) {
        this.bdHierarchy=this.previewDetails?.bdHierarchy;
        this.teamHierarchy2=this.previewDetails?.team2Hierarchy;
        this.team2Owner=this.previewDetails?.team2Owner;
      }else{
        setTimeout(() => {
          if(this.userId){
            this.getAllHierarchy();
            this.getSingleHierarchy(this.userId);
          }
           
        }, 600);

        if(this.owner){
          this.team2Selected(this.owner);
        }     
      }
     
      this.proposedSplitData1= this.previewDetails?.proposedSplit?this.previewDetails?.proposedSplit:this.additionalPreviewDetails?.isProposedSplit ? 'Yes' : 'No';      
      console.log("additional previw data :", this.additionalPreviewDetails);
      this.opeType1 = (this.opeTypeList.find((o) => o?.id == this.additionalPreviewDetails?.opeType))?.value;
      console.log("Ope Type ",this.opeType1);
      this.caseClassification1 = (this.caseClassList.find((CaseClassification) => CaseClassification?.id == this.additionalPreviewDetails?.caseClassification))?.value;
      this.qcStatus1=this.additionalPreviewDetails?.qcStatus;
      this.priorityForQc1 = (this.priorityQcList.find((priorityQc) => priorityQc?.id == this.additionalPreviewDetails?.priorityForQc))?.value;
      this.mandateTypeList1 = (this.mandateTypeList.find((mandateType) => mandateType?.id == this.additionalPreviewDetails?.mandateType))?.value;
      this.currency1 =this.additionalPreviewDetails?.currency;
      this.sfFeeRule1 = (this.sfFeeRuleList.find((s) => s?.id == (this.previewDetails?.sfFeeRule?this.previewDetails?.sfFeeRule:this.additionalPreviewDetails?.sfFeeRule)))?.value;
      this.proposedSplitData2 = (this.proposedSplitList.find((p) => p?.id == (this.previewDetails?.proposedSplit1?this.previewDetails?.proposedSplit1:this.additionalPreviewDetails?.proposedSplit)))?.value;  
      this.qcStatusData = (this.qcStatusList.find((q) => q?.id == this.additionalPreviewDetails?.qcStatus))?.value;     
      this.initType1 =this.additionalPreviewDetails?.initiationType;
  
      //calculation for proposed split team 2
      this.prposedSplitTeam1=this.previewDetails?.splitTeam1?this.previewDetails?.splitTeam1:this.additionalPreviewDetails?.proposedSplitTeam1;
      this.actualIrf=this.previewDetails?.irfActualFees?this.previewDetails?.irfActualFees:this.additionalPreviewDetails?.irfActualFees;
     if (this.prposedSplitTeam1){
      this.prposedSplitTeam2= (Number(this.actualIrf) - Number(this.prposedSplitTeam1));
      console.log("prposedSplitTeam2",this.prposedSplitTeam2);
     }

      if (this.previewDetails.opeType != undefined && this.previewDetails.opeType != null) {
        console.log("in side if condition data :", this.previewDetails.opeType);
        this.opeTypeData = this.previewDetails.opeType;
      }
      if (this.previewDetails.opeType == undefined && this.opeType1 != undefined && this.opeType1 != null) {
        this.opeTypeData = this.opeType1;
        console.log("backend opetype data :", this.opeTypeData);
      }
      // Priority for qc
      if (this.previewDetails.priorityForQc != undefined && this.previewDetails.priorityForQc != null) {
        console.log("in side if condition data :", this.previewDetails.priorityForQc);
        this.priorityForQcData = this.previewDetails.priorityForQc;
      }
      if (this.previewDetails.priorityForQc == undefined && this.priorityForQc1 != undefined && this.priorityForQc1 != null) {
        this.priorityForQcData = this.priorityForQc1;
      }
      // currency
      if (this.previewDetails.currency != undefined && this.previewDetails.currency != null) {
        console.log("in side if condition data :", this.previewDetails.currency);
        this.currencyData = this.previewDetails.currency;
      }
      if (this.previewDetails.currency == undefined && this.currency1 != undefined && this.currency1 != null) {
        this.currencyData = this.currency1;
      }
      if (this.previewDetails.mandateType != undefined && this.previewDetails.mandateType != null) {
        console.log("in side if condition data :", this.previewDetails.mandateType);
        this.mandateTypeData = this.previewDetails.mandateType;
      }
      if (this.previewDetails.mandateType == undefined && this.mandateTypeList1 != undefined && this.mandateTypeList1 != null) {
        this.mandateTypeData = this.mandateTypeList1;
      }

      // Case classification
      if (this.previewDetails.caseClassification != undefined && this.previewDetails.caseClassification != null) {
        console.log("in side if condition data :", this.previewDetails.caseClassification);
        this.caseClassificatioData = this.previewDetails.caseClassification;
      }
      if (this.previewDetails.caseClassification == undefined && this.caseClassification1 != undefined && this.caseClassification1 != null) {
        this.caseClassificatioData = this.caseClassification1;
      }
      if (this.previewDetails.initType != undefined && this.previewDetails.initType != null) {
        console.log("in side if condition data :", this.previewDetails.initType);
        this.initTypeData = this.previewDetails.initType;
      }
      if (this.previewDetails.initType == undefined && this.initType1 != undefined && this.initType1 != null) {
        this.initTypeData = this.initType1;
      }
    this.agreementId =this.additionalPreviewDetails?.agreementId;
    this.companyCode=this.additionalPreviewDetails?.crmCode;
    this.initType=this.additionalPreviewDetails?.initiationType;
    this.getRowFeedBack();
    this.getPaymentStatus();
    
    console.log("data combinemaster ",this.getCombineMasterDetails());
    this.combo=((this.previewDetails?.initType?this.previewDetails?.initType:this.additionalPreviewDetails?.initiationType));
    
    this.initType=this.additionalPreviewDetails?.initiationType;
    console.log("initType code :",this.initType);
    //payment collected calculation
    this.paymentCollected = this.additionalPreviewDetails?.paymentDetails.filter(item => item?.paymentTypeId !== 88 && (item?.paymentStatusId == 1 || item?.paymentStatusId == 2)).reduce((accumulator, item) => {
      return accumulator + item?.grossAmount;
    }, 0);
  
    this.paymentCollected = this.additionalPreviewDetails?.irfActualFee > 0 ? ((parseFloat(this.paymentCollected) / this.additionalPreviewDetails?.irfActualFee) * 100) + "" : undefined;
    
    if (this.paymentCollected) { // round off to 2 decimal place
      this.paymentCollected = parseFloat(this.paymentCollected + "").toFixed(2);
      console.log("this.paymentCollected",this.paymentCollected);
    }
    this.productId=this.additionalPreviewDetails?.productId;
    this.agreementId1=this.additionalPreviewDetails?.agreementId;
    this.getPrevOppId(this.agreementId1);
    // if(this.additionalPreviewDetails?.initiationType && this.additionalPreviewDetails?.initiationType.toLowerCase() != "alternate") {
    //   this.getLimitDetails();
    // }
   
    this.productService();
    });
  }


   //for hireachy user 
   getSingleHierarchy(userId) {
    this.initiationService.getUserHierarchy(userId).subscribe((res) => {
      this.bdHierarchyData = JSON.parse(res?.upwardHierarchyUsers)?.uppwardhierarchy;
      this.bdHierarchy = res?.finalMapping?.split('->').join(' -> ');
      console.log("bd hirechy data from alternate billing :",this.bdHierarchy);
    });
  }

  team2Selected(owner) {
    this.initiationService.getUserHierarchy(owner).subscribe((res) => {
      this.team2HierarchyData = JSON.parse(res?.upwardHierarchyUsers)?.uppwardhierarchy;
      this.teamHierarchy2 = res?.finalMapping?.split('->').join(' -> ');
      console.log("bd hirechy data from alternate billing :",this.teamHierarchy2);
    });
  }
  equalObjs(id1, id2) {
    return id1 === id2;
  }
  getAllHierarchy() {
    
    this.initiationService.getAllUserHirearchy().subscribe((res) => {      
      this.allUserList = res?.employeeDetails;
      this.team2Owner = (this.allUserList.find((team2Owner) => team2Owner?.employeeId == this.owner))?.employeeName;
    });
  }

  getPrevOppId(agreementId1){
    console.log("agreementId1",this.agreementId1);
    this.apiService.getQCDetailsAgreement(agreementId1).subscribe((response) =>{

      this.demoqcdetailsData=response;
      this.previousOpprtunityId=this.demoqcdetailsData?.previousOpportunityId;
      console.log("this.demoqcdetailsData",this.demoqcdetailsData);
      console.log("this.previousOpprtunityId",this.previousOpprtunityId);
      if(this.additionalPreviewDetails?.initiationType && this.additionalPreviewDetails?.initiationType.toLowerCase() != "alternate") {
        this.getLimitDetails();
       
      
      }
    } )
  }
  productService(){
    this.initiationService.productServices(this.productId).subscribe((res) => {
      if (res && res.length > 0) {
          console.log("result:",res);
          this.masterServiceName= res[0]?.masterServiceName;
          this.subServiceName = res[0]?.subServiceName;
          this.ratableUnitName = res[0]?.ratableUnitName;

      }
    });
  }

  getCombineMasterDetails() {
    this.initiationService.combineMaster().subscribe((comboDetails) => {
      console.log("comboDetails",this.comboDetails);
      this.opeTypeList = comboDetails.filter((c) => {
        return c.name == 'OpeType';
      });
      this.mandateTypeList = comboDetails.filter((c) => {
        return c.name == 'Mandate Type';
      });
      this.priorityQcList = comboDetails.filter((c) => {
        return c.name == 'Priority For QC';
      });
      this.caseClassList = comboDetails.filter((c) => {
        return c.name == 'CaseClassification';
      });
      this.proposedSplitList = comboDetails.filter((c) => {
        return c.name == 'Proposed Split';
      });
      this.initiationTypeList = comboDetails.filter((c) => {
        return c.name == 'Initiation Type';
      });
      this.sfFeeRuleList = comboDetails.filter((c) => {
        return c.name == 'SFFeeRule';
      });
      this.qcStatusList = comboDetails.filter((c) => {
        return c.name == 'QC Status';
      });     
    });
  }
  
  getLimitDetails() {

    let companyCode = this.additionalPreviewDetails?.crmCode;
    let producName = this.additionalPreviewDetails?.product;
    let masterServiceId = this.additionalPreviewDetails?.masterService;
    let subServiceId = this.additionalPreviewDetails?.subService;
    let ratableUnitId = this.additionalPreviewDetails?.ratableUnit;
    let instrumentId = this.additionalPreviewDetails?.instrumentId;
    this.isIrfInitiated=this.additionalPreviewDetails?.isIrfInitiated;
      if (companyCode && producName && masterServiceId && subServiceId && this.isIrfInitiated ){
        this.initiationService.getLimitDetails(companyCode, producName, masterServiceId, subServiceId, ratableUnitId, this.agreementId, instrumentId).subscribe(response => {
          if (response.statusCode == 200) {
            // response.data["currentLimit"] = this.additionalPreviewDetails?.quantum;
            response.data["applicableAmount"] = this.additionalPreviewDetails?.quantum;
            if(this.isIrfInitiated) {
              // response.data["applicableAmount"] = this.additionalPreviewDetails?.applicableAmount;
              response.data["currentLimit"] = this.additionalPreviewDetails?.currentLimit;
              if(this.additionalPreviewDetails?.previousLimit) {
                response.data["selectedPreviousLimit"] = {label: this.additionalPreviewDetails?.previousLimit, amount: null, type: ''};
              }
              if(this.previousOpprtunityId) {
                this.initiationService.getCaseDetails(this.previousOpprtunityId).subscribe(caseResponse => {
                  response.data["previousCaseApplicableAmount"] = caseResponse.opportunity.quantum;
                  response.data["inProgressAmount"] = caseResponse.currentLimit;
                  // if(!response.data["selectedPreviousLimit"]) {
                  //   response.data["selectedPreviousLimit"] = {label: ("In Progress - "+caseResponse.opportunity.quantum), amount: caseResponse.opportunity.quantum, type: 'INPROGRESS'};
                  // }
                  this.handleLimitDetailsResponse(response);
                });
              }
              else {
                // if(!response.data["selectedPreviousLimit"]) {
                //   let previousLimitAmount = response.data.currentLimit-response.data.applicableAmount;
                //   if(previousLimitAmount>0) {
                //     response.data["selectedPreviousLimit"] = {label: ("Accepted Outstanding - "+previousLimitAmount), amount: previousLimitAmount, type: 'OUTSTANDING'};
                //   }
                //   else {
                //     response.data["selectedPreviousLimit"] = {label: '0', amount: 0, type: 'NA'};
                //   }
                // }
                this.handleLimitDetailsResponse(response);
              }
            }
           
          }
    
                console.log("response" ,response);
        },
        (_error) => {},
        () => {});
      }
   
  }

  handleLimitDetailsResponse(response: any) {

    this.limitDetails = {
      "outstandingAmount": response.data.outstandingAmount>0?response.data.outstandingAmount.toFixed(2):0,
      "previousCaseApplicableAmount": response.data.previousCaseApplicableAmount>0?response.data.previousCaseApplicableAmount:0,
      "inProgressAmount": response.data.inProgressAmount>0?response.data.inProgressAmount.toFixed(2):0,
      "mandateStatus": response.data.mandateStatus,
      "prevInprogressAgreementId": response.data.agreementId,
      "selectedPreviousLimit": response.data.selectedPreviousLimit.label,
      "currentLimit": response.data.currentLimit>0?response.data.currentLimit.toFixed(2):0,
      "applicableAmount": response.data.applicableAmount>0?response.data.applicableAmount.toFixed(2):0
    };

    console.log("this.limitDetails",this.limitDetails);
  }


  getPaymentStatus(){
    this.pageService.getPaymentStatus().subscribe((res)=>{
      this.paymentStatusList = res;
      this.paymentStatusList = res;
      console.log("paymentStatusList",this.paymentStatusList);
      this.paymentStatusData = (this.paymentStatusList.find((p) => p?.id == (this.additionalPreviewDetails?.paymentStatusId)))?.name; 
      let paymentDetails = [];
      let secOppPaymentDetails=[];
      this.additionalPreviewDetails?.paymentDetails.forEach((payment) =>{
        let paymentStatus = this.paymentStatusList.find((statusData) => statusData.id == payment.paymentStatusId).name;

        payment.paymentStatus = paymentStatus;

        paymentDetails.push(payment);
      });
      this.additionalPreviewDetails?.secOppPaymentDetails.forEach((secOppPayment) =>{
        let secpaymentStatus = this.paymentStatusList.find((statusData) => statusData.id == secOppPayment.paymentStatusId).name;

        secOppPayment.secpaymentStatus = secpaymentStatus;

        secOppPaymentDetails.push(secOppPayment);
      });
      this.additionalPreviewDetails.paymentDetails = paymentDetails;
      console.log("Payment Details with Statuss: ",paymentDetails);
    });
  }
}
