import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { SfBillingService } from '../sf-billing.service';
import { Subscription } from 'rxjs';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';

@Component({
  selector: 'backoffice-sf-estimates',
  templateUrl: './sf-estimates.component.html',
  styleUrls: ['./sf-estimates.component.scss'],
  providers:[MessageService]
})
export class SfEstimatesComponent implements OnInit {
  financialYear;
  entityStatus;
  clientType;
  assignmentFamily;
  empID = this.encDec.decrypt(localStorage.getItem('empid'));

  ccprimebreadcrumbList = [
    {
      label: `SF Estimates`, routerLink: ['/sf-billing/sf-estimates']
    },
  ]
  homeiconccprimebreadcrumb = { icon: `pi pi-home` };

  financialYearListUnfiltered;
  financialYearList;
  entityStatusList;
  clientTypeNameAPIList;
  clientTypeList;
  assignmentFamilyListUnfiltered;
  assignmentFamilyList;
  filHistoryList;

  defaultValues = {
    financialYear: '2023-24',
    entityStatus: 'Cooperative',
    clientType: 'All',
    assignmentFamily: 'All'
  }
  
  sfEstimatesFilterForm : FormGroup;

  onSaveLoader: boolean = false;
  disableFields: boolean = false;
  disableHistoryList: boolean = false;
  showBillEstimatesListApi: boolean = false;
  displayProcessingModal: boolean = false;
  clearAllDisabled: boolean = true;
  disableExport: boolean = true;
  selectAll: boolean = true;
  statusCheck:string;

  timeOutCheck;

  statusCheckOnIntervalSubscription: Subscription;


  constructor(private fb: FormBuilder,private cd: ChangeDetectorRef, private router: Router, private httpClient: HttpClient, private messageService: MessageService, private sfBillingService: SfBillingService,
    private encDec: EncryptDecryptInterceptor) { 
  }
  ngOnInit(): void {
    console.log(this.sfEstimatesFilterForm);
    this.disableHistoryList = true;
    this.getDropdownDataApi();
    this.estimationStatusApiCheck();
    this.financialYear = "2024-25";
    this.entityStatus = "Cooperative";
    this.clientType = "All";
  }

  estimationStatusApiCheck(){

    this.sfBillingService.getSfBillingEstimationStatus().subscribe({
      next: (data: any) =>{
        console.log("check status", data);
        this.statusCheck = data.batchStatus;
        if(data.batchStatus === "Inprogress"){
          this.messageService.clear();
          this.messageService.add({ severity: 'warn', detail: 'Estimation is in progress.' });
          this.disableFields = true;
          this.checkStatusOnInterval();
        }else if(data.batchStatus === "Error"){
          this.messageService.clear();
          this.messageService.add({ severity: 'warn', detail: 'Estimation Failed.' });
          this.disableFields = false;
          setTimeout(() => {
            this.messageService.clear();
          }, 3000);
        }else{
          this.messageService.clear();
          this.disableFields = false;
        }
      },
      error: (err:any) => {
        console.log("estimation status error", err);
      }
    });
  }

  checkStatusOnInterval(){
    this.timeOutCheck = setTimeout(() => {
      this.statusCheckOnIntervalSubscription = this.sfBillingService.getSfBillingEstimationStatusRecheck().subscribe({
        next: (data:any)=>{
          this.statusCheck = data.batchStatus;
          if(data.batchStatus === "Inprogress"){
            this.checkStatusOnInterval();
          }else if(data.batchStatus === "Error"){
            this.messageService.clear();
            this.messageService.add({ severity: 'warn', detail: 'Estimation Failed.' });
            this.disableFields = false;
            setTimeout(() => {
              this.messageService.clear();
            }, 3000);
            this.statusCheckOnIntervalSubscription.unsubscribe();
          }else{
            this.messageService.clear();
            this.disableFields = false;
            this.messageService.add({ severity: 'success', detail: 'Estimation completed.' });
            setTimeout(() => {
              this.messageService.clear();
            }, 3000);
            this.statusCheckOnIntervalSubscription.unsubscribe();
          }
        },error: (err:any)=>{

        }
      })
    }, 3000);
  }

  getDropdownDataApi(){
    //EntityStatus
    this.sfBillingService.getSfEntityStatus().subscribe({
      next: (data :any)=>{
        
        this.entityStatusList = data
        console.log("this.entityStatusList dropdown",this.entityStatusList);
      },
      error: (err:any)=>{
        console.log(err);
      }
    });
    //clientType
    this.sfBillingService.getClientType().subscribe({
      next: (data :any)=>{
        this.clientTypeNameAPIList = data.SUCCESS;
        console.log("this.clientTypeNameAPIList dropdown",this.clientTypeNameAPIList);
        
        const transformedData = [];
        const uniqueLabels = [];

        transformedData.push({label: 'All', value: 'All'});
        this.clientTypeNameAPIList.forEach(item => {
          let label, value;
          if(item.clientTypeName.startsWith('LCG')){
            label='LCG';
            value='LCG'
          }else{
            label =item.clientTypeName;
            value =item.clientTypeName
          }
          //removing duplicates of LCG type
          if(!uniqueLabels[label]){
            transformedData.push({label,value})
            uniqueLabels[label] = true;
          }
        });
        this.clientTypeList = transformedData;
      },
      error: (err:any)=>{
        console.log(err);
        
      }
    });
    //Assignment Family
    this.sfBillingService.getAssignmentFamily().subscribe({
      next: (data:any)=>{
        this.assignmentFamilyListUnfiltered = data;
        const transformedData = this.assignmentFamilyListUnfiltered.map(item => {return {label: item.name, value: item.id};});
        this.assignmentFamilyList = transformedData;
        this.assignmentFamily = this.assignmentFamilyList;
        console.log("AssignemntFamily data dropdown",this.assignmentFamilyList);
      },
      error: (err:any)=>{
        console.log("AssignemntFamily err",err);
      }
    });
    //Financial Year
    this.sfBillingService.getFinancialYear().subscribe({
      next: (data:any)=>{
        this.financialYearListUnfiltered = data;
        const transformedDataFY = this.financialYearListUnfiltered.map(item => { return {id: item.id, label: item.financialYearDescription, value: item.financialYearDescription};});
        this.financialYearList = transformedDataFY;
        console.log("FinancialYear data dropdown",transformedDataFY);
      },
      error: (err:any)=>{
        console.log("FinancialYear err",err);
      }
    });
  }
  

  homeBreadcrumbClicked(event): void {
    if (event.item?.icon == 'pi pi-home') {
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }
  }

  calculateOverallEstimates(){
    this.messageService.clear();
    this.disableFields = true;
    let assignmentFamilySelected;

    if(this.assignmentFamily === undefined || this.assignmentFamily.length == 0){
      this.disableExport = true;
    }else if(this.assignmentFamily.length == this.assignmentFamilyList.length){
      assignmentFamilySelected = [0]
    }else{
      assignmentFamilySelected = this.assignmentFamily.map(item => item.value);
    }

    let payload = {
      "financialYear": this.financialYear,
      "entityStatus": [this.entityStatus],
      "clientType": [this.clientType],
      "assignmentFamily": assignmentFamilySelected,
      "loggedInUserId": this.empID
    }
    console.log("payload",payload);

    if(this.assignmentFamily != null){
      this.sfBillingService.getOverallEstimatesApi(payload).subscribe({
        next: (data:any)=>{
          
          if(data.EstimationStatus === "Estimation is already in progress by other user"){
            this.messageService.clear();
            this.messageService.add({ severity: 'warn', detail: data.EstimationStatus });
            this.disableFields = true;
            setTimeout(() => {
              this.messageService.clear();
              this.estimationStatusApiCheck();
            }, 3000);
          }else if(data.EstimationStatus == undefined){
            this.estimationStatusApiCheck();
            console.log("overall estimates", data);
            this.disableHistoryList = false;
          }
        },
        error: (err: any)=>{
          console.log("overall estimates error", err);
        }
      });
    }
  }

  chipRemoved(){
    this.clearAllDisabled = false;
    this.selectAll = false;
  }

  selectOnChange(){
    console.log("check vals",this.financialYear,this.entityStatus,this.clientType,this.assignmentFamily);
    
    if(this.financialYear != this.defaultValues.financialYear || this.entityStatus != this.defaultValues.entityStatus || this.clientType != this.defaultValues.clientType || this.assignmentFamily[0].label !== "All" || this.assignmentFamily.length > 1){
      this.clearAllDisabled = false;
    }else{
      this.clearAllDisabled = true;
    }

    if(this.assignmentFamily.length < this.assignmentFamilyList.length && this.assignmentFamily.length != 0){
      this.selectAll = false;
    }else{
      this.selectAll = true;
    }
  }

  changeAssignmentFamilyToEmpty(){
    this.assignmentFamily = [];
  }

  handleChipRemoval(event: any) {
    let index = this.assignmentFamily.findIndex(item => item.label === event.label);
    if (index !== -1) {
      this.assignmentFamily.splice(index, 1);
    }
  }

  resetForm(){    
    console.log("assignment family",this.entityStatus);
    // this.financialYear = '2023-24';
    this.financialYear = this.financialYearList[1].label;
    this.entityStatus = 'Cooperative';
    this.clientType = 'All';
    this.assignmentFamily = this.assignmentFamilyList;
    this.clearAllDisabled = true;
    this.selectAll = true;
  }

  showModalBillEstimatesList(){
    this.displayProcessingModal = true;

    this.sfBillingService.getFileHistoryApi().subscribe({
      next: (data:any)=>{
        console.log("file history log",data);
        this.estimationStatusApiCheck();
        this.filHistoryList = data;
        this.displayProcessingModal = false;
        this.showBillEstimatesListApi = true;
      },
      error: (err:any)=>{
        console.log("file history error",err);
      }
    })
  }

  downloadHistoryFile(data:any){
    let payload= {
      "fileId": data.id
    }
    this.sfBillingService.getFileDownloadApi(payload).subscribe({
      next:(res: any) => {
        
        this.downloadFile(res?.Base64, data.fileName);
        this.showBillEstimatesListApi = false;
        
      },
      error: (err:any) =>{
        console.log("download res error",err);
        
      }
    });
  }
  downloadFile(file, name){
    let src = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${file}`;
    const link = document.createElement('a');
    link.href = src;
    link.download = name;
    link.click();
  }

  ngOnDestroy() {
    if(this.timeOutCheck){
      clearTimeout(this.timeOutCheck);
      this.statusCheckOnIntervalSubscription.unsubscribe();
    }
  }

  onSelectAllChange(e){
    if(e.checked){
      this.assignmentFamily = this.assignmentFamilyList;
      this.clearAllDisabled = true;
      this.selectAll = true;
    }else{
      this.changeAssignmentFamilyToEmpty();
      this.clearAllDisabled = false;
      this.selectAll = false;
    }
  }


}

