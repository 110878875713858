import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IrfInitiationDetailsComponent } from '../irf-initiation-details/irf-initiation-details.component';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ViewChildren, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { PagesService } from '../../pages/pages.service';
import { Table } from 'primeng/table';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { switchMap, take } from 'rxjs/operators';
import { InitiationService } from '../initiation.service';
import { MultiSelect } from 'primeng/multiselect';

@Component({
  selector: 'backoffice-irf-initiation-grid',
  templateUrl: './irf-initiation-grid.component.html',
  styleUrls: ['./irf-initiation-grid.component.scss'],
  providers: [DialogService, MessageService]
})
export class InitiationGridComponent implements OnInit {

  initiationDialog: DynamicDialogRef = new DynamicDialogRef;

  // breadcrumb variables - start
  ccprimebreadcrumbList = [
    { label: `IRF Initiation Listing`, routerLink: ['/initiation/irf'] },
  ];
  homeiconccprimebreadcrumb = { icon: `pi pi-home` };
  // breadcrumb variables - end

  // grid related variables - start
  @ViewChildren('mainGrid') mainGrid;
  @ViewChild('mainGrid') mainGrid1: Table;

  initiationForm = this.fb.group({
    entityNameFilter: [null as any, []],
    nexeraUserFilter: [null as any, []],
    bdSpocFilter: [null as any, []],
    opportunityFilter: [[86], []],
    qcStatusFilter: [null as any, []],
    businessFilter: [null as any, []],
    dateFilter: [null as any, []],
    ageingFilter: [null as any, []],
    gridCalender: [null as any, []],
  });
  // grid related variables - end

  gridDataList = [];

  // golbal calendar variables
  rangeDate: any;
  dateTime = new Date();
  filterDisplay = true;
  filterDisplayRow = true;
  globalStartDate: any = null;
  globalEndDate: any = null;
  minGlobalStartTime = new Date();
  maxGlobalEndTime = new Date();
  @ViewChild('globalCalendar') globalCalendar: any;

  opportunityStatus = "Business Reported";
  tableDataCount: any;

  // loader variables
  firstLoad = false;
  apiFail = false;
  gridLoader = false;
  loadingStatus = false;

  //grid dropdown filter 
  oppIdList: any;
  agreementIdList: any;
  nameList: any;
  nexeraUserList: any;
  qcStatusList: any;
  uniqbdSpocList: any;
  opportunityStatusList: Array<any>;
  opportunityStatusIDList: Array<any>;
  businessTypeList: Array<any> = [];

  // search company popup -
  // auto complete company name related variables
  searchCompanyPopUp = false;
  showCross: boolean = false;
  showEntityLoader: boolean = false;
  emptyMsgVal: string = 'No Company Found';
  emptyMsgFlag: boolean = false;
  companyNameList = [];
  companyNameSearchValue: any;

  showFailureModel: boolean = false;
  errorMessage: string = "";
  userId;
  userRole;
  selectAllQC: boolean = false;
  @ViewChild('oppStatusMultiSelectList') oppStatusMultiSelectList: any;
  @ViewChild('oppStatusMultiSelectList') oppStatusMultiSelectList1: MultiSelect;
  @ViewChild('oppQcStatus') oppQcStatus: MultiSelect;
  selectAllOppsStatus: boolean = false;
  selectAllQCStatus: boolean = false;
  selectAllNexeraUser: boolean = false;

  constructor(private renderer: Renderer2, private router: Router, private _location: Location, protected activatedRoute: ActivatedRoute, private fb: FormBuilder, private pagesService: PagesService, private dialogService: DialogService, private messageService: MessageService, private encyDyc: EncryptDecryptInterceptor, private cd: ChangeDetectorRef, private encryptDecryptInterceptor: EncryptDecryptInterceptor, private initionService: InitiationService) {
    this.minGlobalStartTime.setMonth(this.maxGlobalEndTime.getMonth() - 36);
  }

  ngOnInit(): void {
    // opportunity status dropdown picking required status from masters
    this.userId = this.encyDyc.decrypt(localStorage.getItem('empid'));
    this.userRole = this.encyDyc.decrypt(localStorage.getItem('role'));
    this.initionService.getBusinessTypeMaster().subscribe((res: Array<any>) => {
      if (res.length > 0) {
        res?.forEach((r) => {
          this.businessTypeList.push({ label: r.property, value: r.property });
        });
      }
    });
    this.opportunityStatusList = [];
    this.opportunityStatusIDList = [];
    this.pagesService.getOpprStatusForInitiation().pipe(switchMap((status: Array<any>) => {
      if (status?.length > 0) {
        status.forEach(item => {
          this.opportunityStatusList.push({ label: item[1], value: item[0] });
          this.opportunityStatusIDList.push(item[0]);
        });
        this.opportunityStatusList.push({ label: "Initiated", value: 87 });  // Initiated status is not stored as oppr status on backend, had to hard code it for Initiation screen
        this.opportunityStatusIDList.push(87);  // Initiated status is not stored as oppr status on backend, had to hard code it for Initiation screen
        this.initiationForm.patchValue({ opportunityFilter: [this.opportunityStatusIDList[0]] });
        let payload = {
          status: [86],
          startDate: this.globalStartDate,
          endDate: this.globalEndDate,
          companyCode: this.companyNameSearchValue?.companyCode == undefined ? null : this.companyNameSearchValue?.companyCode,
          bdSpocUserId: this.userId,
          userRole: this.userRole,
          isIrfInitiated: null
        };
        this.gridLoader = true;
        return this.getGridList(payload);
      }
      return null;
    })).subscribe({
      next: item => this.setGridItem(item),
      error: err => this.errorStatus(err)
    });
    if (this.initiationForm.get('opportunityFilter')?.value) {
      this.tableDataCount = this.initiationForm.get('opportunityFilter')?.value;
    }

  }

  // ngAfterViewInit(): void {
  //   this.oppStatusMultiSelectList.onChange.emit({originalEvent:null, value:[{label: 'Business Reported',value: 'Business Reported'}], itemValue: {label: 'Business Reported',value: 'Business Reported'}});
  //   this.cd.detectChanges();
  // }

  // breadrumb related method
  homeBreadcrumbClicked(event) {
    if (event.item?.icon == 'pi pi-home') {
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }
  }

  // global calendar related methods - START
  datePicker() {
    this.globalStartDate = this.initiationForm.value?.gridCalender[0]?.split("/").reverse().join("-");

    if (this.globalEndDate != null) {
      this.globalEndDate = null;
      return;
    }

    this.globalEndDate = this.initiationForm.value?.gridCalender[1]?.split("/").reverse().join("-");

    if (!!this.globalStartDate && !!this.globalEndDate) {
      this.globalCalendar.overlayVisible = false;

      let filteredoppfilter = null;
      let checkInitiated = null;
      const checkInitiation = this.initiationForm.get('opportunityFilter')?.value.indexOf(87);
      if (checkInitiation !== -1) {
        checkInitiated = "Y";
        filteredoppfilter = this.initiationForm.get('opportunityFilter')?.value.filter(item => item !== 87);
      } else {
        checkInitiated = null;
        filteredoppfilter = this.initiationForm.get('opportunityFilter')?.value;
      }
      let payload = {
        status: checkInitiated === "Y" && filteredoppfilter.length == 0 ? [] : filteredoppfilter.length == 0 ? [86, 131] : filteredoppfilter,
        startDate: this.globalStartDate,
        endDate: this.globalEndDate,
        companyCode: this.companyNameSearchValue?.companyCode == undefined ? null : this.companyNameSearchValue?.companyCode,
        bdSpocUserId: this.userId,
        userRole: this.userRole,
        isIrfInitiated: filteredoppfilter.length == 0 ? "Y" : checkInitiated
      };
      this.getIrfInitationListingData(payload);
    }
  }
  onClearDate() {
    this.globalStartDate = null;
    this.globalEndDate = null;

    let filteredoppfilter = null;
    let checkInitiated = null;
    const checkInitiation = this.initiationForm.get('opportunityFilter')?.value.indexOf(87);
    if (checkInitiation !== -1) {
      checkInitiated = "Y";
      filteredoppfilter = this.initiationForm.get('opportunityFilter')?.value.filter(item => item !== 87);
    } else {
      checkInitiated = null;
      filteredoppfilter = this.initiationForm.get('opportunityFilter')?.value;
    }
    let payload = {
      status: checkInitiated === "Y" && filteredoppfilter.length == 0 ? [] : filteredoppfilter.length == 0 ? [86, 131] : filteredoppfilter,
      startDate: this.globalStartDate,
      endDate: this.globalEndDate,
      companyCode: this.companyNameSearchValue?.companyCode == undefined ? null : this.companyNameSearchValue?.companyCode,
      bdSpocUserId: this.userId,
      userRole: this.userRole,
      isIrfInitiated: filteredoppfilter.length == 0 ? "Y" : checkInitiated
    };
    this.getIrfInitationListingData(payload);
    this.cd.detectChanges();
  }
  // global calendar related methods - END

  // clear filter
  showFilter(table: Table) {
    this.filterDisplayRow = !this.filterDisplayRow;
    this.filterDisplay = !this.filterDisplay;
    this.cd.detectChanges();
    if (this.filterDisplay == false) {

      table?.reset();
      if (this.mainGrid?.first?.filters) { // reset all filters
        Object.values(this.mainGrid.first.filters).map(item => {
          (<any>item).value = null;
          return item;
        });
      }
      this.companyNameSearchValue = null;
      this.globalStartDate = null;
      this.globalEndDate = null;
      this.initiationForm.get('qcStatusFilter').reset();
      this.initiationForm.get('qcStatusFilter').setValue([]);
      this.initiationForm.get('opportunityFilter').reset();
      this.initiationForm.get('opportunityFilter').setValue([this.opportunityStatusIDList[0]]);
      this.initiationForm.patchValue({ opportunityFilter: [this.opportunityStatusIDList[0]] });
      this.initiationForm.get('gridCalender').reset();
      // if (this.mainGrid1?.filters) {
      //   this.mainGrid1?.filter(this.initiationForm.get('opportunityFilter').value, 'opportunityStatusColList', 'in');
      // }
      // this.oppStatusMultiSelectList1?.onChange.emit({originalEvent:null, value:[{label: 'Business Reported',value: 'Business Reported'}], itemValue: {label: 'Business Reported',value: 'Business Reported'}});
      let payload = {
        status: [86],
        startDate: this.globalStartDate,
        endDate: this.globalEndDate,
        companyCode: this.companyNameSearchValue?.companyCode == undefined ? null : this.companyNameSearchValue?.companyCode,
        bdSpocUserId: this.userId,
        userRole: this.userRole,
        isIrfInitiated: null
      };
      this.getIrfInitationListingData(payload);
    }
    this.initiationForm.get('opportunityFilter').reset();
    this.initiationForm.get('opportunityFilter').setValue([this.opportunityStatusIDList[0]]);
    this.initiationForm.patchValue({ opportunityFilter: [this.opportunityStatusIDList[0]] });
  }

  clear(table: Table) {

  }

  exportExcel() { }

  generateExcel() { }

  onOpportunityClick(rowData) { }

  onInitiationBackBtn() {
    sessionStorage.removeItem('urlRedirectedTo');
    sessionStorage.setItem('dashboard', 'true');
    window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
  }

  getGridList(payload) {
    return this.pagesService.findAllIrfInitiation(payload);
  }

  getIrfInitationListingData(payload) {
    if (!this.loadingStatus) // show only one loader at a time
      this.gridLoader = true;
    this.getGridList(payload).subscribe({
      next: item => this.setGridItem(item),
      error: err => this.errorStatus(err)
    });
  }

  getOpprByStatus(opportunityStatus: any) {
    if (opportunityStatus) {
      this.tableDataCount = opportunityStatus;
    }
    let filteredoppfilter = null;
    let checkInitiated = null;
    const checkInitiation = opportunityStatus.indexOf(87);
    if (checkInitiation !== -1) {
      checkInitiated = "Y";
      filteredoppfilter = opportunityStatus.filter(item => item !== 87);
    } else {
      checkInitiated = null;
      filteredoppfilter = opportunityStatus;
    }

    let payload = {
      status: checkInitiated === "Y" && filteredoppfilter.length == 0 ? [] : filteredoppfilter.length == 0 ? [86, 131] : filteredoppfilter,
      startDate: this.globalStartDate,
      endDate: this.globalEndDate,
      companyCode: this.companyNameSearchValue?.companyCode == undefined ? null : this.companyNameSearchValue?.companyCode,
      bdSpocUserId: this.userId,
      userRole: this.userRole,
      isIrfInitiated: filteredoppfilter.length == 0 ? "Y" : checkInitiated
    };
    if (this.firstLoad)
      this.loadingStatus = true;
    this.getIrfInitationListingData(payload);
    if (this.initiationForm.get('opportunityFilter')?.value.length == this.opportunityStatusIDList.length) {
      this.selectAllOppsStatus = true;
    } else {
      this.selectAllOppsStatus = false;
    }
  }

  getIrfInitiationDetails(initiationDetails: any) {
    console.log("Row clicked :: ", initiationDetails);
    this.initiationDialog = this.dialogService.open(IrfInitiationDetailsComponent, {
      contentStyle: { "height": "76vh", "overflow": "hidden" },
      closeOnEscape: false,
      closable: true,
      data: initiationDetails,
      width: '96%',
      header: initiationDetails?.name
    });

    this.initiationDialog.onClose.subscribe((closeResponse: any) => {
      console.log(closeResponse, 'ccc')
      if (typeof closeResponse != 'string' && closeResponse && closeResponse.status?.toLowerCase() == 'error' && closeResponse?.showMsg) {
        console.log("Showing Error Message ccc");
        this.showFailureModel = true;
        this.errorMessage = closeResponse.message;
      } else {
        if (closeResponse?.toLowerCase() == "data saved successfully") {
          this.messageService.add({ severity: 'success', summary: 'Data save successfully' });
        }
        else if (closeResponse != undefined) {
          this.messageService.add({ severity: 'error', detail: 'Error while updating data, please try again!' });
        }
        setTimeout(() => {
          this.messageService.clear();
        }, 3000);
      }

      let filteredoppfilter = null;
      let checkInitiated = null;
      const checkInitiation = this.initiationForm.get('opportunityFilter')?.value.indexOf(87);
      if (checkInitiation !== -1) {
        checkInitiated = "Y";
        filteredoppfilter = this.initiationForm.get('opportunityFilter')?.value.filter(item => item !== 87);
      } else {
        checkInitiated = null;
        filteredoppfilter = this.initiationForm.get('opportunityFilter')?.value;
      }
      let payload = {
        status: checkInitiated === "Y" && filteredoppfilter.length == 0 ? [] : filteredoppfilter.length == 0 ? [86, 131] : filteredoppfilter,
        startDate: this.globalStartDate,
        endDate: this.globalEndDate,
        companyCode: this.companyNameSearchValue?.companyCode == undefined ? null : this.companyNameSearchValue?.companyCode,
        bdSpocUserId: this.userId,
        userRole: this.userRole,
        isIrfInitiated: filteredoppfilter.length == 0 ? "Y" : checkInitiated
      };
      this.getIrfInitationListingData(payload);
    });
  }

  showSearchCompanyPopUp() {
    if (!this.gridLoader && !this.loadingStatus)
      this.searchCompanyPopUp = true;
  }

  searchCompanyPopupClosed() {
    this.searchCompanyPopUp = false;
  }

  onChangeCompanySelect(event) {
    console.log("Event :: ", event);
  }

  applyCompanySearch() {
    let filteredoppfilter = null;
    let checkInitiated = null;
    const checkInitiation = this.initiationForm.get('opportunityFilter')?.value.indexOf(87);
    if (checkInitiation !== -1) {
      checkInitiated = "Y";
      filteredoppfilter = this.initiationForm.get('opportunityFilter')?.value.filter(item => item !== 87);
    } else {
      checkInitiated = null;
      filteredoppfilter = this.initiationForm.get('opportunityFilter')?.value;
    }
    let payload = {
      status: checkInitiated === "Y" && filteredoppfilter.length == 0 ? [] : filteredoppfilter.length == 0 ? [86, 131] : filteredoppfilter,
      startDate: this.globalStartDate,
      endDate: this.globalEndDate,
      companyCode: this.companyNameSearchValue?.companyCode == undefined ? null : this.companyNameSearchValue?.companyCode,
      bdSpocUserId: this.userId,
      userRole: this.userRole,
      isIrfInitiated: filteredoppfilter.length == 0 ? "Y" : checkInitiated
    };
    this.getIrfInitationListingData(payload);
    this.searchCompanyPopUp = false;
  }

  loadCompanyList(event) {
    if (event?.query?.length >= 3) {
      this.showEntityLoader = true;
      this.pagesService.getCompanySearchResultsIrfInitiation(event.query).subscribe(
        (results: any) => {
          const responseData = results;
          this.showEntityLoader = false;
          this.companyNameList = [];
          if (responseData && responseData.length > 0) {
            this.companyNameList = responseData.map(
              item => ({ companyCode: item[0], companyName: item[1] }));
          } else {
            this.companyNameList = [];
            this.emptyMsgFlag = true;
          }
        }, (_err) => {
          this.companyNameList = []
          this.showEntityLoader = false;
          this.emptyMsgFlag = true;
          this.showCross = false;
        });
    } else {
      this.companyNameList = [];
      this.showCross = false;
    }
  }

  resetCompanySearch() {
    this.companyNameSearchValue = null;

    let filteredoppfilter = null;
    let checkInitiated = null;
    const checkInitiation = this.initiationForm.get('opportunityFilter')?.value.indexOf(87);
    if (checkInitiation !== -1) {
      checkInitiated = "Y";
      filteredoppfilter = this.initiationForm.get('opportunityFilter')?.value.filter(item => item !== 87);
    } else {
      checkInitiated = null;
      filteredoppfilter = this.initiationForm.get('opportunityFilter')?.value;
    }
    let payload = {
      status: checkInitiated === "Y" && filteredoppfilter.length == 0 ? [] : filteredoppfilter.length == 0 ? [86, 131] : filteredoppfilter,
      startDate: this.globalStartDate,
      endDate: this.globalEndDate,
      companyCode: this.companyNameSearchValue?.companyCode == undefined ? null : this.companyNameSearchValue?.companyCode,
      bdSpocUserId: this.userId,
      userRole: this.userRole,
      isIrfInitiated: filteredoppfilter.length == 0 ? "Y" : checkInitiated
    };
    this.getIrfInitationListingData(payload);
    this.companyNameSearchValue = null;
    this.searchCompanyPopUp = false;
  }

  // called under findAll subscription
  setGridItem(item) {
    console.log("Set item :: ", item);
    this.gridDataList = <[]>item;
    console.log("Grid List :: ", this.gridDataList);
    this.oppIdList = new Set();
    this.agreementIdList = new Set();
    this.nameList = new Set();
    this.nexeraUserList = new Set();
    this.qcStatusList = new Set();
    this.uniqbdSpocList = new Set();

    this.gridDataList.forEach(item => {
      this.oppIdList.add(item.opportunityId);
      item.oppIdByList = { name: item.opportunityId };

      this.agreementIdList.add(item.agreementId);
      item.aggIdByList = { name: item.agreementId };

      this.nameList.add(item.name);
      item.entityNamebyList = { name: item.name };

      this.nexeraUserList.add(item.nexeraUser);
      item.nexeraUserbyList = { name: item.nexeraUser };

      if (item.qcStatus == null || item.qcStatus == undefined) {
        this.qcStatusList.add('');
        item.qcStatusColumnList = { name: '' };
      } else {
        this.qcStatusList.add(item.qcStatus);
        item.qcStatusColumnList = { name: item.qcStatus };
      }

      this.uniqbdSpocList.add(item.bdSpoc);
      item.bdSpocbyList = { name: item.bdSpoc };

      const reportedDate = item?.reportedDate?.split("-").reverse().join("-");
      item.reportedDate = reportedDate;
      const indexBusinessReported = this.opportunityStatusList.find(item => item.value === 86);
      const indexInitiated = this.opportunityStatusList.find(item => item.value === 87);
      const indexCaseClose = this.opportunityStatusList.find(item => item.value === 131);

      if (item.isIRFInitiated == null && item.opportunityStatus === indexBusinessReported.label) {
        item.opportunityStatus = item.opportunityStatus;
      } else if (item.isIRFInitiated === "Y" && item.opportunityStatus === indexBusinessReported.label) {
        item.opportunityStatus = indexInitiated.label;
      } else if ((item.qcStatus == null || item.qcStatus != null) && item.opportunityStatus === indexCaseClose.label) {
        item.opportunityStatus = item.opportunityStatus;
      }

      item.irfActualFees = item.irfActualFees ? Number(item.irfActualFees) : null;

    });

    this.oppIdList = Array.from(this.oppIdList).map(item => ({ name: item }));
    this.agreementIdList = Array.from(this.agreementIdList).map(item => ({ name: item }));
    this.nameList = Array.from(this.nameList).map(item => ({ name: item }));
    this.nexeraUserList = Array.from(this.nexeraUserList).map(item => ({ name: item }));
    this.qcStatusList = Array.from(this.qcStatusList).map(item => {
      if (item == null || item == undefined) {
        return { name: '' }
      } else {
        return { name: item }
      }
    });
    this.uniqbdSpocList = Array.from(this.uniqbdSpocList).map(item => ({ name: item }));

    this.gridLoader = false;
    this.loadingStatus = false;
    this.firstLoad = true;
    this.checkQCStatusAllSelection(this.initiationForm.get('qcStatusFilter')?.value);
  }

  // called on findAll error
  errorStatus(err) {
    console.error("Error while fetchin IRF initiation grid data :: ", err);
    this.gridLoader = false;
    this.loadingStatus = false;
    this.messageService.add({ severity: 'error', detail: 'Error while fetching data, try again! ' });
    setTimeout(() => {
      this.messageService.clear();
    }, 3000);
  }

  applyFilter(event: any) {
    console.log("event", event);
    if (event.checked) {
      this.selectAllOppsStatus = event.checked;
      this.initiationForm.get('opportunityFilter').reset();
      this.initiationForm.patchValue({ opportunityFilter: this.opportunityStatusIDList });
    } else {
      this.selectAllOppsStatus = event.checked;
      this.initiationForm.get('opportunityFilter').reset();
      this.initiationForm.patchValue({ opportunityFilter: [] });
    }
    this.getOpprByStatus(this.initiationForm.get('opportunityFilter')?.value);
  }

  applyFilterQC(event: any) {
    console.log("event", event);
    console.log("event this.qcStatusList", this.qcStatusList);
    if (event.checked) {
      this.selectAllQCStatus = event.checked;
      this.initiationForm.get('qcStatusFilter').reset();
      this.initiationForm.patchValue({ qcStatusFilter: this.qcStatusList });
      if (this.mainGrid1) {
        this.mainGrid1.filter(this.initiationForm.get('qcStatusFilter')?.value, 'qcStatusColumnList', 'in');
      }
    } else {
      this.selectAllQCStatus = event.checked;
      this.initiationForm.get('qcStatusFilter').reset();
      this.initiationForm.patchValue({ qcStatusFilter: [] });
    }
    this.checkQCStatusAllSelection(this.initiationForm.get('qcStatusFilter')?.value)
  }

  checkQCStatusAllSelection(eValue) {
    console.log("eValue", eValue);
    if ((eValue !== null || eValue !== undefined) && (eValue?.length == this.qcStatusList?.length)) {
      if (eValue?.length == this.qcStatusList?.length) {
        if (eValue === this.qcStatusList) {
          this.initiationForm.get('qcStatusFilter').reset();
          this.initiationForm.patchValue({ qcStatusFilter: this.qcStatusList });
          this.selectAllQCStatus = true;
        } else {
          this.selectAllQCStatus = true;
          this.initiationForm.get('qcStatusFilter').reset();
          this.initiationForm.patchValue({ qcStatusFilter: eValue });
        }
      }
    } else if ((eValue !== null || eValue !== undefined) && (eValue?.length !== this.qcStatusList?.length)) {
      if (eValue?.length > this.qcStatusList?.length) {
        this.initiationForm.get('qcStatusFilter').reset();
        this.initiationForm.patchValue({ qcStatusFilter: this.qcStatusList });
        this.selectAllQCStatus = true;
      } else {
        if (eValue === this.qcStatusList) {
          this.initiationForm.get('qcStatusFilter').reset();
          this.initiationForm.patchValue({ qcStatusFilter: this.qcStatusList });
          this.selectAllQCStatus = true;
        } else {
          // this.initiationForm.get('qcStatusFilter').reset();
          // this.initiationForm.patchValue({ qcStatusFilter: [] });
          this.selectAllQCStatus = false;
        }
      }
    } else {
      this.selectAllQCStatus = false;
    }
  }
}
