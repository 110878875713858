import { Component, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { ReportingAttachmentComponent } from '../ReportingAttachment/ReportingAttachment.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { rowData } from './ReportingScreenModel';
import { ReportingValidationComponent } from '../reporting-validation/reporting-validation.component';
import { ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";
import { DomSanitizer } from '@angular/platform-browser';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'jhi-reportingscreendetails',
  templateUrl: './ReportingScreenDetails.component.html',
  styleUrls: ['./ReportingScreenDetails.component.scss'],
  providers: [DialogService, MessageService, ConfirmationService]
})
export class ReportingScreenDetailsComponent implements OnInit, OnDestroy {
  attachData: any[] = [];
  uploadAttachData: any[] = [];
  displayAttachData: any[] = [];
  displayAttachData2: any[] = [];
  migratedDocs: any[] = [];
  @ViewChildren('fileUploadfileUpload13') fileUploadfileUpload12;
  displayUploadAttachData: any[] = [];
  croppedImagefileUpload1: any = '';
  fileUploadfileUpload1: any = {
    files: []
  };
  filenameForfileUpload1: any;
  fileToRemovefileUpload1: any;
  imageChangedEventfileUpload1: any;
  returnBase64: any; //Added
  isEdited = false;
  isEdit = false;
  imgChangeEvt: any = "";
  cropImgPreview: any = "";
  displayModal: boolean;
  displayPreview: boolean;
  fileNameAfterUpload: string;
  fileTypeAfterUpload: string;
  fileUploaded: boolean = false;
  fileUploaded0: boolean = false;
  id = '0';
  fileUploadedDone: boolean = true;
  displayEditTypeModal: boolean;
  documentToBeUploaded = this.fb.group({
    finriskattachment: [null, []],
    thirdPartyPaymentApproval: [null, []],
    macAttachment: [null, []],
    clientTypeAttachment: [null, []],
    standardPricingApprovalAttachment: [null, []],
    invoicebeforePaymentApprovalAttachment: [null, []],
    internalPricingApprovalAttachment: [null, []],
    gstPortalSnapshotAttachment: [null, []],
    gstDocumentAttachment: [null, []],
    panDocumentAttachment: [null, []],
    approvalWithoutDSAttachment: [null, []],
    sezDocumentAttachment: [null, []],
    chequeAttachment: [null, []],
    depositSlipAttachment: [null, []]
  });

  checked: boolean = false;
  checked1: boolean = false;
  checked2: boolean = false;
  checked3: boolean = false;
  checked4: boolean = false;
  checked5: boolean = false;

  ReportingScreenDetailsForm = this.fb.group({
    EntityDetailstabview: [0],
    AttachmentInnertabview: [0],
    entityName: [null, []],
    opportunityStatus: [null, []],
    businessWonDate: [null, []],
    entityCompanyCode: [null, []],
    companyName: [null as any, [Validators.required]],
    CRMCodetextField: [null, []],
    FinRisktextField: [null, []],
    RefNotextField: [null as any, [Validators.minLength(1), Validators.required, Validators.maxLength(100)]],
    GroupCompanytextField: [null, []],
    rptCC: [null, []],
    rptCRM: [null, []],
    rptOverall: [null as any, []],
    finComment: [null, []],
    tanDetails: [null, []],
    triParty: [null, []],
    triCrm: [null, []],
    triCrmCode: [null, []],
    triFinRisk: [null, []],
    triFinComment: [null, []],
    triSalutation: [null, []],
    triFirstName: [null, []],
    triLastName: [null, []],
    triDesignation: [null, []],
    triMobile: [null, []],
    triLandline: [null, []],
    triEmail1: [null, []],
    triEmail2: [null, []],
    triEmail3: [null, []],
    triGstType: [null, []],
    triGstNo: [null, []],
    triPanNo: [null, []],
    triPinCode: [null, []],
    triCity: [null, []],
    triState: [null, []],
    triAddress1: [null, []],
    triAddress2: [null, []],
    triAddress3: [null, []],
    triAddress4: [null, []],
    sfFeeRule: [null as any, []],
    minimum: [null as any, []],
    maximum: [null as any, []],
    sfActualPercent: [null as any, []],
    sfExpectedPercent: [null as any, []],
    CompanyTypeselect: [null, []],
    INCChecktextField: [null, []],
    INCNextSteptextField: [null, []],
    salutationselect: [null, [Validators.required]],
    firstNametextField: [null, [Validators.required]],
    lastNametextField: [null, [Validators.required]],
    designationselect: [null, [Validators.required]],
    mobileNotextField: [null, [Validators.required]],
    landlineNotextField: [null, []],
    emailtextField1: [null, [Validators.required]],
    emailtextField2: [null, []],
    emailtextField3: [null, []],
    GSTtypeselect: [null, [Validators.required]],
    GSTNotextField: [null as any, []],
    PANnotextField: [null as any, []],
    pinCodetextField: [null as any, [Validators.required]],
    CitytextField: [null, [Validators.required]],
    StatetextField: [null, []],
    address1: [null, []],
    address2: [null, []],
    address3: [null, []],
    textField: [null, []],
    opportunityId: [null, []],
    agreementId: [null, []],
    qcStatus: [null, []],
    businessType: [null, []],
    productselect: [null, []],
    instrumentselect: [null, []],
    quantumtextField1: [null, []],
    maCase: [null, []],
    irfexpectedFee: [null, []],
    irfactualFee: [null, []],
    irfDiscount: [null, []],
    irfDiscountPercent: [null, []],
    irfDiscountReason: [null, []],
    sfExpectedFee: [null as any, []],
    sfActualFee: [null as any, []],
    revisedSfActual: [null as any, []],
    sfDiscountPremium: [null as any, []],
    sfDiscountPremiumPercent: [null as any, []],
    sfDiscountReason: [null, []],
    paymentMode1: [null, []],
    bank1: [null, []],
    dateCalendar1: [null, [Validators.required]],
    tdsType1: [null, []],
    amountCollected1: [null, []],
    grossAmount1: [null, []],
    paymenttype1: [null, []],
    tdsAmount1: [null as any, []],
    chequeNEFTdetails1: [null, [Validators.required, Validators.minLength(1)]],
    paymentMode2: [null, []],
    bank2: [null, []],
    dateCalendar2: [null, [Validators.required]],
    tdsType2: [null, []],
    amountCollected2: [null, []],
    grossAmount2: [null, []],
    paymenttype2: [null, []],
    tdsAmount2: [null as any, []],
    chequeNEFTdetails2: [null, [Validators.required, Validators.minLength(1)]],
    paymentMode3: [null, []],
    bank3: [null, []],
    dateCalendar3: [null, [Validators.required]],
    tdsType3: [null, []],
    amountCollected3: [null, []],
    grossAmount3: [null, []],
    paymenttype3: [null, []],
    tdsAmount3: [null as any, []],
    chequeNEFTdetails3: [null, [Validators.required, Validators.minLength(1)]],
    PartyApproval: [null, []],
    partyApprovalComment: [null as any, []],
    UserIdInContact: [null, []],
    comment: [null, []],
    costCenterselect: [null, [Validators.required]],
    clientType: [null, []],
    exposureperMCAtextField: [null, [Validators.required]],
    MCAstatus: [null, []],
    exposureperField: [null, [Validators.required]],
    standardApprovalNeeded: [null as any, []],
    standardApprovalReceived: [null as any, []],
    InternalApprovalNeeded: [null, []],
    criticalDocReceived: [null, []],
    InternalApprovalReceived: [null, []],
    businessActionselect: [null, [Validators.required]],
    reasonselect: [null as any, []],
    actionComment: [null, []],
    pageData: [null],
    entity_id: [null, []],
    categoryName: [null, []],
    existingArc: [null, []],
    tripartyContactName: [null as any, []],
    tripartyMandate_Id: [null, []],
    feeDetails_id: [null, []],
    is_default: [null, []],
    is_reporting: [null, []],
    entityContactId: [null, []],
    reportingDetailsId: [null, []],
    trypartycompany: [null, []],
    entityType_id: [null, []],
    attachmentSelected: [null as any, []]
  });
  attachedFilesSource: any;
  pageData: any;
  pageloadvalues: any;
  entityName: any;
  opportunityStatus: any;
  businessWonDate: any;
  entityCompanyCode: any;
  htmlSrc: any;
  html1Src: any;
  html2Src: any;
  html3Src: any;
  companyNameList: any[] = [];
  businessTypeList: any[] = [];
  CompanyTypeselectList: any[] = [];
  salutationselectResponse: any = {};
  salutationselectList: any[] = [];
  designationselectResponse: any = {};
  designationselectList: any[] = [];
  GSTtypeselectResponse: any = {};
  GSTtypeselectList: any[] = [];
  productselectList: any[] = [];
  instrumentselectList: any[] = [];
  irfDiscountReasonResponse: any = {};
  irfDiscountReasonList: any[] = [];
  paymentModeResponse: any = {};
  paymentModeList: any[] = [];
  bankResponse: any = {};
  bankList: any[] = [];
  bankLoadAtOnce: any[] = [];
  tdsTypeResponse: any = {};
  tdsTypeList: any[] = [];
  paymenttypeResponse: any = {};
  paymenttypeList: any[] = [];
  paymentdetailsList: any[] = [];
  PartyApprovalResponse: any = {};
  PartyApprovalList: any[] = [];
  costCenterselectList: any[] = [];
  businessActionselectList: any[] = [];
  reasonselectList: any[] = [];
  cityList: any[] = [];
  ByPinCodePopulation: any;
  fetchCityByPin: any;
  OpportunityId: any;
  clonedProducts: { [s: number]: rowData; } = {};
  search1LoadAtOnce: any[] = [];
  deleteFile: boolean = false;
  companyNameShowFlag: boolean = false;
  email2Flag: boolean
  email3Flag: boolean
  isInvalid = true
  attachmentTypeList: any[] = [];
  attachmentTypeList2: any[] = [];
  existingAttachmentsLists: any[] = [];
  newAttachmentTypeList: any[] = [];
  updatedAttachmentType: any;
  toShowCheckboxFlag: boolean;
  validationDocList: any = [];


  gstTypeEnable = true;
  panCondition = true;
  panMandate = false;
  PartyApprovalModel: any;
  thirdPartyFlag: boolean = false;
  actionReasonFlag: boolean;
  showReasonFlag: boolean = false;
  actionCommentFlag: boolean = false;

  finDocumentUploaded: string;
  thirdPartyDocumentUploaded: string;
  mcaDocumentUploaded: string;
  clientTypeUploaded: string;
  standardPricingApprovalUploaded: string;
  invoicebeforePaymentApprovalUploaded: string;
  internalPricingApprovalUploaded: string;
  gstPortalSnapshotUploaded: string;
  gstDocumentUploaded: string;
  panDocumentUploaded: string;
  approvalWithoutDSUploaded: string;
  sezDocumentUploaded: string;
  chequeUploaded: string;
  depositSlipUploaded: string;
  companyCodeForTan: boolean = false;

  standardApprovalFlag: boolean;
  internalPricingApprovalAttachmentFlag: boolean = false;
  controlInternalApprovalReceivedFlag: boolean;
  grossAmountMoreFlag: boolean = false;
  grossAmountLessFlag: boolean = false;
  toggle: boolean = false;
  dynamicDialogdialogRef: DynamicDialogRef = new DynamicDialogRef;
  isCompanyName: boolean = false;
  isExposureperMCA: boolean = false;
  isSalutationSelect: boolean = false;
  isFirstName: boolean = false;
  isLastName: boolean = false;
  isDesignation: boolean = false;
  isMobileNo: boolean = false;
  isEmail1: boolean = false;
  isGSTtype: boolean = false;
  isPanNo: boolean = false;
  isGstNoIncorrect: boolean = false;
  isGstNoInvalid: boolean = false;
  isPinCode: boolean = false;
  isCity: boolean = false;
  isBAaction: boolean = false;
  isPartyAppComment: boolean = false;
  isCostCenter: boolean = false;
  isBAreason: boolean = false;
  isBAComment: boolean = false;
  isEmail2: boolean = false;
  isEmail3: boolean = false;
  paymentDetailsMandatoryFlag: boolean = false;
  standardApprovalNeededEnabled: any = true;
  standardApprovalReceivedEnabled: any = false;
  InternalApprovalNeededEnabled: any = false;
  InternalApprovalReceivedEnabled: any = false;
  internalApprovalNeededFlag: boolean;
  criticalDocReceivedFlag: boolean;

  name: any;
  thirdPartyName: any;
  submitedAttachmentType: any;
  SelectedAttachmentType: any;
  SelectedAttachmentTypeFlag: boolean = false;
  mcaName: any;
  result: any[] = [];
  currentFileEvent: any;
  clientTypeName: any;
  standardPricingApprovalName: any;
  invoicebeforePaymentApprovalName: any;
  internalPricingApprovalName: any;
  gstPortalSnapshotName: any;
  gstDocumentName: any;
  panDocumentName: any;
  approvalWithoutDSName: any;
  sezDocumentName: any;
  chequeName: any;
  depositSlipName: any;
  returnUploaded: any;
  empId: string;
  fileType: any;
  imageSrc: any;
  combinedPdfView: any = [];
  combinedPdfData: any = [];
  combinedPdfDeleteData: any = [];
  clientTypeList: any = []
  deleteCombinedF: boolean = false;
  deleteCombineFileData: any;
  payment1Flag: boolean = false;
  payment2Flag: boolean = false;
  payment3Flag: boolean = false;
  paymentOnLoadData: any;
  fileUrl: any;
  obj: any = {};
  from: any;
  refNo: any;


  finThumbSrc: any;
  thirdPartyThumbSrc: any;
  macAttachThumbSrc: any;
  clientTypeThumbSrc: any;
  standardPricingThumbSrc: any;
  invoiceBeforeThumbSrc: any;
  internalPricingThumbSrc: any;
  gstPortalThumbSrc: any;
  gstDocumentThumbSrc: any;
  panDocumentThumbSrc: any;
  approvalDSThumbSrc: any;
  sezDocThumbSrc: any;
  chequeThumbSrc: any;
  depositSlipThumbSrc: any;
  docList: any = [];
  allDocList = [];
  onSaveLoader: boolean;
  selectedOpportunityStatus: any;
  otherOpportunityStatusFlag: boolean;
  errorMessageOnSave: string = " ";
  businessReportedStatusFlagA: boolean;
  businessWonStatusFlagA: boolean;
  otherOpportunityStatusFlagA: boolean;
  caseCloseFlag: boolean = false;

  isFinRiskAttachmentM: boolean;
  isMCAAttachmentM: boolean;
  isClientTypeApprovalM: boolean;
  isStandardPricingApprovalAttachmentM: boolean;
  isGSTPortalSnapshotM: boolean;
  isApprovalPaymentM: boolean;
  isInternalPricingApprovalAttachmentM: boolean;
  isInvoicebeforepaymentApprovalM: boolean;
  saveButtonClicked: boolean = false;
  duplicateDocList: any[];
  mandetoryFileArray: any = [];
  isInternalPricingFile: boolean = true;
  businessReportedPermanentFlag: boolean;
  errorEntityDetails: boolean;
  errorEntityContacts: boolean;
  errorOpportunityDetails: boolean;
  errorMcaCheck: boolean;
  errorAttachments: boolean;
  errorApprovals: boolean;
  errorAction: boolean;
  CRM_Code: any;
  showCompanyMergePopup: boolean = false;
  showSameTripartyPopup: boolean = false;
  isAlreadyCRMEntity: any = "No";
  reportingValidationRef: DynamicDialogRef = new DynamicDialogRef;
  scopeList: any;
  COARoleFlag: boolean;
  isActionReasonTouched: boolean = false;
  dateAdded: any = [];
  isIntApprovalNeeded: boolean;
  IntApprovalNeededM: boolean;
  isAttachmentM: boolean = true;
  disableStdPricing: boolean;
  RefNoValidationFlag: boolean = true;
  isREF: boolean;
  RefNotextFieldCheck: boolean;
  RefNotValid: boolean;
  dateValidationFlag: boolean;
  refDuplicateFlag: boolean;
  gstAutoClear: boolean = false;
  isGstNoM: boolean = false;
  refNoCaps: any = null;
  dynamicRefNoMask: any = null;
  tripartyName: any;
  reportingReadScope: boolean = false;
  combinePDFArray: any = [];
  enablePDF: boolean = false;
  showCombinePDFPopUp: boolean = false;
  multiFieldClear: boolean = false;
  showCrmLoader: boolean = false;
  disabledExposureMca: boolean = false;
  urlSafe: any;
  dataLoader: boolean = false;
  zoomValue: number = 1;
  disableStdPricingReceived: boolean = true;
  approvalTabData: any = {};
  actionTabData: any = {};
  transform: ImageTransform = {};
  canvasRotation = 0;
  apiFail: boolean = false;
  previewFailed: boolean = false;
  emptyMsgVal: string = 'No Entity Found.';
  emptyMsgFlag: boolean = false;
  openedFile: any;
  bdRole: any;
  approvalNeededFlag: boolean = false;
  multipleCompany: boolean = false;
  pdfThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/pdfThumbnail.svg`;
  wordThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/wordThumbnail.svg`;
  imgThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/jpgThumbnail.svg`;
  mailThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/mail.svg`;

  sfActualFeesNA: boolean = false;
  sfIdealFeeNA: boolean = false;
  irfIdealFeeNA: boolean = false;
  instQuan_prodIds = [1, 3, 6, 7, 8, 10, 11, 13, 17, 18, 20, 21, 130, 143, 146, 147, 148, 149, 150, 151, 153];
  sfIdealActual_prodIds = [4, 6, 7, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 30, 31, 32, 33, 34, 35, 36, 37, 38, 50, 130, 140, 141, 143, 144, 145, 146, 151, 152];
  quanProdId: any;
  quantumNA: boolean = false;

  paymentForm = this.fb.group({
    payments: this.fb.array([]),
  });
  openedPaymentData: FormArray;
  isCheque: boolean;
  paymentIndex: any;
  isPaymentContact: boolean = false;
  preview: any;
  apiList: any = [];
  disabledSfAct: boolean = false;
  timer: any;
  productId: any;
  sfFeeRuleList: any = [];
  productConfig: any = [];
  categoryList: any = [];
  feeObj: any = [];
  sfFeeDetails: any = {};
  productExposureList: any = [];
  attachToDelete: any = [];
  disableSfFeeRule: boolean = false;

  // Additional Payment and IRF initiation related fields
  paymentCollected: string;
  isAdditionalPayment: boolean;
  trypartyselectList: any;
  paymentRelatedApproval: boolean;
  PAYMENT_RELATED_APPROVAL_STR = 'Payment Related Approval';
  qcStatusList: any = [];
  isQcRejected: boolean = false;
  isQcApproved: boolean = false;
  attachmentRejected: boolean = false;
  additionalPayResubmit: boolean = false;
  ogMandateUpdated: boolean = false;
  redirectPopup: boolean = false;
  allowRedirection: boolean = false;
  userId: any;
  isMigrated: boolean = false;
  ogMandateRejected: boolean = false;

  qcList: any;
  qcRejectMap: any = {};
  payStatusList: any = [];
  allQCPayments: any = [];
  clientTypeId: any;
  uploadedFileIds: any = [];
  uploadLoader: boolean = false;
  showMinMax: boolean = false;

  constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: FormBuilder, private pagesService: PagesService, public ref: DynamicDialogRef, private dialogService: DialogService, public config: DynamicDialogConfig, private messageService: MessageService, public sanitizer: DomSanitizer, private confirmationService: ConfirmationService, private encryptDecryptInterceptor: EncryptDecryptInterceptor) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.setPageProperties(JSON.parse(data));
    }
  }

  get payments() {
    return this.paymentForm.controls['payments'] as FormArray
  }

  ngOnInit(): void {
    this.dynamicRefNoMask = "M - aaa/99999999/aaaa/9999999";
    this.onSaveLoader = true;
    this.OpportunityId = this.config.data.opportunityId;
    this.isAdditionalPayment = false;
    this.selectedOpportunityStatus = this.config.data.opportunityStatus;
    this.scopeList = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('scopes'));
    this.bdRole = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('role'));
    this.userId = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid'))
    this.paymentRelatedApproval = false;
    let scope: any = this.scopeList.split(",")
    if ((scope.indexOf('COA_EDIT') != -1)) {
      this.COARoleFlag = false;
    } else {
      this.COARoleFlag = true;
    }
    this.getPayStatus();
    this.apiList.push(this.pagesService.getDocList().subscribe(res => {
      res.forEach((r) => {
        if (r.attachment_activity == 112) {
          if (r.type != 'Combine Doc') {
            if (["Mandate,Page 1", "Mandate,Page 2", "Mandate,Page 3", "Mandate,Page 4", "Mandate,Page 5",
              "Mandate,Page 6", "Mandate,Page 7", "Mandate,Page 8", "Mandate,Page 9", "Mandate,Page 10"].includes(r.type)) {
              this.docList.push({
                "type": r.type,
                "is_mandatory": 'O',
                "id": r.id,
                "file": '',
                "fileName": '',
                "thumbNail": '',
                "isDisabled": false
              });
            } else {
              this.docList.push({
                "type": r.type,
                "is_mandatory": r.is_mandatory,
                "id": r.id,
                "file": '',
                "fileName": '',
                "thumbNail": '',
                "isDisabled": false
              });
            }
          }
        }
      });
      if ((this.selectedOpportunityStatus != "Business Reported") && (this.selectedOpportunityStatus != "Business Won")) {
        this.docList.forEach((doc) => {
          doc.isDisabled = true;
        });
      } else if (this.selectedOpportunityStatus == "Business Reported") {
        this.docList = this.docList.map((doc) => {
          if (this.paymentRelatedApproval && doc.type == this.PAYMENT_RELATED_APPROVAL_STR)
            return doc;
          doc.isDisabled = true;
          doc.is_mandatory = "O";
          return doc;
        });
      }
    }));
    this.getAttachmentTypeMasterData();
    this.empId = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid'));
    if (this.selectedOpportunityStatus == "Business Won") {
      this.businessWonStatusFlagA = true;
      this.otherOpportunityStatusFlag = false;
    } else {
      this.otherOpportunityStatusFlag = true;
      this.businessWonStatusFlagA = false;
    }

    if (this.selectedOpportunityStatus == "Business Reported") {
      this.businessReportedStatusFlagA = true;
      this.approvalNeededFlag = true;
      this.companyNameShowFlag = false;
    } else {
      this.businessReportedStatusFlagA = false;
      if (this.bdRole == 'BD Ops Central Coord') {
        this.approvalNeededFlag = true;
      }
      if (this.bdRole == 'BDOps' || this.bdRole == 'BD Ops LCG' || this.bdRole == 'BD Ops Manager') {
        this.approvalNeededFlag = false;
      }
    }

    if ((this.selectedOpportunityStatus != "Business Reported") && (this.selectedOpportunityStatus != "Business Won")) {
      this.otherOpportunityStatusFlagA = true;
    } else {
      this.otherOpportunityStatusFlagA = false;
    }

    if (this.selectedOpportunityStatus == "Case Close") {
      this.caseCloseFlag = true;
    }

    if (this.config.data) {
      this.ReportingScreenDetailsForm.patchValue({ "pageData": this.config.data });
    }

    this.pagesService.getCombineMaster().subscribe((res) => {
      // console
      this.categoryList = res?.filter((p) => {
        return p.name == "Category";
      });

      console.log("categoryList: ", this.categoryList);
      console.log("categoryList master ", res);

      this.sfFeeRuleList = res?.filter((r) => {
        if (r.id == 91 || r.id == 92) {
          return r;
        }
      });

      this.qcStatusList = res?.filter((r) => {
        if (r.name == "QC Status") {
          return r
        }
      });
      this.ReportingScreenDetailsForm.patchValue({
        qcStatus: this.qcStatusList.find(q => q.id == this.paymentOnLoadData?.opportunityDetails?.qcStatus)?.value
      })
    });
    this.getpaymentModeList();
    this.getbusinessTypeList();
    this.htmlSrc = '';
    this.html1Src = '';
    this.html2Src = '';
    this.html3Src = '';
    this.getCompanyTypeList();
    this.getsalutationselectList();
    this.getdesignationselectList();
    this.getGSTtypeselectList();
    this.getbusinessActionselectList();
    this.getCostCenterselectList();
    this.getProductselectList();
    this.getTrypartyselectList();
    this.getDiscountReasonselectList();
    this.getbankList();
    this.getpaymenttypeList();
    setTimeout(() => {
      this.getReportingData();
    }, 500);
    this.PartyApprovalList = ["Yes", "No"];
    if (scope.indexOf('REPORTING_CREATE') == -1 && scope.indexOf('REPORTING_UPDATE') == -1) {
      this.reportingReadScope = true;
      this.ReportingScreenDetailsForm.get('standardApprovalNeeded').disable();
      this.ReportingScreenDetailsForm.disable();
    } else {
      this.reportingReadScope = false;
    }

    let ele = document.getElementsByClassName("p-dialog-header-close-icon");
    ele[0].addEventListener("click", () => {
      this.deleteMultipleFile();
    });
  }

  collapse() {
    this.toggle = !this.toggle;
  }
  onRowEditInit(rowData1: rowData) {
    this.clonedProducts[rowData1.id] = { ...rowData1 };
  }

  onRowEditSave(rowData2: rowData) {
    rowData2.paymentDat = this.ReportingScreenDetailsForm.value.dateCalendar1;
    rowData2.paymentModeId = this.paymentModeList.filter(payMode => payMode.property === rowData2.paymentMode)[0]?.id;
    rowData2.paymentTypeId = this.paymenttypeList.filter(payType => payType.property === rowData2.paymentType)[0]?.id;
    rowData2.bankId = this.bankList.filter(bankType => bankType.name === rowData2.bank)[0]?.id;

    let len = this.paymentdetailsList.length;
    for (let x = 0; x < len; x++) {
      if ((this.paymentdetailsList[x].bank == null) || (this.paymentdetailsList[x].paymentMode == null) ||
        (this.paymentdetailsList[x].paymentType == null) || (this.paymentdetailsList[x].paymentDat == null)) {
        this.paymentDetailsMandatoryFlag = true;
      } else {
        this.paymentDetailsMandatoryFlag = false;
      }
    }

    delete this.clonedProducts[rowData2.id];
  }

  rptData(code) {
    this.apiList.push(this.pagesService.getRptDetails(code).subscribe((res) => {
      let overAll: string;
      let doc = this.docList.find(c => c.id == 38);
      if (res?.rptInCRM == 'Yes' || res?.rptInCC == 'Yes') {
        overAll = 'Yes';
        if (doc) {
          doc.is_mandatory = 'M';
        }
      } else {
        overAll = 'No';
        if (doc) {
          doc.is_mandatory = 'O';
        }
      }

      this.ReportingScreenDetailsForm.patchValue({
        rptCC: res?.rptInCC,
        rptCRM: res?.rptInCRM,
        rptOverall: overAll
      });
    }));
  }

  getReportingData() {
    this.apiList.push(this.pagesService.onReportingScreenDetailsPageLoad(this.OpportunityId, false).subscribe((results: any) => {
      console.log("results", results);
      this.CRM_Code = results.entityCompanyCode;
      this.paymentOnLoadData = results;
      this.paymentdetailsList = results.opportunityDetails.paymentDetails;
      if (this.paymentdetailsList && this.paymentdetailsList.length > 0) {
        const sortedPaymentDetails = this.paymentdetailsList.sort((a, b) => a.id - b.id);
        this.paymentdetailsList = sortedPaymentDetails.map(payment => ({ ...payment }));
      }
      this.isMigrated = results?.opportunityDetails?.isMigrated == 'Y';
      this.clientTypeMaster();
      this.paymentDetailsOnPageLoad();
      if ((results.entityCompanyCode == null || results.entityCompanyCode == '') && (this.paymentOnLoadData?.opportunityStatus != "Business Reported" || this.paymentOnLoadData?.opportunityStatus != "Business Rejected")) {
        this.companyNameShowFlag = true;
        this.onSaveLoader = false;
      } else {
        this.onSaveLoader = false;
        this.companyNameShowFlag = false;
      }
      this.productId = results?.opportunityDetails?.reportingOpportunityDetailsDTOProductId;
      this.isQcRejected = results.opportunityDetails?.qcStatus == 134;
      this.isQcApproved = results.opportunityDetails?.qcStatus == 136;

      let pagePropBind = {
        entityName: results.entityName,
        opportunityStatus: results.opportunityStatus,
        businessWonDate: results.businessWonDate,
        entityCompanyCode: results.entityCompanyCode,
        CRMCodetextField: results.entityId?.companyCode,
        FinRisktextField: (results.entityId?.finRisk == null) ? (null) : (results.entityId?.finRisk),
        finComment: results.entityId?.finRiskComment,
        tanDetails: results?.entityId?.tanNo,
        triFinRisk: results.entityId?.tripartyFinRisk,
        triFinComment: results.entityId?.tripartyFinRiskComment,
        RefNotextField: (results.entityId?.refNo == null) ? (null) : (results.entityId?.refNo),
        GroupCompanytextField: results.entityId?.entityGroupName,
        CompanyTypeselect: results.entityId?.entityType,
        companyName: (results.entityId?.companyCode == null) ? (null) : (results.entityId.name),
        salutationselect: results.entityContacts?.salutation,
        firstNametextField: results.entityContacts?.firstName,
        lastNametextField: results.entityContacts?.lastName,
        designationselect: results.entityContacts?.designation,
        mobileNotextField: results.entityContacts?.mobileNumber,
        landlineNotextField: results.entityContacts?.landlineNumber,
        emailtextField1: results.entityContacts?.email[0],
        emailtextField2: results.entityContacts?.email[1],
        emailtextField3: results.entityContacts?.email[2],
        GSTtypeselect: (results.entityContacts == undefined) ? (null) : (results.entityContacts.gstType),
        GSTNotextField: (results.entityContacts == undefined) ? (null) : (results.entityContacts.gstNumber),
        PANnotextField: (results.entityContacts == undefined) ? (null) : (results.entityContacts.panNumber),
        pinCodetextField: (results.entityContacts == undefined) ? (null) : (results.entityContacts.pincode),
        CitytextField: results.entityContacts?.city,
        StatetextField: results.entityContacts?.state,
        address1: (results.entityContacts) == undefined ? (null) : (results.entityContacts.addressLine1),
        address2: (results.entityContacts) == undefined ? (null) : (results.entityContacts.addressLine2),
        address3: (results.entityContacts) == undefined ? (null) : (results.entityContacts.addressLine3),
        textField: (results.entityContacts) == undefined ? (null) : (results.entityContacts.addressLine4),
        opportunityId: results.opportunityDetails?.id,
        agreementId: results.opportunityDetails?.agreementId,
        qcStatus: this.qcStatusList.find(q => q.id == results.opportunityDetails?.qcStatus)?.value,
        businessType: results.opportunityDetails?.businessType,
        productselect: results.opportunityDetails?.product,
        instrumentselect: results.opportunityDetails?.instrument,
        quantumtextField1: parseFloat(results.opportunityDetails?.quantum),
        comment: results.opportunityDetails?.comment,
        maCase: results.opportunityDetails?.marketingAssociate,
        triParty: results.opportunityDetails?.tripartyMandate,
        triCrm: results.opportunityDetails?.triPartyEntityName,
        triCrmCode: results.opportunityDetails?.tripartyCompanyCode,
        PartyApproval: results.opportunityDetails?.thirdPartyApproval,
        sfFeeRule: results.opportunityDetails.feeDetails?.sfFeeRuleId,
        irfexpectedFee: parseInt(results.opportunityDetails.feeDetails?.idealFeeExpected),
        irfactualFee: parseInt(results.opportunityDetails.feeDetails?.irfActualFees),
        irfDiscount: results.opportunityDetails.feeDetails?.irfDiscount == null ? 'NA' : parseInt(results.opportunityDetails.feeDetails?.irfDiscount),
        irfDiscountPercent: results.opportunityDetails.feeDetails?.irfDiscountPercentage == null ? 'NA' : parseFloat(results.opportunityDetails.feeDetails?.irfDiscountPercentage).toFixed(2),
        irfDiscountReason: results.opportunityDetails.feeDetails?.irfDiscountReason == null ? '' : results.opportunityDetails.feeDetails?.irfDiscountReason,
        sfActualFee: results.opportunityDetails.feeDetails?.sfActualFees,
        sfExpectedFee: results.opportunityDetails.feeDetails?.sfExpectedFee,
        sfDiscountPremium: results.opportunityDetails.feeDetails?.sfDiscount,
        sfActualPercent: results.opportunityDetails.feeDetails?.sfActualPercent,
        sfExpectedPercent: results.opportunityDetails.feeDetails?.expectedSfPercent,
        sfDiscountPremiumPercent: parseFloat(results.opportunityDetails.feeDetails?.sfDiscountPercentage).toFixed(2),
        sfDiscountReason: results.opportunityDetails.feeDetails?.sfDiscountReason == null ? '' : results.opportunityDetails.feeDetails?.sfDiscountReason,
        costCenterselect: this.otherOpportunityStatusFlag ? (results.reportingDetails?.costCenterId) : (6),
        MCAstatus: results.reportingDetails?.mcaStatus,
        exposureperField: results.reportingDetails?.mcaExposure,
        tripartyMandate_Id: results?.tripartyMandateId,
        entity_id: results.entityId?.id,
        entityContactId: results.entityContacts?.id,
        feeDetails_id: results.opportunityDetails.feeDetails?.id,
        is_default: results.entityContacts?.isDefault,
        is_reporting: results.entityContacts?.isReporting,
        partyApprovalComment: results.opportunityDetails?.thirdPartyApprovalComment,
        UserIdInContact: results.entityContacts?.userId,
        reportingDetailsId: results.reportingDetails?.id,
        businessActionselect: results.reportingDetails?.businessAction,
        reasonselect: results.reportingDetails?.reason,
        actionComment: results.reportingDetails?.comment,
        standardApprovalNeeded: results.reportingDetails?.spaApprovalNeeded == "Yes",
        standardApprovalReceived: results.reportingDetails?.spaApprovalReceived == "Yes",
        InternalApprovalNeeded: results.reportingDetails?.ipaApprovalNeeded == "Yes",
        InternalApprovalReceived: results.reportingDetails?.ipaApprovalReceived == "Yes",
        criticalDocReceived: results.reportingDetails?.ipaCriticalDocReceived == "Yes",
        minimum: results?.opportunityDetails?.feeDetails?.minimumValue,
        maximum: results?.opportunityDetails?.feeDetails?.maximumValue,
        revisedSfActual: results?.opportunityDetails?.feeDetails?.revisedSfFee,
        categoryName: this.categoryList.find(q => q.id == results?.categoryId)?.value,
        existingArc: results?.opportunityDetails?.isExistingArc
      };
      console.log("results?.category", pagePropBind.categoryName);
      if (this.CRM_Code != null && this.CRM_Code != undefined) {
        this.patchClientType(this.CRM_Code);
      }

      this.paymentdetailsList.forEach((p) => {
        let form = this.fb.group({
          id: p.id,
          bank: new FormControl(p.bank, [Validators.required]),
          paymentMode: p.paymentMode,
          chequeNo: new FormControl(p.chequeNo, [Validators.required]),
          paymentDat: new FormControl(p.paymentDat, [Validators.required]),
          paymentType: p.paymentType,
          tdsType: p.tdsType,
          tdsAmount: p.tdsAmount,
          amount: p.amount,
          grossAmount: p.grossAmount,
          isAdditionalPayment: p.isAdditionalPayment,
          finComment: p.financeComments,
          payStatus: this.payStatusList.find(x => x.id == p.paymentStatusId)?.name,
          rejectedReason: p.rejectedReason
        });
        if (p.isAdditionalPayment === "Yes")
          this.isAdditionalPayment = true;
        this.payments.push(form);
      });
      this.pageloadvalues = pagePropBind;

      let costCenterValue = this.pageloadvalues.costCenterselect;
      if (this.pageloadvalues.businessType == "Enhancement") {
        this.disabledExposureMca = true;
      }
      if (this.pageloadvalues.sfFeeRule == 92) {
        this.showMinMax = true;
      }
      let payment = this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.paymentType
      let payments = this.paymentOnLoadData.opportunityDetails.paymentDetails
      this.ReportingScreenDetailsForm.patchValue(this.pageloadvalues);

      if (results.opportunityDetails?.tripartyMandate == 'Tri-Party') {
        this.ReportingScreenDetailsForm.patchValue({
          salutationselect: results.defaultContact?.salutation,
          firstNametextField: results.defaultContact?.firstName,
          lastNametextField: results.defaultContact?.lastName,
          designationselect: results.defaultContact?.designation,
          mobileNotextField: results.defaultContact?.mobileNumber,
          landlineNotextField: results.defaultContact?.landlineNumber,
          emailtextField1: results.defaultContact?.email[0],
          emailtextField2: results.defaultContact?.email[1],
          emailtextField3: results.defaultContact?.email[2],
          GSTtypeselect: (results.defaultContact == undefined) ? (null) : (results.defaultContact.gstType),
          GSTNotextField: (results.defaultContact == undefined) ? (null) : (results.defaultContact.gstNumber),
          PANnotextField: (results.defaultContact == undefined) ? (null) : (results.defaultContact.panNumber),
          pinCodetextField: (results.defaultContact == undefined) ? (null) : (results.defaultContact.pincode),
          CitytextField: results.defaultContact?.city,
          StatetextField: results.defaultContact?.state,
          address1: (results.defaultContact) == undefined ? (null) : (results.defaultContact.addressLine1),
          address2: (results.defaultContact) == undefined ? (null) : (results.defaultContact.addressLine2),
          address3: (results.defaultContact) == undefined ? (null) : (results.defaultContact.addressLine3),
          textField: (results.defaultContact) == undefined ? (null) : (results.defaultContact.addressLine4),
          triSalutation: results?.entityContacts?.salutation,
          triFirstName: results?.entityContacts?.firstName,
          triLastName: results?.entityContacts?.lastName,
          triDesignation: results?.entityContacts?.designation,
          triMobile: results?.entityContacts?.mobileNumber,
          triLandline: results?.entityContacts?.landlineNumber,
          triEmail1: results?.entityContacts?.email[0],
          triEmail2: results?.entityContacts?.email[1],
          triEmail3: results?.entityContacts?.email[2],
          triGstType: results?.entityContacts?.gstType,
          triGstNo: results?.entityContacts?.gstNumber,
          triPanNo: results?.entityContacts?.panNumber,
          triPinCode: results?.entityContacts?.pincode,
          triCity: results?.entityContacts?.city,
          triState: results?.entityContacts?.state,
          triAddress1: results?.entityContacts?.addressLine1,
          triAddress2: results?.entityContacts?.addressLine2,
          triAddress3: results?.entityContacts?.addressLine3,
          triAddress4: results?.entityContacts?.addressLine4,
        });
      }
      if (results.attachments != null && results.attachments != undefined) {
        results.attachments?.forEach((a) => {
          if (a.fileTypeName?.toLowerCase() != "migrated doc") {
            a.isDuplicate = false;
            this.displayAttachData.push(a)
          }
          if (a.fileTypeName?.toLowerCase() == "migrated doc") {
            this.migratedDocs.push(a)
          }
        });
      }

      // QC Approved logic
      if (this.isQcApproved) {
        // 41 - original mandate
        console.log("docList",this.docList);
        let ogMandate = this.docList?.find(d => d.id == 41);
        if (ogMandate) {
          ogMandate.isDisabled = false;
        }
        // Enable misc attachments from misc 1 to misc 6
        for(let i = 31; i <= 36; i++){
                  let misc = this.docList?.find(d => d.id == i );
          if (misc) {
            misc.isDisabled = false;
          }
        }
        
      }

      // QC reject logic
      const modules = this.encryptDecryptInterceptor.encryptWith('Reporting,Attachment,Other');
      const qcStatus = this.encryptDecryptInterceptor.encryptWith('Rejected');
      if (this.paymentOnLoadData.opportunityDetails.agreementId != null && this.paymentOnLoadData.opportunityDetails.agreementId != undefined) {
        this.apiList.push(
          forkJoin([
            this.pagesService.getQcActions(modules, qcStatus, this.paymentOnLoadData?.opportunityDetails?.agreementId),
            this.pagesService.getQcActions(this.encryptDecryptInterceptor.encryptWith("Payments"), this.encryptDecryptInterceptor.encryptWith("Approved"), this.paymentOnLoadData?.opportunityDetails?.agreementId)])
            .subscribe((result) => {
              let [res, qCPayments] = result;
              qCPayments.paymentQcAction?.forEach(item => {
                this.allQCPayments = { ...this.allQCPayments, [item['paymentId']]: item };
              });

              if (res?.qcStatusId != 135) { // if qc is not resubmitted
                this.qcList = res;
                if (res?.qcStatusId == 134) { //rejected
                  this.attachmentRejected = true;
                  this.enableAllAttach();
                }
                //136 - approved
                this.qcList?.qcActions?.forEach((q) => {
                  if (q.checklistField?.parentModuleName?.toLowerCase() != "attachment") {
                    this.qcRejectMap[q.checklistField.fieldName] = true;
                  }
                  if (q.checklistField?.parentModuleName?.toLowerCase() == "attachment" && q?.qcFieldStatus?.toLowerCase() == 'rejected') {
                    if (q?.checklistField?.fieldName?.toLowerCase() != 'mandatetype') {
                      this.attachmentRejected = true;
                    }
                    if (q?.checklistField?.fieldName?.toLowerCase() == 'mandatetype') {
                      this.ogMandateRejected = true;
                    }
                  }
                  if (q.checklistField?.parentModuleName?.toLowerCase() == "reporting" &&
                    q?.qcFieldStatus?.toLowerCase() == 'rejected' &&
                    q?.checklistField?.fieldName?.toLowerCase() == 'tan') {
                    this.companyCodeForTan = true;
                  }
                });
                this.qcList?.paymentQcAction?.forEach((p) => {
                  if (p?.isApproved?.toLowerCase() == 'rejected') {
                    this.qcRejectMap[p.paymentId] = true;
                    this.errorOpportunityDetails = true;
                  }
                  if (this.isQcApproved && (p?.chequeAttachmentApproved?.toLowerCase() == 'rejected' || p?.depositeSlipApproved?.toLowerCase() == 'rejected' || p?.isApproved?.toLowerCase() == 'rejected')) {
                    this.additionalPayResubmit = true;
                  }
                  if (p?.chequeAttachmentApproved?.toLowerCase() == 'rejected' || p?.depositeSlipApproved?.toLowerCase() == 'rejected') {
                    this.attachmentRejected = true;
                    this.enableAllAttach();
                  }
                });

                if (this.qcRejectMap['refNo'] || this.qcRejectMap['tan']) {
                  this.errorEntityDetails = true;
                }

                if (this.qcRejectMap['costCenter']) {
                  this.errorMcaCheck = true;
                }
                if (this.qcRejectMap['survFeeRule'] || this.qcRejectMap['survActualPercent'] || this.qcRejectMap['minimumValue'] || this.qcRejectMap['maximumValue']) {
                  this.errorOpportunityDetails = true;
                }
              }
            }, (_err) => {
              this.apiFail = true;
            }));
      }

      //get finrisk data on business won
      if (results.opportunityStatus == "Business Won") {
        if (results?.entityCompanyCode?.length > 0) {
          this.rptData(this.CRM_Code);
          this.patchFinDetials(results.entityCompanyCode, 'Normal');
        }
        if (results?.opportunityDetails?.tripartyCompanyCode?.length > 0) {
          this.patchFinDetials(results?.opportunityDetails?.tripartyCompanyCode, 'Tri');
        }

        this.pagesService.getSfFeeRule().subscribe((res) => {
          this.productConfig = res;
          let proId = JSON.stringify(this.productId);
          // find object for the current product
          this.feeObj = this.productConfig.filter((e) => {
            return e.product_id.replace(/\s/g, '').split(",").indexOf(proId) >= 0;
          });
        });
      }
      if (results.opportunityStatus == "Business Reported") {
        let overAll;
        let cc = results?.reportingDetails?.ccRptStatus;
        let crm = results?.reportingDetails?.crmRptStatus
        if (crm == 'Yes' || cc == 'Yes') {
          overAll = 'Yes';
        } else {
          overAll = 'No';
        }

        this.ReportingScreenDetailsForm.patchValue({
          rptCC: results?.reportingDetails?.ccRptStatus,
          rptCRM: results?.reportingDetails?.crmRptStatus,
          rptOverall: overAll,
        });
      }
      this.displayAttachData.forEach((d) => {
        if (d.fileTypeName == 'Combine Doc') {
          this.combinedPdfView.push(d);
        }
      });

      this.displayAttachData = this.displayAttachData.filter((d) => {
        return d.fileTypeName != 'Combine Doc'
      });

      this.allDocList = [...this.docList];
      this.docList = this.docList.filter((d) => {
        return !this.displayAttachData.find((da) => {
          return d.type == da.fileTypeName
        })
      });
      this.commonMethod();
      this.setPageProperties(this.config.data);
      this.gstTypeChange();
      this.onCompletepincode();
      this.ThirdPartyApprovalCommentControl();
      this.onLoadCheckbox();
      this.removeDuplicate(null);
      this.displayAttachData.forEach(data => {
        if (data.fileContentType === 'application/pdf') {
          data.file = 'data:application/pdf;base64,' + data.file;
        } else if (data.fileContentType === 'image/png' || data.fileContentType === 'image/jpeg') {
          data.file = 'data:image/png;base64,' + data.file;
        }
      });

      this.migratedDocs.forEach(data => {
        if (data.fileContentType === 'application/pdf') {
          data.file = 'data:application/pdf;base64,' + data.file;
        } else if (data.fileContentType === 'image/png' || data.fileContentType === 'image/jpeg') {
          data.file = 'data:image/png;base64,' + data.file;
        }
      });

      if (this.pageloadvalues?.irfDiscountPercent < 0 || this.pageloadvalues?.sfDiscountPremiumPercent < 0) {
        // this.docList.forEach(element => {
        // if (element.type == 'Discount Approval Attachment') {
        // element.is_mandatory = 'M';
        // this.isClientTypeApprovalM = true;
        // }
        // });
      } else {
        this.docList.forEach(element => {
          if (element.type == 'Discount Approval Attachment') {
            element.is_mandatory = 'O';
            this.isClientTypeApprovalM = true;
          }
        });
      }

      if (this.pageloadvalues.MCAstatus == "Approval Needed") {
        this.docList.forEach(element => {
          if (element.type == 'Client Type Approval') {
            element.is_mandatory = 'M';
            this.isClientTypeApprovalM = true;
          }
        });
      } else {
        this.docList.forEach(element => {
          if (element.type == 'Client Type Approval') {
            element.is_mandatory = 'O';
            this.isClientTypeApprovalM = false;
          }
        });
      }

      if ((payment == 'No Payment' || payment == 'Part Payment') && (costCenterValue == 5 || costCenterValue == 6) && this.pageloadvalues?.opportunityStatus == 'Business Won') {
        this.docList.forEach(element => {
          if (element.type == 'Approval (Part Payment / No Payment)') {
            element.is_mandatory = 'M';
            this.isApprovalPaymentM = true;
          }
        });
      }

      if ((costCenterValue == 5 || costCenterValue == 6) && this.pageloadvalues?.opportunityStatus == 'Business Won' && payments.find(p => p.paymentType == 'Full Payment')) {
        this.docList.forEach(element => {
          if (element.type == 'Approval (Part Payment / No Payment)') {
            element.is_mandatory = 'O';
            this.isApprovalPaymentM = false;
          }
        });
      }

      if (this.selectedOpportunityStatus == "Business Reported") {
        this.docList.forEach(element => {
          if (element.type == 'Client Type Approval') {
            element.is_mandatory = 'O';
            this.isFinRiskAttachmentM = false;
          }
        });
      }

      if (this.pageloadvalues.FinRisktextField == 'Medium Risk' || this.pageloadvalues.FinRisktextField == 'High Risk') {
        this.docList.forEach(element => {
          if (element.type == 'Fin Risk Attachment') {
            element.is_mandatory = 'M';
            this.isFinRiskAttachmentM = true;
          }
        });
      } else {
        this.docList.forEach(element => {
          if (element.type == 'Fin Risk Attachment') {
            element.is_mandatory = 'O';
            this.isFinRiskAttachmentM = false;
          }
        });
      }

      if (this.selectedOpportunityStatus == "Business Reported") {
        this.docList.forEach(element => {
          if (element.type == 'Fin Risk Attachment') {
            element.is_mandatory = 'O';
            this.isFinRiskAttachmentM = false;
          }
        });
      }

      if (this.pageloadvalues.businessType == 'New') {
        if (this.ReportingScreenDetailsForm.get('businessActionselect').value != 'Business Rejected') {
          this.docList.forEach(element => {
            if (element.type == 'MCA Attachment') {
              element.is_mandatory = 'M';
              this.isMCAAttachmentM = true;
            }
          });
        }
      } else {
        this.docList.forEach(element => {
          if (element.type == 'MCA Attachment') {
            element.is_mandatory = 'O';
            this.isMCAAttachmentM = false;
          }
        });
      }

      if (this.selectedOpportunityStatus == "Business Reported") {
        this.docList.forEach(element => {
          if (element.type == 'MCA Attachment') {
            element.is_mandatory = 'O';
            this.isFinRiskAttachmentM = false;
          }
        });
      }

      if (this.pageloadvalues.GSTNotextField) {
        this.docList.forEach(element => {
          if (element.type == 'GST Portal Snapshot') {
            element.is_mandatory = 'M';
            this.isGSTPortalSnapshotM = true;
          }
        });
      } else {
        this.docList.forEach(element => {
          if (element.type == 'GST Portal Snapshot') {
            element.is_mandatory = 'O';
            this.isGSTPortalSnapshotM = false;
          }
        });
      }

      if (this.selectedOpportunityStatus == "Business Reported") {
        this.docList.forEach(element => {
          if (element.type == 'GST Portal Snapshot') {
            element.is_mandatory = 'O';
            this.isFinRiskAttachmentM = false;
          }
        });
      }

      if (this.selectedOpportunityStatus == "Business Reported") {
        this.docList.forEach(element => {
          if (element.type == '3rd Party approval Attachment') {
            element.is_mandatory = 'O';
          }
        });
      }

      if (this.CRM_Code) {
        this.pagesService.getClientType(this.CRM_Code).subscribe((res) => {
          this.clientTypeId = res?.clientTypeId;
          if (res?.clientTypeId == 1) {
            this.docList.forEach(element => {
              if (element.id == 26 || element.id == 6) {
                element.is_mandatory = 'O';
              }
            });
          }
        });
      }




      this.approvalTabData = {
        spaNeeded: this.ReportingScreenDetailsForm.get('standardApprovalNeeded').value,
        spaReceived: this.ReportingScreenDetailsForm.get('standardApprovalReceived').value,
        ipaNeeded: this.ReportingScreenDetailsForm.get('InternalApprovalNeeded').value,
        ipaReceived: this.ReportingScreenDetailsForm.get('InternalApprovalReceived').value,
        cdReceived: this.ReportingScreenDetailsForm.get('criticalDocReceived').value,
      }

      this.actionTabData = {
        businessAction: this.ReportingScreenDetailsForm.get('businessActionselect').value,
        reason: this.ReportingScreenDetailsForm.get('reasonselect').value,
        comment: this.ReportingScreenDetailsForm.get('actionComment').value,
      }
      setTimeout(() => {
        this.displayAttachData.forEach((da) => {
          this.docList.forEach((d, i) => {
            if (da.fileTypeName == d.type) {
              this.docList.splice(i, 1);
            }
          });
        });
      }, 2000);

      let sfProductID: any;
      this.quanProdId = null;
      //make instrument and quantum read only
      this.instQuan_prodIds.forEach((item) => {
        if (item == results?.opportunityDetails?.reportingOpportunityDetailsDTOProductId) {
          this.quanProdId = item;
        }
      })

      //product_id whose sf ideal and actual fee as NA
      this.sfIdealActual_prodIds.forEach((item) => {
        if (item == results?.opportunityDetails?.reportingOpportunityDetailsDTOProductId) {
          sfProductID = item;
        }
      })
      if (sfProductID == results?.opportunityDetails?.reportingOpportunityDetailsDTOProductId) {
        this.sfIdealFeeNA = true;
        this.sfActualFeesNA = true;
      } else {
        this.sfIdealFeeNA = false;
        this.sfActualFeesNA = false;
      }

      if (results?.entityContacts?.isPaymentContact == "Yes") {
        this.isPaymentContact = true;
      }

      this.sfFeeDetails = {
        sfExpectedFee: results.opportunityDetails.feeDetails?.sfExpectedFee,
        sfActualFee: results.opportunityDetails.feeDetails?.sfActualFees,
        sfDiscountPremium: results.opportunityDetails.feeDetails?.sfDiscount,
        sfDiscountPremiumPercent: parseFloat(results.opportunityDetails.feeDetails?.sfDiscountPercentage).toFixed(2),
      }

      this.pagesService.getSfFeeRule().subscribe((res) => {
        this.productConfig = res;
        let proId = JSON.stringify(this.productId);
        // find object for the current product
        this.feeObj = this.productConfig.filter((e) => {
          return e.product_id.replace(/\s/g, '').split(",").indexOf(proId) >= 0;
        });
        if (this.feeObj[0].minimum_sf == 'NA') {
          this.ReportingScreenDetailsForm.patchValue({
            sfExpectedFee: 'NA',
            sfExpectedPercent: 'NA',
            sfActualFee: 'NA',
            sfActualPercent: 'NA',
            sfDiscountPremium: 'NA',
            sfDiscountPremiumPercent: 'NA'
          });
          this.disableSfFeeRule = true;
        }
        this.pagesService.getCombineMaster().subscribe((res) => {
          if (this.feeObj[0].sf_expected_percentage != null && this.feeObj[0].sf_expected_percentage != undefined) {
            this.sfFeeRuleList = res?.filter((r) => {
              if (r.id == 91 || r.id == 92) {
                return r;
              }
            });
          } else {
            this.sfFeeRuleList = res?.filter((r) => {
              if (r.id == 91) {
                return r;
              }
            });
          }
          if (results.opportunityStatus == "Business Won") {
            this.ReportingScreenDetailsForm.patchValue({ sfFeeRule: 91 });
            this.disabledSfAct = true;
          } else {
            this.ReportingScreenDetailsForm.patchValue({
              sfFeeRule: results.opportunityDetails.feeDetails?.sfFeeRuleId,
            });
          }
        });
      });

      // set payment percent collected
      // TODO: add filter for payment status = REALIZED , PENDING to only be considered- => to be done during payment realization in IRF
      // 88 : No Payment -> combineMasters
      this.paymentCollected = this.paymentdetailsList.filter(item => item?.paymentTypeId !== 88 && (item?.paymentStatusId == 1 || item?.paymentStatusId == 2)).reduce((accumulator, item) => {
        return accumulator + item?.grossAmount;
      }, 0);

      this.paymentCollected = this.pageloadvalues?.irfactualFee > 0 ? ((parseFloat(this.paymentCollected) / this.pageloadvalues?.irfactualFee) * 100) + "" : undefined;

      if (this.paymentCollected) { // round off to 2 decimal place
        this.paymentCollected = parseFloat(this.paymentCollected + "").toFixed(2);
      }
      this.apiFail = false;
    }, (_error) => {
      this.onSaveLoader = false;
      this.apiFail = true;
    }));

  }

  setPageProperties(props: any): void {
    if (this.ReportingScreenDetailsForm.value.instrumentselect != null) {
      this.instrumentselectList.push(this.ReportingScreenDetailsForm.value.instrumentselect);
    }
    const pageData = {
      instrumentselect: this.instrumentselectList[0]
    }

    const calendarControls = ["dateCalendar"];
    Object.keys(props).forEach((key) => {
      if (calendarControls.includes(key)) {
        pageData[key] = new Date(props[key]);
      } else {
        pageData[key] = props[key];
      }
    })
    this.ReportingScreenDetailsForm.patchValue(pageData);

  }

  enableAllAttach() {
    this.docList.forEach((d) => {
      d.isDisabled = false;
    });
  }
  patchClientType(code) {
    this.pagesService.getClientType(code).subscribe((res) => {
      this.clientTypeId = res?.clientTypeId;
      let clientType = this.clientTypeList.find(f => f.id == res?.clientTypeId);
      if (clientType) {
        this.ReportingScreenDetailsForm.patchValue({ clientType: clientType?.clientTypeName });
        if (this.paymentCollected && parseInt(this.paymentCollected) < 50 && (clientType?.clientTypeName == 'MCG' || clientType?.clientTypeName == 'LCG -B')) {
          if (this.payments?.value?.length === 1 && this.payments?.value[0]?.paymentType === "No Payment")
            return;
          this.docList.forEach(element => {
            if (element.type == this.PAYMENT_RELATED_APPROVAL_STR) {
              element.is_mandatory = 'M';
              element.isDisabled = false;
              this.paymentRelatedApproval = true;
            }
          });
        }

        if (res?.clientTypeId == 1) {
          this.docList.forEach(element => {
            if (element.id == 26 || element.id == 6) {
              element.is_mandatory = 'O';
            }

          });
        }
      }
    });
  }

  commonMethod() {
    let IRF_ActualFee = Number(this.ReportingScreenDetailsForm.value.irfactualFee);
    let IRF_ExpectedFee = Number(this.ReportingScreenDetailsForm.value.irfexpectedFee);

    if (this.ReportingScreenDetailsForm.value.InternalApprovalNeeded && this.selectedOpportunityStatus == "Business Reported") {
      if (this.bdRole == 'BDOps') {
        this.businessReportedPermanentFlag = true;
      }
      if (this.ReportingScreenDetailsForm.value.InternalApprovalReceived) {
        this.businessReportedPermanentFlag = true;
      } else {
        if (this.bdRole == 'BD Ops Central Coord' || this.bdRole == 'BD Ops LCG' || this.bdRole == 'BD Ops Manager') {
          this.businessReportedPermanentFlag = false;
        } else {
          this.businessReportedPermanentFlag = true;
        }
      }
    } else {
      this.businessReportedPermanentFlag = true;
    }

    if (this.businessWonStatusFlagA) {
      this.actionReasonFlag = true;
    } else {
      if (this.ReportingScreenDetailsForm.value.businessActionselect == "Business Reported") {
        this.getBusinessReportedReasonselectList();
      }
      if (this.ReportingScreenDetailsForm.value.businessActionselect == "Business Rejected") {
        this.getBusinessRejectedReasonselectList();
      }
    }

    if (this.ReportingScreenDetailsForm.value.entityCompanyCode == null && (this.ReportingScreenDetailsForm.value.businessActionselect == "Business Reported" || this.ReportingScreenDetailsForm.value.businessActionselect == undefined || this.ReportingScreenDetailsForm.value.businessActionselect == null || this.ReportingScreenDetailsForm.value.businessActionselect == '')) {
      this.companyNameShowFlag = true;
    } else {
      this.companyNameShowFlag = false;

      if (this.ReportingScreenDetailsForm.value.companyName != null) {
        let company = this.ReportingScreenDetailsForm.value.companyName;
        let companyChar = company?.slice(0, 4);
        this.dynamicRefNoMask = "M - aaa/99999999/" + companyChar.toUpperCase() + "/9999999";
      }
    }
    if (this.selectedOpportunityStatus == "Business Reported") {
      this.companyNameShowFlag = false;
    }

    if (IRF_ActualFee < IRF_ExpectedFee) {
      if (this.businessWonStatusFlagA || this.otherOpportunityStatusFlagA) {
        this.disableStdPricing = false;
        const stdApprovalNeeded = {
          standardApprovalNeeded: true,
        }
        this.disableStdPricingReceived = false;
        this.ReportingScreenDetailsForm.patchValue(stdApprovalNeeded);
      }
    } else {
      this.disableStdPricing = true;
    }
    if (this.ReportingScreenDetailsForm.value.firstNametextField != undefined) {
      const contact = {
        tripartyContactName: this.ReportingScreenDetailsForm.value.firstNametextField + " " + this.ReportingScreenDetailsForm.value.lastNametextField,
      }
      this.ReportingScreenDetailsForm.patchValue(contact);
    }
  }

  onLoadCheckbox() {
    let displayLength = this.displayAttachData.length;
    for (let x = 0; x < displayLength; x++) {
      if ((this.displayAttachData[x].fileTypeName == "Mandate,Page 1") || (this.displayAttachData[x].fileTypeName == "Mandate,Page 2") ||
        (this.displayAttachData[x].fileTypeName == "Mandate,Page 3") || (this.displayAttachData[x].fileTypeName == "Mandate,Page 4") ||
        (this.displayAttachData[x].fileTypeName == "Mandate,Page 5") || (this.displayAttachData[x].fileTypeName == "Mandate,Page 6") ||
        (this.displayAttachData[x].fileTypeName == "Mandate,Page 7") || (this.displayAttachData[x].fileTypeName == "Mandate,Page 8") ||
        (this.displayAttachData[x].fileTypeName == "Mandate,Page 9") || (this.displayAttachData[x].fileTypeName == "Mandate,Page 10")) {
        this.toShowCheckboxFlag = true;
      } else {
        this.toShowCheckboxFlag = false;
      }
    }
  }


  PressNumbers(event) {
    const reg = /^-?\d*(\.\d{0,2})?$/; //NOSONAR
    let input = event.target.value + String.fromCharCode(event.charCode);
    if (!reg.test(input)) {
      event.preventDefault();
    } else {
      this.isExposureperMCA = false;
    }
  }

  actionComment(event) {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
    const reg = /^[a-z\d\-_\s]+$/i;
    let input = String.fromCharCode(event.keyCode);
    if (!reg.test(input)) {
      event.preventDefault();
    } else {
      this.isBAComment = false;
    }
  }

  refNumberVal() {
    let refNo = this.ReportingScreenDetailsForm.value.RefNotextField;
    if (refNo?.length > 0) {
      this.isREF = false;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        let payload = {
          refNo: refNo
        }
        this.apiList.push(this.pagesService.checkDuplicateRefNo(payload).subscribe(results => {
          if (results.isMandatePresent == "Yes") {
            this.refDuplicateFlag = true;
          } else {
            this.refDuplicateFlag = false;
          }
        }));
      }, 1000);
    } else {
      this.isREF = true;
    }
  }

  costCenter: any;

  onExposureChange(event) {
    let status: any;
    let StringValue = this.ReportingScreenDetailsForm.value.exposureperField;
    let Exposure = Number(StringValue);
    let costCenter = this.ReportingScreenDetailsForm.value.costCenterselect;
    if (costCenter && Exposure) {
      let payload = {
        productId: this.productId,
        costCenterId: costCenter
      }
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.pagesService.productExposure(payload).subscribe((res) => {
          this.productExposureList = res?.exposureList;
          let exposureVal;
          if (Exposure == 0) {
            exposureVal = '1';
          } else {
            // remove leading zero
            exposureVal = Exposure;
          }
          let evlResult = eval(exposureVal + this.productExposureList[0]?.exposureQuentum);
          if (evlResult) {
            status = this.productExposureList[0]?.approvalStatus;
            this.ReportingScreenDetailsForm.patchValue({ MCAstatus: this.productExposureList[0]?.approvalStatus });
          } else {
            status = 'No Approval Needed';
            this.ReportingScreenDetailsForm.patchValue({ MCAstatus: status });
          }

          if (status == "Approval Needed") {
            this.docList.forEach(element => {
              if (element.type == 'Client Type Approval') {
                element.is_mandatory = 'M';
                this.isClientTypeApprovalM = true;
              }
            });
          } else {
            this.docList.forEach(element => {
              if (element.type == 'Client Type Approval') {
                element.is_mandatory = 'O';
                this.isClientTypeApprovalM = false;
              }
            });
          }
        });

      }, 1000);
    }


    if ((StringValue != null) && (StringValue != "")) {
      if (this.pageloadvalues.businessType == 'New') {
        if (this.ReportingScreenDetailsForm.get('businessActionselect').value != 'Business Rejected') {
          this.docList.forEach(element => {
            if (element.type == 'MCA Attachment') {
              element.is_mandatory = 'M';
              this.isMCAAttachmentM = true;
            }
          });
        }
      } else {
        this.docList.forEach(element => {
          if (element.type == 'MCA Attachment') {
            element.is_mandatory = 'O';
            this.isMCAAttachmentM = false;
          }
        });
      }

    }

    if (event?.value == undefined) {
      this.costCenter = this.pageloadvalues.costCenterselect;
    } else {
      this.costCenter = event?.value;
    }

    if ((this.paymentType == 'No Payment' || this.paymentType == 'Part Payment') && (this.costCenter == 5 || this.costCenter == 6)) {
      this.docList.forEach(element => {
        if (element.type == 'Approval (Part Payment / No Payment)') {
          element.is_mandatory = 'M';
          this.isApprovalPaymentM = true;
        }
      });
    } else {
      this.docList.forEach(element => {
        if (element.type == 'Approval (Part Payment / No Payment)') {
          element.is_mandatory = 'O';
          this.isApprovalPaymentM = false;
        }
      });
    }

    let payments = this.paymentOnLoadData.opportunityDetails.paymentDetails
    if ((this.costCenter == 5 || this.costCenter == 6) && this.pageloadvalues?.opportunityStatus == 'Business Won' && payments.find(payment => payment.paymentType == 'Full Payment')) {
      this.docList.forEach(element => {
        if (element.type == 'Approval (Part Payment / No Payment)') {
          element.is_mandatory = 'O';
          this.isApprovalPaymentM = false;
        }
      });
    }

    if (this.clientTypeId == 1) {
      this.docList.forEach(element => {
        if (element.id == 26 || element.id == 6) {
          element.is_mandatory = 'O';
          element.isDisabled = false;
        }

      });
    }
  }

  duplicateEmail() {
    const email1 = this.ReportingScreenDetailsForm.value.emailtextField1
    const email2 = this.ReportingScreenDetailsForm.value.emailtextField2
    const email3 = this.ReportingScreenDetailsForm.value.emailtextField3
    if (email1 == email2) {
      this.email2Flag = true;
      this.email3Flag = false;
      this.isInvalid = false
      this.ReportingScreenDetailsForm.controls['emailtextField2'].setValidators([Validators.required]);
    }
    else if (email1 == email3) {
      this.email3Flag = true
      this.isInvalid = false
      this.email2Flag = false;
      this.ReportingScreenDetailsForm.controls['emailtextField3'].setValidators([Validators.required]);
    }
    else if ((email2 == email3) && (email2 != "") && (email3 != "") && (email2 != null) && (email3 != null)) {
      this.email3Flag = true
      this.isInvalid = false
      this.ReportingScreenDetailsForm.controls['emailtextField2'].setValidators([Validators.required]);
      this.ReportingScreenDetailsForm.controls['emailtextField3'].setValidators([Validators.required]);
    }
    else {
      this.email3Flag = false;
      this.email2Flag = false;
      this.isInvalid = true
    }

    if (email1 == email3 && email1 == email2) {
      this.email3Flag = true
      this.email2Flag = true;
      this.isInvalid = false
      this.ReportingScreenDetailsForm.controls['emailtextField2'].setValidators([Validators.required]);
      this.ReportingScreenDetailsForm.controls['emailtextField3'].setValidators([Validators.required]);
    }
    if (email2 == null || email2 == "") {
      this.isInvalid = true
    }
    if (email3 == null || email3 == "") {
      this.isInvalid = true
    }

  }

  ThirdPartyApprovalCommentControl() {
    const ThirdPartyApproval = this.ReportingScreenDetailsForm.value.PartyApproval;
    if (ThirdPartyApproval == "Yes") {
      this.thirdPartyFlag = true;
      this.ReportingScreenDetailsForm.controls['partyApprovalComment'].setValidators([Validators.required]);
      if (this.ReportingScreenDetailsForm.get('businessActionselect').value != 'Business Rejected') {
        this.docList.forEach(element => {
          if (element.type == '3rd Party approval Attachment') {
            element.is_mandatory = 'M';
          }
        });
      }
    } else if (ThirdPartyApproval == "No") {
      this.thirdPartyFlag = false;
      this.ReportingScreenDetailsForm.controls['partyApprovalComment'].clearValidators();
      this.docList.forEach(element => {
        if (element.type == '3rd Party approval Attachment') {
          element.is_mandatory = 'O';
        }
      });
    }
  }
  handleChangeEntityDetailstabview(e: any): void {
    this.ReportingScreenDetailsForm.patchValue({ "EntityDetailstabview": e.index });
  }
  handleChangeAttachmentInnertabview(e: any): void {
    this.ReportingScreenDetailsForm.patchValue({ "AttachmentInnertabview": e.index });
  }
  getbankList(): void {
    this.apiList.push(this.pagesService.getbankDataReportingScreenDetails().subscribe((results: any) => {
      const responseData = results;
      this.bankList = responseData.sort((a, b) => a.id - b.id);
    }));
  }

  getsalutationselectList(): void {
    this.apiList.push(this.pagesService.getsalutationselectDataReportingScreenDetails().subscribe(results => {
      const responseData = results;
      this.salutationselectList = responseData;
    }));
  }


  getdesignationselectList(): void {
    this.apiList.push(this.pagesService.getdesignationselectDataReportingScreenDetails().subscribe(results => {
      const responseData = results;
      this.designationselectList = responseData;
    }));
  }

  getGSTtypeselectList(): void {
    this.apiList.push(this.pagesService.getGSTtypeselectDataReportingScreenDetails().subscribe(results => {
      const responseData = results;
      this.GSTtypeselectList = responseData;
    }));
  }



  getpaymentModeList(): void {
    this.apiList.push(this.pagesService.getpaymentModeDataReportingScreenDetails().subscribe(results => {
      const responseData = results;
      this.paymentModeList = responseData;
    }));
  }



  getpaymenttypeList(): void {
    this.apiList.push(this.pagesService.getpaymenttypeDataReportingScreenDetails().subscribe(results => {
      const responseData = results;
      this.paymenttypeList = responseData;
    }));
  }


  show() {
    this.reportingValidationRef = this.dialogService.open(ReportingValidationComponent, {
      header: 'Alert',
      width: '30vw',
      closable: true,
      contentStyle: { "max-height": "216px", "overflow": "auto" },
      styleClass: 'sm',
    });
    this.dynamicDialogdialogRef.onClose.subscribe((response: any) => {
      // this.ReportingScreenDetailsForm.get('dialog')?.setValue(response);
    });
  }

  validations() {
    this.saveButtonClicked = true;
    this.verifyUploadedDoc()
    if (this.businessWonStatusFlagA) {
      this.businessWonvalidations();
    } else if (this.businessReportedStatusFlagA) {
      if (this.isMigrated) {
        this.onSaveBtn();
      } else {
        this.businessReportedValidations();
      }
    } else if (this.otherOpportunityStatusFlagA) {
      this.onSaveBtn();
    }
  }


  businessWonvalidations() {
    this.errorMessageOnSave = "";
    if (this.businessWonStatusFlagA) {
      this.checkAttachmentValidation();
      this.mandatoryFileNotUploded();
    }
    this.readyPaymentList();

    if ((this.CRM_Code == null || this.CRM_Code == "" || this.CRM_Code == undefined) && this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Reported") {
      this.ReportingScreenDetailsForm.patchValue({
        companyName: null
      });
    }
    let minMaxError: boolean = false;
    const EmailPattern = /^\w+[a-zA-Z0-9-._]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-_!@#$%^&)(,+=*]{2,10})+$/; //NOSONAR
    const companyName = this.ReportingScreenDetailsForm.value.companyName;
    const salutationSelect = this.ReportingScreenDetailsForm.value.salutationselect;
    const exposureperMCA = this.ReportingScreenDetailsForm.value.exposureperField;
    const firstName = this.ReportingScreenDetailsForm.value.firstNametextField;
    const LastName = this.ReportingScreenDetailsForm.value.lastNametextField;
    const designation = this.ReportingScreenDetailsForm.value.designationselect;
    const mobileNo = this.ReportingScreenDetailsForm.value.mobileNotextField;
    const email1 = this.ReportingScreenDetailsForm.value.emailtextField1;
    const email2 = this.ReportingScreenDetailsForm.value.emailtextField2;
    const email3 = this.ReportingScreenDetailsForm.value.emailtextField3;
    const GSTtype = this.ReportingScreenDetailsForm.value.GSTtypeselect;
    const PanNo = this.ReportingScreenDetailsForm.value.PANnotextField;
    const pinCode = this.ReportingScreenDetailsForm.value.pinCodetextField;
    const City = this.ReportingScreenDetailsForm.value.CitytextField;
    const PartyApp = this.ReportingScreenDetailsForm.value.PartyApproval;
    const partyAppComment = this.ReportingScreenDetailsForm.value.partyApprovalComment;
    const costCenter = this.ReportingScreenDetailsForm.value.costCenterselect;
    const BAreason = this.ReportingScreenDetailsForm.value.reasonselect;
    const BAComment = this.ReportingScreenDetailsForm.value.actionComment;
    const BAaction = this.ReportingScreenDetailsForm.value.businessActionselect;
    const REF_No = this.ReportingScreenDetailsForm.value.RefNotextField;
    const min = this.ReportingScreenDetailsForm.value.minimum;
    const max = this.ReportingScreenDetailsForm.value.maximum;

    if (REF_No != null && BAaction == "Business Rejected") {
      this.refNo = this.ReportingScreenDetailsForm.controls.RefNotextField.value;
    }

    for (let i = 0; i < this.payments.value.length; i++) {
      if (this.payments.value[i].paymentType !== 'No Payment' && BAaction == "Business Reported" && (!this.payments.value[i].chequeNo ||
        !this.payments.value[i].bank || !this.payments.value[i].paymentDat)
      ) {
        this.paymentDetailsMandatoryFlag = true;
        break;
      } else {
        this.paymentDetailsMandatoryFlag = false;
      }
    }

    if ((companyName == null || companyName == "") && this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Reported") {
      this.isCompanyName = true;
      document.getElementById('companyName').classList.add("ng-dirty");
    } else {
      this.isCompanyName = false;
    }
    if ((this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Reported") && (REF_No == null || REF_No == "")) {
      this.isREF = true;
    } else {
      this.isREF = false;
    }
    if ((this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Reported") && (this.RefNotextFieldCheck || this.dateValidationFlag || this.refDuplicateFlag)) {
      this.RefNotValid = true;
    } else {
      this.RefNotValid = false;
    }
    if (salutationSelect == null && this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Reported" && !this.isNoPayment) {
      this.isSalutationSelect = true;
      document.getElementById('salutationselectid').classList.add("ng-dirty");
    } else {
      this.isSalutationSelect = false;
    }
    if ((exposureperMCA == null || exposureperMCA == '') && this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Reported" && this.pageloadvalues.businessType == "New") {
      this.isExposureperMCA = true;
      document.getElementById('exposureperMCAtextField_id').classList.add("ng-dirty");
    } else {
      this.isExposureperMCA = false;
    }
    if ((firstName == null || firstName == "") && this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Reported" && !this.isNoPayment) {
      this.isFirstName = true;
      document.getElementById('firstNametextField_id').classList.add("ng-dirty");
    } else {
      this.isFirstName = false;
    }
    if ((LastName == null || LastName == "") && this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Reported" && !this.isNoPayment) {
      this.isLastName = true;
      document.getElementById('lastNametextField_id').classList.add("ng-dirty");
    } else {
      this.isLastName = false;
    }
    if (designation == null && this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Reported" && !this.isNoPayment) {
      this.isDesignation = true;
      document.getElementById('designationselectid').classList.add("ng-dirty");
    } else {
      this.isDesignation = false;
    }
    if ((mobileNo == null || mobileNo == "") && this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Reported" && !this.isNoPayment) {
      this.isMobileNo = true;
      document.getElementById('mobileNotextField_id').classList.add("ng-dirty");
    } else {
      this.isMobileNo = false;
    }
    if (email1 == null && this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Reported" && !this.isNoPayment) {
      this.isEmail1 = true;
      document.getElementById('emailtextField_id1').classList.add("ng-dirty");
    } else {
      this.isEmail1 = false;
    }
    if (email2 != null && email2 != "" && !this.isNoPayment) {
      if (!EmailPattern.test(email2)) {
        this.isEmail2 = true;
        document.getElementById('emailtextField_id2').classList.add("ng-dirty");
      } else {
        this.isEmail2 = false;
      }
    } else {
      this.isEmail2 = false;
    }
    if (email3 != null && email3 != "" && !this.isNoPayment) {
      if (!EmailPattern.test(email3)) {
        this.isEmail3 = true;
        document.getElementById('emailtextField_id3').classList.add("ng-dirty");
      } else {
        this.isEmail3 = false;
      }
    } else {
      this.isEmail3 = false;
    }
    if (GSTtype == null && this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Reported" && !this.isNoPayment) {
      this.isGSTtype = true;
      document.getElementById('GSTtypeselectid').classList.add("ng-dirty");
    } else {
      this.isGSTtype = false;
    }
    if ((this.ReportingScreenDetailsForm?.value?.GSTtypeselect === 'Exempt' || this.ReportingScreenDetailsForm?.value?.GSTtypeselect === 'Export' || this.ReportingScreenDetailsForm?.value?.GSTtypeselect === 'GST NA') && (PanNo == null) && !this.isNoPayment) {
      this.isPanNo = true;
      document.getElementById('PANnotextField_id').classList.add("ng-dirty");
    } else {
      this.isPanNo = false;
    }
    if ((this.ReportingScreenDetailsForm?.value?.GSTtypeselect === 'GST Normal' || this.ReportingScreenDetailsForm?.value?.GSTtypeselect === 'SEZ') && (this.ReportingScreenDetailsForm.get('GSTNotextField')?.value?.length < 15 || this.ReportingScreenDetailsForm.get('GSTNotextField')?.value == undefined || this.ReportingScreenDetailsForm.get('GSTNotextField')?.value == null)) {
      this.isGstNoIncorrect = true;
    } else {
      this.isGstNoIncorrect = false;
    }
    if (pinCode == null && this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Reported" && !this.isNoPayment) {
      this.isPinCode = true;
      document.getElementById('pinCodetextField_id').classList.add("ng-dirty");
    } else {
      this.isPinCode = false;
    }
    if (City == null && this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Reported" && !this.isNoPayment) {
      this.isCity = true;
      document.getElementById('cityid').classList.add("ng-dirty");
    } else {
      this.isCity = false;
    }
    if ((this.ReportingScreenDetailsForm?.value?.PartyApproval == "Yes") && (partyAppComment == null || partyAppComment == '' || partyAppComment.length < 2) && this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Reported") {
      this.isPartyAppComment = true;
      document.getElementById('partyApprovalComment_id').classList.add("ng-dirty");
    } else {
      this.isPartyAppComment = false;
    }
    if (costCenter == null) {
      this.isCostCenter = true;
      document.getElementById('costCenterselectid').classList.add("ng-dirty");
    } else {
      this.isCostCenter = false;
    }
    if ((this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Rejected" || this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Reported") && (BAreason == null)) {
      this.isBAreason = true;
      document.getElementById('reasonselectid').classList.add("ng-dirty");
    } else {
      this.isBAreason = false;
    }
    if (BAaction == null) {
      this.isBAaction = true;
      document.getElementById('businessAction').classList.add("ng-dirty");
    } else {
      this.isBAaction = false;
    }
    if ((this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Rejected") && (BAComment == null || BAComment == '')) {
      this.isBAComment = true;
      document.getElementById('actionComment_id').classList.add("ng-dirty");
    } else {
      this.isBAComment = false;
    }
    if (this.ReportingScreenDetailsForm?.value?.businessActionselect == "Business Reported" && this.mandetoryFileArray.length > 0) {
      this.isAttachmentM = true;
    } else {
      this.isAttachmentM = false;
    }

    if (Number(min) > 0 && (max == null || max == undefined || max == '')) {
      this.ReportingScreenDetailsForm.controls['maximum'].setValidators([Validators.required]);
      minMaxError = true;
    } else {
      this.ReportingScreenDetailsForm.controls['maximum'].setValidators([]);
    }
    this.ReportingScreenDetailsForm.controls.maximum.updateValueAndValidity();

    if (Number(max) > 0 && (min == null || min == undefined || min == '')) {
      this.ReportingScreenDetailsForm.controls['minimum'].setValidators([Validators.required]);
      this.ReportingScreenDetailsForm.controls.minimum.updateValueAndValidity();
      minMaxError = true;
    }

    if (this.isCompanyName || this.isREF || this.RefNotValid || this.isSalutationSelect || this.isExposureperMCA || this.isFirstName || this.isLastName || this.isDesignation || this.isMobileNo || this.isEmail1 ||
      this.isEmail2 || this.isEmail3 || this.isGSTtype || this.isPanNo || this.isGstNoIncorrect || this.isGstNoInvalid || this.isPinCode || this.isCity || this.isPartyAppComment || this.isCostCenter || this.isBAaction || this.isBAreason || this.isBAComment
      || this.paymentDetailsMandatoryFlag || this.isAttachmentM || minMaxError) {
      if (this.isCompanyName || this.isREF || this.RefNotValid) {
        this.errorMessageOnSave = this.errorMessageOnSave + " " + "Entity Details ";
        this.errorEntityDetails = true;
      } else {
        this.errorEntityDetails = false;
      }
      if (this.isSalutationSelect || this.isFirstName || this.isLastName || this.isDesignation || this.isMobileNo || this.isEmail1 ||
        this.isEmail2 || this.isEmail3 || this.isGSTtype || this.isPanNo || this.isGstNoIncorrect || this.isGstNoInvalid || this.isPinCode || this.isCity) {
        this.errorMessageOnSave = this.errorMessageOnSave + ", " + "Entity Contact ";
        this.errorEntityContacts = true;
      } else {
        this.errorEntityContacts = false;
      }
      if (this.isPartyAppComment || this.paymentDetailsMandatoryFlag || minMaxError) {
        this.errorMessageOnSave = this.errorMessageOnSave + ", " + "Opportunity Details ";
        this.errorOpportunityDetails = true;
      } else {
        this.errorOpportunityDetails = false;
      }
      if (this.isCostCenter || this.isExposureperMCA) {
        this.errorMessageOnSave = this.errorMessageOnSave + ", " + "MCA Check ";
        this.errorMcaCheck = true;
      } else {
        this.errorMcaCheck = false;
      }
      if (this.isBAreason || this.isBAComment || this.isBAaction) {
        this.errorMessageOnSave = this.errorMessageOnSave + ", " + "Action";
        this.errorAction = true;
      } else {
        this.errorAction = false;
      }
      if (this.duplicateDocList.length != 0) {
        this.errorMessageOnSave = this.errorMessageOnSave + ", " + "Duplicate attachment types";
        this.errorAttachments = true;
      } else {
        this.errorAttachments = false;
      }
      if (this.mandetoryFileArray.length != 0 && this.isAttachmentM == true) {
        this.errorMessageOnSave = this.errorMessageOnSave + ", " + "Attachment";
        this.errorAttachments = true;
      } else {
        this.errorAttachments = false;
      }
      this.pagesService.setMessageOnSaveError(this.errorMessageOnSave);
      this.show();
    } else {
      this.onSaveBtn();
    }
  }

  businessReportedValidations() {
    this.errorMessageOnSave = "";
    const REF_No = this.ReportingScreenDetailsForm.value.RefNotextField;
    const BAaction = this.ReportingScreenDetailsForm.value.businessActionselect;
    // mandatory file check after report
    // usecase - payments come after business report and are still < 50%
    // hence, will make - 'Payment related approval' mandatory
    this.mandatoryFileNotUploded();
    if (REF_No == null || REF_No == "") {
      this.isREF = true;
    } else {
      this.isREF = false;
    }
    if (this.refDuplicateFlag) {
      this.RefNotValid = true;
    } else {
      this.RefNotValid = false;
    }

    if (this.isREF || this.RefNotValid) {
      this.errorMessageOnSave = this.errorMessageOnSave + ", " + "Entity Details";
      this.errorEntityDetails = true;
    } else {
      this.errorEntityDetails = false;
    }

    for (let i = 0; i < this.payments.value.length; i++) {
      if (this.payments.value[i].paymentType !== 'No Payment' && BAaction == "Business Reported" && (!this.payments.value[i].chequeNo ||
        !this.payments.value[i].bank || !this.payments.value[i].paymentDat)
      ) {
        this.paymentDetailsMandatoryFlag = true;
        break;
      } else {
        this.paymentDetailsMandatoryFlag = false;
      }
    }

    if (this.paymentDetailsMandatoryFlag) {
      this.errorMessageOnSave = this.errorMessageOnSave + ", " + "Opportunity Details";
      this.errorOpportunityDetails = true;
    } else {
      this.errorOpportunityDetails = false;
    }

    if (this.mandetoryFileArray.length > 0) {
      this.errorMessageOnSave = this.errorMessageOnSave + ", " + "Attachment";
      this.errorAttachments = true;
      // to show modal - 
      this.pagesService.setMessageOnSaveError(this.errorMessageOnSave);
      this.show();
      return;
    } else {
      this.errorAttachments = false;
    }
    const BAreason = this.ReportingScreenDetailsForm.value.reasonselect;
    if (BAreason == null) {
      this.isBAreason = true;
      this.errorMessageOnSave = this.errorMessageOnSave + ", " + "Action";
      document.getElementById('reasonselectid').classList.add("ng-dirty");
    } else {
      this.isBAreason = false;
    }
    if ((this.isInternalPricingFile == false) || this.isBAreason || this.paymentDetailsMandatoryFlag || this.isREF || this.RefNotValid) {
      this.pagesService.setMessageOnSaveError(this.errorMessageOnSave);
      this.show();
    } else {
      if (this.isQcRejected) {
        this.redirectPopup = true;
      } else {
        this.onSaveBtn();
      }
    }
  }

  noRedirect() {
    this.allowRedirection = false;
    this.onSaveBtn();
  }

  redirect() {
    this.allowRedirection = true;
    this.onSaveBtn();
  }

  onCancel(): void {
    this.ref.close();
    this.deleteMultipleFile();
  }
  onPreviewScreen(): void {
    let previewDtata = {
      "ReportingScreenDetailsForm": this.ReportingScreenDetailsForm?.value,
      "opportunityStatus": this.ReportingScreenDetailsForm?.value?.opportunityStatus,
      "oppDate": this.formatDate(this.ReportingScreenDetailsForm?.value?.businessWonDate),
      "crmCode": this.ReportingScreenDetailsForm?.value?.CRMCodetextField,
      "companyName": this.ReportingScreenDetailsForm?.value?.companyName,
      "crmName": this.ReportingScreenDetailsForm?.value?.CompanyTypeselect,
      "contact": this.ReportingScreenDetailsForm?.value?.tripartyContactName,
      "finRisk": this.ReportingScreenDetailsForm?.value?.FinRisktextField,
      "refNo": this.ReportingScreenDetailsForm?.value?.RefNotextField,
      "groupCompnay": this.ReportingScreenDetailsForm?.value?.GroupCompanytextField,
      "companyType": this.ReportingScreenDetailsForm?.value?.CompanyTypeselect,
      "rptInCC": this.ReportingScreenDetailsForm?.value?.rptCC,
      "rptInCrm": this.ReportingScreenDetailsForm?.value?.rptCRM,
      "rptStatusOverall": this.ReportingScreenDetailsForm?.value?.rptOverall,
      "finRiskComment": this.ReportingScreenDetailsForm?.value?.finComment,
      "triPartyCompanyName": this.ReportingScreenDetailsForm?.value?.triCrm,
      "triPartyNormal": this.ReportingScreenDetailsForm?.value?.triParty,
      "triCrmCode": this.ReportingScreenDetailsForm?.value?.triCrmCode,
      "triFinRisk": this.ReportingScreenDetailsForm?.value?.triFinRisk,
      "triFinRiskComment": this.ReportingScreenDetailsForm?.value?.triFinComment,
      "entitySalutation": this.ReportingScreenDetailsForm?.value?.salutationselect,
      "entityFirstName": this.ReportingScreenDetailsForm?.value?.firstNametextField,
      "entityLastName": this.ReportingScreenDetailsForm?.value?.lastNametextField,
      "entityDesignation": this.ReportingScreenDetailsForm?.value?.designationselect,
      "entityMobileNo": this.ReportingScreenDetailsForm?.value?.mobileNotextField,
      "entityLandlineNo": this.ReportingScreenDetailsForm?.value?.landlineNotextField,
      "entityEmail": this.ReportingScreenDetailsForm?.value?.emailtextField1,
      "email2": this.ReportingScreenDetailsForm?.value?.emailtextField2,
      "email3": this.ReportingScreenDetailsForm?.value?.emailtextField3,
      "entityGstType": this.ReportingScreenDetailsForm?.value?.GSTtypeselect,
      "entityGstNumber": this.ReportingScreenDetailsForm?.value?.GSTNotextField,
      "entityPanNumber": this.ReportingScreenDetailsForm?.value?.PANnotextField,
      "entityPinCode": this.ReportingScreenDetailsForm?.value?.pinCodetextField,
      "enityCity": this.ReportingScreenDetailsForm?.value?.CitytextField,
      "entityState": this.ReportingScreenDetailsForm?.value?.StatetextField,
      "enityAdressLine1": this.ReportingScreenDetailsForm?.value?.address1,
      "enityAdressLine2": this.ReportingScreenDetailsForm?.value?.address2,
      "enityAddressLine3": this.ReportingScreenDetailsForm?.value?.address3,
      "enityAddressLine4": this.ReportingScreenDetailsForm?.value?.textField,
      "triSalutation": this.ReportingScreenDetailsForm?.value?.triSalutation,
      "triFirstName": this.ReportingScreenDetailsForm?.value?.triFirstName,
      "triLastName": this.ReportingScreenDetailsForm?.value?.triLastName,
      "triDesignation": this.ReportingScreenDetailsForm?.value?.triDesignation,
      "triMobileNo": this.ReportingScreenDetailsForm?.value?.triMobile,
      "triLandlineNo": this.ReportingScreenDetailsForm?.value?.triLandline,
      "triEmail": this.ReportingScreenDetailsForm?.value?.triEmail1,
      "triEmail2": this.ReportingScreenDetailsForm?.value?.triEmail2,
      "triEmail3": this.ReportingScreenDetailsForm?.value?.triEmail3,
      "triGstType": this.ReportingScreenDetailsForm?.value?.triGstType,
      "triGstNumber": this.ReportingScreenDetailsForm?.value?.triGstNo,
      "triPanNumber": this.ReportingScreenDetailsForm?.value?.triPanNo,
      "triPincode": this.ReportingScreenDetailsForm?.value?.triPinCode,
      "triCity": this.ReportingScreenDetailsForm?.value?.triCity,
      "triState": this.ReportingScreenDetailsForm?.value?.triState,
      "triAddressLine1": this.ReportingScreenDetailsForm?.value?.triAddress1,
      "triAddressLine2": this.ReportingScreenDetailsForm?.value?.triAddress2,
      "triAddressLine3": this.ReportingScreenDetailsForm?.value?.triAddress3,
      "triAddressLine4": this.ReportingScreenDetailsForm?.value?.triAddress4,
      "opportunityId": this.ReportingScreenDetailsForm?.value?.opportunityId,
      "agreementId": this.ReportingScreenDetailsForm?.value?.agreementId,
      "qcStatus": this.ReportingScreenDetailsForm?.value?.qcStatus,
      "businessType": this.ReportingScreenDetailsForm?.value?.businessType,
      "product": this.ReportingScreenDetailsForm?.value?.productselect,
      "instrument": this.ReportingScreenDetailsForm?.value?.instrumentselect,
      "quantum": this.ReportingScreenDetailsForm?.value?.quantumtextField1,
      "maCase": this.ReportingScreenDetailsForm?.value?.maCase,
      "irfExpectedFee": this.ReportingScreenDetailsForm?.value?.irfexpectedFee,
      "irfActualFee": this.ReportingScreenDetailsForm?.value?.irfactualFee,
      "irfDiscountPremium": this.ReportingScreenDetailsForm?.value?.irfDiscount,
      "irfDiscountPremiumPer": this.ReportingScreenDetailsForm?.value?.irfDiscountPercent,
      "irfDiscountReason": this.ReportingScreenDetailsForm?.value?.irfDiscountReason,
      "sfFeeRule": this.ReportingScreenDetailsForm?.value?.sfFeeRule,
      "sfExpectedFee": this.ReportingScreenDetailsForm?.value?.sfExpectedFee,
      "sfActualFee": this.ReportingScreenDetailsForm?.value?.sfActualFee,
      "sfDiscountPremium": this.ReportingScreenDetailsForm?.value?.sfDiscountPremium,
      "sfDiscountPremiumPer": this.ReportingScreenDetailsForm?.value?.sfDiscountPremiumPercent,
      "sfDiscountReason": this.ReportingScreenDetailsForm?.value?.sfDiscountReason,
      "paymentCollected": this.paymentCollected,
      "paymentMode": this.ReportingScreenDetailsForm?.value?.paymentMode1,
      "neftChequeNo": this.ReportingScreenDetailsForm?.value?.chequeNEFTdetails1,
      "bank": this.ReportingScreenDetailsForm?.value?.bank1,
      "paymentDate": this.ReportingScreenDetailsForm?.value?.dateCalendar1,
      "paymentType": this.ReportingScreenDetailsForm?.value?.paymenttype1,
      "tdsType": this.ReportingScreenDetailsForm?.value?.tdsType1,
      "tdsAmount": this.ReportingScreenDetailsForm?.value?.tdsAmount1,
      "amountCollected": this.ReportingScreenDetailsForm?.value?.amountCollected1,
      "grossAmount": this.ReportingScreenDetailsForm?.value?.grossAmount1,
      "paymentDetailsArray": this.payments?.value,
      "costCenter": this.costCenterselectList.filter(costCenter => costCenter.id === this.ReportingScreenDetailsForm?.value?.costCenterselect)[0]?.name,
      "exposureAsPerMca": this.ReportingScreenDetailsForm?.value?.exposureperField,
      "status": this.ReportingScreenDetailsForm?.value?.MCAstatus,
      "businessAction": this.ReportingScreenDetailsForm?.value?.businessActionselect,
      "comment": this.ReportingScreenDetailsForm?.value?.actionComment,
      "reason1": this.ReportingScreenDetailsForm?.value?.reasonselect,
      "sApprovalNeeded": this.ReportingScreenDetailsForm?.value?.standardApprovalNeeded ? 'Yes' : 'No',
      "sApprovalReceived": this.ReportingScreenDetailsForm?.value?.standardApprovalReceived ? 'Yes' : 'No',
      "iApprovalNeeded": this.ReportingScreenDetailsForm?.value?.InternalApprovalNeeded ? 'Yes' : 'No',
      "iApprovalReceived": this.ReportingScreenDetailsForm?.value?.InternalApprovalReceived ? 'Yes' : 'No',
      "criticalDocReceived": this.ReportingScreenDetailsForm?.value?.criticalDocReceived ? 'Yes' : 'No',
      "tanNo": this.ReportingScreenDetailsForm?.value.tanDetails,
      "minimumValue": this.ReportingScreenDetailsForm?.value?.minimum,
      "maximumValue": this.ReportingScreenDetailsForm?.value?.maximum,
      "revisedSfFee": this.ReportingScreenDetailsForm?.value?.revisedSfActual,
      "sfExpectedPer": this.ReportingScreenDetailsForm?.value?.sfExpectedPercent == 'NA' ? null : this.ReportingScreenDetailsForm?.value?.sfExpectedPercent,
      "sfActualPer": this.ReportingScreenDetailsForm?.value?.sfActualPercent == 'NA' ? null : this.ReportingScreenDetailsForm?.value?.sfActualPercent,
      "categoryName": this.ReportingScreenDetailsForm?.value?.categoryName,

    }
    const encPreviewData = this.encryptDecryptInterceptor.encryptLocalObject(previewDtata);

    localStorage.setItem('previewData', encPreviewData);
    let previewUrl = DOMAIN_ROUTE + "#/backoffice/pages/preview";
    const newWindow = window.open(previewUrl, '_blank');
    // const newWindow = window.open("http://localhost:4200/#/backoffice/pages/preview", '_blank');

  }
  onCancelBtn($event: FormGroup): void {
    this.ref.close($event);
  }

  onSaveBtn(): void {
    this.onSaveLoader = true;
    this.ReportingScreenDetailsForm.value.emailtextField2 = ((this.ReportingScreenDetailsForm.value.emailtextField2 == undefined) || (this.ReportingScreenDetailsForm.value.emailtextField2 == '')) ? (null) : (this.ReportingScreenDetailsForm.value.emailtextField2);
    this.ReportingScreenDetailsForm.value.emailtextField3 = ((this.ReportingScreenDetailsForm.value.emailtextField3 == undefined) || (this.ReportingScreenDetailsForm.value.emailtextField3 == '')) ? (null) : (this.ReportingScreenDetailsForm.value.emailtextField3);
    this.grossAmountMoreFlag = false;
    this.grossAmountLessFlag = false;
    let documents = [...this.displayAttachData2, ...this.result];
    documents.forEach((d) => {
      d.file = null;
    });

    this.payments.value.forEach(element => {
      if (element.paymentType != "No Payment") {
        const formattedDate = this.formatDate(element.paymentDat);
        element.paymentDat = formattedDate;
      }
    });

    const reqBody: any = {};
    reqBody['tripartyMandateId'] = this.ReportingScreenDetailsForm.value.tripartyMandate_Id;
    reqBody['isAlreadyCRMEntity'] = this.isAlreadyCRMEntity;
    reqBody['entityId'] =
    {
      "id": this.ReportingScreenDetailsForm?.value?.entity_id,
      "entityType": this.ReportingScreenDetailsForm?.value?.CompanyTypeselect,
      "entityTypeId": this.CompanyTypeselectList.filter(CompanyType => CompanyType.type === this.ReportingScreenDetailsForm?.value?.CompanyTypeselect)[0]?.id,
      "name": this.ReportingScreenDetailsForm.value?.companyName == null ? this.ReportingScreenDetailsForm?.value?.companyName?.value : this.ReportingScreenDetailsForm?.value?.companyName?.companyName,
      "companyCode": this.ReportingScreenDetailsForm?.value?.CRMCodetextField,
      "finRisk": this.ReportingScreenDetailsForm?.value?.FinRisktextField,
      "finRiskComment": this.ReportingScreenDetailsForm?.value?.finComment,
      "tripartyFinRisk": this.ReportingScreenDetailsForm?.value?.triFinRisk,
      "tripartyFinRiskComment": this.ReportingScreenDetailsForm?.value?.triFinComment,
      "refNo": this.ReportingScreenDetailsForm?.value?.RefNotextField,
      "entityGroupName": this.ReportingScreenDetailsForm?.value?.GroupCompanytextField,
    };

    if (this.ReportingScreenDetailsForm.value.businessActionselect == "Business Rejected") {
      this.deleteMultipleFile();
      reqBody['entityId'].refNo = null;
    }

    reqBody['entityContacts'] =
    {
      "id": this.ReportingScreenDetailsForm?.value?.entityContactId,
      "firstName": this.ReportingScreenDetailsForm?.value?.firstNametextField,
      "lastName": this.ReportingScreenDetailsForm?.value?.lastNametextField,
      "salutationId": this.salutationselectList.filter(salutation => salutation.name === this.ReportingScreenDetailsForm?.value?.salutationselect)[0]?.id,
      "salutation": this.ReportingScreenDetailsForm?.value?.salutationselect,
      "designationId": this.designationselectList.filter(designation => designation.name === this.ReportingScreenDetailsForm?.value?.designationselect)[0]?.id,
      "designation": this.ReportingScreenDetailsForm?.value?.designationselect,
      "landlineNumber": this.ReportingScreenDetailsForm?.value?.landlineNotextField,
      "email": [
        this.ReportingScreenDetailsForm?.value?.emailtextField1,
        this.ReportingScreenDetailsForm?.value?.emailtextField2,
        this.ReportingScreenDetailsForm?.value?.emailtextField3,
      ],
      "mobileNumber": this.ReportingScreenDetailsForm?.value?.mobileNotextField,
      "gstNumber": this.ReportingScreenDetailsForm?.value?.GSTNotextField,
      "panNumber": this.ReportingScreenDetailsForm?.value?.PANnotextField,
      "isDefault": this.ReportingScreenDetailsForm?.value?.is_default,
      "gstTypeId": this.GSTtypeselectList.filter(GSTtype => GSTtype.property === this.ReportingScreenDetailsForm?.value?.GSTtypeselect)[0]?.id,
      "gstType": this.ReportingScreenDetailsForm?.value?.GSTtypeselect,
      "addressLine1": this.ReportingScreenDetailsForm?.value?.address1,
      "addressLine2": this.ReportingScreenDetailsForm?.value?.address2,
      "addressLine3": this.ReportingScreenDetailsForm?.value?.address3,
      "addressLine4": this.ReportingScreenDetailsForm?.value?.textField,
      "cityId": this.cityList.filter(city => city.cityName === this.ReportingScreenDetailsForm?.value?.CitytextField)[0]?.cityId,
      "stateId": this.cityList.filter(state => state.stateName === this.ReportingScreenDetailsForm?.value?.StatetextField)[0]?.stateId,
      "pincode": this.ReportingScreenDetailsForm?.value?.pinCodetextField,
      "city": this.ReportingScreenDetailsForm?.value?.CitytextField,
      "state": this.ReportingScreenDetailsForm?.value?.StatetextField,
      "userId": this.ReportingScreenDetailsForm?.value?.UserIdInContact,
      "isReporting": this.ReportingScreenDetailsForm?.value?.is_reporting
    };
    reqBody['opportunityDetails'] =
    {
      "id": this.OpportunityId,
      "thirdPartyApproval": this.ReportingScreenDetailsForm?.value?.PartyApproval,
      "thirdPartyApprovalComment": this.ReportingScreenDetailsForm?.value?.partyApprovalComment,
      "feeDetails":
      {
        "id": this.ReportingScreenDetailsForm?.value?.feeDetails_id,
        "idealFeeExpected": this.ReportingScreenDetailsForm?.value?.irfexpectedFee,
        "irfActualFees": this.ReportingScreenDetailsForm?.value?.irfactualFee,
        "irfDiscountPercentage": this.ReportingScreenDetailsForm?.value?.irfDiscountPercent,
        "irfDiscount": this.ReportingScreenDetailsForm?.value?.irfDiscount == null ? 'NA' : this.ReportingScreenDetailsForm?.value?.irfDiscount,
        "discountReason": this.ReportingScreenDetailsForm?.value?.irfDiscountReason,
        "sfActualFees": this.ReportingScreenDetailsForm?.value?.sfActualFee == 'NA' ? null : this.ReportingScreenDetailsForm?.value?.sfActualFee,
        "sfDiscount": this.ReportingScreenDetailsForm?.value?.sfDiscountPremium == 'NA' ? null : this.ReportingScreenDetailsForm?.value?.sfDiscountPremium,
        "sfDiscountPercentage": this.ReportingScreenDetailsForm?.value?.sfDiscountPremiumPercent == 'NA' ? null : this.ReportingScreenDetailsForm?.value?.sfDiscountPremiumPercent,
        "idealFeeSfExpected": this.ReportingScreenDetailsForm?.value?.sfExpectedFee == 'NA' ? null : this.ReportingScreenDetailsForm?.value?.sfExpectedFee,
        "sfFeeRuleId": this.ReportingScreenDetailsForm?.value?.sfFeeRule,
        "sfActualPercent": this.ReportingScreenDetailsForm?.value?.sfActualPercent == 'NA' ? null : this.ReportingScreenDetailsForm?.value?.sfActualPercent,
        "expectedSfPercent": this.ReportingScreenDetailsForm?.value?.sfExpectedPercent == 'NA' ? null : this.ReportingScreenDetailsForm?.value?.sfExpectedPercent,
        "minimumValue": this.ReportingScreenDetailsForm?.value?.minimum,
        "maximumValue": this.ReportingScreenDetailsForm?.value?.maximum,
        "revisedSfFee": this.ReportingScreenDetailsForm?.value?.revisedSfActual
      },
      "paymentDetails": this.payments.value,
    };

    reqBody['userId'] = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid'));

    let approvalTabDetails: any = {
      cdReceived: this.ReportingScreenDetailsForm?.value?.criticalDocReceived ? 'Yes' : 'No',
      ipaNeeded: this.ReportingScreenDetailsForm?.value?.InternalApprovalNeeded ? 'Yes' : 'No',
      ipaReceived: this.ReportingScreenDetailsForm?.value?.InternalApprovalReceived ? 'Yes' : 'No',
      spaNeeded: this.ReportingScreenDetailsForm?.value?.standardApprovalNeeded ? 'Yes' : 'No',
      spaReceived: this.ReportingScreenDetailsForm?.value?.standardApprovalReceived ? 'Yes' : 'No'
    }
    let finalApprovalDetails: any = {
      cdReceived: this.ReportingScreenDetailsForm?.value?.criticalDocReceived == this.approvalTabData?.cdReceived ? null : approvalTabDetails?.cdReceived,
      ipaNeeded: this.ReportingScreenDetailsForm?.value?.InternalApprovalNeeded == this.approvalTabData?.ipaNeeded ? null : approvalTabDetails?.ipaNeeded,
      ipaReceived: this.ReportingScreenDetailsForm?.value?.InternalApprovalReceived == this.approvalTabData?.ipaReceived ? null : approvalTabDetails?.ipaReceived,
      spaNeeded: this.ReportingScreenDetailsForm?.value?.standardApprovalNeeded == this.approvalTabData?.spaNeeded ? null : approvalTabDetails?.spaNeeded,
      spaReceived: this.ReportingScreenDetailsForm?.value?.standardApprovalReceived == this.approvalTabData?.spaReceived ? null : approvalTabDetails?.spaReceived,
    }
    reqBody['reportingDetails'] =
    {
      "id": this.ReportingScreenDetailsForm?.value?.reportingDetailsId,
      "costCenter": this.costCenterselectList.filter(costCenter => costCenter.id === this.ReportingScreenDetailsForm?.value?.costCenterselect)[0]?.name,
      "costCenterId": this.ReportingScreenDetailsForm?.value?.costCenterselect,
      "mcaExposure": this.ReportingScreenDetailsForm?.value?.exposureperField,
      "mcaStatus": this.ReportingScreenDetailsForm?.value?.MCAstatus,
      "spaApprovalNeeded": finalApprovalDetails?.spaNeeded,
      "spaApprovalReceived": finalApprovalDetails?.spaReceived,
      "ipaApprovalReceived": finalApprovalDetails?.ipaReceived,
      "ipaApprovalNeeded": finalApprovalDetails?.ipaNeeded,
      "ipaCriticalDocReceived": finalApprovalDetails?.cdReceived,
      "businessAction": this.ReportingScreenDetailsForm?.value?.businessActionselect,
      "businessActionId": this.businessActionselectList.filter(businessAction => businessAction.property === this.ReportingScreenDetailsForm?.value?.businessActionselect)[0]?.id,
      "reason": this.ReportingScreenDetailsForm?.value?.reasonselect,
      "reasonId": this.reasonselectList?.filter(reasonselectList => reasonselectList.name === this.ReportingScreenDetailsForm?.value?.reasonselect)[0]?.id,
      "comment": this.ReportingScreenDetailsForm?.value?.actionComment,
      "ccRptStatus": this.ReportingScreenDetailsForm?.value.rptCC,
      "crmRptStatus": this.ReportingScreenDetailsForm?.value.rptCRM,
      "tanNo": this.ReportingScreenDetailsForm?.value.tanDetails
    };

    if (this.paymentOnLoadData?.opportunityStatus == "Business Rejected" || this.paymentOnLoadData?.opportunityStatus == "Business Reported") {
      reqBody['reportingDetails'].ipaApprovalNeededActionedBy = this.paymentOnLoadData?.reportingDetails?.ipaApprovalNeededActionedBy;
      reqBody['reportingDetails'].ipaApprovalNeededActionedDate = this.paymentOnLoadData?.reportingDetails?.ipaApprovalNeededActionedDate;
      reqBody['reportingDetails'].ipaApprovalReceivedActionedBy = this.paymentOnLoadData?.reportingDetails?.ipaApprovalReceivedActionedBy;
      reqBody['reportingDetails'].ipaApprovalReceivedActionedDate = this.paymentOnLoadData?.reportingDetails?.ipaApprovalReceivedActionedDate;
      reqBody['reportingDetails'].ipaCriticalDocReceivedActionedBy = this.paymentOnLoadData?.reportingDetails?.ipaCriticalDocReceivedActionedBy;
      reqBody['reportingDetails'].ipaCriticalDocReceivedActionedDate = this.paymentOnLoadData?.reportingDetails?.ipaCriticalDocReceivedActionedDate;
      reqBody['reportingDetails'].caseActionedBy = this.paymentOnLoadData?.reportingDetails?.caseActionedBy;
    }

    reqBody['uploadedDocument'] = documents;
    reqBody['documentToBeUploaded'] = [];

    reqBody['combinedDocument'] = this.combinedPdfData;

    let formData: any = {};
    if (this.paymentOnLoadData?.opportunityStatus == "Business Won" && this.ReportingScreenDetailsForm?.value?.businessActionselect == 'Business Rejected') {
      let payload = {
        userId: this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid')),
        opportunityDetails: {
          "id": this.OpportunityId
        },
        reportingDetails: {
          "businessAction": this.ReportingScreenDetailsForm?.value?.businessActionselect,
          "businessActionId": this.businessActionselectList.filter(businessAction => businessAction.property === this.ReportingScreenDetailsForm?.value?.businessActionselect)[0]?.id,
          "reason": this.ReportingScreenDetailsForm?.value?.reasonselect,
          "reasonId": this.reasonselectList?.filter(reasonselectList => reasonselectList.name === this.ReportingScreenDetailsForm?.value?.reasonselect)[0]?.id,
          "comment": this.ReportingScreenDetailsForm?.value?.actionComment
        }
      };
      formData = payload;
    } else if (this.paymentOnLoadData?.opportunityStatus == "Business Rejected") {
      let payload = {
        userId: this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid')),
        opportunityDetails: {
          "id": this.OpportunityId
        },
        reportingDetails: {
          "spaApprovalNeeded": finalApprovalDetails?.spaNeeded,
          "spaApprovalReceived": finalApprovalDetails?.spaReceived,
          "ipaApprovalReceived": finalApprovalDetails?.ipaReceived,
          "ipaApprovalNeeded": finalApprovalDetails?.ipaNeeded,
          "ipaCriticalDocReceived": finalApprovalDetails?.cdReceived,
        }
      };
      formData = payload;
    }
    else if (this.paymentOnLoadData?.opportunityStatus == "Business Reported") {
      let reason = this.ReportingScreenDetailsForm?.value?.reasonselect == this.actionTabData?.reason ? null : this.ReportingScreenDetailsForm?.value?.reasonselect;
      let comment = this.ReportingScreenDetailsForm?.value?.actionComment == this.actionTabData?.comment ? null : this.ReportingScreenDetailsForm?.value?.actionComment;
      if (comment == '') {
        comment = null;
      }
      let payload = {
        userId: this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid')),
        opportunityDetails: {
          "id": this.OpportunityId,
          feeDetails:
          {
            "id": this.ReportingScreenDetailsForm?.value?.feeDetails_id,
            "sfActualFees": this.ReportingScreenDetailsForm?.value?.sfActualFee == 'NA' ? null : this.ReportingScreenDetailsForm?.value?.sfActualFee,
            "sfDiscount": this.ReportingScreenDetailsForm?.value?.sfDiscountPremium == 'NA' ? null : this.ReportingScreenDetailsForm?.value?.sfDiscountPremium,
            "sfDiscountPercentage": this.ReportingScreenDetailsForm?.value?.sfDiscountPremiumPercent == 'NA' ? null : this.ReportingScreenDetailsForm?.value?.sfDiscountPremiumPercent,
            "idealFeeSfExpected": this.ReportingScreenDetailsForm?.value?.sfExpectedFee == 'NA' ? null : this.ReportingScreenDetailsForm?.value?.sfExpectedFee,
            "sfFeeRuleId": this.ReportingScreenDetailsForm?.value?.sfFeeRule,
            "sfActualPercent": this.ReportingScreenDetailsForm?.value?.sfActualPercent == 'NA' ? null : this.ReportingScreenDetailsForm?.value?.sfActualPercent,
            "expectedSfPercent": this.ReportingScreenDetailsForm?.value?.sfExpectedPercent == 'NA' ? null : this.ReportingScreenDetailsForm?.value?.sfExpectedPercent,
            "minimumValue": this.ReportingScreenDetailsForm?.value?.minimum,
            "maximumValue": this.ReportingScreenDetailsForm?.value?.maximum,
            "revisedSfFee": this.ReportingScreenDetailsForm?.value?.revisedSfActual
          },
          paymentDetails: this.payments.value,
        },
        reportingDetails: {
          "spaApprovalNeeded": finalApprovalDetails?.spaNeeded,
          "spaApprovalReceived": finalApprovalDetails?.spaReceived,
          "ipaApprovalReceived": finalApprovalDetails?.ipaReceived,
          "ipaApprovalNeeded": finalApprovalDetails?.ipaNeeded,
          "ipaCriticalDocReceived": finalApprovalDetails?.cdReceived,
          "reason": reason,
          "reasonId": reason == null ? null : this.reasonselectList?.filter(reasonselectList => reasonselectList.name === this.ReportingScreenDetailsForm?.value?.reasonselect)[0]?.id,
          "comment": comment,
          "costCenter": this.costCenterselectList.filter(costCenter => costCenter.id === this.ReportingScreenDetailsForm?.value?.costCenterselect)[0]?.name,
          "costCenterId": this.ReportingScreenDetailsForm?.value?.costCenterselect,
          "businessAction": this.ReportingScreenDetailsForm?.value?.businessActionselect,
          "businessActionId": this.businessActionselectList.filter(businessAction => businessAction.property === this.ReportingScreenDetailsForm?.value?.businessActionselect)[0]?.id,
        },
        entityId: {
          "id": this.ReportingScreenDetailsForm?.value?.entity_id,
          "tanNo": this.ReportingScreenDetailsForm?.value?.tanDetails,
          "refNo": this.ReportingScreenDetailsForm?.value?.RefNotextField,
        },
        deleteAttachmentIds: this.attachToDelete
      };
      formData = payload;
    } else {
      formData = reqBody;
    }
    formData = { ...formData, isAdditionalPayment: !!this.isAdditionalPayment };
    this.apiList.push(this.pagesService.onSaveBtnSubmitCreateReportingData(formData).subscribe(results => {
      this.onSaveLoader = false;
      if (this.isQcApproved) {
        let payload = {
          agreementId: this.ReportingScreenDetailsForm.value?.agreementId,
          isAdditionalPaymentResubmitted: this.additionalPayResubmit,
          isOriginalMandateUploaded: this.ogMandateUpdated,
          actionedBy: this.userId
        }
        this.pagesService.qcResubmit(payload).subscribe((_res) => {

        });
      }

      if (this.isQcRejected && this.allowRedirection) {
        window.location.href = `${DOMAIN_ROUTE}#/backoffice/initiation/irf`;
      }
      this.onCancelBtn(results);
    }, (error) => {
      this.onSaveLoader = false;
      this.messageService.clear();
      this.deleteMultipleFile();
      this.onCancelBtn(error);
    }));
  }

  verifyUploadedDoc() {
    this.displayAttachData2 = [];
    this.displayAttachData.forEach((x) => {
      this.displayAttachData2.push({
        fileId: x.fileId,
        fileTypeName: x.fileTypeName,
        fileTypeid: x.fileTypeid,
        moduleId: x.moduleId,
        moduleName: x.moduleName,
        userId: x.userId
      })
    });
  }

  getbusinessTypeList(): void {
    this.apiList.push(this.pagesService.getbusinessTypeDataEditOpportunity().subscribe(results => {
      const responseData = results;
      this.businessTypeList = responseData;
    }));
  }

  getCompanyTypeList(): void {
    this.apiList.push(this.pagesService.getentityTypeDataCreateEntity().subscribe(results => {
      const responseData = results;
      this.CompanyTypeselectList = responseData;
    }));
  }

  loadcompanyNameLazy(event: any): any {
    if (event.query.length >= 4) {
      this.showCrmLoader = true;
      this.pagesService.getCompanyType(event.query).subscribe(results => {
        const responseData = results;
        this.companyNameList = [];
        this.search1LoadAtOnce = [];
        this.showCrmLoader = false;
        if (responseData && responseData.length > 0) {
          this.companyNameList = responseData;
        } else {
          this.companyNameList = [];
          this.emptyMsgFlag = true;
        }
        this.displayAttachData.forEach((da) => {
          this.docList.forEach((d, i) => {
            if (da.fileTypeName == d.type) {
              this.docList.splice(i, 1);
            }
          });
        });
      }, (_err) => {
        this.companyNameList = [];
        this.emptyMsgFlag = true;
        this.showCrmLoader = false;
      });
    } else {
      this.showCrmLoader = false;
      this.companyNameList = [];
    }
  }

  patchFinDetials(code, companyType) {
    let payload = { "companyCode": code };
    let data = {};
    this.apiList.push(this.pagesService.getCompanyGroupFinRisk(payload).subscribe((response: any) => {
      if (companyType == 'Normal') {
        data = {
          FinRisktextField: response?.finRisk,
          GroupCompanytextField: response?.groupCompany,
          finComment: response?.finRiskComment,
          tanDetails: response?.tan
        }
      } else {
        data = {
          triFinRisk: response?.finRisk,
          triFinComment: response?.finRiskComment,
        }
      }
      if (response?.finRisk == 'Medium Risk' || response?.finRisk == 'High Risk') {
        this.docList.forEach(element => {
          if (element.type == 'Fin Risk Attachment') {
            element.is_mandatory = 'M';
            this.isFinRiskAttachmentM = true;
          }
        });
      } else {
        this.docList.forEach(element => {
          if (element.type == 'Fin Risk Attachment') {
            element.is_mandatory = 'O';
            this.isFinRiskAttachmentM = false;
          }
        });
      }
      this.ReportingScreenDetailsForm.patchValue(data);
    }));
  }

  companyNameOnSelection(event) {
    let selectedCrmCompany: any = event?.value;
    this.CRM_Code = selectedCrmCompany.companyCode;
    let fin = null;
    let refValue = this.ReportingScreenDetailsForm.get('RefNotextField').value;
    this.patchClientType(this.CRM_Code);
    if (refValue?.startsWith('M')) {
      this.ReportingScreenDetailsForm.get('RefNotextField').reset();
    }
    if (selectedCrmCompany.crmFinriskIdName != null) {
      fin = selectedCrmCompany.crmFinriskIdName;
    }
    if (this.tripartyName?.toLowerCase() == selectedCrmCompany?.companyName?.toLowerCase()) {
      this.showSameTripartyPopup = true;
    } else {
      this.showSameTripartyPopup = false;
    }

    if (!this.showSameTripartyPopup) {
      this.checkCompanyPresent();
    }

    const responseMapComapnyCode = {
      entityName: selectedCrmCompany.companyName,
      entityCompanyCode: selectedCrmCompany.companyCode,
      CRMCodetextField: selectedCrmCompany.companyCode,
      FinRisktextField: fin
    };
    this.ReportingScreenDetailsForm.patchValue(responseMapComapnyCode);
    this.patchFinDetials(this.CRM_Code, 'Normal');
    this.rptData(this.CRM_Code);
    if (selectedCrmCompany.crmFinriskIdName == 'Medium' || selectedCrmCompany.crmFinriskIdName == 'High') {
      this.docList.forEach(element => {
        if (element.type == 'Fin Risk Attachment') {
          element.is_mandatory = 'M';
          this.isFinRiskAttachmentM = true;
        }
      });
    } else {
      this.docList.forEach(element => {
        if (element.type == 'Fin Risk Attachment') {
          element.is_mandatory = 'O';
          this.isFinRiskAttachmentM = false;
        }
      });
    }
    if (this.selectedOpportunityStatus == "Business Reported") {
      this.docList.forEach(element => {
        if (element.type == 'Fin Risk Attachment') {
          element.is_mandatory = 'O';
          this.isFinRiskAttachmentM = false;
        }
      });
    }
    let companyName;
    if (this.ReportingScreenDetailsForm.value.companyName != undefined) {
      companyName = this.ReportingScreenDetailsForm.value.companyName;
    } else {
      companyName = this.ReportingScreenDetailsForm.value.companyName;
    }
    let companyChar = companyName.slice(0, 4);
    this.dynamicRefNoMask = "M - aaa/99999999/" + companyChar.toUpperCase() + "/9999999";
  }

  checkCompanyPresent() {
    this.apiList.push(this.pagesService.getStatusCompanyPresent(this.CRM_Code).subscribe((results: any) => {
      if (results.ExistenceStatus == "Yes") {
        this.showCompanyMergePopup = true;
      } else if (results.ExistenceStatus == "No") {
        this.showCompanyMergePopup = false;
      } else {
        this.showCompanyMergePopup = false;
      }
    }, (_err) => {
      this.multipleCompany = true;
    }));
  }

  blankCompanyname() {
    this.showCompanyMergePopup = false;
    this.ReportingScreenDetailsForm.controls.companyName.reset();
    this.ReportingScreenDetailsForm.controls.CRMCodetextField.reset();
    this.ReportingScreenDetailsForm.controls.FinRisktextField.reset();
    this.ReportingScreenDetailsForm.controls.entityCompanyCode.reset();
    this.ReportingScreenDetailsForm.controls.GroupCompanytextField.reset();
    this.ReportingScreenDetailsForm.controls.tanDetails.reset();
    this.isAlreadyCRMEntity = 'No';
  }

  mergeCompany() {
    this.isAlreadyCRMEntity = 'Yes';
    this.showCompanyMergePopup = false;
  }

  sameTriparty() {
    this.ReportingScreenDetailsForm.controls.companyName.reset();
    this.ReportingScreenDetailsForm.controls.CRMCodetextField.reset();
    this.ReportingScreenDetailsForm.controls.FinRisktextField.reset();
    this.ReportingScreenDetailsForm.controls.entityCompanyCode.reset();
    this.ReportingScreenDetailsForm.controls.GroupCompanytextField.reset();
    this.showSameTripartyPopup = false;
  }

  multiCompanyNo() {
    this.multipleCompany = false;
    this.blankCompanyname();
  }

  showDialogdialog(): void {
    this.dynamicDialogdialogRef = this.dialogService.open(ReportingAttachmentComponent, {
      header: 'Crop Image',
      closable: true,

      data: this.ReportingScreenDetailsForm.value,
      styleClass: 'sm',
    });
    this.dynamicDialogdialogRef.onClose.subscribe(response => {
      // this.ReportingScreenDetailsForm.get('dialog')?.setValue(response);
    });
  }

  dataURLtoFile(dataurl: any, filename: any): any {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  uploadFile: any = {};
  previewFile: any = {};
  previewUploadDocument(name) {
    this.obj = {}
    this.result.forEach((r) => {
      if (r.fileTypeName == name) {
        this.previewFile = r;
      }
    });
    this.showAttachmentPreview(this.previewFile);
  }

  fileId: any;
  onSelectfileUpload1(event: any, type: any, id: any): void {
    this.canvasRotation = 0;
    this.currentFileEvent = event.currentFiles[0];
    if (this.currentFileEvent == undefined) {
      this.messageService.add({ severity: 'error', summary: 'File size cannot exceed 20 MB.' });
      return;
    }
    let ext = this.currentFileEvent?.name?.split('.');
    if (ext[ext.length - 1] == 'jfif') {
      this.messageService.add({ severity: 'error', summary: 'Invalid File Format.' });
      return;
    }
    if (["image/svg+xml", "image/webp", "xlxs", "xls", "html", "xhtml", "txt", "gif", "application/exe", "zip", ".oft"].includes(this.currentFileEvent.type)) {
      this.messageService.add({ severity: 'error', summary: 'Invalid File Format.' });
      return;
    }
    this.fileType = type;
    this.fileId = id;
    this.imgChangeEvt = event.originalEvent;
    if (event.currentFiles[0]?.type == 'image/jpeg' || event.currentFiles[0]?.type == 'image/jpg' || event.currentFiles[0]?.type == 'image/png') {
      this.displayModal = true;
    } else {
      this.displayModal = false;
    }
    this.uploadLoader = true;
    for (const file of event.files) {
      if (file.type.includes('image')) {
        this.imageChangedEventfileUpload1 = new Blob([file], { type: file.type });
        this.filenameForfileUpload1 = file.name;
      } else {
        //Added
        const fileReader = new FileReader();
        fileReader.readAsDataURL(event.currentFiles[0]);
        fileReader.onload = () => {
          this.returnBase64 = fileReader.result;
          this.onCroppedUploadBtn();
        };
      }
    }
    if (this.attachmentRejected && this.isQcRejected) {
      let data;
      if (this.fileId == 41) {
        data = this.docList.find(d => d.id == 40);
        data.is_mandatory = 'O';
      }
      if (this.fileId == 40) {
        data = this.docList.find(d => d.id == 41);
        data.is_mandatory = 'O';
      }
    }
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  getThumbnail(type) {
    if (type == 'image/png' || type == 'image/jpeg' || type == 'image/jpg') {
      return this.imgThumb;
    } else if (type == 'application/pdf') {
      return this.pdfThumb;
    } else if (type.startsWith('application/vnd.openxmlformats') || type.startsWith('application/msword')) {
      return this.wordThumb;
    } else {
      return this.mailThumb;
    }
  }

  onCroppedUploadBtn(): void {
    if (this.dataURLtoFile && this.croppedImagefileUpload1) {
      const file = this.dataURLtoFile(this.croppedImagefileUpload1, this.filenameForfileUpload1);
      this.fileUploadfileUpload1.files.push(file);
    }
    this.uploadFile = {};
    if (this.displayModal) {
      const displayData = this.getUploadedObjDisplay(this.fileType, this.fileId);
      this.result.push(displayData);
      this.uploadFile = displayData;
      this.uploadSingleFile(displayData);
    } else {
      this.returnUploaded = this.getUploadedObj(this.fileType, this.fileId);
      this.result.push(this.returnUploaded);
      this.uploadFile = this.returnUploaded;
      this.uploadSingleFile(this.returnUploaded);
    }
    this.docList.filter((d) => {
      if (d.type == this.fileType) {
        d.file = this.fileType;
        d.fileName = this.uploadFile.fileName;
        d.thumbNail = this.getThumbnail(this.uploadFile.fileContentType);
      }
    });
    if (this.paymentOnLoadData?.opportunityStatus == "Business Won") {
      //fax - 40
      //original - 41

      let data;
      if (this.fileId == 41) {
        data = this.docList.find(d => d.id == 40);
        data.is_mandatory = 'O';
      }
      if (this.fileId == 40) {
        data = this.docList.find(d => d.id == 41);
        data.is_mandatory = 'O';
      }
    }

    if (this.isQcApproved && this.fileId == 41) {
      this.ogMandateUpdated = true;
    }
    this.displayModal = false;
    this.result.forEach((r) => {
      if (r.fileTypeName == "3rd Party Payment Approval") {
        this.finDocumentUploaded = r.fileTypeName;
        this.thirdPartyName = r.fileName;
        if (r.fileContentType == 'image/png') {
          this.thirdPartyThumbSrc = this.imgThumb;
        } else if (r.fileContentType == 'application/pdf') {
          this.thirdPartyThumbSrc = this.pdfThumb;
        } else {
          this.thirdPartyThumbSrc = this.wordThumb;
        }
      }
      if (r.fileTypeName == "Fin Risk Attachment") {
        this.finDocumentUploaded = r.fileTypeName;
        this.name = r.fileName;
        if (r.fileContentType == 'image/png') {
          this.finThumbSrc = this.imgThumb;
        } else if (r.fileContentType == 'application/pdf') {
          this.finThumbSrc = this.pdfThumb;
        } else {
          this.finThumbSrc = this.wordThumb;
        }
      }
      if (r.fileTypeName == "MCA Attachment") {
        this.mcaDocumentUploaded = r.fileTypeName;
        this.mcaName = r.fileName;
        if (r.fileContentType == 'image/png') {
          this.macAttachThumbSrc = this.imgThumb;
        } else if (r.fileContentType == 'application/pdf') {
          this.macAttachThumbSrc = this.pdfThumb;
        } else {
          this.macAttachThumbSrc = this.wordThumb;
        }
      }
      if (r.fileTypeName == "Client Type Attachment") {
        this.clientTypeUploaded = r.fileTypeName;
        this.clientTypeName = r.fileName;
        if (r.fileContentType == 'image/png') {
          this.clientTypeThumbSrc = this.imgThumb;
        } else if (r.fileContentType == 'application/pdf') {
          this.clientTypeThumbSrc = this.pdfThumb;
        } else {
          this.clientTypeThumbSrc = this.wordThumb;
        }
      }
      if (r.fileTypeName == "Standard Pricing Approval Attachment") {
        this.standardPricingApprovalUploaded = r.fileTypeName;
        this.standardPricingApprovalName = r.fileName;
        if (r.fileContentType == 'image/png') {
          this.standardPricingThumbSrc = this.imgThumb;
        } else if (r.fileContentType == 'application/pdf') {
          this.standardPricingThumbSrc = this.pdfThumb;
        } else {
          this.standardPricingThumbSrc = this.wordThumb;
        }
      }
      if (r.fileTypeName == "Invoice before Payment Approval") {
        this.invoicebeforePaymentApprovalUploaded = r.fileTypeName;
        this.invoicebeforePaymentApprovalName = r.fileName;
        if (r.fileContentType == 'image/png') {
          this.invoiceBeforeThumbSrc = this.imgThumb;
        } else if (r.fileContentType == 'application/pdf') {
          this.invoiceBeforeThumbSrc = this.pdfThumb;
        } else {
          this.invoiceBeforeThumbSrc = this.wordThumb;
        }
      }
      if (r.fileTypeName == "Internal Pricing Approval Attachment") {
        this.internalPricingApprovalUploaded = r.fileTypeName;
        this.internalPricingApprovalName = r.fileName;
        if (r.fileContentType == 'image/png') {
          this.internalPricingThumbSrc = this.imgThumb;
        } else if (r.fileContentType == 'application/pdf') {
          this.internalPricingThumbSrc = this.pdfThumb;
        } else {
          this.internalPricingThumbSrc = this.wordThumb;
        }
      }
      if (r.fileTypeName == "GST Portal Snapshot") {
        this.gstPortalSnapshotUploaded = r.fileTypeName;
        this.gstPortalSnapshotName = r.fileName;
        if (r.fileContentType == 'image/png') {
          this.gstPortalThumbSrc = this.imgThumb;
        } else if (r.fileContentType == 'application/pdf') {
          this.gstPortalThumbSrc = this.pdfThumb;
        } else {
          this.gstPortalThumbSrc = this.wordThumb;
        }
      }
      if (r.fileTypeName == "GST Document") {
        this.gstDocumentUploaded = r.fileTypeName;
        this.gstDocumentName = r.fileName;
        if (r.fileContentType == 'image/png') {
          this.gstDocumentThumbSrc = this.imgThumb;
        } else if (r.fileContentType == 'application/pdf') {
          this.gstDocumentThumbSrc = this.pdfThumb;
        } else {
          this.gstDocumentThumbSrc = this.wordThumb;
        }
      }
      if (r.fileTypeName == "PAN Document") {
        this.panDocumentUploaded = r.fileTypeName;
        this.panDocumentName = r.fileName;
        if (r.fileContentType == 'image/png') {
          this.panDocumentThumbSrc = this.imgThumb;
        } else if (r.fileContentType == 'application/pdf') {
          this.panDocumentThumbSrc = this.pdfThumb;
        } else {
          this.panDocumentThumbSrc = this.wordThumb;
        }
      }
      if (r.fileTypeName == "Approval without DS") {
        this.approvalWithoutDSUploaded = r.fileTypeName;
        this.approvalWithoutDSName = r.fileName;
        if (r.fileContentType == 'image/png') {
          this.approvalDSThumbSrc = this.imgThumb;
        } else if (r.fileContentType == 'application/pdf') {
          this.approvalDSThumbSrc = this.pdfThumb;
        } else {
          this.approvalDSThumbSrc = this.wordThumb;
        }
      }
      if (r.fileTypeName == "SEZ Document") {
        this.sezDocumentUploaded = r.fileTypeName;
        this.sezDocumentName = r.fileName;
        if (r.fileContentType == 'image/png') {
          this.sezDocThumbSrc = this.imgThumb;
        } else if (r.fileContentType == 'application/pdf') {
          this.sezDocThumbSrc = this.pdfThumb;
        } else {
          this.sezDocThumbSrc = this.wordThumb;
        }
      }
      if (r.fileTypeName == "Cheque") {
        this.chequeUploaded = r.fileTypeName;
        this.chequeName = r.fileName;
        if (r.fileContentType == 'image/png') {
          this.chequeThumbSrc = this.imgThumb;
        } else if (r.fileContentType == 'application/pdf') {
          this.chequeThumbSrc = this.pdfThumb;
        } else {
          this.chequeThumbSrc = this.wordThumb;
        }
      }
      if (r.fileTypeName == "Deposit Slip") {
        this.depositSlipUploaded = r.fileTypeName;
        this.depositSlipName = r.fileName;
        if (r.fileContentType == 'image/png') {
          this.depositSlipThumbSrc = this.imgThumb;
        } else if (r.fileContentType == 'application/pdf') {
          this.depositSlipThumbSrc = this.pdfThumb;
        } else {
          this.depositSlipThumbSrc = this.wordThumb;
        }
      }
    });
  }

  hideFileds(item) {
    if (item == "FinRisk Attachment") {
      this.finDocumentUploaded = 'false';
      this.name = '';
    }
    if (item == "3rd Party Payment Approval") {
      this.thirdPartyDocumentUploaded = 'false';
      this.thirdPartyName = '';
    }
    if (item == "MCA Attachment") {
      this.mcaDocumentUploaded = 'false';
      this.mcaName = '';
    }
    if (item == "Client Type Attachment") {
      this.clientTypeUploaded = 'false';
      this.clientTypeName = '';
    }
    if (item == "Standard Pricing Approval Attachment") {
      this.standardPricingApprovalUploaded = 'false';
      this.standardPricingApprovalName = '';
    }
    if (item == "Invoice before Payment Approval") {
      this.invoicebeforePaymentApprovalUploaded = 'false';
      this.invoicebeforePaymentApprovalName = '';
    }
    if (item == "Internal Pricing Approval Attachment") {
      this.internalPricingApprovalUploaded = 'false';
      this.internalPricingApprovalName = '';
    }
    if (item == "GST Portal Snapshot") {
      this.gstPortalSnapshotUploaded = 'false';
      this.gstPortalSnapshotName = '';
    }
    if (item == "GST Document") {
      this.gstDocumentUploaded = 'false';
      this.gstDocumentName = '';
    }
    if (item == "PAN Document") {
      this.panDocumentUploaded = 'false';
      this.panDocumentName = '';
    }
    if (item == "Approval without DS") {
      this.approvalWithoutDSUploaded = 'false';
      this.approvalWithoutDSName = '';
    }
    if (item == "SEZ Document") {
      this.sezDocumentUploaded = 'false';
      this.sezDocumentName = '';
    }
    if (item == "Cheque") {
      this.chequeUploaded = 'false';
      this.chequeName = '';
    }
    if (item == "Deposit Slip") {
      this.depositSlipUploaded = 'false';
      this.depositSlipName = '';
    }
  }

  getUploadedObj(fileType, id): any {
    return {
      userId: this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid')),
      moduleName: 'Opportunity',
      moduleId: this.OpportunityId,
      fileName: this.currentFileEvent.name,
      file: this.returnBase64,
      fileContentType: this.currentFileEvent.type,
      fileSize: (this.currentFileEvent.size).toString(),
      fileTypeid: id,
      fileTypeName: fileType,
      fileId: null,
    };
  }

  getUploadedObjDisplay(fileType, id): any {
    let data = this.returnBase64;
    if (data.startsWith('data:application/pdf;base64,')) {
      data = data.replace('data:application/pdf;base64,', '');
    } else if (data.startsWith('data:image/png;base64,')) {
      data = data.replace('data:image/png;base64,', '');
    } else if (data.startsWith('data:application/doc;base64,')) {
      data = data.replace('data:application/doc;base64,', '');
    }

    return {
      userId: this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid')),
      moduleName: 'Opportunity',
      moduleId: this.OpportunityId,
      fileName: this.fileUploadfileUpload1.files[this.fileUploadfileUpload1.files.length - 1].name,
      file: data,
      fileContentType: this.fileUploadfileUpload1.files[this.fileUploadfileUpload1.files.length - 1].type,
      fileSize: (this.fileUploadfileUpload1.files[this.fileUploadfileUpload1.files.length - 1].size).toString(),
      fileTypeid: id,
      fileTypeName: fileType,
      fileId: null,
    };
  }

  imageCroppedfileUpload1(event: any): any {
    // this.croppedImagefileUpload1 = event.base64;
    // this.returnBase64 = event.base64; //Added

    fetch(event.objectUrl)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.returnBase64 = reader.result;
          this.croppedImagefileUpload1 = this.returnBase64;
        };
        reader.readAsDataURL(blob);
      });
  }

  deleteFileData: any = {};
  onDeletePopup(item, id, fileTypeId, deleteId) {
    this.deleteFile = true;
    this.deleteFileData = { type: item, id: id, fileTypeId: fileTypeId, deleteId: deleteId };
  }

  onDelete() {
    // deleted attachment from uploaded doc if any doc is rejected
    if (this.isQcRejected || this.isQcApproved) {
      const index = this.displayAttachData.findIndex((r) => r.fileId == this.deleteFileData?.id);
      if (index != -1) {
        this.pagesService.deleteFileById(this.deleteFileData?.deleteId).subscribe((res) => {
          if (res?.status == "success") {
            this.attachToDelete.push(this.deleteFileData.id);
            this.displayAttachData.splice(index, 1);
            let index1 = this.uploadedFileIds.findIndex((r) => r == this.deleteFileData?.deleteId);
            this.uploadedFileIds.splice(index1, 1);
          }
        }, (_err) => {
          this.apiFail = true;
        });

        // for fax and original mandate, if both absent, enable highlight and validation for both (on QC Rejection)
        // if QC rejected, and user deletes both FAX and original mandate, then only trigger validation
        const enableMandationForMandate = this.isQcRejected && (this.deleteFileData.fileTypeId === "40" || this.deleteFileData.fileTypeId === "41") && (!this.displayAttachData.find(item => item.fileTypeid === "40") || !this.displayAttachData.find(item => item.fileTypeid === "41"));

        this.docList.push({
          file: "",
          fileName: "",
          id: this.deleteFileData.fileTypeId,
          isDisabled: false,
          is_mandatory: (this.attachmentRejected || this.ogMandateRejected) ?
            this.isQcRejected && (this.deleteFileData.fileTypeId === "40" || this.deleteFileData.fileTypeId === "41") && !enableMandationForMandate ? "O" : "M" :
            "O",
          thumbNail: "",
          type: this.deleteFileData.type
        });

        if (enableMandationForMandate) {
          let data;
          data = this.docList.find(d => d.id == 41);
          data.is_mandatory = 'M';
          data = this.docList.find(d => d.id == 40);
          data.is_mandatory = 'M';
        }
        // if user deletes original mandate before saving
        if (this.isQcApproved && this.fileId == 41) {
          this.ogMandateUpdated = false;
        }
      }
    }
    this.pagesService.deleteFileById(this.deleteFileData?.deleteId).subscribe((res) => {
      if (res?.status == "success") {
        this.docList.filter((d) => {
          if (d.type == this.deleteFileData?.type) {
            d.file = '';
            d.fileName = '';
            d.thumbNail = '';
          }
        });
        let index1 = this.uploadedFileIds.findIndex((r) => r == this.deleteFileData?.id);
        this.uploadedFileIds.splice(index1, 1);
        const index = this.result.findIndex((r) => r.fileTypeName == this.deleteFileData?.type);
        this.result.splice(index, 1);
        this.hideFileds(this.deleteFileData?.type);
      }
    }, (_err) => {
      this.apiFail = true;
    });

    // make fax/original mandate mandatory
    if (this.deleteFileData?.id == 40 || this.deleteFileData?.id == 41) {
      let deletedManName;
      let otherManName;
      if (this.deleteFileData?.id == 40) {
        deletedManName = "originalMan";
        otherManName = "faxMan";
      } else {
        deletedManName = "faxMan";
        otherManName = "originalMan";
      }
      let mandateDocs = { originalMan: this.docList.find(d => d.id == 40), faxMan: this.docList.find(d => d.id == 41) };
      if (mandateDocs[otherManName]) {
        mandateDocs[otherManName].is_mandatory = 'M';
        if (mandateDocs[otherManName].file) {
          mandateDocs[deletedManName].is_mandatory = 'O';
        } else {
          mandateDocs[deletedManName].is_mandatory = 'M';
        }
      }
    }

    this.deleteFile = false;
  }

  companyNameOnClear(event) {
    if (event.companyName == null) {
      const responseMapComapnyCode = {
        entityName: event.companyName,
        entityCompanyCode: event.companyCode,
        CRMCodetextField: event.companyCode,
        FinRisktextField: event.crmFinriskIdName,
        GroupCompanytextField: event.GroupCompanytextField,
        tanDetails: event.tanDetails
      };
      this.ReportingScreenDetailsForm.patchValue(responseMapComapnyCode);
    }
    this.docList.forEach(element => {
      if (element.type == 'Fin Risk Attachment') {
        element.is_mandatory = 'O';
        this.isFinRiskAttachmentM = false;
      }
    });
    this.dynamicRefNoMask = "M - aaa/99999999/aaaa/9999999";
  }

  onCompletepincode(): any {
    if (this.ReportingScreenDetailsForm?.value?.pinCodetextField != null || this.ReportingScreenDetailsForm?.value?.pinCodetextField != undefined) {
      this.pagesService.getpincodeDataReportingScreenDetails(this.ReportingScreenDetailsForm.value).subscribe((results: any) => {
        const responseMappincode = {
          CitytextField: results[0].cityName,
          StatetextField: results[0].stateName,
        };
        this.cityList = results;
        this.ReportingScreenDetailsForm.patchValue(responseMappincode);
      });
    }
  }

  resetPin(): any {
    if (this.ReportingScreenDetailsForm?.value?.pinCodetextField?.length < 6) {
      this.ReportingScreenDetailsForm.controls.CitytextField.reset();
      this.ReportingScreenDetailsForm.controls.StatetextField.reset();
    }
  }

  onCompleteGSTNumber(event: any): void {
    if (event?.target?.value?.length == 15) {
      const responseMapPANNumber = {
        PANnotextField: this.ReportingScreenDetailsForm.value.GSTNotextField?.slice(2, -3)?.toUpperCase(),
      };

      if (responseMapPANNumber) {
        this.docList.forEach(element => {
          if (element.type == 'GST Portal Snapshot') {
            element.is_mandatory = 'M';
            this.isGSTPortalSnapshotM = true;
          }
        });
      } else {
        this.docList.forEach(element => {
          if (element.type == 'GST Portal Snapshot') {
            element.is_mandatory = 'O';
            this.isGSTPortalSnapshotM = false;
          }
        });
      }
      this.ReportingScreenDetailsForm.patchValue(responseMapPANNumber);
    }
  }

  gstTypeChange(): any {
    if (this.ReportingScreenDetailsForm.value.GSTtypeselect === 'GST Normal' || this.ReportingScreenDetailsForm.value.GSTtypeselect === 'SEZ') {
      this.gstTypeEnable = false;
      this.panCondition = true;
      this.isGstNoM = true;
      this.ReportingScreenDetailsForm.controls['GSTNotextField'].setValidators([Validators.required]);
    } else {
      this.gstTypeEnable = true;
      this.panCondition = false;
      this.isGstNoM = false;
      this.ReportingScreenDetailsForm.controls['GSTNotextField'].clearValidators();
    }
    if (this.ReportingScreenDetailsForm.value.GSTtypeselect === 'Exempt' || this.ReportingScreenDetailsForm.value.GSTtypeselect === 'Export' || this.ReportingScreenDetailsForm.value.GSTtypeselect === 'GST NA') {
      this.panMandate = true;
      this.ReportingScreenDetailsForm.controls['PANnotextField'].setValidators([Validators.required]);
    } else {
      this.ReportingScreenDetailsForm.controls['PANnotextField'].clearValidators();
      this.panMandate = false;
    }
  }

  gstReset() {
    this.ReportingScreenDetailsForm.controls.GSTNotextField.reset();
    this.ReportingScreenDetailsForm.controls.PANnotextField.reset();
  }

  onChangeBusinessAction(event) {
    this.ReportingScreenDetailsForm.controls.actionComment.reset();
    if (event.value == "Business Reported") {
      this.actionReasonFlag = false;
      this.isActionReasonTouched = false;
      this.isBAaction = false;
      this.isBAreason = false;
      this.showReasonFlag = true;
      this.actionCommentFlag = false;
      this.isAttachmentM = true;
      this.RefNoValidationFlag = true;
      this.ThirdPartyApprovalCommentControl();
      if (localStorage.getItem('role') == 'BDOps') {
        this.internalApprovalNeededFlag = false;
      } else {
        this.internalApprovalNeededFlag = true;
      }
      this.getBusinessReportedReasonselectList();
      this.ReportingScreenDetailsForm.controls['reasonselect'].setValidators([Validators.required]);
      this.ReportingScreenDetailsForm.controls['actionComment'].clearValidators();
      const abc = {
        reasonselect: 'Ops to Initiate',
      }
      this.ReportingScreenDetailsForm.patchValue(abc);
    } else if (event.value == "Business Rejected") {
      this.ReportingScreenDetailsForm.controls.reasonselect.reset();
      this.actionReasonFlag = false;
      this.isBAaction = false;
      this.showReasonFlag = true;
      this.actionCommentFlag = true;
      this.isAttachmentM = false;
      this.RefNoValidationFlag = false;
      this.getBusinessRejectedReasonselectList();
      this.ReportingScreenDetailsForm.controls['reasonselect'].setValidators([Validators.required]);
      this.ReportingScreenDetailsForm.controls['actionComment'].setValidators([Validators.required]);
    } else {
      this.actionReasonFlag = true;
      this.showReasonFlag = false;
      this.actionCommentFlag = false;
      this.reasonselectList = null;
      this.isAttachmentM = false;
      this.ReportingScreenDetailsForm.controls['reasonselect'].clearValidators();
      this.ReportingScreenDetailsForm.controls['actionComment'].clearValidators();
    }
  }

  handleInternalApprovalReceived(event) {
    if (event.checked) {
      this.internalPricingApprovalAttachmentFlag = true;
      this.docList.forEach(element => {
        if (element.type == 'Internal Pricing Approval Attachment') {
          element.is_mandatory = 'M';
          element.isDisabled = false;
          this.isInternalPricingApprovalAttachmentM = true;
        }
      });
    } else {
      this.internalPricingApprovalAttachmentFlag = false;
      this.docList.forEach(element => {
        if (element.type == 'Internal Pricing Approval Attachment') {
          element.is_mandatory = 'O';
          element.isDisabled = true;
          element.id = '';
          element.file = '';
          element.fileName = '';
          element.thumbNail = '';
          element.isDisabled = true;
          this.isInternalPricingApprovalAttachmentM = false;
        }
      });
    }
  }

  handleStandardPricingReceived(event) {
    if (event.checked) {
      this.docList.forEach(element => {
        if (element.type == 'Standard Pricing Approval Attachment') {
          element.is_mandatory = 'M';
          this.isStandardPricingApprovalAttachmentM = true;
        }
      });
    } else {
      this.docList.forEach(element => {
        if (element.type == 'Standard Pricing Approval Attachment') {
          element.is_mandatory = 'O';
          this.isStandardPricingApprovalAttachmentM = false;
        }
      });
    }
  }

  getBusinessReportedReasonselectList(): void {
    this.apiList.push(this.pagesService.getBusinessReportedReasonDataReportingScreenDetails().subscribe(results => {
      const responseData = results;
      this.reasonselectList = responseData;
    }));
  }

  getBusinessRejectedReasonselectList(): void {
    this.apiList.push(this.pagesService.getBusinessRejectedReasonDataReportingScreenDetails().subscribe(results => {
      const responseData = results;
      this.reasonselectList = responseData;
    }));
  }

  getbusinessActionselectList(): void {
    this.apiList.push(this.pagesService.getBusinessActionDataReportingScreenDetails().subscribe(results => {
      const responseData = results;
      this.businessActionselectList = responseData;
    }));
  }

  getCostCenterselectList(): void {
    this.apiList.push(this.pagesService.getCostCenterDataReportingScreenDetails().subscribe(results => {
      const responseData = results;
      this.costCenterselectList = responseData;
    }));
  }

  getProductselectList(): void {
    this.apiList.push(this.pagesService.getProductDataReportingScreenDetails().subscribe(results => {
      const responseData = results;
      this.productselectList = responseData;
    }));
  }

  getTrypartyselectList(): void {
    this.pagesService.getTrypartyDataReportingScreenDetails().subscribe(results => {
      const responseData = results;
      this.trypartyselectList = responseData;
    });
  }

  getDiscountReasonselectList(): void {
    this.apiList.push(this.pagesService.getReasonDataReportingScreenDetails().subscribe(results => {
      const responseData = results;
      this.irfDiscountReasonList = responseData;
      this.irfDiscountReasonList.push({ name: '', id: 0 })
    }));
  }

  cropImg(e: ImageCroppedEvent) {
    this.cropImgPreview = e.base64;
    this.fileUploaded = true;
    this.fileUploadedDone = false;
  }

  imgLoad() {
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() {
    // error msg
  }

  getAttachmentTypeMasterData() {
    this.apiList.push(this.pagesService.getAttachmentTypeMasterData().subscribe(results => {
      const responseData = results;
      this.attachmentTypeList = responseData;
    }));
  }

  removeDuplicate(attachmentTypeName: any): void {
    if (attachmentTypeName == null && attachmentTypeName == undefined) {
      this.SelectedAttachmentTypeFlag = false;
      for (let i = 0; i < this.attachmentTypeList.length; i++) {
        for (let j = 0; j < this.displayAttachData.length; j++) {
          let index = this.attachmentTypeList.findIndex(x => x.type === this.displayAttachData[j].fileTypeName);
          if (index !== -1) {
            this.attachmentTypeList.splice(index, 1);
          }
        }
      }
      this.newAttachmentTypeList = this.attachmentTypeList;
    } else {
      let index = this.attachmentTypeList.findIndex(x => x.type === attachmentTypeName.type);
      if (index !== -1) {
        this.SelectedAttachmentTypeFlag = true;
        this.attachmentTypeList.splice(index, 1);
      } else {
        this.SelectedAttachmentTypeFlag = false;
      }
      if (this.SelectedAttachmentType.fileTypeName != null && this.SelectedAttachmentTypeFlag == true) {
        const response = {
          "type": this.SelectedAttachmentType.fileTypeName,
          "id": this.attachmentTypeList2.filter(x => x.type === this.SelectedAttachmentType.fileTypeName)[0]?.id,
        };
        this.attachmentTypeList.push(response);
      }
    }
  }

  showDelCombineFilePop(pdf) {
    this.deleteCombinedF = true;
    this.deleteCombineFileData = pdf;
  }

  deleteCombinedFile() {
    this.combinedPdfDeleteData.push(this.deleteCombineFileData)
    let index = this.combinedPdfView.findIndex(c => c.fileName == this.deleteCombineFileData.fileName);
    let index1 = this.combinedPdfData.findIndex(c => c.fileName == this.deleteCombineFileData.fileName);
    if (index != -1) {
      this.combinedPdfView.splice(index, 1);
    }

    if (index1 != -1) {
      this.combinedPdfData.splice(index1, 1);
    }
    this.deleteCombinedF = false;
  }

  // Attachment Type Popup
  showEditTypeDialog(selectedAttachment) {
    this.displayEditTypeModal = true;
    this.SelectedAttachmentType = selectedAttachment;
    let index = this.newAttachmentTypeList.findIndex((item) => item.type == selectedAttachment.fileTypeName);
    let data = {
      type: selectedAttachment.fileTypeName,
      id: parseInt(selectedAttachment.fileTypeid)
    }
    if (index == -1) {
      this.newAttachmentTypeList.push(data);
    }
    this.updatedAttachmentType = data;
    this.onLoadCheckbox();
  }

  onUpdateAttachmentType() {
    this.submitedAttachmentType = this.updatedAttachmentType;
    this.removeDuplicate(this.submitedAttachmentType);
    let index = this.displayAttachData.findIndex(x => x.fileId === this.SelectedAttachmentType.fileId);
    let oldFile = this.displayAttachData[index];
    this.removeAddDocList(oldFile, this.submitedAttachmentType);
    this.displayAttachData[index].fileTypeName = this.submitedAttachmentType.type;
    this.displayAttachData[index].fileTypeid = this.submitedAttachmentType.id;
    this.displayAttachData.forEach((doc) => {
      if (doc.fileTypeName == this.updatedAttachmentType.type) {
        doc.isDuplicate = false;
      }
    });
    this.updatedAttachmentType = null;
    this.onLoadCheckbox();
    this.closeAttachmentTypeModal();
  }

  //when user changes mandate attachment type from uploaded document remove new from documnet to
  // be uploaded and add the old type
  removeAddDocList(oldType: any, newType: any) {
    let index = this.docList.findIndex((file) => file.type == newType.type);
    this.docList.splice(index, 1);
    if (["Mandate,Page 1", "Mandate,Page 2", "Mandate,Page 3", "Mandate,Page 4", "Mandate,Page 5",
      "Mandate,Page 6", "Mandate,Page 7", "Mandate,Page 8", "Mandate,Page 9", "Mandate,Page 10"].includes(oldType.fileTypeName)) {
      this.docList.unshift({
        file: "",
        fileName: "",
        id: parseInt(oldType.fileTypeid),
        isDisabled: false,
        is_mandatory: "O",
        thumbNail: "",
        type: oldType.fileTypeName
      });
    }
  }

  closeAttachmentTypeModal() {
    this.displayEditTypeModal = false;
    if (this.SelectedAttachmentType) {
      let index = this.newAttachmentTypeList.findIndex(item => item.id == parseInt(this.SelectedAttachmentType?.id));
      this.newAttachmentTypeList.splice(index, 1);
    }
  }

  checkedMandate(event, item) {
    if (this.ReportingScreenDetailsForm?.value?.attachmentSelected) {
      this.combinePDFArray.push(item);
    } else {
      let fileIndex = this.combinePDFArray.findIndex(c =>
        c.fileId === item.fileId
      );
      this.combinePDFArray.splice(fileIndex, 1);
    }
    if (this.combinePDFArray.length > 1) {
      this.enablePDF = true;
    } else {
      this.enablePDF = false;
    }
  }
  mandateCount: number = 0;

  combinePDF() {
    this.showCombinePDFPopUp = false;
    // this.ReportingScreenDetailsForm.patchValue({ attachmentSelected: [] });
    let finalArray = [];
    this.combinePDFArray.forEach((temp) => {
      finalArray.push(temp.fileId);
    });
    let payload = {
      fileIds: finalArray
    }
    let companyName;
    if (this.ReportingScreenDetailsForm.get('companyName')?.value) {
      companyName = this.ReportingScreenDetailsForm.get('companyName')?.value;
    } else {
      companyName = this.ReportingScreenDetailsForm.get('companyName')?.value;
    }
    this.apiList.push(this.pagesService.mergeByIds(payload).subscribe((res) => {
      let base64 = res.fileData;
      let fileLength = base64.length;
      let sizeInBytes = 4 * Math.ceil((fileLength / 3)) * 0.5624896334383812;
      let sizeInKb = sizeInBytes / 1000;
      let finalSize = Math.round(sizeInKb);
      let data = {
        userId: this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid')),
        moduleName: 'Opportunity',
        moduleId: this.OpportunityId,
        fileName: `${companyName} ${this.ReportingScreenDetailsForm.get('productselect').value} - Mandate ${this.mandateCount}.pdf`,
        file: res.fileData,
        fileContentType: 'application/pdf',
        fileSize: finalSize,
        fileTypeId: 37,
        fileTypeName: 'Combine Doc',
        fileId: null,
      }
      this.combinedPdfView.push(data);
      this.combinedPdfData.push(data);
    }));
    this.combinePDFArray = [];
    this.enablePDF = false;
    this.mandateCount += 1;
  }

  //Attachment Preview PopUp
  isPdf: boolean = true;
  showAttachmentPreview(item) {
    this.openedFile = item;
    this.zoomValue = 1;
    this.rotate = 0;
    if (item?.fileId) {
      this.displayPreview = true;
      this.dataLoader = true;
      let payload = {
        id: item.fileId
      }
      this.apiList.push(this.pagesService.findPDF(payload).subscribe((res) => {
        if (res && res?.file) {
          let ext = res?.fileName?.split('.');
          let ext1 = ext[ext.length - 1];
          let mime: any;
          let isPreviewable = true;
          if (['png', 'jpg', 'jpeg'].includes(ext1?.toLowerCase())) {
            this.isPdf = false;
            mime = 'data:image/png;base64,'
          } else if ("msg" === ext1?.toLowerCase()) {
            mime = 'data:application/vnd.ms-outlook;base64,';
            isPreviewable = false;
          } else {
            this.isPdf = true;
            mime = 'data:application/pdf;base64,'
          }
          this.urlSafe = `${mime}${res.file}`
          if (!this.isPdf) {
            let el = document.getElementById('imagePrev');
            setTimeout(() => {
              el.style.transform = 'rotate(0deg) scale(1)';
            }, 100);
          }
          this.displayPreview = isPreviewable;
          if (!isPreviewable) {
            this.pagesService.downloadFile(res, this.urlSafe);
          }
          this.dataLoader = false;
        } else {
          this.displayPreview = false;
          this.previewFailed = true;
        }
      }, (_error) => {
        this.displayPreview = false;
        this.previewFailed = true;
      }));
    } else {
      let tempArray: any = [];
      if (item.fileContentType == "application/pdf") {
        this.displayPreview = true;
        this.dataLoader = true;
        let data = '';
        if (item.file.startsWith('data:application/pdf;base64')) {
          data = item.file;
        } else {
          data = `data:application/pdf;base64,${item.file}`;
        }
        this.urlSafe = data;
        this.isPdf = true;
        this.dataLoader = false;
      } else {
        let obj: any = {};
        if (item.fileContentType.startsWith('application/vnd') || item.fileContentType == 'application/msword') {
          obj = {
            fileType: 'doc',
            fileName: item.fileName,
            attachmentType: item.fileTypeName,
            fileData: item.file.split(',')[1]
          }
        } else {
          obj = {
            fileType: item.fileContentType.split('/')[1],
            fileName: item.fileName,
            attachmentType: item.fileTypeName,
            fileData: item.file
          }
        }
        tempArray.push(obj);
        this.displayPreview = true;
        this.dataLoader = true;
        this.apiList.push(this.pagesService.combineAllPDF(tempArray).subscribe((res) => {
          if (res && res?.fileData) {
            this.urlSafe = `data:application/pdf;base64,${res.fileData}`
            this.dataLoader = false;
            this.previewFailed = false;
          } else {
            this.displayPreview = false;
            this.previewFailed = true;
          }
        }, (_error) => {
          this.displayPreview = false;
          this.previewFailed = true;
        }));
      }
    }
  }

  rotate: number = 0
  rotateFile(x) {
    if (x === -1) {
      this.rotate -= 90;
    } else {
      this.rotate += 90;
    }
    this.transformImage();
  }

  zoom(x) {
    if (x === -1) {
      if (this.zoomValue > 0.1) {
        this.zoomValue -= 0.1;
      }
    } else {
      this.zoomValue += 0.1;
    }
    this.transformImage();
  }

  transformImage() {
    let el = document.getElementById('imagePrev');
    el.style.transform = `rotate(${this.rotate}deg) scale(${this.zoomValue})`;
  }

  combinedPdfPreview(fileName) {
    this.displayPreview = true;
    for (let i = 0; i < this.combinedPdfView.length; i++) {
      if (this.combinedPdfView[i].fileName == fileName) {
        let imageUrl = this.combinedPdfView[i].fileData;
        let file = this.b64toBlob2(imageUrl);
        let url = URL.createObjectURL(file);
        this.imageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
    }
  }

  b64toBlob2(dataURI) {
    let byteString = atob(dataURI);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
  }

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  paymentType;
  isNoPayment: boolean = false;
  firstPayType: boolean = false;
  secondPayType: boolean = false;
  thirdPayType: boolean = false;
  paymentDetailsOnPageLoad() {
    let len = this.paymentdetailsList.length;
    if (len == 1) {
      this.payment1Flag = true;
      this.payment2Flag = false;
      this.payment3Flag = false;
      const paymentDetails = {
        paymentMode1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.paymentMode,
        bank1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.bank,
        dateCalendar1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.paymentDat,
        tdsType1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.tdsType,
        amountCollected1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.amount,
        grossAmount1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.grossAmount,
        paymenttype1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.paymentType,
        tdsAmount1: Math.round(parseFloat(this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.tdsAmount)),
        chequeNEFTdetails1: (this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.chequeNo == 0) ? (null) : (this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.chequeNo),
      }

      this.ReportingScreenDetailsForm.patchValue(paymentDetails);
      this.ReportingScreenDetailsForm.controls.chequeNEFTdetails1.setValidators([Validators.required, Validators.minLength(1)]);
      this.ReportingScreenDetailsForm.controls.chequeNEFTdetails1.updateValueAndValidity();
      this.firstPayType = paymentDetails.paymenttype1 == 'No Payment' ? true : false;
      if (paymentDetails.paymenttype1 == 'No Payment' && this.paymentOnLoadData?.opportunityStatus == 'Business Won') {
        this.docList.forEach(element => {
          if (element.type == 'Invoice before payment Approval') {
            element.is_mandatory = 'M';
            this.isInvoicebeforepaymentApprovalM = true;
          }
        });
      } else {
        this.docList.forEach(element => {
          if (element.type == 'Invoice before payment Approval') {
            element.is_mandatory = 'O';
            this.isInvoicebeforepaymentApprovalM = false;
          }
        });
      }
      this.paymentType = paymentDetails.paymenttype1;
      if (this.paymentOnLoadData?.opportunityDetails?.paymentDetails[0]?.paymentType == 'No Payment') {
        this.isNoPayment = true;
      }
    } else if (len == 2) {
      this.payment1Flag = true;
      this.payment2Flag = true;
      this.payment3Flag = false;

      const paymentDetails = {
        paymentMode1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.paymentMode,
        bank1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.bank,
        dateCalendar1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.paymentDat,
        tdsType1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.tdsType,
        amountCollected1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.amount,
        grossAmount1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.grossAmount,
        paymenttype1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.paymentType,
        tdsAmount1: Math.round(parseFloat(this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.tdsAmount)),
        chequeNEFTdetails1: (this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.chequeNo == 0) ? (null) : (this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.chequeNo),

        paymentMode2: this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.paymentMode,
        bank2: this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.bank,
        dateCalendar2: this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.paymentDat,
        tdsType2: this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.tdsType,
        amountCollected2: this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.amount,
        grossAmount2: this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.grossAmount,
        paymenttype2: this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.paymentType,
        tdsAmount2: Math.round(parseFloat(this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.tdsAmount)),
        chequeNEFTdetails2: (this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.chequeNo == 0) ? (null) : (this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.chequeNo),
      }

      this.ReportingScreenDetailsForm.patchValue(paymentDetails);
      this.ReportingScreenDetailsForm.controls.chequeNEFTdetails1.setValidators([Validators.required, Validators.minLength(1)]);
      this.ReportingScreenDetailsForm.controls.chequeNEFTdetails1.updateValueAndValidity();
      this.ReportingScreenDetailsForm.controls.chequeNEFTdetails2.setValidators([Validators.required, Validators.minLength(1)]);
      this.ReportingScreenDetailsForm.controls.chequeNEFTdetails2.updateValueAndValidity();
      if (this.paymentOnLoadData?.opportunityDetails?.paymentDetails[1]?.paymentType != 'No Payment') {
        this.isNoPayment = false;
      }

      this.firstPayType = paymentDetails.paymenttype1 == 'No Payment' ? true : false;
      this.secondPayType = paymentDetails.paymenttype2 == 'No Payment' ? true : false;
      this.paymentType = paymentDetails.paymenttype1;
    } else if (len == 3) {
      this.payment1Flag = true;
      this.payment2Flag = true;
      this.payment3Flag = true;

      const paymentDetails = {
        paymentMode1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.paymentMode,
        bank1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.bank,
        dateCalendar1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.paymentDat,
        tdsType1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.tdsType,
        amountCollected1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.amount,
        grossAmount1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.grossAmount,
        paymenttype1: this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.paymentType,
        tdsAmount1: Math.round(parseFloat(this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.tdsAmount)),
        chequeNEFTdetails1: (this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.chequeNo == 0) ? (null) : (this.paymentOnLoadData.opportunityDetails.paymentDetails[0]?.chequeNo),

        paymentMode2: this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.paymentMode,
        bank2: this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.bank,
        dateCalendar2: this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.paymentDat,
        tdsType2: this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.tdsType,
        amountCollected2: this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.amount,
        grossAmount2: this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.grossAmount,
        paymenttype2: this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.paymentType,
        tdsAmount2: Math.round(parseFloat(this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.tdsAmount)),
        chequeNEFTdetails2: (this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.chequeNo == 0) ? (null) : (this.paymentOnLoadData.opportunityDetails.paymentDetails[1]?.chequeNo),

        paymentMode3: this.paymentOnLoadData.opportunityDetails.paymentDetails[2]?.paymentMode,
        bank3: this.paymentOnLoadData.opportunityDetails.paymentDetails[2]?.bank,
        dateCalendar3: this.paymentOnLoadData.opportunityDetails.paymentDetails[2]?.paymentDat,
        tdsType3: this.paymentOnLoadData.opportunityDetails.paymentDetails[2]?.tdsType,
        amountCollected3: this.paymentOnLoadData.opportunityDetails.paymentDetails[2]?.amount,
        grossAmount3: this.paymentOnLoadData.opportunityDetails.paymentDetails[2]?.grossAmount,
        paymenttype3: this.paymentOnLoadData.opportunityDetails.paymentDetails[2]?.paymentType,
        tdsAmount3: Math.round(parseFloat(this.paymentOnLoadData.opportunityDetails.paymentDetails[2]?.tdsAmount)),
        chequeNEFTdetails3: (this.paymentOnLoadData.opportunityDetails.paymentDetails[2]?.chequeNo == 0) ? (null) : (this.paymentOnLoadData.opportunityDetails.paymentDetails[2]?.chequeNo),

      }
      this.ReportingScreenDetailsForm.patchValue(paymentDetails);
      this.ReportingScreenDetailsForm.controls.chequeNEFTdetails1.setValidators([Validators.required, Validators.minLength(1)]);
      this.ReportingScreenDetailsForm.controls.chequeNEFTdetails1.updateValueAndValidity();
      this.ReportingScreenDetailsForm.controls.chequeNEFTdetails2.setValidators([Validators.required, Validators.minLength(1)]);
      this.ReportingScreenDetailsForm.controls.chequeNEFTdetails2.updateValueAndValidity();
      this.ReportingScreenDetailsForm.controls.chequeNEFTdetails3.setValidators([Validators.required, Validators.minLength(1)]);
      this.ReportingScreenDetailsForm.controls.chequeNEFTdetails3.updateValueAndValidity();
      if (this.paymentOnLoadData?.opportunityDetails?.paymentDetails[2]?.paymentType != 'No Payment') {
        this.isNoPayment = false;
      }
      this.firstPayType = paymentDetails.paymenttype1 == 'No Payment' ? true : false;
      this.secondPayType = paymentDetails.paymenttype2 == 'No Payment' ? true : false;
      this.thirdPayType = paymentDetails.paymenttype3 == 'No Payment' ? true : false;
      this.paymentType = paymentDetails.paymenttype1;
    }
  }

  readyPaymentList() {

    if ((this.payment1Flag) && (!this.payment2Flag) && (!this.payment3Flag)) {
      this.paymentdetailsList[0].paymentMode = this.ReportingScreenDetailsForm.value.paymentMode1;
      this.paymentdetailsList[0].bank = this.ReportingScreenDetailsForm.value.bank1;
      this.paymentdetailsList[0].paymentDat = this.ReportingScreenDetailsForm.value.dateCalendar1;
      this.paymentdetailsList[0].paymentType = this.ReportingScreenDetailsForm.value.paymenttype1;
      this.paymentdetailsList[0].chequeNo = (this.ReportingScreenDetailsForm.value.chequeNEFTdetails1 == "") ? (0) : (this.ReportingScreenDetailsForm.value.chequeNEFTdetails1);
      this.paymentdetailsList[0].paymentModeId = this.paymentModeList.filter(payMode => payMode.property === this.ReportingScreenDetailsForm.value.paymentMode1)[0]?.id;
      this.paymentdetailsList[0].paymentTypeId = this.paymenttypeList.filter(payType => payType.property === this.ReportingScreenDetailsForm.value.paymenttype1)[0]?.id;
      this.paymentdetailsList[0].bankId = this.bankList.filter(bankType => bankType.name === this.ReportingScreenDetailsForm.value.bank1)[0]?.id;

    } else if ((this.payment1Flag) && (this.payment2Flag) && (!this.payment3Flag)) {

      this.paymentdetailsList[0].paymentMode = this.ReportingScreenDetailsForm.value.paymentMode1;
      this.paymentdetailsList[0].bank = this.ReportingScreenDetailsForm.value.bank1;
      this.paymentdetailsList[0].paymentDat = this.ReportingScreenDetailsForm.value.dateCalendar1;
      this.paymentdetailsList[0].paymentType = this.ReportingScreenDetailsForm.value.paymenttype1;
      this.paymentdetailsList[0].chequeNo = (this.ReportingScreenDetailsForm.value.chequeNEFTdetails1 == "") ? (0) : (this.ReportingScreenDetailsForm.value.chequeNEFTdetails1);
      this.paymentdetailsList[0].paymentModeId = this.paymentModeList.filter(payMode => payMode.property === this.ReportingScreenDetailsForm.value.paymentMode1)[0]?.id;
      this.paymentdetailsList[0].paymentTypeId = this.paymenttypeList.filter(payType => payType.property === this.ReportingScreenDetailsForm.value.paymenttype1)[0]?.id;
      this.paymentdetailsList[0].bankId = this.bankList.filter(bankType => bankType.name === this.ReportingScreenDetailsForm.value.bank1)[0]?.id;

      this.paymentdetailsList[1].paymentMode = this.ReportingScreenDetailsForm.value.paymentMode2;
      this.paymentdetailsList[1].bank = this.ReportingScreenDetailsForm.value.bank2;
      this.paymentdetailsList[1].paymentDat = this.ReportingScreenDetailsForm.value.dateCalendar2;
      this.paymentdetailsList[1].paymentType = this.ReportingScreenDetailsForm.value.paymenttype2;
      this.paymentdetailsList[1].chequeNo = (this.ReportingScreenDetailsForm.value.chequeNEFTdetails2 == "") ? (0) : (this.ReportingScreenDetailsForm.value.chequeNEFTdetails2);
      this.paymentdetailsList[1].paymentModeId = this.paymentModeList.filter(payMode => payMode.property === this.ReportingScreenDetailsForm.value.paymentMode2)[0]?.id;
      this.paymentdetailsList[1].paymentTypeId = this.paymenttypeList.filter(payType => payType.property === this.ReportingScreenDetailsForm.value.paymenttype2)[0]?.id;
      this.paymentdetailsList[1].bankId = this.bankList.filter(bankType => bankType.name === this.ReportingScreenDetailsForm.value.bank2)[0]?.id;
    } else if ((this.payment1Flag) && (this.payment2Flag) && (this.payment3Flag)) {

      this.paymentdetailsList[0].paymentMode = this.ReportingScreenDetailsForm.value.paymentMode1;
      this.paymentdetailsList[0].bank = this.ReportingScreenDetailsForm.value.bank1;
      this.paymentdetailsList[0].paymentDat = this.ReportingScreenDetailsForm.value.dateCalendar1;
      this.paymentdetailsList[0].paymentType = this.ReportingScreenDetailsForm.value.paymenttype1;
      this.paymentdetailsList[0].chequeNo = (this.ReportingScreenDetailsForm.value.chequeNEFTdetails1 == "") ? (0) : (this.ReportingScreenDetailsForm.value.chequeNEFTdetails1);
      this.paymentdetailsList[0].paymentModeId = this.paymentModeList.filter(payMode => payMode.property === this.ReportingScreenDetailsForm.value.paymentMode1)[0]?.id;
      this.paymentdetailsList[0].paymentTypeId = this.paymenttypeList.filter(payType => payType.property === this.ReportingScreenDetailsForm.value.paymenttype1)[0]?.id;
      this.paymentdetailsList[0].bankId = this.bankList.filter(bankType => bankType.name === this.ReportingScreenDetailsForm.value.bank1)[0]?.id;

      this.paymentdetailsList[1].paymentMode = this.ReportingScreenDetailsForm.value.paymentMode2;
      this.paymentdetailsList[1].bank = this.ReportingScreenDetailsForm.value.bank2;
      this.paymentdetailsList[1].paymentDat = this.ReportingScreenDetailsForm.value.dateCalendar2;
      this.paymentdetailsList[1].paymentType = this.ReportingScreenDetailsForm.value.paymenttype2;
      this.paymentdetailsList[1].chequeNo = (this.ReportingScreenDetailsForm.value.chequeNEFTdetails2 == "") ? (0) : (this.ReportingScreenDetailsForm.value.chequeNEFTdetails2);
      this.paymentdetailsList[1].paymentModeId = this.paymentModeList.filter(payMode => payMode.property === this.ReportingScreenDetailsForm.value.paymentMode2)[0]?.id;
      this.paymentdetailsList[1].paymentTypeId = this.paymenttypeList.filter(payType => payType.property === this.ReportingScreenDetailsForm.value.paymenttype2)[0]?.id;
      this.paymentdetailsList[1].bankId = this.bankList.filter(bankType => bankType.name === this.ReportingScreenDetailsForm.value.bank2)[0]?.id;

      this.paymentdetailsList[2].paymentMode = this.ReportingScreenDetailsForm.value.paymentMode3;
      this.paymentdetailsList[2].bank = this.ReportingScreenDetailsForm.value.bank3;
      this.paymentdetailsList[2].paymentDat = this.ReportingScreenDetailsForm.value.dateCalendar3;
      this.paymentdetailsList[2].paymentType = this.ReportingScreenDetailsForm.value.paymenttype3;
      this.paymentdetailsList[2].chequeNo = (this.ReportingScreenDetailsForm.value.chequeNEFTdetails3 == "") ? (0) : (this.ReportingScreenDetailsForm.value.chequeNEFTdetails3);
      this.paymentdetailsList[2].paymentModeId = this.paymentModeList.filter(payMode => payMode.property === this.ReportingScreenDetailsForm.value.paymentMode3)[0]?.id;
      this.paymentdetailsList[2].paymentTypeId = this.paymenttypeList.filter(payType => payType.property === this.ReportingScreenDetailsForm.value.paymenttype3)[0]?.id;
      this.paymentdetailsList[2].bankId = this.bankList.filter(bankType => bankType.name === this.ReportingScreenDetailsForm.value.bank3)[0]?.id;
    }

  }

  checkAttachmentValidation() {
    //to be called on status = bussiness won
    this.duplicateDocList = [];
    this.docList.filter((d) => {
      this.displayAttachData.forEach((df) => {
        if (d.type == df.fileTypeName) {
          this.duplicateDocList.push(d);
        }
      })
    })
  }

  mandatoryFileNotUploded() {
    //to be called on status = bussiness won
    this.mandetoryFileArray = [];
    this.docList.filter((d) => {
      if (d.file == "" && d.is_mandatory == 'M') {
        this.mandetoryFileArray.push(d);
      }
    });
  }

  checkFilesBussinessReported() {
    //to be called on status = bussiness reported
    this.docList.filter((d) => {
      if (d.type == "Internal Pricing Approval Attachment") {
        if (d.file != "" && d.is_mandatory == 'M' && d.isDisabled == false) {
          this.isInternalPricingFile = true;
        } else if (d.isDisabled == true && d.file == "") {
          this.isInternalPricingFile = true;
        } else {
          this.isInternalPricingFile = false;
        }
      }
    })
  }

  numberOnlyCheque(event) {
    let text = event.key;
    let paymentMode = this.openedPaymentData.value.paymentMode;
    if (paymentMode && paymentMode == "Cheque") {
      this.isCheque = true;
      return /^[0-9]*$/.test(text);
    } else {
      this.isCheque = false;
      return /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]+$/.test(text);
    }
  }

  checkCombineDeleteCond() {
    if (this.otherOpportunityStatusFlagA == true || this.businessReportedStatusFlagA == true) {
      return false;
    }
    return true;
  }

  gstNoCleared(event) {
    if (event.target.value == '' || event.target.value == null) {
      this.docList.forEach(element => {
        if (element.type == 'GST Portal Snapshot') {
          element.is_mandatory = 'O';
          this.isGSTPortalSnapshotM = true;
        }
      });
    } else {
      this.docList.forEach(element => {
        if (element.type == 'GST Portal Snapshot') {
          element.is_mandatory = 'M';
          this.isGSTPortalSnapshotM = true;
        }
      });
    }
  }

  clientTypeMaster() {
    this.pagesService.clientTypeMaster().subscribe((res) => {
      this.clientTypeList = res?.SUCCESS;
      if (this.CRM_Code != null && this.CRM_Code != undefined) {
        this.patchClientType(this.CRM_Code);
      }
    });
  }

  getPayStatus() {
    this.pagesService.getPaymentStatus().subscribe((res) => {
      this.payStatusList = res;
    })
  }

  businessReasonValidation() {
    if (this.ReportingScreenDetailsForm.value.reasonselect == null || this.ReportingScreenDetailsForm.value.reasonselect == undefined) {
      this.isActionReasonTouched = true;
    } else {
      this.isActionReasonTouched = false;
    }
    this.isBAreason = false;
  }

  paymentDateSelected(text) {
    if (this.dateAdded.indexOf(text) == -1) {
      this.dateAdded.push(text)
    }
    let noOfDates = this.paymentOnLoadData.opportunityDetails.paymentDetails.length;
    if (noOfDates == this.dateAdded.length) {
      this.paymentDetailsMandatoryFlag = false;
    }
  }

  dateTyped(event, text) {
    if (event.target.value == '') {
      let index = this.dateAdded.indexOf(text);
      this.dateAdded.splice(index, 1);
    }
  }

  resetPan(event: any): void {
    let gstNo = event?.target?.value.toUpperCase();
    let regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[A-Z0-9]{1}$/; //NOSONAR
    let correctGst = regex.test(gstNo);
    if (gstNo == null || gstNo == "" || gstNo.length < 15) {
      this.ReportingScreenDetailsForm.controls.PANnotextField.reset();
    } else {
      this.isGstNoIncorrect = false;
    }

    if (gstNo.length == 15) {
      if (correctGst) {
        this.isGstNoInvalid = false;
      } else {
        this.isGstNoInvalid = true;
      }
    }
  }

  SPANeededCliked(event) {
    if (event.checked) {
      this.disableStdPricingReceived = false;
    } else {
      this.disableStdPricingReceived = true;
      this.ReportingScreenDetailsForm.patchValue({ standardApprovalReceived: false });
      this.docList.forEach(element => {
        if (element.type == 'Standard Pricing Approval Attachment') {
          element.is_mandatory = 'O';
          this.isStandardPricingApprovalAttachmentM = false;
        }
      });
    }
  }

  validateSfActual() {
    let regex = /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,8})?)$/;
    let sfPercent = this.ReportingScreenDetailsForm.value.sfActualPercent;
    let quantum = this.paymentOnLoadData?.opportunityDetails?.quantum;
    let regValid;
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      regValid = regex.test(sfPercent);
      if (regValid) {
        let finalVal = ((quantum * Number(sfPercent)) / 100) * 10000000;
        let sfDiscount = Number(finalVal) - Math.round(Number(this.ReportingScreenDetailsForm?.value?.sfExpectedFee));
        let sfDiscountPercent = sfDiscount * 100 / Math.round(Number(this.ReportingScreenDetailsForm?.value?.sfExpectedFee));
        this.ReportingScreenDetailsForm.patchValue({
          sfActualFee: finalVal.toFixed(2),
          sfDiscountPremium: sfDiscount.toFixed(2),
          sfDiscountPremiumPercent: sfDiscountPercent.toFixed(2)
        });
        this.calMinMax();
      } else {
        this.ReportingScreenDetailsForm.patchValue({
          sfActualFee: '0',
          sfActualPercent: '0',
          sfDiscountPremium: null,
          sfDiscountPremiumPercent: null
        });
      }
    }, 1500);
  }

  fixedFeeChanged(event) {
    let percent;
    if (event.value == 92) {
      this.disabledSfAct = false;
      this.showMinMax = true;
    } else {
      this.disabledSfAct = true;
      this.showMinMax = false;
      this.ReportingScreenDetailsForm.patchValue({
        ...this.sfFeeDetails,
        sfActualPercent: null,
        minimum: null,
        maximum: null
        // sfExpectedPercent: null,
      });
    }
  }

  validateMin() {
    let regex = /^\-?[0-9]+(?:\.[0-9]{2})?$/;
    let min = this.ReportingScreenDetailsForm.value.minimum;
    let regValid;
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      regValid = regex.test(min);
      if (regValid) {
        this.calMinMax();
      } else {
        if (Number(min) == 0 || min == null || min == undefined) {
          this.calMinMax();
        } else {
          this.ReportingScreenDetailsForm.patchValue({
            minimum: null
          });
        }
      }
    }, 1200);
  }

  validateMax() {
    //test regex for both min max
    let regex = /^\-?[0-9]+(?:\.[0-9]{2})?$/;
    let max = this.ReportingScreenDetailsForm.value.maximum;
    let regValid;
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      regValid = regex.test(max);
      if (regValid) {
        this.calMinMax();
      } else {
        if (Number(max) == 0 || max == null || max == undefined) {
          this.calMinMax();
        } else {
          this.ReportingScreenDetailsForm.patchValue({
            maximum: null
          });
        }
      }
    }, 1200);
  }

  calMinMax() {
    let min = this.ReportingScreenDetailsForm.value.minimum;
    let max = this.ReportingScreenDetailsForm.value.maximum;
    let sfActual = Number(this.ReportingScreenDetailsForm.value?.sfActualFee);
    let revisedSfActual: number = 0;
    let sfDiscount: any = 0;
    if (Number(min) > 0 && Number(max) > 0) {
      if (Number(min) > Number(max)) {
        this.ReportingScreenDetailsForm.patchValue({
          minimum: null
        });
      } else {
        if (sfActual > Number(max)) {
          revisedSfActual = Number(max);
        } else if (sfActual < Number(min)) {
          revisedSfActual = Number(min);
        } else {
          revisedSfActual = sfActual;
        }
        sfDiscount = Number(revisedSfActual) - Number(this.ReportingScreenDetailsForm?.value?.sfExpectedFee);
        let sfDiscountPercent: any = (sfDiscount * 100) / Math.round(Number(this.ReportingScreenDetailsForm?.value?.sfExpectedFee));
        this.ReportingScreenDetailsForm.patchValue({
          sfDiscountPremium: sfDiscount.toFixed(2),
          sfDiscountPremiumPercent: sfDiscountPercent.toFixed(2),
          revisedSfActual: revisedSfActual
        });
        this.DAAMandatory(sfDiscount)
      }
    }

    if (Number(min) == 0 && Number(max) == 0) {
      let sfDiscount = Number(this.pageloadvalues?.sfActualFee) - Number(this.ReportingScreenDetailsForm?.value?.sfExpectedFee);
      let sfDiscountPercent: any = (sfDiscount * 100) / Math.round(Number(this.ReportingScreenDetailsForm?.value?.sfExpectedFee));
      this.ReportingScreenDetailsForm.patchValue({
        sfDiscountPremium: sfDiscount.toFixed(2),
        sfDiscountPremiumPercent: sfDiscountPercent.toFixed(2),
        revisedSfActual: this.pageloadvalues?.sfActualFee
      });
      this.DAAMandatory(sfDiscount)
    }
    if ((min == null || min == undefined) && (max == null || max == undefined)) {
      this.ReportingScreenDetailsForm.patchValue({
        sfDiscountPremium: this.pageloadvalues?.sfDiscountPremium,
        sfDiscountPremiumPercent: this.pageloadvalues?.sfDiscountPremiumPercent,
        revisedSfActual: null
      });
    }
  }

  DAAMandatory(amount) {
    let attachment = this.docList.find(element => element.type == 'Discount Approval Attachment');
    if (amount == 0) {
      attachment.is_mandatory = 'O';
    } else {
      attachment.is_mandatory = 'M';
    }
  }

  downloadFile() {
    let linkSource: any;
    if (this.openedFile?.fileContentType?.includes('image')) {
      let mime = this.openedFile?.file?.split(',')[0];
      linkSource = `${mime},${this.urlSafe?.split(',')[1]}`;
    } else {
      linkSource = this.urlSafe;
    }
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = this?.openedFile?.fileName.split('.')[0];
    downloadLink.click();
  }

  currentPayment(event) {
    const paymentIndex = event.index;
    this.paymentIndex = paymentIndex;
    const currentPaymentData = this.payments.at(paymentIndex) as FormArray;
    this.openedPaymentData = currentPaymentData;
    if (currentPaymentData.value.paymentType == "No Payment") {
      ['chequeNo', 'paymentDat', 'bank'].forEach(controlName => {
        const control = currentPaymentData.get(controlName);
        if (control) control.disable();
      });
    }
  }

  formatDate(date: Date): string {
    if (typeof date != "string") {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();
      return `${day}-${month}-${year}`;
    } else {
      return date;
    }
  }

  uploadSingleFile(data) {
    this.uploadLoader = false;
    this.pagesService.singleFileUpload(data).subscribe((response) => {
      let obj = this.docList?.find((d) => d?.id == data?.fileTypeid);
      let obj1 = this.result?.find((d) => d?.fileTypeid == data?.fileTypeid);
      obj.deleteId = response?.fileId;
      obj1.fileId = response?.fileId;
      this.uploadedFileIds.push(response?.fileId);
    }, (_err) => {
      let obj = this.docList?.find((d) => d?.id == data?.fileTypeid);
      obj.file = '';
      obj.fileName = ''
      this.messageService.add({ severity: 'error', summary: 'something went wrong please try again!' });
    });
  }

  deleteMultipleFile() {
    this.pagesService.deleteMultipleFiles(this.uploadedFileIds).subscribe((res) => {
    });
  }

  ngOnDestroy() {
    this.apiList.forEach((a) => {
      a.unsubscribe();
    });
  }

  getTan() {
    let data = {};
    let payload = { "companyCode": this.ReportingScreenDetailsForm?.value?.CRMCodetextField };
    this.apiList.push(this.pagesService.getCompanyGroupFinRisk(payload).subscribe((response: any) => {
      console.log("response for tan is:", response);
      // data = {
      //   tanDetails: response?.tan
      // }
      this.ReportingScreenDetailsForm.get("tanDetails").reset();
      this.ReportingScreenDetailsForm.get("tanDetails").setValue(response?.tan);
    }));
  }
}
