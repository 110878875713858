<div class="p-col-12 preview-container background-colour main-frame">
    <div class="p-col-12 ">
        <!-- Main -->
        <div class="p-col-12 p-card pcard-css card-side-padding layout-content"> 
            <div class="ui-fluid p-mb-3">
                <!-- header -->
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-4 p-d-flex p-ai-center">
                    <backoffice-svg [name]="'attachment-icon-preview-list'"></backoffice-svg>

                    <h6 class="margin-bottom heading-css p-ml-2">Ops Related Attachment</h6>
                </div>

                <!-- listing cards -->
                
                <!-- attachment list -->
                <div class="p-fluid p-formgrid p-grid p-pt-1">
                    <div class=" p-col-12 p-p-0">
                        <div class="ui-fluid p-formgrid p-grid">
                            <div class="p-col-12 p-p-0 p-sm-12 p-md-12 p-lg-12 p-xl-12  left">
                                <div class="p-col-12 p-p-0 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-flex-wrap attachmentScroll">
                                    <div class="p-col-12 p-sm-4 p-pl-0 p-d-flex p-ai-center p-flex-wrap p-pt-1 attachmentContainer" *ngFor="let item of opsAttachments">
                                        <p-card>
                                            <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                                <div class="p-col-10 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                                    <div style="padding-right: 10px;">
                                                            <img alt="" *ngIf="item.fileContentType == 'image/png' || item.fileContentType == 'image/jpg' || item.fileContentType == 'image/jpeg'" [src]="imgThumb">
                                                            <img alt="" width="50" height="64" *ngIf="item.fileContentType ==='application/pdf' " [src]="pdfThumb" />
                                                            <img alt="" width="50" height="64" *ngIf="item.fileContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || item.fileContentType == 'application/msword' " [src]="wordThumb" />
                                                            <img alt="" width="50" height="64" *ngIf="item.fileContentType == '' " [src]="mailThumb" />
                                                    </div>
                                                    <div class="p-col-9 p-lg-9 p-xl-10 p-md-9 p-sm-7 p-pr-0 p-pl-0 p-d-flex p-flex-column p-flex-wrap">
                                                        <a class="attachmentName limit-text-length" (click)="previewFile($event, item)">
                                                            {{item.fileName}}</a>
                                                        <label class="attachmentFileType">Type : 
                                                            {{item.fileTypeName}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-card>
                                    </div>
                                    <p-confirmDialog appendTo="body"></p-confirmDialog>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-fluid p-col-12 p-d-flex p-jc-center" *ngIf="opsAttachments.length===0">
                    <backoffice-svg [name]="'empty-state-light'"></backoffice-svg>
                    <backoffice-svg [name]="'empty-state-dark'"></backoffice-svg>
                </div>

                <!-- end - listing cards -->
            </div>
            <div class="ui-fluid">
                <!-- header -->
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-4 p-d-flex p-ai-center">
                    <backoffice-svg [name]="'attachment-icon-preview-list'"></backoffice-svg>

                    <h6 class="margin-bottom heading-css p-ml-2">BD Document For Reference</h6>
                </div>

                <!-- listing cards -->
                
                <!-- attachment list -->
                <div class="p-fluid p-formgrid p-grid p-pt-1">
                    <div class=" p-col-12 p-p-0">
                        <div class="ui-fluid p-formgrid p-grid">
                            <div class="p-col-12 p-p-0 p-sm-12 p-md-12 p-lg-12 p-xl-12  left">
                                <div class="p-col-12 p-p-0 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-flex-wrap attachmentScroll">
                                    <div class="p-col-12 p-sm-4 p-pl-0 p-d-flex p-ai-center p-flex-wrap p-pt-1 attachmentContainer" *ngFor="let item of bdAttachments">
                                        <p-card>
                                            <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                                <div class="p-col-10 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                                    <div style="padding-right: 10px;">
                                                            <img alt="" *ngIf="item.fileContentType == 'image/png' || item.fileContentType == 'image/jpg' || item.fileContentType == 'image/jpeg'" [src]="imgThumb">
                                                            <img alt="" width="50" height="64" *ngIf="item.fileContentType ==='application/pdf' " [src]="pdfThumb" />
                                                            <img alt="" width="50" height="64" *ngIf="item.fileContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || item.fileContentType == 'application/msword' " [src]="wordThumb" />
                                                            <img alt="" width="50" height="64" *ngIf="item.fileContentType == '' " [src]="mailThumb" />
                                                    </div>
                                                    <div class="p-col-9 p-lg-9 p-xl-10 p-md-9 p-sm-7 p-pr-0 p-pl-0 p-d-flex p-flex-column p-flex-wrap">
                                                        <a class="attachmentName limit-text-length" (click)="previewFile($event, item)">
                                                            {{item.fileName}}</a>
                                                        <label class="attachmentFileType">Type : 
                                                            {{item.fileTypeName}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-card>
                                    </div>
                                    <p-confirmDialog appendTo="body"></p-confirmDialog>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-fluid p-col-12 p-d-flex p-jc-center" *ngIf="bdAttachments.length===0">
                    <backoffice-svg [name]="'empty-state-light'"></backoffice-svg>
                    <backoffice-svg [name]="'empty-state-dark'"></backoffice-svg>
                </div>

                <!-- end - listing cards -->
            </div>
        </div>
        
    </div>
</div>
