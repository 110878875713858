<div class="entityDetailsModal">
    <p-toast class="custom-toast"></p-toast>
    <div class="p-col-12 p-p-0">
        <div class="ui-fluid p-formgrid p-grid">
            <div class="p-col-3 p-sm-3 p-md-3 p-lg-3 p-xl-3 p-text-left p-pl-0">
                <div class="p-d-flex p-ai-center"> 
                    <label class="p-mr-2 p-mb-2 label_light">Opportunity Status:</label>
                    <h1 class="p-medium display-4">{{oppGridDetials?.opportunityStatus}}</h1>
                </div>
            </div>
            <div class="p-col-3 p-sm-3 p-md-3 p-lg-3 p-xl-3 p-text-left">
                <div class="p-d-flex p-ai-center"> 
                    <label class="p-mr-2 p-mb-2 label_light">Date:</label>
                    <h1 class="p-medium display-4">{{oppGridDetials?.reportedDate}}</h1>
                </div>
            </div>
            <div class="p-col-3 p-sm-3 p-md-3 p-lg-3 p-xl-3 p-text-left">
                <div class="p-d-flex p-ai-center"> 
                    <label class="p-mr-2 p-mb-2 label_light">CRM Code:</label>
                    <h1 class="p-medium display-4">{{oppGridDetials?.companyCode}}</h1>
                </div>
            </div>
            <div class="p-col-3 p-sm-3 p-md-3 p-lg-3 p-xl-3 p-text-left">
                <div class="p-d-flex p-ai-center"> 
                    <label class="p-mr-2 p-mb-2 label_light">Reference No:</label>
                    <h1 class="p-medium display-4" *ngIf="showRefNo">{{refNo}}</h1>
                    <h1 class="p-medium display-4" *ngIf="!showRefNo">-</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="ui-fluid p-formgrid p-grid">
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 verticalTabContainer p-p-0">
            <p-tabView [(activeIndex)]="activeIndex" (onChange) = "onTabChange($event)" styleClass="p-tabview-vertical">
                <p-tabPanel>
                    <ng-template pTemplate="header"><span>Entity
                            Details</span>
                    </ng-template>
                    <form [formGroup]="entityDetailsForm">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-col-12">
                                <h1 class="p-medium display-3">Initiation Type</h1>
                            </div>
                            <div class="p-col-12 p-p-0 p-mt-1">
                                <div class="ui-fluid p-formgrid p-grid">
                                    <div *ngFor="let item of initiationTypeList">
                                        <div class="p-col-2 p-sm-2 p-md-2 p-lg-2 p-xl-2 left">
                                            <div class="field-radiobutton">
                                                <p-radioButton class="p-mr-1" value="{{item.id}}"
                                                    (onClick)="initiationTypeSelected()" formControlName="initType"
                                                    id="{{item.id}}"
                                                    [disabled]="isIrfInitiated || disableAllFileds || initiationReadScope"></p-radioButton>
                                                <label for="{{item.id}}">{{item.value}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-mt-3">
                                <h1 class="p-medium display-3">Normal Company Details</h1>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 left">
                                <div class="p-fluid p-field">
                                    <label for="tds_No">
                                        TDS No
                                    </label>
                                    <input type="text" id="tds_No" pInputText formControlName="tdsNo"
                                        class="p-inputtext" [readonly]="true" />
                                </div>
                            </div>
                            <div class="p-col-12">
                                <h1 class="p-medium display-3">Tri Party Details</h1>
                                <!-- <a [href]="link" target="_blank">test</a> -->
                            </div>
                            <div class="p-col-12 p-p-0">
                                <div class="ui-fluid p-formgrid p-grid">
                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                        <div class="p-fluid p-field">
                                            <label for="triNormal">
                                                Tri Party/Normal
                                            </label>
                                            <input type="text" id="triNormal" pInputText placeholder="Enter Tri Party/Normal"
                                                class="p-inputtext" [readonly]="true" formControlName="triNormal" />
                                        </div>
                                    </div>
                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                        <div class="p-fluid p-field">
                                            <label for="triCompany">
                                                Tri Party Company Name (CRM)
                                            </label>
                                            <input type="text" id="triCompany" pInputText placeholder="Enter Tri Party Company Name (CRM)"
                                                class="p-inputtext" [readonly]="true" formControlName="triCrm" />
                                        </div>
                                    </div>
                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                        <div class="p-fluid p-field">
                                            <label for="crmCode">
                                                CRM Code
                                            </label>
                                            <input type="text" id="crmCode" pInputText placeholder="Enter CRM Code"
                                                class="p-inputtext" [readonly]="true" formControlName="crmCode" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-p-0">
                                <div class="ui-fluid p-formgrid p-grid">
                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                        <div class="p-fluid p-field">
                                            <label for="tdsNum">
                                                TDS Number
                                            </label>
                                            <input type="text" id="tdsNum" pInputText class="p-inputtext" [readonly]="true"
                                                formControlName="tdsNum" placeholder="Enter TDS Number" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </p-tabPanel>
                <p-tabPanel [disabled]="disableComboTab">
                    <ng-template pTemplate="header"><span [ngClass]="{'errorTab': errorCombo}">Combo
                            Product</span>
                    </ng-template>
                    <form [formGroup]="comboDetailsForm">
                        <div class="p-col-12 p-p-0">
                            <div class="p-col-12">
                                <h1 class="p-medium display-3">Combo case details</h1>
                            </div>
                            <div class="ui-fluid p-formgrid p-grid">
                                <div class="p-col-8 p-sm-8 p-md-8 p-lg-8 p-xl-8 left">
                                    <div class="p-fluid p-field">
                                        <label for="secondOpp">Second Opportunity <span
                                                class='invalid-feedback-astrik'>*</span></label>
                                        <p-dropdown id="secondOpp" placeholder="Select" [options]="secondaryOppList"
                                            (onChange)="secondOppSelected($event)" [autoDisplayFirst]="false"
                                            optionLabel="opportunityName" optionValue="opportunityId"
                                            formControlName="secondOpp"
                                            [disabled]="isIrfInitiated || disableAllFileds || initiationReadScope"
                                            [ngClass]="{'error': (comboDetailsForm.get('secondOpp')?.touched && comboDetailsForm.get('secondOpp')?.errors?.required) || errorCombo}">
                                        </p-dropdown>
                                        <div class="invalid-feedback"
                                        *ngIf="(comboDetailsForm.get('secondOpp')?.touched && comboDetailsForm.get('secondOpp')?.errors?.required) || errorCombo">
                                            Second opportunity is required
                                            </div>
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="ratable_unit">
                                            Product Selected
                                        </label>
                                        <input type="text" id="ratable_unit" pInputText placeholder="Enter Product Selected"
                                            class="p-inputtext" formControlName="productSelected" disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-col-12">
                                <h1 class="p-medium display-3">Main Opportunity Details</h1>
                            </div>
                        </div>
                        <div class="p-col-12 p-p-0">
                            <div class="ui-fluid p-formgrid p-grid">
                                <div class="p-col-8 p-sm-8 p-md-8 p-lg-8 p-xl-8 left">
                                    <div class="p-fluid p-field">
                                        <label for="ratable_unit">
                                            Opportunity Name
                                        </label>
                                        <input disabled type="text" id="ratable_unit" pInputText placeholder="Enter Opportunity Name"
                                            class="p-inputtext" formControlName="mainOppName" disabled />
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="ratable_unit">
                                            Product
                                        </label>
                                        <input disabled type="text" id="ratable_unit" pInputText placeholder="Enter Product"
                                            class="p-inputtext" formControlName="mainProduct" disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-p-0">
                            <div class="ui-fluid p-formgrid p-grid">
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="ratable_unit">
                                            IRF Fee
                                        </label>
                                        <input disabled type="text" id="ratable_unit" pInputText placeholder="Enter IRF Fee"
                                            class="p-inputtext" formControlName="mainIrfFee" disabled />
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="ratable_unit">
                                            SF Fee
                                        </label>
                                        <input disabled type="text" id="ratable_unit" pInputText placeholder="Enter SF Fee"
                                            class="p-inputtext" formControlName="mainSfFee" disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-col-12">
                                <h1 class="p-medium display-3">Combo Opportunity Details</h1>
                            </div>
                        </div>
                        <div class="p-col-12 p-p-0">
                            <div class="ui-fluid p-formgrid p-grid">
                                <div class="p-col-8 p-sm-8 p-md-8 p-lg-8 p-xl-8 left">
                                    <div class="p-fluid p-field">
                                        <label for="ratable_unit">
                                            Opportunity Name
                                        </label>
                                        <input disabled type="text" id="ratable_unit" pInputText placeholder="Enter Opportunity Name"
                                            class="p-inputtext" formControlName="comboOppName" disabled />
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="ratable_unit">
                                            Product
                                        </label>
                                        <input disabled type="text" id="ratable_unit" pInputText placeholder="Enter Product"
                                            class="p-inputtext" formControlName="comboProduct" disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-p-0">
                            <div class="ui-fluid p-formgrid p-grid">
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="master_service">
                                            IRF Fee
                                        </label>
                                        <input type="text" id="master_service" pInputText placeholder="Enter IRF Fee"
                                            class="p-inputtext" formControlName="comboIrfFee" disabled />
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="sub_service">
                                            Total IRF Fee
                                        </label>
                                        <input disabled type="text" id="sub_service" pInputText placeholder="Enter Total IRF Fee"
                                            class="p-inputtext" formControlName="comboTotalIrfFee" disabled />
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="ratable_unit">
                                            SF Fee
                                        </label>
                                        <input type="text" id="ratable_unit" pInputText placeholder="Enter SF Fee"
                                            class="p-inputtext" formControlName="comboSfFee" disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-p-0">
                            <div class="ui-fluid p-formgrid p-grid">
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="master_service">
                                            Total SF Fee
                                        </label>
                                        <input disabled type="text" id="master_service" pInputText placeholder="Enter Total SF Fee"
                                            class="p-inputtext" formControlName="comboTotalSfFee" disabled />
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="sub_service">
                                            BD User
                                        </label>
                                        <input disabled type="text" id="sub_service" pInputText placeholder="Enter BD User"
                                            class="p-inputtext" formControlName="comboBdUser" disabled />
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="ratable_unit">
                                            Reported on
                                        </label>
                                        <input disabled type="text" id="ratable_unit" pInputText placeholder="Enter Reported on"
                                            class="p-inputtext" formControlName="comboReportedOn" disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header"><span [ngClass]="{'errorTab': errorClientClass}">Client
                            Classification</span>
                    </ng-template>
                    <div class="p-col-12 p-p-0">
                        <div class="ui-fluid p-formgrid p-grid">
                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                <div class="p-fluid p-field">
                                    <label for="client_Class">
                                        Client Classification
                                    </label>
                                    <input type="text" id="client_Class" pInputText placeholder="Enter Client Classification" class="p-inputtext"
                                        [readonly]="true" [formControl]="clientClass" [ngClass]="{error: qcRejectMap['clientClassification']}" />
                                    <div class="invalid-feedback" *ngIf="errorClientClass && isSaveClicked">
                                        Client classification is required.
                                    </div>
                                    <div class="p-mt-1 p-text-right extra-action-container">
                                        <span class="addLabel" (click)="clientTypeClicked()"><a
                                                class="cursor-pointer">Client Type Master</a></span>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                <div class="p-fluid p-field">
                                    <label for="agreement_class">
                                        Agreement Classification
                                    </label>
                                    <p-dropdown id="agreement_class" placeholder="Enter Agreement Classification" [formControl]="agreementClass"
                                        [options]="clientTypeList" optionLabel="clientTypeName"
                                        [readonly]="disableAgreeClass || disableAllFileds || initiationReadScope || (isIrfInitiated && !qcRejectMap['agreementClassification'])"
                                        (onChange)="agreeClassChanged()" [ngClass]="{error: qcRejectMap['agreementClassification']}">
                                    </p-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid p-mt-2">
                        <div class="p-col-12">
                            <h1 class="p-medium display-3">Tri Party Company</h1>
                        </div>
                    </div>
                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                        <div class="p-fluid p-field">
                            <label for="client_class">
                                Client Classification
                            </label>
                            <input type="text" id="client_class" pInputText placeholder="Enter Client Classification" class="p-inputtext" [readonly]="true" [(ngModel)]="triClass" />
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header"><span [ngClass]="{'errorTab': errorEntityContact}">Entity Contacts</span>
                    </ng-template>
                    <div class="p-col-12">
                        <div class="invalid-feedback" *ngIf="errorEntityContact && !crmContactFetched">
                            Please select CRM contact for Company/Tri party.
                        </div>
                        <p-accordion [activeIndex]="0" collapseIcon="pi pi-minus">
                            <p-accordionTab header="Company Contact">
                                <form [formGroup]="contactForm">
                                    <div class="p-fluid p-formgrid p-grid p-mt-2">
                                        <div class="p-col-12">
                                            <h1 class="p-medium display-3">Basic Info</h1>
                                        </div>
                                        <div class="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-mt-2">
                                            <div class="btnwrap">
                                                <button pButton style="height: 28px"
                                                    class="p-button-secondary p-button-sm" type="button"
                                                    label="Get CRM Data" (click)="getCrm('normal')"
                                                    [disabled]="disableAllFileds || initiationReadScope || (isIrfInitiated && !enableEntityCrm)">
                                                </button>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-p-0 p-mt-3">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="salutation">
                                                            Salutation
                                                        </label>
                                                        <input type="text" id="salutation" formControlName="salutation"
                                                            pInputText class="p-inputtext" [readonly]="true" placeholder="Select" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.salutation?.toLowerCase() == entityCrmContact?.salutation?.toLowerCase()}">
                                                            {{entityCrmContact?.salutation}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="first_name">
                                                            First Name
                                                        </label>
                                                        <input type="text" id="first_name" formControlName="firstName"
                                                            pInputText class="p-inputtext" [readonly]="true" placeholder="Enter First Name" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.firstName?.toLowerCase() == entityCrmContact?.firstName?.toLowerCase()}">
                                                            {{entityCrmContact?.firstName}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="last_name">
                                                            Last Name
                                                        </label>
                                                        <input type="text" id="last_name" formControlName="lastName"
                                                            pInputText class="p-inputtext" [readonly]="true" placeholder="Enter Last Name"/>
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.lastName?.toLowerCase() == entityCrmContact?.lastName?.toLowerCase()}">
                                                            {{entityCrmContact?.lastName}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-p-0">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="designation">
                                                            Designation
                                                        </label>
                                                        <input type="text" id="designation" placeholder="Enter Designation"
                                                            formControlName="designation" pInputText class="p-inputtext" [readonly]="true" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.designation?.toLowerCase() == entityCrmContact?.designation?.toLowerCase()}">
                                                            {{entityCrmContact?.designation}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="mobile_no">
                                                            Mobile No
                                                        </label>
                                                        <input type="text" id="mobile_no" formControlName="mobile" placeholder="Enter Mobile No" pInputText class="p-inputtext" [readonly]="true" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.mobile?.toLowerCase() == entityCrmContact?.mobileNum?.toLowerCase()}">
                                                            {{entityCrmContact?.mobileNum}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="landline_no">
                                                            Landline No
                                                        </label>
                                                        <input type="text" id="landline_no" formControlName="landline" placeholder="Enter Landline No" pInputText class="p-inputtext" [readonly]="true" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.landline?.toLowerCase() == entityCrmContact?.phoneNum?.toLowerCase()}">
                                                            {{entityCrmContact?.phoneNum}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-p-0">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="email_1">
                                                            Email 1
                                                        </label>
                                                        <input type="text" id="email_1" placeholder="Enter Email 1" formControlName="email1" pInputText class="p-inputtext" [readonly]="true" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.email1?.toLowerCase() == entityEmail1?.toLowerCase()}">
                                                            {{entityCrmContact?.email}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="email_2">
                                                            Email 2
                                                        </label>
                                                        <input type="text" id="email_2" formControlName="email2" placeholder="Enter Email 2" pInputText class="p-inputtext" [readonly]="true" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.email2?.toLowerCase() == entityEmail2?.toLowerCase()}">
                                                            {{entityEmail2}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="email_3">
                                                            Email 3
                                                        </label>
                                                        <input type="text" id="email_3" formControlName="email3" placeholder="Enter Email 3" pInputText class="p-inputtext" [readonly]="true" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.email3?.toLowerCase() == entityEmail3?.toLowerCase()}">
                                                            {{entityEmail3}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-mt-1">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                                    <h1 class="p-medium display-3">GST &amp; PAN Details</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-p-0">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="gst_type">
                                                            GST Type
                                                        </label>
                                                        <input type="text" id="gst_type" formControlName="gstType" placeholder="Enter GST Type" pInputText class="p-inputtext" [readonly]="true" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.gstType?.toLowerCase() == entityCrmContact?.gstType?.toLowerCase()}">
                                                            {{entityCrmContact?.gstType}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="gst_no">
                                                            GST No
                                                        </label>
                                                        <input type="text" id="gst_no" formControlName="gstNo" placeholder="Enter GST No" pInputText class="p-inputtext" [readonly]="true" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.gstNo?.toLowerCase() == entityCrmContact?.gstNumber?.toLowerCase()}">
                                                            {{entityCrmContact?.gstNumber}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="pan_no">
                                                            PAN No
                                                        </label>
                                                        <input type="text" id="pan_no" formControlName="panNo" placeholder="Enter PAN No" pInputText class="p-inputtext" [readonly]="true" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.panNo?.toLowerCase() == entityCrmContact?.pan?.toLowerCase()}">
                                                            {{entityCrmContact?.pan}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-mt-1">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                                                    <h1 class="p-medium display-3">Address Details</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-p-0">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="pin_code">
                                                            Pin Code
                                                        </label>
                                                        <input type="text" id="pin_code" formControlName="pinCode" placeholder="Enter Pin Code" pInputText class="p-inputtext" [readonly]="true" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.pinCode?.toLowerCase() == entityCrmContact?.pin?.toLowerCase()}">
                                                            {{entityCrmContact?.pin}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="city">
                                                            City
                                                        </label>
                                                        <input type="text" id="city" formControlName="city" placeholder="Enter City" pInputText class="p-inputtext" [readonly]="true" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.city?.toLowerCase() == entityCrmContact?.city?.toLowerCase()}">
                                                            {{entityCrmContact?.city}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="state">
                                                            State
                                                        </label>
                                                        <input type="text" id="state" formControlName="state" placeholder="Enter State" pInputText class="p-inputtext" [readonly]="true" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.state?.toLowerCase() == entityCrmContact?.state?.toLowerCase()}">
                                                            {{entityCrmContact?.state}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-p-0">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="addressLine1">
                                                            Address Line 1
                                                        </label>
                                                        <input type="text" id="addressLine1" formControlName="address1" placeholder="Enter Address Line 1" pInputText class="p-inputtext" [readonly]="true" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.address1?.toLowerCase() == entityCrmContact?.address1?.toLowerCase()}">
                                                            {{entityCrmContact?.address1}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="addressLine2">
                                                            Address Line 2
                                                        </label>
                                                        <input type="text" id="addressLine2" formControlName="address2" placeholder="Enter Address Line 2" pInputText class="p-inputtext" [readonly]="true" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.address2?.toLowerCase() == entityCrmContact?.address2?.toLowerCase()}">
                                                            {{entityCrmContact?.address2}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="addressLine3">
                                                            Address Line 3
                                                        </label>
                                                        <input type="text" id="addressLine3" formControlName="address3" placeholder="Enter Address Line 3" pInputText class="p-inputtext" [readonly]="true" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.address3?.toLowerCase() == entityCrmContact?.address3?.toLowerCase()}">
                                                            {{entityCrmContact?.address3}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-p-0">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="addressLine4">
                                                            Address Line 4
                                                        </label>
                                                        <input type="text" id="addressLine4" formControlName="address4" placeholder="Enter Address Line 4" pInputText class="p-inputtext" [readonly]="true" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': contactForm?.value?.address4?.toLowerCase() == entityCrmContact?.address4?.toLowerCase()}">
                                                            {{entityCrmContact?.address4}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </p-accordionTab>
                        </p-accordion>
                    </div>
                    <div class="p-col-12">
                        <p-accordion [activeIndex]="0" collapseIcon="pi pi-minus">
                            <p-accordionTab header="Tri Party Contact">
                                <form [formGroup]="triContactForm">
                                    <div class="p-fluid p-formgrid p-grid p-mt-2">
                                        <div class="p-col-12">
                                            <h1 class="p-medium display-3">Basic Info</h1>
                                        </div>
                                        <div class="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-mt-2">
                                            <div class="btnwrap">
                                                <button pButton style="height: 28px"
                                                    class="p-button-secondary p-button-sm" type="button"
                                                    label="Get CRM Data" (click)="getCrm('tri')"
                                                    [disabled]="disableAllFileds || initiationReadScope || (isIrfInitiated && !enabletriCrm)">
                                                </button>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-p-0 p-mt-3">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="salutation_tri">
                                                            Salutation
                                                        </label>
                                                        <input type="text" id="salutation_tri" pInputText placeholder="Select"
                                                            class="p-inputtext" disabled formControlName="salutation" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.salutation?.toLowerCase() == triCrmContact?.salutation?.toLowerCase()}">
                                                            {{triCrmContact?.salutation}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="first_name_tri">
                                                            First Name
                                                        </label>
                                                        <input type="text" id="first_name_tri" pInputText  placeholder="Enter First Name"
                                                            class="p-inputtext" disabled formControlName="firstName" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.firstName?.toLowerCase() == triCrmContact?.firstName?.toLowerCase()}">
                                                            {{triCrmContact?.firstName}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="last_name_tri">
                                                            Last Name
                                                        </label>
                                                        <input type="text" id="last_name_tri" pInputText placeholder="Enter Last Name"
                                                            class="p-inputtext" disabled formControlName="lastName" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.lastName?.toLowerCase() == triCrmContact?.lastName?.toLowerCase()}">
                                                            {{triCrmContact?.lastName}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-p-0">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="designation_tri">
                                                            Designation
                                                        </label>
                                                        <input type="text" id="designation_tri" pInputText
                                                            class="p-inputtext" disabled placeholder="Enter Designation"
                                                            formControlName="designation" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.designation?.toLowerCase() == triCrmContact?.designation?.toLowerCase()}">
                                                            {{triCrmContact?.designation}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="mobile_no_tri">
                                                            Mobile No
                                                        </label>
                                                        <input type="text" id="mobile_no_tri" pInputText placeholder="Enter Mobile No"
                                                            class="p-inputtext" disabled formControlName="mobile" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.mobile?.toLowerCase() == triCrmContact?.mobileNum?.toLowerCase()}">
                                                            {{triCrmContact?.mobileNum}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="landline_no_tri">
                                                            Landline No
                                                        </label>
                                                        <input type="text" id="landline_no_tri" pInputText placeholder="Enter Landline No"
                                                            class="p-inputtext" disabled formControlName="landline" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.landline?.toLowerCase() == triCrmContact?.phoneNum?.toLowerCase()}">
                                                            {{triCrmContact?.phoneNum}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-p-0">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="email_1_tri">
                                                            Email 1
                                                        </label>
                                                        <input type="text" id="email_1_tri" pInputText placeholder="Enter Email 1"
                                                            class="p-inputtext" disabled formControlName="email1" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.email1?.toLowerCase() == triEmail1?.toLowerCase()}">
                                                            {{triCrmContact?.email}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="email_2_tri">
                                                            Email 2
                                                        </label>
                                                        <input type="text" id="email_2_tri" pInputText placeholder="Enter Email 2"
                                                            class="p-inputtext" disabled formControlName="email2" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.email2?.toLowerCase() == triEmail2?.toLowerCase()}">
                                                            {{triEmail2}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="email_3_tri">
                                                            Email 3
                                                        </label>
                                                        <input type="text" id="email_3_tri" pInputText placeholder="Enter Email 3"
                                                            class="p-inputtext" disabled formControlName="email3" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.email3?.toLowerCase() == triEmail3?.toLowerCase()}">
                                                            {{triEmail3}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-mt-1">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <h1 class="p-medium display-3">GST &amp; PAN Details</h1>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-p-0">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="gst_type_tri">
                                                            GST Type
                                                        </label>
                                                        <input type="text" id="gst_type_tri" pInputText placeholder="Enter GST Type"
                                                            class="p-inputtext" disabled formControlName="gstType" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.gstType?.toLowerCase() == triCrmContact?.gstType?.toLowerCase()}">
                                                            {{triCrmContact?.gstType}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="gst_no_tri">
                                                            GST No
                                                        </label>
                                                        <input type="text" id="gst_no_tri" pInputText placeholder="Enter GST No"
                                                            class="p-inputtext" disabled formControlName="gstNo" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.gstNo?.toLowerCase() == triCrmContact?.gstNumber?.toLowerCase()}">
                                                            {{triCrmContact?.gstNumber}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="pan_no_tri">
                                                            PAN No
                                                        </label>
                                                        <input type="text" id="pan_no_tri" pInputText placeholder="Enter PAN No"
                                                            class="p-inputtext" disabled formControlName="panNo" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.panNo?.toLowerCase() == triCrmContact?.pan?.toLowerCase()}">
                                                            {{triCrmContact?.pan}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-mt-1">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <h1 class="p-medium display-3">Address Details</h1>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-p-0">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="pin_code_tri">
                                                            Pin Code
                                                        </label>
                                                        <input type="text" id="pin_code_tri" pInputText placeholder="Enter Pin Code"
                                                            class="p-inputtext" disabled formControlName="pinCode" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.pinCode?.toLowerCase() == triCrmContact?.pin?.toLowerCase()}">
                                                            {{triCrmContact?.pin}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="city_tri">
                                                            City
                                                        </label>
                                                        <input disabled type="text" id="city_tri" pInputText placeholder="Enter City"
                                                            class="p-inputtext" disabled formControlName="city" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.city?.toLowerCase() == triCrmContact?.city?.toLowerCase()}">
                                                            {{triCrmContact?.city}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="state_tri">
                                                            State
                                                        </label>
                                                        <input type="text" id="state_tri" pInputText class="p-inputtext" placeholder="Enter State"
                                                            disabled formControlName="state" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.state?.toLowerCase() == triCrmContact?.state?.toLowerCase()}">
                                                            {{triCrmContact?.state}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-p-0">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="addressLine1_tri">
                                                            Address Line 1
                                                        </label>
                                                        <input type="text" id="addressLine1_tri" pInputText placeholder="Enter Address Line 1"
                                                            class="p-inputtext" disabled formControlName="address1" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.address1?.toLowerCase() == triCrmContact?.address1?.toLowerCase()}">
                                                            {{triCrmContact?.address1}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="addressLine2_tri">
                                                            Address Line 2
                                                        </label>
                                                        <input type="text" id="addressLine2_tri" pInputText placeholder="Enter Address Line 2"
                                                            class="p-inputtext" disabled formControlName="address2" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.address2?.toLowerCase() == triCrmContact?.address2?.toLowerCase()}">
                                                            {{triCrmContact?.address2}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="addressLine3_tri">
                                                            Address Line 3
                                                        </label>
                                                        <input type="text" id="addressLine3_tri" pInputText placeholder="Enter Address Line 3"
                                                            class="p-inputtext" disabled formControlName="address3" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.address3?.toLowerCase() == triCrmContact?.address3?.toLowerCase()}">
                                                            {{triCrmContact?.address3}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-p-0">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <label for="addressLine4_tri">
                                                            Address Line 4
                                                        </label>
                                                        <input type="text" id="addressLine4_tri" pInputText placeholder="Enter Address Line 4"
                                                            class="p-inputtext" disabled formControlName="address4" />
                                                        <div class="p-d-flex p-jc-start invalid-feedback"
                                                            [ngClass]="{'black-color': triContactForm?.value?.address4?.toLowerCase() == triCrmContact?.address4?.toLowerCase()}">
                                                            {{triCrmContact?.address4}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </p-accordionTab>
                        </p-accordion>
                    </div>
                    <div class="p-col-12 p-d-flex p-ai-center">
                        <p-checkbox binary="true" [(ngModel)]="contactChecked"
                            [disabled]="isIrfInitiated || disableAllFileds || initiationReadScope"
                            inputId="binary"></p-checkbox>
                        <span class="p-ml-2">Contact Details are correct and sourced from CRM.
                            <span class='invalid-feedback-astrik'>*</span>
                        </span>
                    </div>
                    <div class="invalid-feedback p-col-12" *ngIf="!contactChecked && errorEntityContact">
                        Please tick the checkbox.
                    </div>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header"><span>Opportunity Details</span>
                    </ng-template>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-col-12">
                            <h1 class="p-medium display-3">Opportunity Information</h1>
                        </div>
                    </div>
                    <div class="p-col-12 p-p-0 p-mt-1">
                        <div class="ui-fluid p-formgrid p-grid">
                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                <div class="p-fluid p-field">
                                    <label for="agreement_id">
                                        Agreement Id
                                    </label>
                                    <input type="text" id="agreement_id" pInputText placeholder="Enter Agreement Id"
                                        [(ngModel)]="agreementId" class="p-inputtext" disabled />
                                </div>
                            </div>
                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                <div class="p-fluid p-field">
                                    <label for="qc_status">
                                        QC Status
                                    </label>
                                    <input type="text" id="qc_status" pInputText placeholder="Enter QC Status" class="p-inputtext"
                                        [(ngModel)]="qcStatus" disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header"><span [ngClass]="{'errorTab': errorFeeCap}">Fee Cap</span>
                    </ng-template>
                    <ng-container *ngIf="!feeCapMissing">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-d-flex p-px-2">
                                <h1 class="p-mb-0 p-mr-2 p-regular display-4 fitcontent">Fee Cap:</h1>
                                <h1 class="p-mb-0 p-medium display-4 fitcontent">Fee Cap Client</h1>
                            </div>
                        </div>
                        <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                            <form [formGroup]="feeCapForm">
                                <div class="p-fluid p-field">
                                    <label for="tag_mandate">
                                        Tag Mandate to Fee Cap<span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <p-dropdown id="tag_mandate" placeholder="Select" [options]="feecapTagList"
                                        [disabled]="feeCapDisable && !qcRejectMap['mandateFeeCao']" [autoDisplayFirst]="false" optionLabel="property"
                                        optionValue="property" formControlName="tagMandate" [ngClass]="{'error': (feeCapForm.get('tagMandate')?.dirty && feeCapForm.get('tagMandate')?.errors?.required && feeCapForm.get('tagMandate')?.invalid) || qcRejectMap['mandateFeeCao']}">
                                    </p-dropdown>
                                </div>
                            </form>
                        </div>
                        <div class="p-col-12">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-p-0 p-col-12" >
                                    <p-table [scrollable]="true" scrollHeight="10%" scrollDirection="both"
                                        [value]="feeCapData">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th scope="col" style="max-width:33%;min-width:33%">Company Name</th>
                                                <th scope="col" style="max-width:33%;min-width:33%">Group</th>
                                                <th scope="col" style="max-width:34%;min-width:34%">Fee Cap Validity</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-rowData>
                                            <tr>
                                                <td style="max-width:33%;min-width:33%">{{oppGridDetials?.name}}</td>
                                                <td style="max-width:33%;min-width:33%">{{rowData.group}}</td>
                                                <td style="max-width:34%;min-width:34%">{{rowData.validTo}}</td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="feeCapMissing">
                        <div class="p-col-12 p-d-flex p-jc-center p-ai-center p-flex-column">
                            <div class="p-mt-6 p-mb-3">
                                <svg width="204" height="124" class="illustrationLight" viewBox="0 0 204 124"
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M194.286 18.2354C198.98 18.2354 202.786 22.0453 202.786 26.7452C202.786 31.445 198.98 35.255 194.286 35.255H145.714C150.409 35.255 154.214 39.0649 154.214 43.7648C154.214 48.4646 150.409 52.2746 145.714 52.2746H172.429C177.123 52.2746 180.929 56.0845 180.929 60.7844C180.929 65.4842 177.123 69.2942 172.429 69.2942H160.075C154.155 69.2942 149.357 73.1041 149.357 77.804C149.357 80.9372 151.786 83.7738 156.643 86.3138C161.337 86.3138 165.143 90.1238 165.143 94.8236C165.143 99.5234 161.337 103.333 156.643 103.333H55.8571C51.1627 103.333 47.3571 99.5234 47.3571 94.8236C47.3571 90.1238 51.1627 86.3138 55.8571 86.3138H8.5C3.80558 86.3138 0 82.5038 0 77.804C0 73.1041 3.80558 69.2942 8.5 69.2942H57.0714C61.7659 69.2942 65.5714 65.4842 65.5714 60.7844C65.5714 56.0845 61.7659 52.2746 57.0714 52.2746H26.7143C22.0199 52.2746 18.2143 48.4646 18.2143 43.7648C18.2143 39.0649 22.0199 35.255 26.7143 35.255H75.2857C70.5913 35.255 66.7857 31.445 66.7857 26.7452C66.7857 22.0453 70.5913 18.2354 75.2857 18.2354H194.286ZM194.286 52.2746C198.98 52.2746 202.786 56.0845 202.786 60.7844C202.786 65.4842 198.98 69.2942 194.286 69.2942C189.591 69.2942 185.786 65.4842 185.786 60.7844C185.786 56.0845 189.591 52.2746 194.286 52.2746Z"
                                        fill="#EDEFF6" />
                                    <mask id="path-2-inside-1_368_11471" fill="white">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M129.679 17.0195L140.987 99.495L142.003 107.769C142.331 110.435 140.435 112.861 137.769 113.188L66.5786 121.93C63.913 122.257 61.4868 120.361 61.1595 117.696L50.1991 28.4308C50.0355 27.098 50.9833 25.8849 52.3161 25.7213C52.3245 25.7202 52.3329 25.7192 52.3414 25.7183L74.7643 23.0979" />
                                    </mask>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M129.679 17.0195L140.987 99.495L142.003 107.769C142.331 110.435 140.435 112.861 137.769 113.188L66.5786 121.93C63.913 122.257 61.4868 120.361 61.1595 117.696L50.1991 28.4308C50.0355 27.098 50.9833 25.8849 52.3161 25.7213C52.3245 25.7202 52.3329 25.7192 52.3414 25.7183L74.7643 23.0979"
                                        fill="white" />
                                    <path
                                        d="M132.088 16.6892C131.906 15.3588 130.679 14.4282 129.349 14.6106C128.019 14.793 127.088 16.0194 127.27 17.3498L132.088 16.6892ZM140.987 99.495L143.401 99.1987C143.399 99.1873 143.398 99.176 143.396 99.1647L140.987 99.495ZM142.003 107.769L144.417 107.473L142.003 107.769ZM137.769 113.188L138.066 115.602L137.769 113.188ZM66.5786 121.93L66.8749 124.343L66.5786 121.93ZM61.1595 117.696L63.5727 117.399L61.1595 117.696ZM52.3414 25.7183L52.6124 28.1345L52.6236 28.1332L52.3414 25.7183ZM75.0465 25.5128C76.3802 25.357 77.3351 24.1494 77.1792 22.8157C77.0234 21.4819 75.8158 20.5271 74.4821 20.683L75.0465 25.5128ZM127.27 17.3498L138.579 99.8253L143.396 99.1647L132.088 16.6892L127.27 17.3498ZM138.574 99.7913L139.59 108.066L144.417 107.473L143.401 99.1987L138.574 99.7913ZM139.59 108.066C139.754 109.398 138.806 110.612 137.473 110.775L138.066 115.602C142.064 115.111 144.908 111.471 144.417 107.473L139.59 108.066ZM137.473 110.775L66.2823 119.516L66.8749 124.343L138.066 115.602L137.473 110.775ZM66.2823 119.516C64.9495 119.68 63.7364 118.732 63.5727 117.399L58.7462 117.992C59.2372 121.99 62.8765 124.834 66.8749 124.343L66.2823 119.516ZM63.5727 117.399L52.6124 28.1345L47.7859 28.7271L58.7462 117.992L63.5727 117.399ZM52.6124 28.1345L52.6124 28.1345L52.0198 23.308C49.3542 23.6353 47.4586 26.0615 47.7859 28.7271L52.6124 28.1345ZM52.6124 28.1345L52.0703 23.3021C52.0535 23.304 52.0366 23.306 52.0198 23.308L52.6124 28.1345ZM52.6236 28.1332L75.0465 25.5128L74.4821 20.683L52.0591 23.3034L52.6236 28.1332Z"
                                        fill="#9DC0FB" mask="url(#path-2-inside-1_368_11471)" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M126.601 22.2093L136.837 96.9542L137.757 104.453C138.054 106.869 136.359 109.064 133.972 109.358L70.2192 117.185C67.8321 117.479 65.6565 115.758 65.3599 113.342L55.4521 32.6491C55.2884 31.3163 56.2362 30.1032 57.569 29.9395L65.4559 28.9711"
                                        fill="#EBF3FE" />
                                    <path
                                        d="M73.0008 4.86274C73.0008 2.84853 74.6337 1.21569 76.6479 1.21569H132.031C132.998 1.21569 133.925 1.59962 134.609 2.28309L135.456 1.43497L134.609 2.28309L150.951 18.6157C151.636 19.2998 152.02 20.2277 152.02 21.1954V97.2549C152.02 99.2691 150.388 100.902 148.373 100.902H76.6479C74.6337 100.902 73.0008 99.2691 73.0008 97.2549V4.86274Z"
                                        fill="white" stroke="#76A7F9" stroke-width="2.43137" />
                                    <path
                                        d="M133.327 2.43164V17.0199C133.327 19.0341 134.96 20.6669 136.974 20.6669H151.353"
                                        stroke="#76A7F9" stroke-width="2.43137" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M86.374 21.8823H117.982" stroke="#9DC0FB" stroke-width="2.43137"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M86.314 80.2354H138.588" stroke="#9DC0FB" stroke-width="2.43137"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M86.374 36.4707H138.649" stroke="#9DC0FB" stroke-width="2.43137"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M86.374 51.0586H138.649" stroke="#9DC0FB" stroke-width="2.43137"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M86.374 65.647H138.649" stroke="#9DC0FB" stroke-width="2.43137"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <svg width="204" height="124" class="illustrationDark" viewBox="0 0 204 124" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M194.286 18.2354C198.98 18.2354 202.786 22.0453 202.786 26.7452C202.786 31.445 198.98 35.255 194.286 35.255H145.714C150.409 35.255 154.214 39.0649 154.214 43.7648C154.214 48.4646 150.409 52.2746 145.714 52.2746H172.429C177.123 52.2746 180.929 56.0845 180.929 60.7844C180.929 65.4842 177.123 69.2942 172.429 69.2942H160.075C154.155 69.2942 149.357 73.1041 149.357 77.804C149.357 80.9372 151.786 83.7738 156.643 86.3138C161.337 86.3138 165.143 90.1237 165.143 94.8236C165.143 99.5234 161.337 103.333 156.643 103.333H55.8571C51.1627 103.333 47.3571 99.5234 47.3571 94.8236C47.3571 90.1237 51.1627 86.3138 55.8571 86.3138H8.5C3.80558 86.3138 0 82.5038 0 77.804C0 73.1041 3.80558 69.2942 8.5 69.2942H57.0714C61.7659 69.2942 65.5714 65.4842 65.5714 60.7844C65.5714 56.0845 61.7659 52.2746 57.0714 52.2746H26.7143C22.0199 52.2746 18.2143 48.4646 18.2143 43.7648C18.2143 39.0649 22.0199 35.255 26.7143 35.255H75.2857C70.5913 35.255 66.7857 31.445 66.7857 26.7452C66.7857 22.0453 70.5913 18.2354 75.2857 18.2354H194.286ZM194.286 52.2746C198.98 52.2746 202.786 56.0845 202.786 60.7844C202.786 65.4842 198.98 69.2942 194.286 69.2942C189.591 69.2942 185.786 65.4842 185.786 60.7844C185.786 56.0845 189.591 52.2746 194.286 52.2746Z"
                                        fill="#2C2C2C" />
                                    <mask id="path-2-inside-1_422_8539" fill="white">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M129.679 17.02L140.987 99.4955L142.003 107.77C142.33 110.435 140.435 112.862 137.769 113.189L66.5781 121.93C63.9125 122.257 61.4863 120.362 61.159 117.696L50.1987 28.4313C50.035 27.0985 50.9828 25.8854 52.3156 25.7218C52.324 25.7207 52.3324 25.7197 52.3409 25.7188L74.7638 23.0984" />
                                    </mask>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M129.679 17.02L140.987 99.4955L142.003 107.77C142.33 110.435 140.435 112.862 137.769 113.189L66.5781 121.93C63.9125 122.257 61.4863 120.362 61.159 117.696L50.1987 28.4313C50.035 27.0985 50.9828 25.8854 52.3156 25.7218C52.324 25.7207 52.3324 25.7197 52.3409 25.7188L74.7638 23.0984"
                                        fill="#141414" />
                                    <path
                                        d="M132.088 16.6897C131.905 15.3593 130.679 14.4287 129.348 14.6111C128.018 14.7935 127.087 16.0199 127.27 17.3502L132.088 16.6897ZM140.987 99.4955L143.4 99.1992C143.399 99.1878 143.397 99.1765 143.396 99.1652L140.987 99.4955ZM142.003 107.77L144.416 107.473L142.003 107.77ZM137.769 113.189L138.065 115.602L137.769 113.189ZM66.5781 121.93L66.8744 124.343L66.5781 121.93ZM61.159 117.696L63.5722 117.4L61.159 117.696ZM50.1987 28.4313L47.7854 28.7276L50.1987 28.4313ZM52.3409 25.7188L52.6119 28.135L52.6231 28.1337L52.3409 25.7188ZM75.046 25.5133C76.3797 25.3575 77.3346 24.1499 77.1787 22.8162C77.0229 21.4824 75.8153 20.5276 74.4816 20.6834L75.046 25.5133ZM127.27 17.3502L138.578 99.8257L143.396 99.1652L132.088 16.6897L127.27 17.3502ZM138.574 99.7918L139.59 108.066L144.416 107.473L143.4 99.1992L138.574 99.7918ZM139.59 108.066C139.753 109.399 138.805 110.612 137.473 110.776L138.065 115.602C142.064 115.111 144.907 111.472 144.416 107.473L139.59 108.066ZM137.473 110.776L66.2818 119.517L66.8744 124.343L138.065 115.602L137.473 110.776ZM66.2818 119.517C64.949 119.68 63.7359 118.733 63.5722 117.4L58.7458 117.992C59.2367 121.991 62.876 124.834 66.8744 124.343L66.2818 119.517ZM63.5722 117.4L52.6119 28.135L47.7854 28.7276L58.7458 117.992L63.5722 117.4ZM52.6119 28.135L52.6119 28.135L52.0193 23.3085C49.3537 23.6358 47.4581 26.062 47.7854 28.7276L52.6119 28.135ZM52.6119 28.135L52.0698 23.3026C52.053 23.3045 52.0361 23.3064 52.0193 23.3085L52.6119 28.135ZM52.6231 28.1337L75.046 25.5133L74.4816 20.6834L52.0587 23.3038L52.6231 28.1337Z"
                                        fill="#9DC0FB" mask="url(#path-2-inside-1_422_8539)" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M126.601 22.2093L136.837 96.9542L137.757 104.453C138.054 106.869 136.359 109.064 133.972 109.358L70.2192 117.185C67.8321 117.479 65.6565 115.758 65.3599 113.342L55.4521 32.6491C55.2884 31.3163 56.2362 30.1032 57.569 29.9395L65.4559 28.9711"
                                        fill="#233655" />
                                    <path
                                        d="M73.0004 4.86274C73.0004 2.84853 74.6332 1.21569 76.6474 1.21569H132.03C132.997 1.21569 133.924 1.59962 134.608 2.28309L135.468 1.4232L134.608 2.28309L150.951 18.6157C151.635 19.2998 152.02 20.2277 152.02 21.1954V97.2549C152.02 99.2691 150.387 100.902 148.373 100.902H76.6474C74.6332 100.902 73.0004 99.2691 73.0004 97.2549V4.86274Z"
                                        fill="#141414" stroke="#76A7F9" stroke-width="2.43137" />
                                    <path
                                        d="M133.326 2.43115V17.0194C133.326 19.0336 134.959 20.6664 136.973 20.6664H151.353"
                                        stroke="#76A7F9" stroke-width="2.43137" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M86.373 21.8823H117.981" stroke="#9DC0FB" stroke-width="2.43137"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M86.314 80.2354H138.588" stroke="#9DC0FB" stroke-width="2.43137"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M86.373 36.4707H138.648" stroke="#9DC0FB" stroke-width="2.43137"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M86.373 51.0586H138.648" stroke="#9DC0FB" stroke-width="2.43137"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M86.373 65.647H138.648" stroke="#9DC0FB" stroke-width="2.43137"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <p>No Fee Cap Available</p>
                            <div class="btnwrap">
                                <button pButton style="height: 28px"
                                    class="p-button-secondary p-button-sm" [disabled]="!errorFeeCap" type="button" label="Add Fee Cap"
                                    (click)="navigateToFeeCap()">
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <span [ngClass]="{'errorTab': errorOtherKey}">
                            Other Key Details
                        </span>
                    </ng-template>
                    <form [formGroup]="otherKeyForm">
                        <p-toast key="tabAckMsg" class="custom-toast"></p-toast>
                        <div class="p-col-12 p-p-0">
                            <div class="ui-fluid p-formgrid p-grid">
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="master_service">
                                            Master Service (MS)
                                        </label>
                                        <input type="text" id="master_service" pInputText placeholder="Enter Master Service (MS)"
                                            class="p-inputtext" disabled formControlName="masterService" />
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="sub_service">
                                            Sub Service (SS)
                                        </label>
                                        <input type="text" id="sub_service" pInputText placeholder="Enter Sub Service (SS)"
                                            class="p-inputtext" disabled formControlName="subService" />
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="ratable_unit">
                                            Ratable Unit (RU)
                                        </label>
                                        <input type="text" id="ratable_unit" pInputText placeholder="Enter Ratable Unit (RU)"
                                            class="p-inputtext" disabled formControlName="ratableUnit" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="showMF">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-col-12">
                                    <h1 class="p-medium display-3">Mutual Fund Details</h1>
                                </div>
                            </div>
                            <div class="p-col-12 p-p-0">
                                <div class="ui-fluid p-formgrid p-grid">
                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                        <div class="p-fluid p-field">
                                            <label for="mandate_date">
                                                AMC Name<span class='invalid-feedback-astrik'>*</span>
                                            </label>
                                            <p-dropdown id="amcName" placeholder="Select" [options]="amcNameList" formControlName="amcName"
                                                [disabled]="disableAllFileds || initiationReadScope || (isIrfInitiated && !qcRejectMap['amcName'])"    
                                                [autoDisplayFirst]="false" optionLabel="value" (onChange)="getMFScheme($event)"
                                                [ngClass]="{'error':(otherKeyForm.get('amcName')?.dirty && otherKeyForm.get('amcName')?.errors?.required && otherKeyForm.get('amcName')?.invalid) || qcRejectMap['amcName']}">
                                            </p-dropdown>
                                            
                                            <div class="invalid-feedback"
                                            *ngIf="(otherKeyForm.get('amcName')?.dirty && otherKeyForm.get('amcName')?.errors?.required && otherKeyForm.get('amcName')?.invalid)">
                                            AMC Code is required</div>
                                        </div>
                                    </div>
                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                        <div class="p-fluid p-field">
                                            <label for="mandate_date">
                                                Mutual Fund Scheme<span class='invalid-feedback-astrik'>*</span>
                                            </label>
                                            <p-dropdown id="mfScheme" placeholder="Select" [options]="mfList" formControlName="mfSchemeName"
                                                [autoDisplayFirst]="false" optionLabel="value" (onChange)="setMFParentId($event)"
                                                [disabled]="disableAllFileds || initiationReadScope || (isIrfInitiated && !qcRejectMap['mutualFundSchemeName'])"
                                                [ngClass]="{'error':((otherKeyForm.get('mfSchemeName')?.dirty && otherKeyForm.get('mfSchemeName')?.errors?.required && otherKeyForm.get('mfSchemeName')?.invalid)) || qcRejectMap['mutualFundSchemeName']}">
                                            </p-dropdown>
                                            <div class="invalid-feedback"
                                            *ngIf="(otherKeyForm.get('mfSchemeName')?.dirty && otherKeyForm.get('mfSchemeName')?.errors?.required && otherKeyForm.get('mfSchemeName')?.invalid)">
                                            Mutual Fund Scheme name is required</div>
                                        </div>
                                    </div>
                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                        <div class="p-fluid p-field">
                                            <label for="invoice_value">AMC Code</label>
                                            <input disabled type="text" id="amc_code" pInputText placeholder="Enter AMC Code" formControlName="amcCode"
                                                class="p-inputtext" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-col-12">
                                <h1 class="p-medium display-3">Mandate Details</h1>
                            </div>
                        </div>
                        <div class="p-col-12 p-p-0">
                            <div class="ui-fluid p-formgrid p-grid">
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="mandate_date">
                                            Mandate Date<span class='invalid-feedback-astrik'>*</span>
                                        </label>
                                        <p-calendar [showIcon]="true" id="mandate_date" yearRange="2000:2030"
                                            dateFormat="dd-mm-yy" hourFormat="12" [readonlyInput]="true"
                                            name="paymentDate" [hideOnDateTimeSelect]="true" [stepHour]="1"
                                            [stepMinute]="1" (onSelect)="dateSelected()" [showButtonBar]="true"
                                            [maxDate]="maxDateLimit" formControlName="mandateDate" placeholder="Select"
                                            [disabled]="(isIrfInitiated && !qcRejectMap['mandateDate']) || disableAllFileds || initiationReadScope"
                                            [ngClass]="{'error':(otherKeyForm.get('mandateDate')?.dirty && otherKeyForm.get('mandateDate')?.errors?.required && otherKeyForm.get('mandateDate')?.invalid) || qcRejectMap['mandateDate']}">
                                        </p-calendar>
                                        <div class="invalid-feedback"
                                            *ngIf="(otherKeyForm.get('mandateDate')?.dirty && otherKeyForm.get('mandateDate')?.errors?.required && otherKeyForm.get('mandateDate')?.invalid)">
                                            Mandate date is required</div>
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="client_date">
                                            Client Signed Date<span class='invalid-feedback-astrik'>*</span>
                                        </label>
                                        <p-calendar [showIcon]="true" id="client_date" yearRange="2000:2030"
                                            dateFormat="dd-mm-yy" hourFormat="12" [readonlyInput]="true"
                                            [ngClass]="{'error': clientSignedError}" name="paymentDate"
                                            [hideOnDateTimeSelect]="true" [stepHour]="1" [stepMinute]="1"
                                            (onSelect)="dateSelected()" [showButtonBar]="true" [maxDate]="maxDateLimit"
                                            formControlName="clientSignedDate" placeholder="Select"
                                            [disabled]="disableAllFileds || initiationReadScope || (isIrfInitiated && !qcRejectMap['clientSignedDate'])"
                                            [ngClass]="{'error':(otherKeyForm.get('clientSignedDate')?.dirty && otherKeyForm.get('clientSignedDate')?.errors?.required && otherKeyForm.get('clientSignedDate')?.invalid) || qcRejectMap['clientSignedDate']}">
                                        </p-calendar>
                                        <div class="invalid-feedback" *ngIf="clientSignedError">
                                            Client Signed date should be greater than or equal to Mandate sent to client
                                            date.
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="(otherKeyForm.get('clientSignedDate')?.dirty && otherKeyForm.get('clientSignedDate')?.errors?.required && otherKeyForm.get('clientSignedDate')?.invalid)">
                                            Client signed date is required</div>
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="crisil_date">
                                            Crisil Signed Date<span class='invalid-feedback-astrik'>*</span>
                                        </label>
                                        <p-calendar [showIcon]="true" id="client_date" yearRange="2000:2030"
                                            dateFormat="dd-mm-yy" hourFormat="12" [readonlyInput]="true"
                                            [ngClass]="{'error': crisilSignedError}" name="paymentDate"
                                            [hideOnDateTimeSelect]="true" [stepHour]="1" [stepMinute]="1"
                                            (onSelect)="dateSelected()" [showButtonBar]="true" [maxDate]="maxDateLimit"
                                            formControlName="crisilSignedDate" placeholder="Select"
                                            [disabled]="disableAllFileds || initiationReadScope || (isIrfInitiated && !qcRejectMap['crisilSignedDate'])"
                                            [ngClass]="{'error': (otherKeyForm.get('crisilSignedDate')?.dirty && otherKeyForm.get('crisilSignedDate')?.errors?.required && otherKeyForm.get('crisilSignedDate')?.invalid) || qcRejectMap['crisilSignedDate']}">
                                        </p-calendar>
                                        <div class="invalid-feedback" *ngIf="crisilSignedError">
                                            Crisil Signed date should be greater than or equal to Mandate date.
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="(otherKeyForm.get('crisilSignedDate')?.dirty && otherKeyForm.get('crisilSignedDate')?.errors?.required && otherKeyForm.get('crisilSignedDate')?.invalid)">
                                            Crisil signed date is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-p-0">
                            <div class="ui-fluid p-formgrid p-grid">
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="mandate_sent">
                                            Mandate Sent To Client Date<span class='invalid-feedback-astrik'>*</span>
                                        </label>
                                        <p-calendar [showIcon]="true" id="mandate_sent" yearRange="2000:2030"
                                            dateFormat="dd-mm-yy" hourFormat="12" [readonlyInput]="true"
                                            [ngClass]="{'error': sentToClientError}" name="paymentDate"
                                            [hideOnDateTimeSelect]="true" [stepHour]="1" [stepMinute]="1"
                                            (onSelect)="dateSelected()" [showButtonBar]="true" [maxDate]="maxDateLimit"
                                            formControlName="sentToClient" placeholder="Select"
                                            [disabled]="disableAllFileds || initiationReadScope || (isIrfInitiated && !qcRejectMap['sentToClientDate'])"
                                            [ngClass]="{'error': (otherKeyForm.get('sentToClient')?.dirty && otherKeyForm.get('sentToClient')?.errors?.required && otherKeyForm.get('sentToClient')?.invalid) || qcRejectMap['sentToClientDate']}">
                                        </p-calendar>
                                        <div class="invalid-feedback" *ngIf="sentToClientError">
                                            Mandate Sent to client date should be lower than or equal to Client Signed
                                            date.
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="(otherKeyForm.get('sentToClient')?.dirty && otherKeyForm.get('sentToClient')?.errors?.required && otherKeyForm.get('sentToClient')?.invalid)">
                                            Mandate Sent to client date is required
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="mandate_recieved">
                                            Mandate Recieved From Client Date
                                        </label>
                                        <p-calendar [showIcon]="true" id="mandate_recieved" yearRange="2000:2030"
                                            dateFormat="dd-mm-yy" hourFormat="12" [readonlyInput]="true"
                                            [ngClass]="{'error': recievedFromClientError}" name="paymentDate"
                                            [hideOnDateTimeSelect]="true" [stepHour]="1" [stepMinute]="1" placeholder="Select"
                                            (onSelect)="dateSelected()" [showButtonBar]="true" [maxDate]="maxDateLimit"
                                            formControlName="recievedFromClient" [ngClass]="{error: qcRejectMap['mandateReceivedDate']}"
                                            [disabled]="disableAllFileds || initiationReadScope || (isIrfInitiated && !qcRejectMap['mandateReceivedDate'])">
                                        </p-calendar>
                                        <div class="invalid-feedback" *ngIf="recievedFromClientError">
                                            Mandate Recieved from client date should be greater than or equal to Client
                                            Signed date.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="p-fluid p-formgrid p-grid">
                            <div class="p-col-12">
                                <h1 class="p-medium display-3">Mandate Type</h1>
                            </div>
                        </div>
                        <div class="p-col-12 p-p-0 p-mt-1">
                            <div class="ui-fluid p-formgrid p-grid">
                                <div class="p-col-2 p-sm-2 p-md-2 p-lg-2 p-xl-2 left">
                                    <div class="field-radiobutton">
                                        <p-radioButton name="mandate" value="zoho" [(ngModel)]="mandateTypeRadio" id="zoho">
                                        </p-radioButton>
                                        <label for="zoho">Zoho</label>
                                    </div>
                                </div>
                                <div class="p-col-2 p-sm-2 p-md-2 p-lg-2 p-xl-2 left">
                                    <div class="field-radiobutton">
                                        <p-radioButton name="mandate" value="other" [(ngModel)]="mandateTypeRadio"
                                            id="other_digital"></p-radioButton>
                                        <label for="other_digital">Other Digital</label>
                                    </div>
                                </div>
                                <div class="p-col-2 p-sm-2 p-md-2 p-lg-2 p-xl-2 left">
                                    <div class="field-radiobutton">
                                        <p-radioButton name="mandate" value="physical" [(ngModel)]="mandateTypeRadio"
                                            id="physical"></p-radioButton>
                                        <label for="physical">Physical</label>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="p-col-12 p-p-0 p-mt-3">
                            <div class="ui-fluid p-formgrid p-grid">
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="mandateType">Mandate Type<span
                                                class='invalid-feedback-astrik'>*</span></label>
                                        <p-dropdown id="mandateType" placeholder="Select" optionValue="id"
                                            [options]="mandateTypeList" formControlName="mandateType"
                                            [autoDisplayFirst]="false" optionLabel="value"
                                            [disabled]="disableAllFileds || initiationReadScope || (isIrfInitiated && !qcRejectMap['mandateOption'])"
                                            [ngClass]="{'error': (otherKeyForm.get('mandateType')?.dirty && otherKeyForm.get('mandateType')?.errors?.required && otherKeyForm.get('mandateType')?.invalid) || qcRejectMap['mandateOption']}">
                                        </p-dropdown>
                                        <div class="invalid-feedback"
                                            *ngIf="(otherKeyForm.get('mandateType')?.dirty && otherKeyForm.get('mandateType')?.errors?.required && otherKeyForm.get('mandateType')?.invalid)">
                                            Mandate type is required</div>
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <label for="feedback">Feedback For QC</label>
                                    <textarea pInputTextarea id="feedback" rows="3" cols="3" placeholder="Enter Feedback For QC"
                                        maxlength="1000" formControlName="feedbackQc"
                                        [readonly]="isIrfInitiated || disableAllFileds || initiationReadScope" [disabled]="this.oppGridDetials?.fromIrfPushPull == true">
                                    </textarea>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <label for="priority">Priority For QC</label>
                                    <div class="p-col-12 p-p-0">
                                        <div class="ui-fluid p-formgrid p-grid">
                                            <div *ngFor="let p of priorityQcList">
                                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                                    <div class="p-fluid p-field">
                                                        <div class="field-radiobutton">
                                                            <p-radioButton value="{{p.id}}" formControlName="priorityQc"
                                                                id="{{p.value}}"
                                                                [disabled]="isIrfInitiated || disableAllFileds || initiationReadScope"></p-radioButton>
                                                            <label for="{{p.value}}">{{p.value}}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-p-0 p-mt-1">
                            <div class="ui-fluid p-formgrid p-grid">
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="currency">Currency</label>
                                        <p-dropdown id="currency" placeholder="Select" [options]="currencyList"
                                            [autoDisplayFirst]="false" optionLabel="currency_name" optionValue="id"
                                            formControlName="currency" (onChange)="currencyChanged($event)"
                                            [disabled]="isIrfInitiated || disableAllFileds || initiationReadScope">
                                        </p-dropdown>
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="invoice_value">Invoice Value</label>
                                        <input type="text" id="invoice_value" pInputText placeholder="Enter Invoice Value"
                                            formControlName="invoiceVal" class="p-inputtext" [readonly]="disableInvVal"
                                            (input)="invoiceValEntered()"
                                            [disabled]="isIrfInitiated || disableAllFileds || initiationReadScope" />
                                        <div class="invalid-feedback" *ngIf="invalidInvVal">
                                            Please enter correct value.
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="ope_type">OPE Type<span
                                            class='invalid-feedback-astrik' *ngIf="otherKeyForm.get('opeType')?.errors?.required">*</span>
                                        </label>
                                        <p-dropdown id="ope_type" placeholder="Select" formControlName="opeType"
                                            (onChange)="opeTypeChanged($event)" [options]="opeTypeList"
                                            [autoDisplayFirst]="false" optionLabel="value" optionValue="id"
                                            [disabled]="disableAllFileds || initiationReadScope || (isIrfInitiated && !qcRejectMap['opeType'])"
                                            [ngClass]="{'error': (otherKeyForm.get('opeType')?.dirty && otherKeyForm.get('opeType')?.errors?.required && otherKeyForm.get('opeType')?.invalid) || qcRejectMap['opeType']}">
                                        </p-dropdown>
                                        <div class="invalid-feedback"
                                            *ngIf="(otherKeyForm.get('opeType')?.dirty && otherKeyForm.get('opeType')?.errors?.required && otherKeyForm.get('opeType')?.invalid)">
                                            Ope type type is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-p-0 p-mt-1">
                            <div class="ui-fluid p-formgrid p-grid">
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left" *ngIf="showOpeAmt">
                                    <div class="p-fluid p-field">
                                        <label for="ope_amount">OPE Amount<span class='invalid-feedback-astrik'>*</span>
                                        </label>
                                        <input type="text" id="ope_amount" pInputText placeholder="Enter OPE Amount" class="p-inputtext"
                                            formControlName="opeAmount" (input)="opeAmtEntered()"
                                            [readonly]="disableAllFileds || initiationReadScope || (isIrfInitiated && !qcRejectMap['opeAmount'])"
                                            [ngClass]="{'error': (otherKeyForm.get('opeAmount')?.dirty && otherKeyForm.get('opeAmount')?.errors?.required && otherKeyForm.get('opeAmount')?.invalid) || invalidOpeAmt || qcRejectMap['opeAmount']}" />
                                        <div class="invalid-feedback"
                                            *ngIf="(otherKeyForm.get('opeAmount')?.dirty && otherKeyForm.get('opeAmount')?.errors?.required && otherKeyForm.get('opeAmount')?.invalid)">
                                            Ope amount is required</div>
                                        <div class="invalid-feedback" *ngIf="invalidOpeAmt">
                                            Please enter valid amount
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="case_calss">Case Classification<span
                                                class='invalid-feedback-astrik'>*</span></label>
                                        <p-dropdown id="case_calss" placeholder="Select" [options]="caseClassList"
                                            [autoDisplayFirst]="false" formControlName="caseClass" optionLabel="value"
                                            optionValue="id"
                                            [disabled]="(isIrfInitiated && !qcRejectMap['caseClassification']) || disableAllFileds || initiationReadScope"
                                            [ngClass]="{'error': (otherKeyForm.get('caseClass')?.dirty && otherKeyForm.get('caseClass')?.errors?.required && otherKeyForm.get('caseClass')?.invalid) || qcRejectMap['caseClassification']}">
                                        </p-dropdown>
                                        <div class="invalid-feedback"
                                            *ngIf="(otherKeyForm.get('caseClass')?.dirty && otherKeyForm.get('caseClass')?.errors?.required && otherKeyForm.get('caseClass')?.invalid)">
                                            Case classification is required</div>
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="sf_mandate_validity">SF Mandate Validity<span
                                                class='invalid-feedback-astrik'>*</span></label>
                                        <p-dropdown id="sf_mandate_validity" placeholder="Select" [options]="mandateValidityList"
                                            [autoDisplayFirst]="false" formControlName="sfMandateValidity" optionLabel="sf_mandate_validity"
                                            optionValue="id"
                                            [disabled]="disableAllFileds || initiationReadScope || (isIrfInitiated && !qcRejectMap['sfMandateValidity'])"
                                            [ngClass]="{'error': (otherKeyForm.get('sfMandateValidity')?.dirty && otherKeyForm.get('sfMandateValidity')?.errors?.required && otherKeyForm.get('sfMandateValidity')?.invalid) || qcRejectMap['sfMandateValidity']}">
                                        </p-dropdown>
                                        <div class="invalid-feedback"
                                            *ngIf="(otherKeyForm.get('sfMandateValidity')?.dirty && otherKeyForm.get('sfMandateValidity')?.errors?.required && otherKeyForm.get('sfMandateValidity')?.invalid)">
                                            SF Mandate Validity is required</div>
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="crisil_date">
                                            Effective MIS Date    
                                        </label>
                                        <p-calendar [showIcon]="true" id="effectiveMisDate_date" 
                                        [readonlyInput]="true"
                                            dateFormat="dd-mm-yy"  placeholder="Select"
                                            formControlName="effectiveMisDate">    
                                        </p-calendar>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-col-12">
                                <h1 class="p-medium display-3">Split Revenue</h1>
                            </div>
                            <div class="p-col-12 p-p-0 p-mt-1">
                                <div class="ui-fluid p-formgrid p-grid">
                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                        <p class="p-mb-0">Proposed Split</p>
                                        <div class="p-col-12 p-p-0 p-d-flex p-ai-center">
                                            <p-checkbox id="proposed_split" binary="true" formControlName="split"
                                                inputId="binary" (onChange)="splitClicked()"
                                                [disabled]="disableAllFileds || initiationReadScope || disableSplit"></p-checkbox>
                                            <label for="proposed_split" class="p-ml-2 p-mt-1">Yes</label>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="otherKeyformControls?.split?.value">
                                        <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                            <div class="p-fluid p-field">
                                                <label for="irf_actual">
                                                    IRF Actual Fee
                                                </label>
                                                <input type="text" id="irf_actual" pInputText placeholder="Enter IRF Actual Fee"
                                                    class="p-inputtext" disabled formControlName="irfActualFee" />
                                            </div>
                                        </div>
                                        <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                            <label>Proposed Split (Type)</label>
                                            <div class="p-col-12 p-p-0">
                                                <div class="ui-fluid p-formgrid p-grid">
                                                    <div class="p-col-6 p-sm-6 p-md-4 p-lg-6 p-xl-6 left"
                                                        *ngFor="let item of proposedSplitList">
                                                        <div class="p-fluid p-field">
                                                            <div class="field-radiobutton">
                                                                <p-radioButton value="{{item.id}}"
                                                                    [disabled]="disableAllFileds || initiationReadScope || disableSplit"
                                                                    (onClick)="splitTypeClicked()" id="{{item.value}}"
                                                                    formControlName="proposedSplit"></p-radioButton>
                                                                <label for="{{item.value}}">{{item.value}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="invalid-feedback"
                                                        *ngIf="(otherKeyForm.get('proposedSplit')?.errors?.required && otherKeyForm.get('proposedSplit')?.invalid)">
                                                        Proposed split (type) is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-p-0 p-mt-1" *ngIf="splitTypeSelected">
                            <div class="ui-fluid p-formgrid p-grid">
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="team_1">
                                            Team 1 <span class='invalid-feedback-astrik'>*</span>
                                        </label>
                                        <input type="text" id="team_1" pInputText placeholder="Enter Team 1" formControlName="team1"
                                            (input)="team1Entered()" class="p-inputtext"
                                            [readonly]="disableAllFileds || initiationReadScope || disableSplit"
                                            [ngClass]="{'error': team1Err || (otherKeyForm.get('team1')?.dirty && otherKeyForm.get('team1')?.errors?.required && otherKeyForm.get('team1')?.invalid)}" />
                                        <div class="invalid-feedback" *ngIf="team1Err">
                                            {{team1ErrTxt}}
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="(otherKeyForm.get('team1')?.dirty && otherKeyForm.get('team1')?.errors?.required && otherKeyForm.get('team1')?.invalid)">
                                            Team 1 is required
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="prop_team_1">
                                            Proposed Split Team 1
                                        </label>
                                        <input type="text" id="prop_team_1" pInputText placeholder="Enter Proposed Split Team 1"
                                            class="p-inputtext" formControlName="splitTeam1" disabled />
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="prop_team_2">
                                            Proposed Split Team 2
                                        </label>
                                        <input type="text" id="prop_team_2" pInputText placeholder="Enter Proposed Split Team 2"
                                            class="p-inputtext" formControlName="splitTeam2" disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid" *ngIf="splitTypeSelected">
                            <div class="p-col-12">
                                <h1 class="p-medium display-3">Team 1 Hierarchy</h1>
                            </div>
                            <div class="p-col-12">
                                <div class="p-d-flex"> <label>Owner:</label>
                                    <h1 class="p-medium display-3">{{oppGridDetials?.nexeraUser}}</h1>
                                </div>
                            </div>
                            <div class="p-col-12">
                                <p class="bdCase">{{bdHierarchy}}</p>
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid" *ngIf="splitTypeSelected">
                            <div class="p-col-12">
                                <h1 class="p-medium display-3">Team 2 Hierarchy</h1>
                            </div>
                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                <div class="p-fluid p-field">
                                    <label for="owner">
                                        Owner<span class='invalid-feedback-astrik'
                                            *ngIf="otherKeyformControls?.split?.value">*</span>
                                    </label>
                                    <p-dropdown id="owner" placeholder="Select" [options]="allUserList"
                                        [disabled]="disableAllFileds || initiationReadScope || disableSplit"
                                        (onChange)="team2Selected(otherKeyForm.value.team2Owner)"
                                        [autoDisplayFirst]="false" [filter]="true" optionLabel="employeeName"
                                        optionValue="employeeId" formControlName="team2Owner"
                                        [ngClass]="{'error':(otherKeyForm.get('team2Owner')?.dirty && otherKeyForm.get('team2Owner')?.errors?.required && otherKeyForm.get('team2Owner')?.invalid)}">
                                    </p-dropdown>
                                    <div class="invalid-feedback"
                                        *ngIf="(otherKeyForm.get('team2Owner')?.dirty && otherKeyForm.get('team2Owner')?.errors?.required && otherKeyForm.get('team2Owner')?.invalid)">
                                        Owner is required.
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12" *ngIf="team2Hierarchy">
                                <p class="bdCase">{{team2Hierarchy}}</p>
                            </div>
                        </div>
                        <div class="p-col-12 p-p-0 p-mt-1">
                            <div class="ui-fluid p-formgrid p-grid">
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                                    <div class="p-fluid p-field">
                                        <label for="comment_split">
                                            Comment<span class='invalid-feedback-astrik'
                                                *ngIf="otherKeyformControls?.split?.value">*</span>
                                        </label>
                                        <textarea pInputTextarea id="comment_split" rows="3" cols="3" placeholder="Enter Comment"
                                            maxlength="1000" formControlName="comment"
                                            [readonly]="disableAllFileds || initiationReadScope || disableSplit" [disabled]="this.oppGridDetials?.fromIrfPushPull == true">
                                        </textarea>
                                        <div class="invalid-feedback"
                                            *ngIf="(otherKeyForm.get('comment')?.dirty && otherKeyForm.get('comment')?.errors?.required && otherKeyForm.get('comment')?.invalid)">
                                            Comment is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4" *ngIf="enableSplitOta">
                                    <div class="p-fluid p-field">
                                        <label for="ota">
                                            OTA<span class='invalid-feedback-astrik' *ngIf="enableSplitOta">*</span>
                                        </label>
                                        <p-inputMask placeholder="Enter OTA" [ngClass]="{'error': (otherKeyForm.get('ota')?.dirty && otherKeyForm.get('ota')?.errors?.required && otherKeyForm.get('ota')?.invalid)}" slotChar="" formControlName="ota"  mask="999999"></p-inputMask>
                                        <div class="invalid-feedback"
                                            *ngIf="(otherKeyForm.get('ota')?.dirty && otherKeyForm.get('ota')?.errors?.required && otherKeyForm.get('ota')?.invalid)">
                                            Ota is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-col-12">
                                <h1 class="p-medium display-3">Limits</h1>
                            </div>
                            <div class="p-col-12 p-p-0">
                                <div class="ui-fluid p-formgrid p-grid">
                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                        <div class="p-fluid p-field">
                                            <label for="outstanding_amount">
                                                Outstanding Amount
                                            </label>
                                            <input type="text" id="outstanding_amount" pInputText placeholder="Enter Outstanding Amount"
                                                class="p-inputtext" formControlName="outstandingAmount" />
                                        </div>
                                    </div>
                                    <!-- <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                        <div class="p-fluid p-field">
                                            <label for="in_progress">
                                                In Progress Amount
                                            </label>
                                            <input type="text" id="in_progress" pInputText placeholder="Enter "
                                                class="p-inputtext" formControlName="inprogressAmount" />
                                        </div>
                                    </div> -->
                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                        <div class="p-fluid p-field">
                                            <label for="current_limit">
                                                Current Limit
                                            </label>
                                            <input type="text" id="current_limit" pInputText placeholder="Enter Current Limit"
                                                class="p-inputtext" formControlName="currentLimit" />
                                        </div>
                                    </div>
                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                        <div class="p-fluid p-field">
                                            <label for="previous_limit">
                                                Previous Limit <span class='invalid-feedback-astrik' *ngIf="otherKeyForm.get('previousLimit')?.errors?.required">*</span>
                                            </label>
                                            <p-dropdown id="previous-limit" placeholder="Select" [disabled]="isIrfInitiated || disableAllFileds"
                                                [options]="previousLimitList" [autoDisplayFirst]="false"
                                                optionLabel="label" formControlName="previousLimit"
                                                [ngClass]="{error: otherKeyForm.get('previousLimit').dirty && otherKeyForm.get('previousLimit')?.errors?.required && otherKeyForm.get('previousLimit').invalid}"
                                                (onChange)="onPreviousLimitChange()">
                                            </p-dropdown>
                                            <div class="invalid-feedback"
                                                *ngIf="otherKeyForm.get('previousLimit').dirty && otherKeyForm.get('previousLimit')?.errors?.required && otherKeyForm.get('previousLimit').invalid">
                                                Previous Limit is required.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-p-0">
                                <div class="ui-fluid p-formgrid p-grid">
                                    <!-- <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                        <div class="p-fluid p-field">
                                            <label for="current_limit">
                                                Current Limit
                                            </label>
                                            <input type="text" id="current_limit" pInputText placeholder="Enter "
                                                class="p-inputtext" formControlName="currentLimit" />
                                        </div>
                                    </div> -->
                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                        <div class="p-fluid p-field">
                                            <label for="applicable_amount">
                                                Applicable Amount
                                            </label>
                                            <input type="text" id="applicable_amount" pInputText placeholder="Enter Applicable Amount"
                                                class="p-inputtext" formControlName="applicableAmount" />
                                            <div class="invalid-feedback"
                                                *ngIf="(otherKeyForm.get('applicableAmount').dirty && otherKeyForm.get('applicableAmount')?.errors?.required && otherKeyForm.get('applicableAmount').invalid)">
                                                Applicable Amount is required</div>
                                            <div class="invalid-feedback"
                                                *ngIf="(otherKeyForm.get('previousLimit').value && otherKeyForm.get('applicableAmount').value<0)">
                                                Applicable Amount should be greater than or equal to 0</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header"><span [ngClass]="{'errorTab': errorAttachment}">Attachment
                            Details</span>
                    </ng-template>
                    <div class="p-fluid p-formgrid p-grid attachmentSectionReporting">
                        <div class=" p-field p-col-12 p-px-3 horizontalTabView">
                            <p-tabView>
                                <p-tabPanel header="Uploaded Document">
                                    <div *ngIf="doRevision && showRevisionAddButton" class="p-d-flex p-jc-end">
                                        <button style="width: 11rem;" pButton 
                                            class="p-button-secondary p-button-sm revision-attachment-btn"
                                            label="Add Revision Attachment"
                                            (click)="showAttachmentModal()">
                                        </button>
                                    </div>
                                    <div class="p-grid p-d-flex p-flex-wrap p-col-12 p-p-0 documentToBeUploaded">
                                        <div class="p-col-12 p-md-4 p-lg-4 attachmentCard attachmentContainer p-flex-wrap"
                                            *ngFor="let attach of documentList; let i = index">
                                            <!-- [ngClass]="{'attachmentError': saveBtnClicked && attach.is_mandatory == 'M' && attach.file == '' && isAttachmentM == true}"> -->
                                            <p-card class="attachmentCard"
                                                [ngClass]="{'error' : isSaveClicked && attach.is_mandatory == 'M' && attach.file == ''}">
                                                <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-jc-between p-flex-wrap">
                                                    <div class="p-col-2" style="padding-right: 9px;" *ngIf="!attach.file && !attach.fileId">
                                                        <img class="uploadedThumbnail"
                                                            *ngIf="attach.fileContentType == 'application/pdf' && attach.fileId" [src]="pdfThumb"
                                                            alt="">
                                                        <img class="uploadedThumbnail"
                                                            *ngIf="(attach.fileContentType == 'image/png' || attach.fileContentType == 'image/jpg' || attach.fileContentType == 'image/jpeg') && attach.fileId"
                                                            [src]="imgThumb" alt="">
                                                        <img class="uploadedThumbnail"
                                                            *ngIf="(attach.fileContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || attach.fileContentType == 'application/msword') && attach.fileId"
                                                            [src]="wordThumb" alt="">
                                                        <img class="uploadedThumbnail" *ngIf="attach.fileContentType == '' && attach.fileId"
                                                            [src]="mailThumb" alt="">
                                                    </div>
                                                    <div class="p-pl-0 p-pr-0 p-d-flex p-ai-center" *ngIf="!attach.file && !attach.fileId" [ngClass]="!attach.file && !attach.fileId ? 'p-col-12' : 'p-col-10'">
                                                        <div class="p-col-7 p-pl-0">
                                                            <label class="attachmentType lableToBeUploaded">{{attach.type}}
                                                                <span class='invalid-feedback-astrik'
                                                                    *ngIf="attach.is_mandatory == 'M'">*</span>
                                                            </label>
                                                        </div>
                                                        <div class="p-col-5 p-pl-0 p-d-flex p-jc-end p-pr-0">
                                                            <p-fileUpload customUpload="true" id="fileUpload" name="file"
                                                                accept="image/*, .pdf, .jpeg , .jpg, .png, .doc, .docx, .msg"
                                                                maxFileSize=20000000 mode="advanced" [showUploadButton]="false"
                                                                [showCancelButton]="false"
                                                                [disabled]="disableAllFileds || initiationReadScope || attach.isDisabled"
                                                                (onSelect)="onFileUplaod($event, attach.type, attach.code, attach.id)"
                                                                chooseLabel="Choose File">
                                                            </p-fileUpload>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-px-0 p-d-flex p-ai-center p-flex-wrap"
                                                        *ngIf="attach.file && !attach.fileId">
                                                        <div class="p-col-10 p-px-0 p-d-flex p-ai-center p-flex-wrap">
                                                            <div class="p-px-2">
                                                                    <img *ngIf="attach?.thumbNail" [src]="attach.thumbNail" alt="">
                                                                    <ng-container *ngIf="!attach?.thumbNail">
                                                                        <img class="uploadedThumbnail"
                                                                            *ngIf="attach.fileContentType == 'application/pdf'" [src]="pdfThumb"
                                                                            alt="">
                                                                        <img class="uploadedThumbnail"
                                                                            *ngIf="(attach.fileContentType == 'image/png' || attach.fileContentType == 'image/jpg' || attach.fileContentType == 'image/jpeg')"
                                                                            [src]="imgThumb" alt="">
                                                                        <img class="uploadedThumbnail"
                                                                            *ngIf="(attach.fileContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || attach.fileContentType == 'application/msword')"
                                                                            [src]="wordThumb" alt="">
                                                                        <img class="uploadedThumbnail" *ngIf="attach.fileContentType == ''"
                                                                            [src]="mailThumb" alt="">
                                                                    </ng-container>
                                                            </div>
                                                            <div class="p-col-9 p-p-1 p-d-flex contentContainer p-flex-column p-flex-wrap">
                                                                <div class="limit-text-length attachmentName"> {{attach.fileName}}
                                                                </div>
                                                                <label class="attachmentFileType" title="{{attach.type||attach.fileTypeName}}">Type: {{attach.type || attach.fileTypeName}}</label>
                                                            </div>
                                                        </div>
                                                        <div class="p-col-2 p-pr-0 p-d-flex p-jc-end">
                                                            <i class="pi pi-trash cursor-pointer attachmentIcon"
                                                                *ngIf="!attach.fileId" aria-hidden="true"
                                                                (click)="onDeletePopup(attach)"></i>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-11 p-px-0 p-d-flex p-ai-center p-flex-wrap"
                                                        *ngIf="attach.fileId">
                                                        <div class="p-col-10 p-px-0 p-d-flex p-ai-center p-flex-wrap">
                                                            <div class="p-px-2">
                                                                    <img class="uploadedThumbnail"
                                                                        *ngIf="attach.fileContentType == 'application/pdf' && attach.fileId" [src]="pdfThumb"
                                                                        alt="">
                                                                    <img class="uploadedThumbnail"
                                                                        *ngIf="(attach.fileContentType == 'image/png' || attach.fileContentType == 'image/jpg' || attach.fileContentType == 'image/jpeg') && attach.fileId"
                                                                        [src]="imgThumb" alt="">
                                                                    <img class="uploadedThumbnail"
                                                                        *ngIf="(attach.fileContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || attach.fileContentType == 'application/msword') && attach.fileId"
                                                                        [src]="wordThumb" alt="">
                                                                    <img class="uploadedThumbnail" *ngIf="attach.fileContentType == '' && attach.fileId"
                                                                        [src]="mailThumb" alt="">
                                                            </div>
                                                            <div class="p-col-9 p-p-1 p-d-flex p-flex-column p-flex-wrap contentContainer">
                                                                <div class="limit-text-length attachmentName cursor-pointer">
                                                                    <a (click)="showAttachmentPreview(attach)">
                                                                     {{attach.fileName}}
                                                                    </a>
                                                                </div>
                                                                <label class="attachmentFileType cursor-pointer" title="{{attach.type||attach.fileTypeName}}">Type: {{attach.type || attach.fileTypeName}}</label>
                                                            </div>
                                                        </div>
                                                        <div class="p-col-2 p-pr-0 p-d-flex p-jc-end">
                                                            <i class="pi pi-trash cursor-pointer attachmentIcon"
                                                                *ngIf="!attach.fileId || attachmentRejected" aria-hidden="true"
                                                                (click)="onDeletePopup(attach)"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </p-card>
                                            <div class="required invalid-feedback p-d-flex p-ai-center"
                                                *ngIf="isSaveClicked && attach.is_mandatory == 'M' && attach.file == ''">
                                                <i class="pi pi-exclamation-circle p-mr-1" aria-hidden="true"></i>Attachment
                                                Required
                                            </div>
                                        </div>
                                    </div>
                                </p-tabPanel>
                                <p-tabPanel header="Migrated Document">
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div class=" p-col-12 p-pl-0 p-pr-0">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div class="p-field p-d-flex p-flex-wrap p-col-12 displayEditTypeModal p-p-0">
                                                    <div class="uploadedDocumentAttachment p-col-12 p-md-4 p-lg-4 p-flex-wrap"
                                                        *ngFor="let item of migratedDocs">
                                                        <p-card class="attachmentCard">
                                                            <div
                                                                class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center">
                                                                <div class="p-col-11 p-p-0 p-d-flex"
                                                                    style="align-items: center;">
                                                                    <div
                                                                        style="padding-right: 9px;">
                                                                        <img class="uploadedThumbnail"
                                                                            *ngIf="item.fileContentType == 'application/pdf'"
                                                                            [src]="pdfThumb"
                                                                            alt="">
                                                                        <img class="uploadedThumbnail"
                                                                            *ngIf="item.fileContentType == 'image/png' || item.fileContentType == 'image/jpg' || item.fileContentType == 'image/jpeg'"
                                                                            [src]="imgThumb"
                                                                            alt="">
                                                                        <img class="uploadedThumbnail"
                                                                            *ngIf="item.fileContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || item.fileContentType == 'application/msword' "
                                                                            [src]="wordThumb"
                                                                            alt="">
                                                                        <img class="uploadedThumbnail"
                                                                            *ngIf="item.fileContentType == '' || !item.fileContentType || item.fileContentType == 'application/octet-stream'"
                                                                            [src]="mailThumb"
                                                                            alt="">
                                                                    </div>
                                                                    <div
                                                                        class="p-col-10 p-pl-0 p-pr-0">
                                                                        <a
                                                                            (click)="showAttachmentPreview(item)">
                                                                            <div class="p-col-11 p-d-flex p-flex-column contentContainer p-flex-wrap p-ai-start p-pl-0"
                                                                                style="text-align:left;">
                                                                                <div
                                                                                    class="limit-text-length attachmentName migrated-doc-name cursor-pointer">
                                                                                    {{item.fileName}}
                                                                                </div>
                                                                                <label
                                                                                    class="attachmentFileType">Type:{{item.fileTypeName}}
                                                                                </label>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </p-card>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-tabPanel>
                            </p-tabView>
                        </div>
                    </div>
                    <!-- <div class="horizontalTabView">
                        <div class="p-grid p-d-flex p-flex-wrap p-col-12 p-p-0 documentToBeUploaded">
                            <div class="p-col-12 p-md-4 p-lg-4 attachmentCard attachmentContainer p-flex-wrap"
                                *ngFor="let attach of documentList; let i = index">
                                [ngClass]="{'attachmentError': saveBtnClicked && attach.is_mandatory == 'M' && attach.file == '' && isAttachmentM == true}">
                                <p-card
                                    [ngClass]="{'error' : isSaveClicked && attach.is_mandatory == 'M' && attach.file == ''}">
                                    <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-jc-between p-flex-wrap">
                                        <div class="p-col-2" style="padding-right: 9px;" *ngIf="!attach.file && !attach.fileId">
                                            <img class="uploadedThumbnail"
                                                *ngIf="attach.fileContentType == 'application/pdf' && attach.fileId" [src]="pdfThumb"
                                                alt="">
                                            <img class="uploadedThumbnail"
                                                *ngIf="(attach.fileContentType == 'image/png' || attach.fileContentType == 'image/jpg' || attach.fileContentType == 'image/jpeg') && attach.fileId"
                                                [src]="imgThumb" alt="">
                                            <img class="uploadedThumbnail"
                                                *ngIf="(attach.fileContentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || attach.fileContentType == 'application/msword') && attach.fileId"
                                                [src]="wordThumb" alt="">
                                            <img class="uploadedThumbnail" *ngIf="attach.fileContentType == '' && attach.fileId"
                                                [src]="mailThumb" alt="">
                                        </div>
                                        <div class="p-col-10 p-pl-0 p-pr-0 p-d-flex p-ai-center" *ngIf="!attach.file && !attach.fileId">
                                            <div class="p-col-7 p-pl-0">
                                                <label class="attachmentType lableToBeUploaded">{{attach.type}}
                                                    <span class='invalid-feedback-astrik'
                                                        *ngIf="attach.is_mandatory == 'M'">*</span>
                                                </label>
                                            </div>
                                            <div class="p-col-5 p-pl-0 p-d-flex p-jc-end p-pr-0">
                                                <p-fileUpload customUpload="true" id="fileUpload" name="file"
                                                    accept="image/*, .pdf, .jpeg , .jpg, .png, .doc, .docx, .msg"
                                                    maxFileSize=7339900 mode="advanced" [showUploadButton]="false"
                                                    [showCancelButton]="false"
                                                    [disabled]="disableAllFileds || initiationReadScope || attach.isDisabled"
                                                    (onSelect)="onFileUplaod($event, attach.type, attach.code, attach.id)"
                                                    chooseLabel="Choose File">
                                                </p-fileUpload>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-px-0 p-d-flex p-ai-center p-flex-wrap"
                                            *ngIf="attach.file && !attach.fileId">
                                            <div class="p-col-10 p-px-0 p-d-flex p-ai-center p-flex-wrap">
                                                <div class="p-col-3">
                                                    <img [src]="attach.thumbNail" alt="">
                                                </div>
                                                <div class="p-col-9 p-p-1 p-d-flex p-flex-column p-flex-wrap">
                                                    <div class="limit-text-length attachmentName"> {{attach.fileName}}
                                                    </div>
                                                    <label class="attachmentFileType">Type: {{attach.type}}</label>
                                                </div>
                                            </div>
                                            <div class="p-col-2 p-pr-0 p-d-flex p-jc-end">
                                                <i class="pi pi-trash cursor-pointer attachmentIcon"
                                                    *ngIf="!attach.fileId" aria-hidden="true"
                                                    (click)="onDeletePopup(attach)"></i>
                                            </div>
                                        </div>
                                        <div class="p-col-11 p-px-0 p-d-flex p-ai-center p-flex-wrap"
                                            *ngIf="attach.fileId">
                                            <div class="p-col-10 p-px-0 p-d-flex p-ai-center p-flex-wrap">
                                                <div class="p-col-3">
                                                    <img [src]="attach.thumbNail" alt="">
                                                </div>
                                                <div class="p-col-9 p-p-1 p-d-flex p-flex-column p-flex-wrap">
                                                    <div class="limit-text-length attachmentName cursor-pointer">
                                                        <a (click)="showAttachmentPreview(attach)">
                                                         {{attach.fileName}}
                                                        </a>
                                                    </div>
                                                    <label class="attachmentFileType cursor-pointer">Type: {{attach.type}}</label>
                                                </div>
                                            </div>
                                            <div class="p-col-2 p-pr-0 p-d-flex p-jc-end">
                                                <i class="pi pi-trash cursor-pointer attachmentIcon"
                                                    *ngIf="attach.fileId && attachmentRejected" aria-hidden="true"
                                                    (click)="onDeletePopup(attach)"></i>
                                            </div>
                                        </div>
                                    </div>
                                </p-card>
                                <div class="required invalid-feedback p-d-flex p-ai-center"
                                    *ngIf="isSaveClicked && attach.is_mandatory == 'M' && attach.file == ''">
                                    <i class="pi pi-exclamation-circle p-mr-1" aria-hidden="true"></i>Attachment
                                    Required
                                </div>
                            </div>
                        </div>
                    </div> -->
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate="header"><span [ngClass]="{'errorTab': errorAction}">Action</span>
                    </ng-template>
                    <form [formGroup]="businessActionForm">
                        <p-toast key="tabAckMsg" class="custom-toast"></p-toast>
                        <div class="p-col-12 p-p-0">
                            <div class="ui-fluid p-formgrid p-grid">
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="business_action">
                                            Business Action <span class='invalid-feedback-astrik'>*</span>
                                        </label>
                                        <p-dropdown id="business_action" placeholder="Select"
                                            formControlName="businessAction" [options]="businessTypeList"
                                            [autoDisplayFirst]="false" optionLabel="property" optionValue="id"
                                            (onChange)="businessActionSelect()" [disabled]="disableBusinessAction || initiationReadScope"
                                            [ngClass]="{'error': (businessActionForm.get('businessAction').touched && businessActionForm.get('businessAction')?.errors?.required && businessActionForm.get('businessAction').invalid)}">
                                        </p-dropdown>
                                        <div class="invalid-feedback"
                                            *ngIf="(businessActionForm.get('businessAction').touched && businessActionForm.get('businessAction')?.errors?.required && businessActionForm.get('businessAction').invalid)">
                                            Business action is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label>
                                            Reason<span *ngIf="!disableReason" class='invalid-feedback-astrik'>*</span>
                                        </label>
                                        <p-dropdown name="approver" placeholder="Select" formControlName="reason"
                                            [autoDisplayFirst]="false" [options]="reasonList" optionLabel="property"
                                            optionValue="id" [disabled]="disableReason" (onChange)="reasonSelect()"
                                            [ngClass]="{'error': (businessActionForm.get('reason').touched && businessActionForm.get('reason')?.errors?.required && businessActionForm.get('reason').invalid)}">
                                        </p-dropdown>
                                        <div class="invalid-feedback"
                                            *ngIf="(businessActionForm.get('reason').touched && businessActionForm.get('reason')?.errors?.required && businessActionForm.get('reason').invalid)">
                                            Reason is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label>
                                            Ops Reason<span *ngIf="!disableReason" class='invalid-feedback-astrik'>*</span>
                                        </label>
                                        <p-dropdown name="approver" placeholder="Select" formControlName="opsReaseon"
                                            [autoDisplayFirst]="false" [options]="opsReasonList" optionLabel="name"
                                            optionValue="id" [disabled]="disableReason" (onChange)="reasonSelect()"
                                            [ngClass]="{'error': (businessActionForm.get('opsReaseon').touched && businessActionForm.get('opsReaseon')?.errors?.required && businessActionForm.get('opsReaseon').invalid)}">
                                        </p-dropdown>
                                        <div class="invalid-feedback"
                                            *ngIf="(businessActionForm.get('opsReaseon').touched && businessActionForm.get('opsReaseon')?.errors?.required && businessActionForm.get('opsReaseon').invalid)">
                                            Ops Reaseon is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                    <div class="p-fluid p-field">
                                        <label for="comment">
                                            Comment
                                        </label>
                                        <textarea pInputTextarea id="comment" rows="3" cols="3" placeholder="Enter Comment"
                                            formControlName="comment"
                                            [readonly]="disableComment || initiationReadScope" [disabled]="this.oppGridDetials?.fromIrfPushPull == true"></textarea>
                                    </div>
                                </div>
                                <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left" *ngIf="enableOta">
                                    <div class="p-fluid p-field">
                                        <label for="ota">
                                            OTA<span *ngIf="!disableOta" class='invalid-feedback-astrik'>*</span>
                                        </label>
                                        <p-inputMask placeholder="Enter 6 digit code" [ngClass]="{'error': (businessActionForm.get('ota').touched && businessActionForm.get('ota')?.errors?.required && businessActionForm.get('ota').invalid)}"
                                            slotChar="" formControlName="ota" mask="999999"
                                            [disabled]="disableOta"></p-inputMask>
                                        <div class="invalid-feedback"
                                            *ngIf="(businessActionForm.get('ota').touched && businessActionForm.get('ota')?.errors?.required && businessActionForm.get('ota').invalid)">
                                            Ota is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 left"
                                    *ngIf="enableOtaAttachmentMsg">
                                    <div class="p-fluid p-field">
                                        <p *ngIf="enableOtaAttachmentMsg"><b>Note:</b> Attachment is mandatory when the
                                            user selects Business Action as Case Close</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </p-tabPanel>
                <p-tabPanel [disabled]="disableInvoice">
                    <ng-template pTemplate="header"><span [ngClass]="{'errorTab': errorAction}">Invoices</span>
                    </ng-template>
                    <div class="p-grid" >
                        <div class="p-col-12 p-px-0 p-d-flex">
                            <div *ngFor="let invoice of invoiceCategoryList" class="p-col-1 p-mb-1 p-mr-1 field-radiobutton ">
                                <p-radioButton [inputId]="invoice.key" name="invoice" [value]="invoice" [(ngModel)]="selectedInvoiceCategory"
                                ></p-radioButton>
                                <label [for]="invoice.key">{{invoice.name}}</label>
                            </div>
                        </div>

                        <!-- invoice doc list -->
                        <div class="p-grid p-col-12">
                            <ng-container *ngFor="let attachData of invoiceDocList">
                                <!-- New invoice -->
                                <div class="p-col-12 p-sm-4 p-pl-0 p-d-flex p-ai-center p-flex-wrap p-pt-1 attachmentContainer"
                                    *ngIf="selectedInvoiceCategory.name === 'New' && attachData.isCurrentInvoice && attachData.transactionType !== 'CM'">
                                    <p-card>
                                        <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                            <div class="p-col-11 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                                <div style="padding-right: 10px;">
                                                    <img alt="" width="50" height="64"
                                                        (click)="previewAttachment(attachData)" class="cursor-pointer"
                                                        [src]="pdfThumb" />
                                                </div>
                                                <div
                                                    class="p-col-9 p-lg-9 p-xl-10 p-md-9 p-sm-7 p-pr-0 p-pl-0 p-d-flex p-flex-column p-flex-wrap">
                                                    <div class="limit-text-length attachmentName cursor-pointer">
                                                        <a class="cursor-pointer limit-text-length"
                                                            (click)="previewAttachment(attachData)">
                                                            {{attachData.fileName}}</a>
                                                    </div>
                                                    <label class="attachmentFileType">
                                                        {{attachData.invoiceDate}}</label>
                                                </div>
                                            </div>
                                            <div class="p-col-1 p-pr-0 p-d-flex p-jc-end p-ai-center">
                                                <i (click)="downloadInvoice(attachData.invoiceNumber)"
                                                    class="icon-download cursor-pointer attachmentIcon p-mr-2"
                                                    aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </p-card>
                                </div>
    
                                <!-- Old invoice -->
                                <div class="p-col-12 p-sm-4 p-pl-0 p-d-flex p-ai-center p-flex-wrap p-pt-1 attachmentContainer"
                                    *ngIf="selectedInvoiceCategory.name === 'Old' && !attachData.isCurrentInvoice && attachData.transactionType !== 'CM'">
                                    <p-card>
                                        <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                            <div class="p-col-11 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                                <div style="padding-right: 10px;">
                                                    <img alt="" width="50" height="64"
                                                        (click)="previewAttachment(attachData)" class="cursor-pointer"
                                                        [src]="pdfThumb" />
                                                </div>
                                                <div
                                                    class="p-col-9 p-lg-9 p-xl-10 p-md-9 p-sm-7 p-pr-0 p-pl-0 p-d-flex p-flex-column p-flex-wrap">
                                                    <a class="cursor-pointer limit-text-length"
                                                        (click)="previewAttachment(attachData)">
                                                        {{attachData.fileName}}</a>
                                                    <label class="attachmentFileType">
                                                        {{attachData.invoiceDate}}</label>
                                                </div>
                                            </div>
                                            <div class="p-col-1 p-pr-0 p-d-flex p-jc-end p-ai-center">
                                                <i (click)="downloadInvoice(attachData.invoiceNumber)"
                                                    class="icon-download cursor-pointer attachmentIcon p-mr-2"
                                                    aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </p-card>
                                </div>
                                
                                <!-- CM invoice -->
                                <div class="p-col-12 p-sm-4 p-pl-0 p-d-flex p-ai-center p-flex-wrap p-pt-1 attachmentContainer"
                                    *ngIf="selectedInvoiceCategory.name === 'CM' && attachData.transactionType === 'CM'">
                                    <p-card>
                                        <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                            <div class="p-col-11 p-pl-0 p-pr-0 p-d-flex p-ai-center p-flex-wrap">
                                                <div style="padding-right: 10px;">
                                                    <img alt="" width="50" height="64"
                                                        (click)="previewAttachment(attachData)" class="cursor-pointer"
                                                        [src]="pdfThumb" />
                                                </div>
                                                <div
                                                    class="p-col-9 p-lg-9 p-xl-10 p-md-9 p-sm-7 p-pr-0 p-pl-0 p-d-flex p-flex-column p-flex-wrap">
                                                    <a class="cursor-pointer limit-text-length"
                                                        (click)="previewAttachment(attachData)">
                                                        {{attachData.fileName}}</a>
                                                    <label class="attachmentFileType">
                                                        {{attachData.invoiceDate}}</label>
                                                </div>
                                            </div>
                                            <div class="p-col-1 p-pr-0 p-d-flex p-jc-end p-ai-center">
                                                <i (click)="downloadInvoice(attachData.invoiceNumber)"
                                                    class="icon-download cursor-pointer attachmentIcon p-mr-2"
                                                    aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </p-card>
                                </div>
                                
                            </ng-container>
                        </div>

                        <!-- no invoice present - empty state -->
                        <ng-container *ngIf="showEmptyInvoiceState">
                            <div class="p-w-100 p-d-flex p-jc-center">
                                <backoffice-svg [name]="'empty-state-light'"></backoffice-svg>
                                <backoffice-svg [name]="'empty-state-dark'"></backoffice-svg>
                            </div>
                            <div class="p-w-100 p-d-flex p-jc-center">No documents available</div>
                        </ng-container>
                                      

                    </div>
                </p-tabPanel>
                <p-tabPanel [disabled]="disableRevisionTab">
                    <ng-template pTemplate="header">
                        <span [ngClass]="{'errorTab': errorRevision}">Revision</span>
                    </ng-template>
                    <!-- Contact details -->
                    <div>
                        <p-toast key="tabAckMsg" class="custom-toast"></p-toast>
    
                        <div class="ui-fluid p-formgrid p-grid">
                            <div class="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                                <h1 class="p-medium display-3"> Contact Details </h1>
                            </div>
                            <div class="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-d-flex p-jc-end">
                                <div class="btnwrap">
                                    <button pButton style="height: 28px; padding: 3px 13px;"
                                        [disabled]="runtimeCancel || disableRevision"
                                        class="p-button-secondary p-button-sm" type="submit"
                                        label="Get CRM Data" (click)="getCrm('normal', 'revision')">
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="p-mt-2 contact-main-container">
                            <div class="p-fluid p-grid contact-container">
                                <div class="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6 contact_1">
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        Name: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('oldContact')?.value?.salutation}} {{revisionForm.get('oldContact')?.value?.firstName}}
                                            {{revisionForm.get('oldContact')?.value?.lastName}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        Designation: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('oldContact')?.value?.designation}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        Address Line 1: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('oldContact')?.value?.addressLine1}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        Address Line 2: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('oldContact')?.value?.addressLine2}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        Address Line 3: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('oldContact')?.value?.addressLine3}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        Address Line 4: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('oldContact')?.value?.addressLine4}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        Pin Code: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('oldContact')?.value?.pincode}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        City: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('oldContact')?.value?.city}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        State: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('oldContact')?.value?.state}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        GST Number: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('oldContact')?.value?.gstNo}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        GST Type: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('oldContact')?.value?.gstType}}</h1>
                                    </div>
                                </div>
                                <div *ngIf="revisionForm.get('newContact').value" class="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-6">
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        Name: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('newContact')?.value?.salutation}} {{revisionForm.get('newContact')?.value?.firstName}}
                                            {{revisionForm.get('newContact')?.value?.lastName}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        Designation: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('newContact')?.value?.designation}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        Address Line 1: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('newContact')?.value?.address1 || revisionForm.get('newContact')?.value?.addressLine1}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        Address Line 2: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('newContact')?.value?.address2 || revisionForm.get('newContact')?.value?.addressLine2}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        Address Line 3: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('newContact')?.value?.address2 || revisionForm.get('newContact')?.value?.addressLine3}}</h1>
                                    </div><div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        Address Line 4: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('newContact')?.value?.address2 || revisionForm.get('newContact')?.value?.addressLine4}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        Pin Code: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('newContact')?.value?.pin || revisionForm.get('newContact')?.value?.pincode}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        City: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('newContact')?.value?.city}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        State: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('newContact')?.value?.state}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        GST Number: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('newContact')?.value?.gstNumber || revisionForm.get('newContact')?.value?.gstNo}}</h1>
                                    </div>
                                    <div class="p-mb-1 p-ml-1 p-d-flex contact-alignment">
                                        GST Type: <h1 class="p-medium display-4 p-mb-0 p-mx-1">{{revisionForm.get('newContact')?.value?.gstType}}</h1>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- Invoice Particulars -->
                    <form [formGroup]="revisionForm">
                        <div>
                            <div class="ui-fluid p-formgrid p-mt-3 p-col-12">
                                <!-- Bill details -->
                                <div class="p-col-12">
                                    <h1 class="p-medium display-3">Invoice Particulars (Content) change</h1>
                                    <label>Invoice Details<span class='invalid-feedback-astrik'>*</span></label>
                                    <textarea maxlength="700" [attr.disabled]="(runtimeCancel || disableRevision) ? true : null" style="width: 66%; display: block;"  [ngClass]="{'error':(revisionForm.get('billDetail')?.touched && (revisionForm.get('billDetail')?.errors?.required || revisionForm.get('billDetail')?.errors?.minlength) && revisionForm.get('billDetail')?.invalid)}"
                pInputTextarea id="billDetail" formControlName="billDetail" rows="1" cols="3" placeholder="Enter bill detail "></textarea>
    
                                    <div class="invalid-feedback" *ngIf="revisionForm.get('billDetail')?.touched && revisionForm.get('billDetail')?.errors?.required && revisionForm.get('billDetail')?.invalid">
                                        invoice detail is required
                                    </div>
                                    <div class="invalid-feedback" *ngIf="revisionForm.get('billDetail')?.touched && !revisionForm.get('billDetail')?.errors?.required && revisionForm.get('billDetail')?.errors?.minlength && revisionForm.get('billDetail')?.invalid">
                                        Please enter atleast 3 characters
                                    </div>
                                </div>

                                <!-- Revision Details -->
                                <div class="p-pt-3 ui-fluid p-formgrid p-grid">
                                    <div class="p-col-4 p-d-flex p-ai-center">
                                        <p-checkbox binary="true" formControlName="isCancel"
                                        [disabled]="disableRevision"
                                        (onChange)="cancelInvoice($event)"
                                        inputId="cancel-invoice-checkbox"></p-checkbox>
                                        <label class="p-ml-1 p-mb-0 cursor-pointer" [for]="'cancel-invoice-checkbox'">Cancel Invoice</label>
                                    </div>

                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                        <label>Current Invoice Value</label>
                                        <p-inputNumber id="curr-val"
                                            formControlName="currentInvoiceVal"
                                            mode="decimal"
                                            [disabled]="true"
                                            placeholder="Enter Current Invoice Value"></p-inputNumber>
                                    </div>

                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 left">
                                        <label>Revised Value</label>
                                        <p-inputNumber id="revised-val"
                                            formControlName="revisedInvoiceVal"
                                            mode="decimal"
                                            [disabled]="true"
                                            placeholder="Enter Revised Value"></p-inputNumber>
                                    </div>
                                </div>

                                <div class="p-grid p-pt-3">
                                    <div class="p-fluid p-field p-col-4">
                                        <label>
                                            Reason For Revision<span class='invalid-feedback-astrik'>*</span>
                                        </label>
                                        <!-- placeholder="Select"
                                                        [(ngModel)]="noteToManager"
                                                        appendTo="body"
                                                        [options]="revisionReasons"
                                                        (onChange)="noteToManagerEntered()"
                                                        resetFilterOnHide="true"
                                                        optionLabel="reason_description" optionValue="reason_description"
                                                        [autoDisplayFirst]="false" [disabled]="isOtaPending || disableAll" -->
                                        <p-dropdown id="reason-for-rev" placeholder="Select" [options]="reasonForRevision"
                                        appendTo="body" [autoDisplayFirst]="false"
                                            formControlName="reasonForrevision"
                                            (onChange)="noteToManagerRevisionEntered()"
                                            [disabled]="disableRevision"
                                            optionLabel="reason_description" optionValue="id"
                                            [ngClass]="{'error': (revisionForm.get('reasonForrevision')?.touched && revisionForm.get('reasonForrevision')?.errors?.required) || errorCombo}">
                                        </p-dropdown>
                                        <div class="invalid-feedback"
                                        *ngIf="(revisionForm.get('reasonForrevision')?.touched && revisionForm.get('reasonForrevision')?.errors?.required) || errorCombo">
                                            Reason for revision is required
                                            </div>
                                    </div>

                                    <div class="p-fluid p-field p-col-8">
                                        <label>
                                            Note To Manager<span class='invalid-feedback-astrik'>*</span>
                                        </label>
                                        <input type="text" [attr.disabled]="disableRevision?true:null" maxlength="300" pInputText placeholder="Enter the note" [ngClass]="{'error': (revisionForm.get('noteToManager')?.dirty && revisionForm.get('noteToManager')?.errors?.required && revisionForm.get('noteToManager')?.invalid)}" formControlName="noteToManager" />
                                        <div class="invalid-feedback"
                                            *ngIf="(revisionForm.get('noteToManager')?.dirty && revisionForm.get('noteToManager')?.errors?.required && revisionForm.get('noteToManager')?.invalid)">
                                            Field is required.
                                        </div>
                                        <div class="invalid-feedback" *ngIf="revisionForm.get('noteToManager')?.touched && !revisionForm.get('noteToManager')?.errors?.required && revisionForm.get('noteToManager')?.errors?.minlength && revisionForm.get('noteToManager')?.invalid">
                                            Please enter atleast 3 characters
                                        </div>
                                    </div>
                                </div>

                                <!-- OTA -->
                                <div class="p-grid p-pt-3" *ngIf="showRevOTA">
                                    <div class="p-fluid p-field p-col-4 ota-field">
                                        <label>
                                            OTA<span class='invalid-feedback-astrik'>*</span>
                                        </label>
                                        <input pInputText type="text" maxlength="6" id="curr-val"
                                            formControlName="ota"
                                            placeholder="000000"/>
                                        <div class="invalid-feedback"
                                        *ngIf="(revisionForm.get('ota')?.touched && revisionForm.get('ota')?.errors?.required)">
                                            OTA is required
                                            </div>
                                        <div class="invalid-feedback" *ngIf="revisionForm.get('ota')?.touched && !revisionForm.get('ota')?.errors?.required && revisionForm.get('ota')?.errors?.minlength && revisionForm.get('ota')?.invalid">
                                            Please enter atleast 6 digits
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 popupFooterBtnContainer">
        <div
            class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-jc-between p-ai-center popupFooterBtnContent p-pr-3 p-mt-2 p-mb-2">

            <div>
                <button _ngcontent-xuq-c136="" pbutton="" type="button" (click)="onPreviewScreen()"
                    label="Preview Details" class="p-element p-button-secondary p-ml-2 p-button p-component">
                    <span class="p-button-label">Preview Data</span>
                </button>
            </div>

            <div>
                <div class="btnwrap">
                    <button pButton class="p-button-brand2 p-button" type="button" label="Cancel"
                        (click)="closeInitiationPopup()">
                    </button>
                </div>
                <div class="btnwrap" *ngIf="enableSendOtaBtn">
                    <button pButton class="p-button-secondary p-button p-mr-2" type="button" label="Send OTA"
                        (click)="generateOta()">
                    </button>
                </div>
                <div class="btnwrap" *ngIf="enableCancelOtaBtn">
                    <button pButton class="p-button-secondary p-button p-mr-2" type="button" label="Cancel OTA"
                        (click)="cancelOta()">
                    </button>
                </div>
                <div class="btnwrap" *ngIf="enableResubmit">
                    <button pButton class="p-button-primary p-button p-mr-2" type="button" label="Resubmit"
                        (click)="validateOta()" [disabled]="disableSaveBtn">
                    </button>
                </div>
                <div class="btnwrap" *ngIf="showSubmit">
                    <button pButton class="p-button-primary p-button p-mr-0" (click)="validateOta()" type="submit"
                        label="Save"
                        [disabled]="disableSaveBtn">
                    </button>
                </div>
                <div class="btnwrap" *ngIf="verifyAndSave">
                    <button pButton class="p-button-primary p-button p-mr-0" (click)="validateOta()" type="submit"
                        label="Verify And Save">
                    </button>
                </div>
            </div>

        </div>
    </div>
    <div class="no_data_div" *ngIf="uploadLoader">
        <div class="app-loading" id="loader">
            <div class="loadbg"></div>
            <div class="bdload">
                <div class="spinner">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                    <span>Loading...</span>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Send OTA" [(visible)]="approverPopup" class="crmContact" [modal]="true" [style]="{width: '30vw'}"
    [draggable]="false" [resizable]="false" [closable]="false">
    <ng-container>
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0">
            <div class="p-fluid p-field">
                <label>
                    Approver Name<span class='invalid-feedback-astrik'>*</span>
                </label>
                <p-dropdown name="approver" placeholder="Select" appendTo="body" [autoDisplayFirst]="false"
                    [(ngModel)]="approverName" [options]="hierarchyList" optionLabel="employeeName">
                </p-dropdown>
            </div>
        </div>
    </ng-container>
    <ng-template pTemplate="footer">
        <button pButton (click)="cancelApproverPop()" label="Cancel" class="p-button-brand2"></button>
        <button pButton (click)="sendOta()" label="Send" class="p-button-primary p-mr-0"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="apiFail" [modal]="true"
    [style]="{width: '55vw', height: '20vh'}" [draggable]="false" [resizable]="false">
    <p>Failed to connect with the server, Sorry for the inconvenience</p>
</p-dialog>

<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="showFailureModel" [modal]="true"
    [style]="{width: '55vw', height: '20vh'}" [draggable]="false" [resizable]="false">
    <p>{{errorMessage}}</p>
</p-dialog>

<p-dialog header="CRM Contacts" [(visible)]="crmContactPopup" class="crmContact" [modal]="true"
    [style]="{width: '45vw'}" [draggable]="false" [resizable]="false">
    <ng-container *ngIf="crmContacts?.length > 0; else empty" style="overflow: auto;">
        <div class="p-p-0 p-d-flex p-jc-between" *ngFor="let item of crmContacts; let i = index;">
            <div class="field-radiobutton">
                <p-radioButton name="crmcontacts" [inputId]="i" [value]="item.contactId" [(ngModel)]="selectedCrmContact"
                    (onClick)="crmContactClicked()"></p-radioButton>
            </div>
            <p-card class="p-ml-1 p-col-11 p-p-0 p-mb-2" [for]="i">
                <h6 class="crm-h6">{{item?.firstName}} {{item?.lastName}}</h6>
                <div class="p-grid p-nogutter">
                    <div class="p-col-6">
                        <div class="p-grid p-nogutter">
                            <div class="p-col-3">
                                <label>GST No: </label>
                            </div>
                            <div class="p-col-8">
                                <span>{{item?.gstNumber}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-6">
                        <div class="p-grid p-nogutter">
                            <div class="p-col-4">
                                <label> Contact ID: </label>
                            </div>
                            <div class="p-col-8">
                                <span> {{item?.contactId}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
    </ng-container>
    <ng-template #empty>No contacts available.</ng-template>
    <ng-template pTemplate="footer">
        <button pButton (click)="crmContactPopup=false" label="Cancel" class="p-button-brand2"></button>
        <button pButton (click)="crmContactSelected()" [disabled]="disabledCrmApplyBtn" label="Apply"
            class="p-button-primary"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Crop Image" [(visible)]="displayModal" (onHide)="uploadLoader=false;" [modal]="true" [style]="{width: '60vw', height: '80vh'}"
    [draggable]="true" [resizable]="false" maskStyleClass="negativemask">
    <div class="p-col-12 p-text-center popupAttachmentBodyScrollContainer">
        <image-cropper styleClass="imageCropper" class="crop-content" [imageChangedEvent]="imgChangeEvt" [imageQuality]="75"
            [canvasRotation]="canvasRotation" [imageFile]="imageBlob" [maintainAspectRatio]="false" format="jpeg"
            id="image_cropper" (imageCropped)="imageCropEvent($event)">
        </image-cropper>
        <i class="pi pi-replay p-mr-3 p-pt-2" style="cursor:pointer;" (click)="rotateLeft()" aria-hidden="true"></i>
        <i class="pi pi-refresh p-pt-2" style="cursor:pointer;" (click)="rotateRight()" aria-hidden="true"></i>
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" (click)="displayModal=false" label="Cancel" class="p-button-brand2"></button>
        <button pButton type="button" label="Upload" class="p-button-primary" (click)="onImgUpload();"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Delete" [(visible)]="deleteFile" class="DeleteFileModal" [modal]="true" [style]="{width: '27vw'}"
    [draggable]="false" [resizable]="false">
    <div class="p-fluid p-grid">
        <div class="p-col-12 p-p-0">
            <p>Are you sure you want to delete?</p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton (click)="deleteFile=false" label="No" class="p-button-secondary"></button>
        <button pButton label="Yes" (click)="onDelete()" class="p-button-primary"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Alert" [(visible)]="errorMsgPopup" [modal]="true" [style]="{width: '30vw'}" [draggable]="false"
    [resizable]="false">
    <div class="p-col-12 p-px-1" id="center">
        <ng-container *ngIf="!showRevAttachmentAlertPopUp">
            <p class="p-mb-0">There are few details missing in {{errorMessageSave?.toString()}}. <br>
                Please enter in order to save.</p>
        </ng-container>
        <ng-container *ngIf="showRevAttachmentAlertPopUp">
            <p class="p-mb-0">{{finalRevErrorMsg}}</p>
        </ng-container>
        
    </div>
    <ng-template pTemplate="footer">
        <div class="btnwrap">
            <button pButton id="save" class="p-button-primary p-button" (click)="errorMsgPopup=false" type="button"
                label="Ok">
            </button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog header="Preivew" [(visible)]="displayPreview"
    class="previewModal modal" [modal]="true" [style]="{width: '95vw', height: '100vh'}" [draggable]="false"
    [resizable]="false">
    <div class="no_data_div tableLoader" *ngIf="previewLoader">
        <div class="app-loading " id="loader">
            <div class="loadbg"></div>
            <div class="bdload">
                <div class="spinner">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                    <span>Loading...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="p-mb-2 p-d-flex p-flex-row zoomContainer">
        <label style="margin-left:0px;">Zoom : </label>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-search-plus" (click)="zoom(1)"></button>
        <button type="button" (click)="zoom(-1)" pButton icon="pi pi-search-minus"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" (click)="rotateFile(1)" pButton icon="pi pi-refresh"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-replay" (click)="rotateFile(-1)"></button>
    </div>
    <pdf-viewer id="pdf_viewer" *ngIf="isPdf" [src]="urlSafe" [rotation]="rotate" [original-size]="false"
        [show-all]="true" [fit-to-page]="false" [zoom]="zoomValue" [zoom-scale]="'page-width'" [stick-to-page]="false"
        [render-text]="true" [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
        style="width: 100%; height:100%;"></pdf-viewer>
    <div class="imagePreviewContainer p-d-flex p-jc-center p-ai-center">
        <img *ngIf="!isPdf" src="{{urlSafe}}" id="imagePrev" alt="Image">
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" (click)="displayPreview=false" class="p-button-brand2"></button>
        <button pButton type="button" label="Download" class="p-button-secondary"
            (click)="downloadFile()"></button>
    </ng-template>
</p-dialog> 

<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="previewFailed" [modal]="true"
    [style]="{width: '55vw', height: '20vh'}" [draggable]="false" [resizable]="false">
    <p>Unable to preview attachment, Please try again.</p>
</p-dialog>
<p-dialog header="Alert" [(visible)]="otaErrorMsgPopup" [modal]="true" [style]="{width: '30vw'}" [draggable]="false"
    [resizable]="false">
    <div class="p-col-12 p-px-1" id="center">
        <p class="p-mb-0">There are few details are missing in {{errorMessageSave?.toString()}}. <br>
            Please enter in order to Send Ota.</p>
    </div>
    <ng-template pTemplate="footer">
        <div class="btnwrap">
            <button pButton id="save" class="p-button-primary p-button" (click)="otaErrorMsgPopup=false" type="button"
                label="Ok">
            </button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog [header]="currentPdf ? currentPdf?.fileName : 'Preview'" [(visible)]="displayPreview"
    class="docPreview previewModal modal" [modal]="true" [style]="{width: '95vw', height: '100vh'}" [draggable]="false"
    [resizable]="false">
    <div class="p-mb-2 p-d-flex p-flex-row zoomContainer">
        <label style="margin-left:0px;">Zoom : </label>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-search-plus" (click)="zoom(1)"></button>
        <button type="button" (click)="zoom(-1)" pButton icon="pi pi-search-minus"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" (click)="rotateFile(1)" pButton icon="pi pi-refresh"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-replay" (click)="rotateFile(-1)"></button>
    </div>
    <pdf-viewer id="pdf_viewer" *ngIf="isPdf" [src]="urlSafe" [rotation]="rotate" [original-size]="false"
        [show-all]="true" [fit-to-page]="false" [zoom]="zoomValue" [zoom-scale]="'page-width'" [stick-to-page]="false"
        [render-text]="true" [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"
        style="width: 100%; height:100%;"></pdf-viewer>
    <div class="imagePreviewContainer p-d-flex p-jc-center p-ai-center">
        <img *ngIf="!isPdf" src="{{urlSafe}}" id="imagePrev" alt="Image">
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" (click)="displayPreview=false" class="p-button-brand2"></button>
        <button pButton type="button" label="Download" class="p-button-secondary"
            (click)="isPdf && currentPdf ? downloadInvoice(currentPdf.invoiceNumber) : downloadFile()"></button>
    </ng-template>
</p-dialog>



<p-dialog header="Send OTA" [(visible)]="approverPopup" class="crmContact" [modal]="true" [style]="{width: '30vw'}"
    [draggable]="false" [resizable]="false" [closable]="false">
    <ng-container *ngIf="!doRevision">
            <div class="p-fluid p-field">
                <label>
                    Approver Name<span class='invalid-feedback-astrik'>*</span>
                </label>
                <p-dropdown name="approver" placeholder="Select" appendTo="body" [autoDisplayFirst]="false"
                    [(ngModel)]="approverName" [options]="hierarchyList" optionLabel="employeeName">
                </p-dropdown>
            </div>
    </ng-container>
    <ng-container *ngIf="doRevision">
            <div class="p-fluid p-field">
                <label>
                    Approver Name<span class='invalid-feedback-astrik'>*</span>
                </label>
                <p-dropdown name="approver" placeholder="Select" appendTo="body" [autoDisplayFirst]="false"
                    [(ngModel)]="approverName" [options]="irfApproverList" optionLabel="username"
                    optionValue="employee_id">
                </p-dropdown>
        </div>
    </ng-container>
    <ng-template pTemplate="footer">
        <button pButton (click)="cancelApproverPop()" label="Cancel" class="p-button-brand2"></button>
        <button pButton (click)="sendOta()" label="Send" [disabled]="!approverName" class="p-button-primary p-mr-0"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Alert" [(visible)]="allChangesPopup" class="crmContact" [modal]="true"
    [style]="{width: '30vw', height:'34vh'}" [draggable]="false" [resizable]="false">
    <p class="p-mb-0 p-ml-2">This will cancel all changes including pending OTA.</p>
    <p class="p-mb-0 p-ml-2">Are you sure want to cancel all changes? </p>
    <ng-template pTemplate="footer">
        <button pButton (click)="allChangesPopup=false" label="No" class="p-button-brand2"></button>
        <button pButton (click)="cancelAllChange()" label="Yes" class="p-button-primary"></button>
    </ng-template>
</p-dialog>