import { Component, OnInit, ViewChild } from '@angular/core';
import { DOMAIN_ROUTE } from '../../app.constants'
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { ImageTransform } from 'ngx-image-cropper';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { Console } from 'console';

@Component({
  selector: 'backoffice-partial-cn',
  templateUrl: './partial-cn.component.html',
  styleUrls: ['./partial-cn.component.scss']
})
export class PartialCnComponent implements OnInit {

  breadCrumbList = [
    { label: `Partial CN`, routerLink: ['/pages/sf/partial-cn'] },
  ];
  homeIconBreadCrumb = { icon: 'pi pi-home' };
  @ViewChild('attachmentUpload') attachmentUpload;
  @ViewChild('excelFileUpload') excelFileUpload;

  monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
  rejectReasonList: any = [];
  revisionReason: any;
  excelUpload: boolean = false;
  attachmenUpload: boolean = false
  excelBase64: any;
  attachmentBase64: any;
  excelFile: any;
  attachmentFile: any;
  pageLoader: boolean = false;
  displayPreview: boolean = false;
  dataLoader: boolean = false;
  disableProcess: boolean = true;
  scopeList: any = [];
  readScope: boolean = false;
  attachmentType: any = {};

  displayImageCropper: boolean = false;
  imgChangeEvt: any;
  canvasRotation = 0;
  imageChangedEventfile: any;
  transform: ImageTransform = {};
  fileNameForImage: any;
  croppedImagefile: any;
  zoomValue: number = 1;

  thumbNail: any;
  pdfThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/pdfThumbnail.svg`;
  excelThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/excelThumbnail.svg`;
  wordThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/wordThumbnail.svg`;
  imgThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/jpgThumbnail.svg`;
  mailThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/mail.svg`;

  constructor(private pageService: PagesService, private messageService: MessageService, private encDec: EncryptDecryptInterceptor) {

  }

  ngOnInit(): void {
    this.scopeList = this.encDec.decrypt(localStorage.getItem('scopes'));
    let scope: any = this.scopeList.split(",")
    if (scope.indexOf('SF_PARTIAL_REVISION_CREATE') == -1) {
      this.readScope = true;
    } else {
      this.readScope = false;
    }
    this.getRevisionReason();
    this.getCombineMaster();
  }

  exportExcel() {
    this.pageService.partialCnExport().subscribe((res: any) => {
      let name = this.getDateTime()
      this.downloadFile(res?.response, name, 'excel')
    });
  }

  deleteFile(from) {
    if (from == 'xlsx') {
      this.excelUpload = false;
      this.excelBase64 = null;
      this.excelFile = null;
    }

    if (from == 'all') {
      this.attachmenUpload = false;
      this.attachmentBase64 = null;
      this.attachmentFile = null;
    }
    this.checkValidation();
  }

  fileUploaded(event, from) {
    let file = event?.currentFiles[0];
    if(file == undefined){
      this.messageService.add({ severity: 'error', summary: 'Invalid File Format.' });
      return;
    }

    if (from == 'all' && file?.size > 20000000) {
      this.attachmentUpload.clear();
      this.messageService.add({ severity: 'error', summary: 'File size cannot exceed 20 MB.' });
      return;
    }

    if (from == 'xlsx' && file?.size > 5000000) {
      this.excelFileUpload.clear();
      this.messageService.add({ severity: 'error', summary: 'File size cannot exceed 5 MB.' });
      return;
    }
  
    let ext = file?.name?.split('.');
    if (ext[ext?.length - 1] == 'jfif') {
      this.messageService.add({ severity: 'error', summary: 'Invalid File Format.' });
      return;
    }

    if (from == 'xlsx') {
      if (["image/svg+xml", "image/webp", "image/*", "pdf", "jpeg", "jpg", "png", "doc", "docx", "msg", "xls", "html", "xhtml", "txt", "gif", "application/exe", "zip", ".oft"].includes(file.type)) {
        this.messageService.add({ severity: 'error', summary: 'Invalid File Format.' });
        return;
      }
    }
    
    if (from == 'all') {
      if (["image/svg+xml", "image/webp", "xls", "html", "xhtml", "txt", "gif", "application/exe", "zip", ".oft"].includes(file.type)) {
        this.messageService.add({ severity: 'error', summary: 'Invalid File Format.' });
        return;
      }
    }

    if (file?.type == 'image/jpeg' || file?.type == 'image/jpg' || file?.type == 'image/png') {
      this.displayImageCropper = true;
    } else {
      this.displayImageCropper = false;
    }

    if (file.type.includes('image')) {
      this.imageChangedEventfile = new Blob([file], { type: file.type });
      this.fileNameForImage = file.name;
    }

    const fileReader = new FileReader();
    fileReader.readAsDataURL(event.currentFiles[0]);
    fileReader.onload = () => {
      if (from == 'xlsx') {
        this.excelBase64 = fileReader.result
        this.excelUpload = true;
        this.excelFile = event.currentFiles[0];
        this.checkValidation();
      }

      if (from == 'all') {
        this.attachmentBase64 = fileReader.result
        this.attachmenUpload = true;
        this.attachmentFile = event.currentFiles[0];
        this.checkValidation();
        this.thumbNail = this.getThumbnail(file.type);
      }
    };
  }

  checkValidation() {
    if (this.attachmenUpload && this.excelUpload && this.revisionReason) {
      this.disableProcess = false;
    } else {
      this.disableProcess = true;
    }
  }

  reasonSelected() {
    this.checkValidation();
  }

  getRevisionReason() {
    this.pageService.getSfRevisionReasons().subscribe((res: any) => {
      if (res?.length > 0) {
        this.rejectReasonList = res;
      }
    });
  }

  processCn() {
    let paylaod = {
      excelBase64: this.excelBase64?.split('base64,')[1],
      userId: this.encDec.decrypt(localStorage.getItem('empid')),
      cmReasonDesc: this.revisionReason,
      attachmentsDataList: {
        userId: this.encDec.decrypt(localStorage.getItem('empid')),
        moduleName: "Partial CN",
        moduleId: null,
        fileName: this.attachmentFile.name,
        file: this.attachmentBase64?.split('base64,')[1],
        fileContentType: this.attachmentFile.type,
        fileSize: this.attachmentFile.size,
        fileTypeid: this.attachmentType.id,
        fileTypeName: this.attachmentType.value,
        fileId: null
      }
    }

    this.pageService.partialCnImport(paylaod).subscribe((res: any) => {
      if (res?.response == "File processed successfully") {
        this.messageService.add({ severity: 'success', summary: 'Revision Processed Successfully.' });
        setTimeout(() => {
          this.messageService.clear();
        }, 3000);
      }
      this.resetPage();
    }, (err) => {
      if (err.status == 400) {
        let errData: any = JSON.parse(this.encDec.decrypt(err?.error?.data));
        if (errData?.body == "OTP is still pending." || errData?.body == "Invoice or Revision for SF Bill Id is in progress.") {
          this.messageService.add({ severity: 'warn', summary: 'Revision is in Progress.', detail: `Reason: ${errData?.body}` });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Revision not Processed.', detail: `Reason: ${errData?.body}` });
        }
      }

      if (err.status == 500) {
        this.messageService.add({ severity: 'error', summary: 'Something went wrong.' });
      }
      this.resetPage();
    });
  }

  resetPage(){
    this.disableProcess = true;
    this.excelUpload = false;
    this.excelBase64 = null;
    this.excelFile = null;
    this.attachmenUpload = false;
    this.attachmentBase64 = null;
    this.attachmentFile = null;
    this.revisionReason = null;
  }

  imageCroppedfileUpload(event: any): any {
    fetch(event.objectUrl)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.attachmentBase64 = reader.result;
          this.croppedImagefile = reader.result;
        };
        reader.readAsDataURL(blob);
      });
  }

  croppedBtn() {
    this.displayImageCropper = false;
  }

  getCombineMaster() {
    this.pageService.getCombineMaster().subscribe((res) => {
      if (res?.length > 0) {
        this.attachmentType = res?.find(x => x.name == "AttachmentType");
      }
    });
  }

  getThumbnail(type) {
    if (type == 'image/png' || type == 'image/jpeg' || type == 'image/jpg') {
      return this.imgThumb;
    } else if (type == 'application/pdf') {
      return this.pdfThumb;
    } else if (type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return this.excelThumb;
    } else if (type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return this.wordThumb;
    } else {
      return this.mailThumb;
    }
  }

  previewUrl: any;
  attachmentPreview() {
    if (this.attachmentFile.type.includes('image')) {
      this.displayPreview = true;
      this.previewUrl = this.attachmentBase64;
    } else {
      const link = document.createElement('a');
      link.href = this.attachmentBase64;
      link.download = this.attachmentFile.name;
      link.click();
      this.messageService.add({ severity: 'success', summary: 'Document downloaded successfully' });
    }
  }

  downloadFile(file, name, from) {
    let src;
    if (from == 'excel') {
      src = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${file}`;
    }
    if (from == 'image') {
      src = this.attachmentBase64;
      name = this.attachmentFile.name;
    }
    const link = document.createElement('a');
    link.href = src;
    link.download = name;
    link.click();
    this.messageService.add({ severity: 'success', summary: 'Document downloaded successfully' });
  }

  getDateTime() {
    let today = new Date();
    let yy = today.getFullYear();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth()); //January is 0!

    return `Partial CN ${dd}${this.monthNames[mm]}${yy}`
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  rotate: number = 0
  rotateFile(x) {
    if (x === -1) {
      this.rotate -= 90;
    } else {
      this.rotate += 90;
    }
    this.transformImage();
  }

  zoom(x) {
    if (x === -1) {
      if (this.zoomValue > 0.1) {
        this.zoomValue -= 0.1;
      }
    } else {
      this.zoomValue += 0.1;
    }
    this.transformImage();
  }

  transformImage() {
    let el = document.getElementById('imagePrev');
    el.style.transform = `rotate(${this.rotate}deg) scale(${this.zoomValue})`;
  }

  dataURLtoFile(dataurl: any, filename: any): any {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  homeIconClicked(event) {
    if (event.item?.icon == 'pi pi-home') {
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }
  }
}
