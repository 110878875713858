import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { SfReportingScreenDetailsComponent } from '../sf-reporting-screen-details/sf-reporting-screen-details.component';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { FormBuilder } from '@angular/forms';
import { PagesService } from '../pages.service';
import { Table } from 'primeng/table';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import * as FileSaver from 'file-saver';
import { MultiSelect } from 'primeng/multiselect';

@Component({
  selector: 'backoffice-sf-reporting-screen-grid',
  templateUrl: './sf-reporting-screen-grid.component.html',
  styleUrls: ['./sf-reporting-screen-grid.component.scss'],
  providers: [DialogService, MessageService]
})
export class SfReportingScreenGridComponent implements OnInit {

  @ViewChildren('businessWonDataGridid') businessWonDataGridid;
  @ViewChild('businessWonDataGridid') businessWonDataGridid1 : Table;
  SfReportingScreenGridForm = this.fb.group({
    oppIdFilter: [null, []],
    entityNameFilter: [null as any, []],
    nexeraUserFilter: [null, []],
    bdManagerFilter: [null, []],
    opportinutyFilter: [null, []],
    bdSpocFilter: [null, []],
    dateFilter: [null, []],
    ageingFilter: [null, []],
    productFilter: [null, []],
    EntityDetailstabview: [0],
    gridCalender: [null as any, []],
    financialYearFilter: [null as any,[]],
    rptFlagFilter: [null,[]],
    finRiskFilter:[null,[]],
    businessWonDataGrid:[null,[]]
  });
  detailsTable: DynamicDialogRef = new DynamicDialogRef;
  loadingStatus = false; 
  gridDataList: any[] = [];
  opportunityIdList: any[] = [];
  entityNameList: any[] = [];
  nameList: any[] = [];
  oppIdList: any[] = [];
  productList: any[] = [];
  productList1: any[] = [];
  nexeraUserList: any[] = [];
  nexeraUserList1: any[] = [];
  uniqbdManagerList: any[] = [];
  bdManagerList: any[] = [];
  bdSpocList: any[] = [];
  opportunityList: any[] = [];
  opportunityStatusList: any[] = [];
  uniqbdSpocList: any[] = [];
  dateList: any[] = [];
  uniqDateList: any[] = [];
  ageingList: any[] = [];
  uniqAgeingList: any[] = [];
  financialYearList: any[] = [];
  financialYear: any[] = [];
  rptFlagList: any[] = [];
  rptFlag: any[] = [];
  finRiskList: any[] = [];
  finRisk: any[] = [];
  dateTime = new Date();
  filterMsg:any = "";
  dataLoader: boolean = false;
  gridLoader: boolean = true;
  firstLoad: boolean = false;
  pageLoaded: boolean = false;
  apiFail: boolean = false;
  maxDate: Date;
  minDate: Date;
  startDate: any;
  endDate: any;
  rangeDate: any;
  isFirstDate = true;
  filterDisplay: boolean = true;
  filterDisplayRow: boolean = true;
  businessWonCount: any = 0;
  rowsFilterCount: any = 0;
  showFilteredRows: boolean = false;
  noWonRecord: boolean = false;
  excelIds: any[] = [];
  opportunityIds: any[] = [];
  excelData = {};
  employeeName: any;
  bdSpocListData: any;
  userRole: any;
  disableFilter: boolean = false;
  additionalPaymentData: any;
  addPayOppIds: any[] = [];
  addnPayChecked: boolean;
  addnPayCount: any = 0;
  tempAddnPayGrid: any[];
  @ViewChild('calender') calender;
  @ViewChild('oppStatusMultiSelect') oppStatusMultiSelect: any;
  @ViewChild('oppIds') oppIds: any;
  // @ViewChild('oppName') oppName: any;
  @ViewChild('oppUser') oppUser: any;
  @ViewChild('oppBd') oppBd: any;
  // @ViewChild('oppFinancialYear') oppFinancialYear: any;
  ccprimebreadcrumbList = [
    { label: `SF Reporting Screen`, routerLink: ['/pages/sf'] },
  ];

  homeiconccprimebreadcrumb = { icon: `pi pi-home` };
  checkFail: boolean = false;
  errorCheckData: string;
  isScreenClosed: boolean;
  departmentName: any;
  selectAllEntityName: boolean = false;
  @ViewChild('oppName') oppName: MultiSelect;
  selectAllFinancialYear: boolean = false;
  @ViewChild('oppFinancialYear') oppFinancialYear: MultiSelect;

  constructor(private dialogService: DialogService, private fb: FormBuilder, private pagesService: PagesService,  private cd: ChangeDetectorRef,private messageService: MessageService,private encryptDecryptInterceptor : EncryptDecryptInterceptor) { 
    this.dateTime.setDate(this.dateTime.getDate());
    this.maxDate = new Date();
    this.minDate = new Date();
    this.minDate.setMonth(this.maxDate.getMonth() - 3);
    this.startDate = this.minDate.getFullYear() + "-" + (this.minDate.getMonth() + 1) + "-" + this.minDate.getDate();
    this.endDate = this.maxDate.getFullYear() + "-" + (this.maxDate.getMonth() + 1) + "-" + this.maxDate.getDate();
  }

  ngOnInit(): void {
    this.employeeName = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empName'));
    this.userRole = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('role'));
    this.departmentName = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('departmentId'));
    this.getbusinessWonDataGridList(this.startDate, this.endDate);
    this.dataLoader = true;
  }

  ngAfterViewInit() {
    this.patchBdSpoc();
    this.setGridWonData();
  }

  patchBdSpoc(){
    if(this.departmentName == "DEP36"){
      this.disableFilter = true;
      this.oppBd?.onChange.emit({originalEvent:null, value:[{name:this.employeeName}], itemValue: {name:this.employeeName}});
      this.cd.detectChanges();
    }
  }

  async setGridWonData(){
    this.oppStatusMultiSelect.onChange.emit({originalEvent:null, value:[{name:'Won - Payment Recd'}], itemValue: {name:'Won - Payment Recd'}});
    this.cd.detectChanges();
    if(this.oppStatusMultiSelect.value){
      await this.getCountRowsData();
      this.showFilteredRows = true;
    }
  }

  openPopup(rowData: any){
    rowData.addnPaymentCase = this.addnPayChecked;
    this.pagesService.getCheckData(rowData.opportunityId).subscribe(results => {
      if(!results["isValidForInitiation"]){
        this.checkFail = true;
      } else {
        this.checkFail = false;
        this.detailsTable = this.dialogService.open(SfReportingScreenDetailsComponent, {
          contentStyle: { "height": "80vh", "overflow": "hidden" },
          closeOnEscape: false,
          closable: true,
          data: rowData,
          width: '96%',
          header: rowData.entityName
        });
        this.detailsTable.onClose.subscribe((response: any) => {
          console.log(response, 'rrr')
          if (response?.status?.toLowerCase() == "success") {
            this.messageService.add({ severity: 'success', detail: 'Data updated successfully' });
          } else if(response?.status != undefined ){
            this.messageService.add({ severity: 'error', detail: 'Error while updating data,try again! ' });
          }
          setTimeout(() => {
            this.messageService.clear();
          }, 4000);
          this.isScreenClosed = true;
          this.SfReportingScreenGridForm.get('businessWonDataGrid')?.setValue(response);
          this.getbusinessWonDataGridList(this.startDate, this.endDate);
        });
      }
    }, (_error) => {
      if(_error.status.toString().startsWith("40")){
        this.errorCheckData =  JSON.parse(this.encryptDecryptInterceptor.decrypt(_error?.error?.data)).body;
      } else {
        this.errorCheckData = "Failed to connect with the server, Sorry for the inconvenience";
      }
      this.dataLoader = false;
      this.checkFail = true;
    });
    
  }

  homeBreadcrumbClicked(event) {
    if(event.item?.icon == 'pi pi-home'){
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#nexera/dashboard/home`;
    }
  }

  getbusinessWonDataGridList(sDate?: any, eDate?: any): void {
    let typeList = ["SF"];
    this.noWonRecord = false;
    let payload = {
      startDate: sDate, 
      endDate: eDate,
      types: typeList,
      department: null,
      bdSpocUserId: this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid')),
      opportunityIds: null,
      userRole: this.userRole
    }
    if(this.addnPayChecked && this.addPayOppIds && this.addPayOppIds.length > 0){
      payload.opportunityIds = this.addPayOppIds;
    }
    this.pagesService.getbusinessWonDataGridDataReportingScreenGrid(payload).subscribe(results => {
      this.dataLoader = false;
      this.gridLoader = false;
      const gridDataListNew = results["reports"];
      this.gridDataList = gridDataListNew?.map((data) => {
        if(data["rptFlag"] == "null" || data["rptFlag"] == null || data["rptFlag"] == ""){
          data["rptFlag"] = "";
        }
        if(data["crmFinrisk"] == "null" || data["crmFinrisk"] == null || data["crmFinrisk"] == ""){
          data["crmFinrisk"] = "";
        }
        return data;
      });
      const responseData = results["reports"];
      if (responseData && responseData.length > 0) {
        //bill id list
        this.opportunityIdList = this.gridDataList.map(gridData=>(gridData.billId));
        this.oppIdList = ([...new Set(this.gridDataList.map(({billId})=>billId))]).sort();
        this.oppIdList = this.oppIdList.map(data => {return{name : data}})
        for (let i = 0; i < this.opportunityIdList.length; i++) {
          const dashboardItem = this.gridDataList[i];
          const createdByItem = this.opportunityIdList[i];
          dashboardItem["oppIdByList"] = { name: createdByItem };
        }
        //entityname list
        this.entityNameList = this.gridDataList.map(gridData=>(gridData.entityName));
        this.nameList = ([...new Set(this.gridDataList.map(({entityName})=>entityName))]).sort();
        this.nameList = (this.nameList.map(data => {return{name:data}})).sort();
        for (let i = 0; i < this.entityNameList.length; i++) {
          const dashboardItem = this.gridDataList[i];
          const createdByItem = this.entityNameList[i];
          dashboardItem["entityNamebyList"] = { name: createdByItem }
        }

        //nexera user list
        this.nexeraUserList = this.gridDataList.map(gridData=>(gridData.nexeraUser));
        this.nexeraUserList1 = ([...new Set(this.gridDataList.map(({nexeraUser})=>nexeraUser))]).sort();
        this.nexeraUserList1 = this.nexeraUserList1.map(data => {return{name:data}});
        for (let i = 0; i < this.nexeraUserList.length; i++) {
          const dashboardItem = this.gridDataList[i];
          const createdByItem = this.nexeraUserList[i];
          dashboardItem["nexeraUserbyList"] = { name: createdByItem };
        }

        //bd manager list
        this.bdManagerList = this.gridDataList.map(gridData=>(gridData.bdManager));
        this.uniqbdManagerList = ([...new Set(this.gridDataList.map(({bdManager})=>bdManager))]).sort();
        this.uniqbdManagerList = this.uniqbdManagerList.map(data => {return{name:data}});
        for (let i = 0; i < this.bdManagerList.length; i++) {
          const dashboardItem = this.gridDataList[i];
          const createdByItem = this.bdManagerList[i];
          dashboardItem["bdManagerbyList"] = { name: createdByItem };
        }

        //bd spoc list
        this.bdSpocList = this.gridDataList.map(gridData=>(gridData.bdSpoc));
        this.uniqbdSpocList = ([...new Set(this.gridDataList.map(({bdSpoc})=>bdSpoc))]).sort();
        this.uniqbdSpocList = this.uniqbdSpocList.map(data => {return{name:data}});

        this.bdSpocListData = 0;
        for (let i = 0; i < this.uniqbdSpocList.length; i++) {
          if(this.uniqbdSpocList[i]["name"] == this.employeeName){
            this.bdSpocListData += 1;
          }
        }
        for (let i = 0; i < this.bdSpocList.length; i++) {
          const dashboardItem = this.gridDataList[i];
          const createdByItem = this.bdSpocList[i];
          dashboardItem["bdSpocbyList"] = { name: createdByItem };
        }

        //opportunity list
        
        this.opportunityStatusList = ([...new Set(this.gridDataList.map(({opportunityStatus})=>opportunityStatus))]).sort();
        this.opportunityStatusList = this.opportunityStatusList.map(data => {return{name : data}})
        let countWon = 0;
        for (let i = 0; i < this.opportunityStatusList.length; i++) {
          if(this.opportunityStatusList[i]["name"] == "Won - Payment Recd"){
            countWon += 1;
          }
        }
        if(countWon == 0 && !this.addnPayChecked){
          this.opportunityStatusList.push({name:'Won - Payment Recd'});
          this.noWonRecord = true;
          this.filterMsg = "No Opportunity in Won - Payment Received  Stage at this time";
          this.firstLoad = true;
          this.rowsFilterCount = 0;
        }
        this.opportunityList = this.gridDataList.map(gridData=>(gridData.opportunityStatus));
        for (let i = 0; i < this.opportunityList.length; i++) {
          const dashboardItem = this.gridDataList[i];
          const createdByItem = this.opportunityList[i];
          dashboardItem["opportunitybyList"] = { name: createdByItem };
        }

        //date list
        this.dateList = this.gridDataList.map(gridData=>(gridData.businessWonDate));
        this.uniqDateList = ([...new Set(this.gridDataList.map(({businessWonDate})=>businessWonDate))]).sort();
        this.uniqDateList = this.uniqDateList.map(data => {return{name : data}})
        for (let i = 0; i < this.dateList.length; i++) {
          const dashboardItem = this.gridDataList[i];
          const createdByItem = this.dateList[i];
          dashboardItem["datebyList"] = { name: createdByItem };
        }

        //ageongList
        this.ageingList = this.gridDataList.map(gridData=>(gridData.ageing));
        this.uniqAgeingList = ([...new Set(this.gridDataList.map(({ageing})=>ageing))]).sort();
        this.uniqAgeingList = (this.uniqAgeingList.map(data => {return{name : data}})).sort();
        for (let i = 0; i < this.ageingList.length; i++) {
          const dashboardItem = this.gridDataList[i];
          const createdByItem = this.ageingList[i];
          dashboardItem["ageingbyList"] = { name: createdByItem };
        }

        //financial year list
        this.financialYearList = this.gridDataList.map(gridData=>(gridData.financialYear));
        this.financialYear = ([...new Set(this.gridDataList.map(({financialYear})=>financialYear))]).sort();
        this.financialYear = this.financialYear.map(data => {return{name : data}});
        for (let i = 0; i < this.financialYearList.length; i++) {
          const dashboardItem = this.gridDataList[i];
          const createdByItem = this.financialYearList[i];
          dashboardItem["financialYearbyList"] = { name: createdByItem };
        }

        //RPT flag list
        this.rptFlagList = this.gridDataList.map(gridData=>(gridData.rptFlag));
        this.rptFlag = ([...new Set(this.gridDataList.map(({rptFlag})=>rptFlag))]).sort();
        this.rptFlag = this.rptFlag.map(data => {return{name : data}});
        for (let i = 0; i < this.rptFlagList.length; i++) {
          const dashboardItem = this.gridDataList[i];
          const createdByItem = this.rptFlagList[i];
          dashboardItem["rptFlagList"] = { name: createdByItem };
        }

        //fin risk list
        this.finRiskList = this.gridDataList.map(gridData=>(gridData.crmFinrisk));
        this.finRisk = ([...new Set(this.gridDataList.map(({crmFinrisk})=>crmFinrisk))]).sort();
        this.finRisk = this.finRisk.map(data => {return{name : data}})
        for (let i = 0; i < this.finRiskList.length; i++) {
          const dashboardItem = this.gridDataList[i];
          const createdByItem = this.finRiskList[i];
          dashboardItem["finRiskList"] = { name: createdByItem };
        }

        this.gridDataList.forEach((data, index) => {
          data.businessWonDate = data?.businessWonDate.split('-').join('-');
        });
        // this.tempAddnPayGrid = this.gridDataList;
        //send opportunity ids for additionalPayment Count
        if(this.SfReportingScreenGridForm.value.gridCalender == undefined){
          this.sendOppIds();
        }        
      } else {
        this.gridDataList = [];
      }

      if(!this.addnPayChecked){
        if(!this.isScreenClosed){
          this.oppStatusMultiSelect.onChange.emit({originalEvent:null, value:[{name:'Won - Payment Recd'}], itemValue: {name:'Won - Payment Recd'}});
        }        
        let filteredCount: number = 0;
        this.gridDataList.forEach(element => {
          if(element.opportunityStatus == "Won - Payment Recd"){
            filteredCount += 1;
          }
        });
        this.rowsFilterCount = filteredCount;
        this.businessWonCount = results["countsByStatus"]["Won - Payment Recd"];
        if(this.businessWonCount == null || this.businessWonCount == undefined || this.businessWonCount == ""){
          this.businessWonCount = 0;          
        }
      }        
      this.firstLoad = true;
      this.apiFail = false;
    }, (_error) => {
      this.gridDataList = [];
      this.dataLoader = false;
      this.apiFail = true;
    });

  }

  onReportingGridBackBtn(): void {
    sessionStorage.removeItem('urlRedirectedTo');
    sessionStorage.setItem('dashboard', 'true');
    window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
  }

  showFilter() {
    this.filterDisplayRow = true;
    this.filterDisplay = true;
    setTimeout(() => {
      this.patchBdSpoc();
    }, 500);
    this.checkEntityNameAllSelection(this.SfReportingScreenGridForm.get('entityNameFilter').value);
    this.checkFinancialYearAllSelection(this.SfReportingScreenGridForm.get('financialYearFilter').value);
  }

  getBusinessWonListCount() {
    let len = this.gridDataList.length;
    for (let opportunityCount = 0; opportunityCount < len; opportunityCount++) {
      if (this.gridDataList[opportunityCount].opportunityStatus == "Business Won") {
        this.businessWonCount++;
      }
    }
  }

  clear(table: Table) {
    table.reset();
    this.cd.detectChanges();
    if(this.businessWonDataGridid?.first?.filters) { // reset all filters
      Object.values(this.businessWonDataGridid.first.filters).map(item => {
        (<any>item).value = null;
        return item;
      });
    }
    
    this.filterDisplayRow = false;
    this.filterDisplay = false;
    if(table.filteredValue == null || table.filteredValue == undefined){
      this.showFilteredRows = false;
      this.rowsFilterCount = 0;
    }
    this.businessWonDataGridid.first.value.filter((data) => {
      this.excelIds.push(data.opportunityId)
    });

    this.excelData = { 'opportunityIdList': this.excelIds }
    this.getbusinessWonDataGridList(this.startDate, this.endDate);
    this.checkEntityNameAllSelection(this.SfReportingScreenGridForm.get('entityNameFilter').value);
    this.checkFinancialYearAllSelection(this.SfReportingScreenGridForm.get('financialYearFilter').value);
  }

  datePicker(): any {
    if (this.isFirstDate === true) {
      this.startDate = this.SfReportingScreenGridForm.value.gridCalender[0].split("/").reverse().join("-");
      this.isFirstDate = false;
    }
    else {
      this.endDate = this.SfReportingScreenGridForm.value.gridCalender[1].split("/").reverse().join("-");
      this.isFirstDate = true;
      this.getbusinessWonDataGridList(this.startDate, this.endDate);
      this.oppStatusMultiSelect.onChange.emit({originalEvent:null, value:null, itemValue: null});
      this.oppIds.onChange.emit({originalEvent:null, value:null, itemValue: null});
      this.oppName.onChange.emit({originalEvent:null, value:null, itemValue: null});
      this.oppUser.onChange.emit({originalEvent:null, value:null, itemValue: null});
      this.oppBd?.onChange.emit({originalEvent:null, value:null, itemValue: null});
      this.oppFinancialYear.onChange.emit({originalEvent:null, value:null, itemValue: null});
      this.patchBdSpoc();
    }
    if (this.rangeDate[1]) {
      this.calender.overlayVisible = false;
    }
  }

  onClearClick() {
    this.dateTime.setDate(this.dateTime.getDate());
    let minDate = new Date();
    let maxDate = new Date();
    let min;
    let max;
    minDate.setMonth(maxDate.getMonth() - 3);
    min = minDate.toLocaleDateString().split('/');
    max = maxDate.toLocaleDateString().split('/');
    this.startDate = `${min[2]}-${min[0]}-${min[1]}`
    this.endDate = `${max[2]}-${max[0]}-${max[1]}`
    this.getbusinessWonDataGridList(this.startDate, this.endDate);
  }

  countRows(event,table: Table){
    if(table.filteredValue == null || table.filteredValue == undefined){
      this.showFilteredRows = false;
      this.filterMsg = "No data found for the selected filter criteria";
      if(event.value == "Won - Payment Recd" && (this.noWonRecord || this.rowsFilterCount == 0 || this.rowsFilterCount == "0")){
        this.rowsFilterCount = 0;
        this.filterMsg = "No Opportunity in Won - Payment Recd  Stage at this time";
        this.firstLoad = true;
      }
    } else{
      this.showFilteredRows = true;
      if(!this.addnPayChecked){
        this.rowsFilterCount = table.filteredValue.length;
        if(event.value == "Won - Payment Recd" && (this.noWonRecord || this.rowsFilterCount == 0 || this.rowsFilterCount == "0")){
          this.rowsFilterCount = 0;
          this.filterMsg = "No Opportunity in Won - Payment Recd  Stage at this time";
          this.firstLoad = true;
        }
      }        
      console.log('rowsFilterCount', this.rowsFilterCount);
    }
  }

  getCountRowsData(){
    let dataCount = 0;
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve('resolved')
        let tableDataRow = document.getElementsByClassName("p-datatable-tbody");
        dataCount = tableDataRow[0].childElementCount;
        if (dataCount != null && !this.noWonRecord) {
          this.showFilteredRows = true;
          this.rowsFilterCount = dataCount;
          this.dataLoader = false;
        }
      }, 500);
    })
  }

  exportExcel(){
    this.excelIds = []
    this.businessWonDataGridid?.first?.filteredValue?.filter((data) => {
      this.excelIds.push(data.opportunityId);
    });

    if (this.excelIds.length == 0) {
      this.gridDataList.forEach((data) => {
        this.excelIds.push(data.opportunityId);
      });
    }
    let src;
    let fileName = 'SF Reporting Data.csv';
    this.pagesService.sendSFExcelIds(this.excelIds).subscribe((res) => {
      /** src = `data:application/vnd.ms-excel;base64,${res.base64}`;
      const link = document.createElement('a');
      link.href = src;
      link.download = fileName;
      link.click(); **/
      const byteCharacters = atob(res.base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "text/csv" });
      FileSaver.saveAs(blob, fileName);
    });
  }

  generateExcel(){

    this.opportunityIds = []
    this.businessWonDataGridid?.first?.filteredValue?.filter((data) => {
      this.opportunityIds.push(data.opportunityId);
    });

    if (this.opportunityIds.length == 0) {
      this.gridDataList.forEach((data) => {
        this.opportunityIds.push(data.opportunityId);
      });
    }
    let excelType = "SFReportingExcel";
    let excelTypeName = "SF Reports";
    let empId = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid'));
    this.pagesService.generateExcel(excelType,excelTypeName,this.opportunityIds,empId).subscribe((res) => {
      this.messageService.add({ severity: 'success', detail: 'Excel Generated successfully' });
      setTimeout(() => {
        this.messageService.clear();
      }, 4000);
    });
  }

  sendOppIds(){
    let totalOppIds = []
    this.gridDataList.forEach((data) => {
      totalOppIds.push(data.opportunityId);
    });
    
    if(totalOppIds && totalOppIds.length > 0){
      this.pagesService.sendIdsForAdditionalPaymentCount(totalOppIds).subscribe(results => {
        const responseData = results;
        console.log('additionalPaymentCount', responseData);
        this.additionalPaymentData = responseData;
        this.addPayOppIds = responseData.map(item => item.opportunityId);
        this.addnPayCount = responseData.reduce((acc, item) => acc + item.additionalPaymentCount, 0);
      });
    }  
    if(this.addPayOppIds.length && this.addPayOppIds.length == this.gridDataList.length){
      const matchedItems = this.gridDataList.filter(item => this.addPayOppIds.includes(item.opportunityId))
        .map(item => {
          return {
            opportunityId: item.opportunityId,
            opportunityStatus: item.opportunityStatus
          };
      });
      const uniqueOpportunityStatuses = [...new Set(matchedItems.map(item => item.opportunityStatus))];
      console.log(uniqueOpportunityStatuses);
      const value = uniqueOpportunityStatuses.map(status => {
        return { name: status };
      });      
      const itemValue = [...value];      
      this.oppStatusMultiSelect.onChange.emit({
        originalEvent: null,
        value,
        itemValue
      });
    }  
  }

  addnPaymentFilter(event){
    this.addnPayChecked = event.checked;
    if(this.addnPayChecked){
      this.getbusinessWonDataGridList(this.startDate, this.endDate);
      this.rowsFilterCount = this.addPayOppIds.length;
      if(this.addPayOppIds && this.addPayOppIds.length == 0){
        this.gridDataList = [];
        this.filterMsg = "No Opportunity with Additional Payment at this time";
        this.firstLoad = true;
        this.rowsFilterCount = 0;
      }      
    }
    if(!this.addnPayChecked){
      this.isScreenClosed = false;
      this.getbusinessWonDataGridList(this.startDate, this.endDate);
    }
  }

  applyFilterEntityName(event:any):void{
    console.log("event", event);
    if (event.checked) {
      this.selectAllEntityName = event.checked;
      this.SfReportingScreenGridForm.get('entityNameFilter').reset();
      this.SfReportingScreenGridForm.patchValue({ entityNameFilter: this.nameList });
      if (this.businessWonDataGridid1) {
        this.businessWonDataGridid1.filter(this.SfReportingScreenGridForm.get('entityNameFilter')?.value, 'entityNamebyList', 'in');
      }
    } else {
      this.selectAllEntityName = event.checked;
      this.SfReportingScreenGridForm.get('entityNameFilter').reset();
      this.SfReportingScreenGridForm.patchValue({ entityNameFilter: [] });
      this.oppName.onChange.emit({ originalEvent: null, value: [], itemValue: null });
    }
  }
  checkEntityNameAllSelection(eValue) {
    console.log("eValue", eValue);
    console.log("this.qcStatusList", this.nameList);

    if(eValue !== null || eValue !== undefined){
      this.selectAllEntityName = false;
    }else if (eValue.length == this.nameList.length) {
      this.selectAllEntityName = true;
    } else {
      this.selectAllEntityName = false;
    }
  }

  applyFilterFinancialYear(event:any):void{
    console.log("event", event);
    if (event.checked) {
      this.selectAllFinancialYear = event.checked;
      this.SfReportingScreenGridForm.get('financialYearFilter').reset();
      this.SfReportingScreenGridForm.patchValue({ financialYearFilter: this.financialYear });
      if (this.businessWonDataGridid1) {
        this.businessWonDataGridid1.filter(this.SfReportingScreenGridForm.get('financialYearFilter')?.value, 'financialYearbyList', 'in');
      }
    } else {
      this.selectAllFinancialYear = event.checked;
      this.SfReportingScreenGridForm.get('financialYearFilter').reset();
      this.SfReportingScreenGridForm.patchValue({ financialYearFilter: [] });
      this.oppFinancialYear.onChange.emit({ originalEvent: null, value: [], itemValue: null });
    }
  }
  checkFinancialYearAllSelection(eValue) {
    console.log("eValue", eValue);
    console.log("this.qcStatusList", this.financialYear);

    if(eValue !== null || eValue !== undefined){
      this.selectAllFinancialYear = false;
    }else if (eValue.length == this.financialYear.length) {
      this.selectAllFinancialYear = true;
    } else {
      this.selectAllFinancialYear = false;
    }
  }

}
