import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import * as CryptoJS from 'crypto-js';
import { AppLoaderService } from 'app/shared/util/app-loader.service';
@Injectable()
export class EncryptDecryptInterceptor implements HttpInterceptor {
  plainText: any;
  encryptedText: any;
  conversionEncryptOutput: any;
  conversionDecryptOutput: any;
  key: any;
  iv: any;
  encryptData: any;
  par = new Map();

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: AppLoaderService) {
    this.key = CryptoJS.enc.Latin1.parse('Y3Jpc2lsQDIwMjE=');
    this.iv = CryptoJS.enc.Latin1.parse('fedcba9876543210');
  }


  paramsObject: any;
  valParamObject: any;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method.toUpperCase() === 'GET') {
      if (request.url.includes("/files/irfPushPull") || request.url.includes("/masters/getSingleMasterData") || request.url.includes("/hiearachy/export")) {
        return next.handle(request).pipe(
          map(evt => {
            if (evt instanceof HttpResponse) {
              evt = evt.clone({ body: evt.body });
            }
            return evt;
          }));
      } else {
        this.paramsObject = [];
        this.paramsObject = request.params.keys();
        let paramS = new HttpParams();

        this.valParamObject = [];
        this.paramsObject.forEach(element => {
          let a = request.params.get(element);
          this.plainText = a.toString();
          let encrypted = CryptoJS.AES.encrypt(this.plainText, this.key, { iv: this.iv, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC });
          this.conversionEncryptOutput = encrypted.toString();
          paramS = paramS.set(element, this.conversionEncryptOutput);
        });

        if (this.paramsObject.length != 0) {
          return next.handle(request.clone({

            params: paramS
          })).pipe(
            map(evt => {
              if (evt instanceof HttpResponse) {
                let body = this.modifyBody(evt.body);
                body = JSON.parse(body);
                evt = evt.clone({ body: body });
              }
              return evt;
            }));
        } else {
          return next.handle(request).pipe(
            map(evt => {
              if (evt instanceof HttpResponse) {
                let body = this.modifyBody(evt.body);
                body = JSON.parse(body);
                evt = evt.clone({ body: body });
              }
              return evt;
            }));
        }
      }


    } else if (request.method.toUpperCase() === 'POST') {
      this.loaderService.showPageLoader();
      this.plainText = JSON.stringify(request.body);
      let padMsg = this.plainText.toString();
      let encrypted = CryptoJS.AES.encrypt(padMsg, this.key, { iv: this.iv, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC });
      this.conversionEncryptOutput = encrypted.toString();
      const obj = {};
      this.loaderService.hidePageLoader();
      obj['data'] = this.conversionEncryptOutput;
      if (request.url.includes("/findPdfById") || request.url.includes("/mergePdfsById") || request.url.includes("/getByClientMandateList") || request.url.includes("/getByOfflineBillByCompany")) {
        return next.handle(request).pipe(
          map(evt => {
            if (evt instanceof HttpResponse) {
              evt = evt.clone({ body: evt.body });
            }
            return evt;
          }));
      } else {
        return next.handle(request.clone({
          body: obj
        })).pipe(
          map(evt => {
            if (evt instanceof HttpResponse) {
              let body = this.modifyBody(evt.body);
              body = JSON.parse(body);
              evt = evt.clone({ body: body });
            }
            return evt;
          }));
      }

    }
  }

  private padString(source: string) {
    let paddingChar = ' ';
    let size = 16;
    let x = source.length % size;
    let padLength = size - x;
    for (let i = 0; i < padLength; i++) source += paddingChar;
    return source;
  }

  private modifyBody(body) {
    this.encryptData = body;
    let padMsgRes = this.encryptData.data.toString();
    let decryptDataPlain = CryptoJS.AES.decrypt(padMsgRes, this.key, { iv: this.iv, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC });
    let decryptData = decryptDataPlain.toString(CryptoJS.enc.Utf8);
    return decryptData;
  }

  decrypt(dec: any) {
    let decryptDataPlain = CryptoJS.AES.decrypt(dec, this.key, { iv: this.iv, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC })
    let decryptData = decryptDataPlain.toString(CryptoJS.enc.Utf8);
    return decryptData;
  }

  encryptWith(enc: any) {
    let slash = enc.toString();
    let encryptData = CryptoJS.AES.encrypt(slash, this.key, { iv: this.iv, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC });
    return this.replaceAll1(encryptData.toString(), '/', 'Por21Ld');
  }

  encrypt(enc: any) {
    let slash = enc.toString();
    let encryptData = CryptoJS.AES.encrypt(slash, this.key, { iv: this.iv, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC });
    return encryptData.toString();
  }


  encryptLocalObject(object: any) {
    // return JSON.stringify(object);
    let encryptData = CryptoJS.AES.encrypt(JSON.stringify(object), this.key, { iv: this.iv, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC });
    return encryptData.toString();
  }

  replaceAll1(str: string, find: string, replace: string) {
    var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return str.replace(new RegExp(escapedFind, 'g'), replace);
  }

}
