import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { PagesService } from '../pages.service';
import {switchMap} from 'rxjs/operators';
import { SfInitiationDetailsComponent } from '../sf-initiation-details/sf-initiation-details.component';
@Component({
  selector: 'backoffice-sf-initiation-grid',
  templateUrl: './sf-initiation-grid.component.html',
  styleUrls: ['./sf-initiation-grid.component.scss'],
  providers: [DialogService, MessageService]
})
export class SfInitiationGridComponent implements OnInit {
    @ViewChildren('mainGrid') mainGrid;
  
    readonly DEFAULT_SELECTED_STATUS_ID = 1;
    sfCaseOpprList:any = [];
    ccprimebreadcrumbList = [
      { label: `SF Case Listing`, routerLink: ['/pages/initiation'] },
    ];
    apiFail = false;
    displayProcessingModal = false;
    homeiconccprimebreadcrumb = { icon: `pi pi-home` };
    scopeList: any;
    sfInitiationGridForm = this.fb.group({
      initiationFilter: [null, []],
      selecteddropdownNexera: [null, []],
      globalCalendar: [null as any, []]
    });
    filterDisplayRow: boolean = true;
    gridLoader: boolean = true;
    reportedDates = [];
    userId;
    userRole;
    employeeName;
    initData;
    initiationStatusList = [];
    loadingStatus = false;
    disableFilter: boolean = false;
    // date related variables

    minGlobalStartTime = new Date();
    maxGlobalEndTime = new Date();

    globalStartTime = null;
    globalEndTime = null;
    departmentName: any;

    @ViewChild('globalCalendar') globalCalendar: any;
    // end of date related variables

    // modal related variables
    dynamicTableDialogEntityNAMERef: DynamicDialogRef = new DynamicDialogRef;
  
  
    constructor(protected activatedRoute: ActivatedRoute, private fb: FormBuilder, private pagesService: PagesService, private dialogService: DialogService, private messageService: MessageService, private encyDyc: EncryptDecryptInterceptor, private cd: ChangeDetectorRef) { 
      this.minGlobalStartTime.setMonth(this.maxGlobalEndTime.getMonth() - 24);
    }

    ngOnInit() {
      this.userId = this.encyDyc.decrypt(localStorage.getItem('empid'));
      this.scopeList = this.encyDyc.decrypt(localStorage.getItem('scopes'));
      this.userRole = this.encyDyc.decrypt(localStorage.getItem('role'));
      this.departmentName = localStorage.getItem('departmentId') != null ? this.encyDyc.decrypt(localStorage.getItem('departmentId')) : null;
      this.employeeName = this.encyDyc.decrypt(localStorage.getItem('empName'));
      this.getSFCaseList();
    }

    homeBreadcrumbClicked(event) {
      if(event.item?.icon == 'pi pi-home'){
        sessionStorage.removeItem('urlRedirectedTo');
        sessionStorage.setItem('dashboard', 'true');
        window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
      }
    }

    exportExcel(){
      let excelIds = [];
      // get oppr Id from filtered data on screen
      this.mainGrid?.first?.filteredValue?.forEach((data) => {
        excelIds.push(data.id);
      });
      if(excelIds.length === 0 && this.mainGrid?.first?.filters) {
        // excelIds can be empty -> if 
        // - no filters prevail on screen OR 
        // - filters exist, but no data matches them
        let filtersAdded = Object.values(this.mainGrid.first.filters).find((item: any) => item.value != null ? (Array.isArray(item.value) ? item.value.length > 0 : !!(item?.value)) : false ) 
        
        if(!filtersAdded) { // no filters were added, hence export whole data
          this.mainGrid?.first?.value?.forEach((data) => {
            excelIds.push(data.id);
          });
        }
      }
      let fileName = 'SF Initiation screen data';
      this.displayProcessingModal = true;
      this.pagesService.sfInitiationExport({ 'opportunityIdList': excelIds}, fileName).subscribe({
        next : () => {
          this.messageService.add({severity: 'success', summary: 'Data exported successfully'});
          this.displayProcessingModal = false;
          setTimeout(() => {
            this.messageService.clear();
          }, 4000);
        },
        error : () => {
          this.messageService.add({ severity: 'error', summary: 'Something went wrong! Please try again' });
          this.displayProcessingModal = false;
          setTimeout(() => {
            this.messageService.clear();
          }, 4000);
        }
      });
    }

    // method to toggle filter icon, and reset table on filter clear
    toggleFilter(table: Table){
      this.filterDisplayRow = !this.filterDisplayRow;
      this.cd.detectChanges();
      if(this.filterDisplayRow === false) {
        table?.reset();
        if(this.mainGrid?.first?.filters) { // reset all filters
          Object.values(this.mainGrid.first.filters).map(item => {
            (<any>item).value = null;
            return item;
          });
        }
        this.sfInitiationGridForm.patchValue({initiationFilter: null});
        this.sfInitiationGridForm.patchValue({globalCalendar: null});
        this.sfCaseOpprList = this.initData;
        this.getReportedDates();
      }
    }

    openManualModal(opprData : any) {
      this.dynamicTableDialogEntityNAMERef = this.dialogService.open(SfInitiationDetailsComponent, {
        // contentStyle: { "height": "76vh", "overflow": "hidden" },
        contentStyle: { "max-height": "76vh", "overflow": "auto", "margin-bottom": "25px" },
        closeOnEscape: false,
        closable: true,
        data: opprData,
        width: '96%',
        header: opprData?.name
      });
      this.dynamicTableDialogEntityNAMERef.onClose.subscribe((response: any) => {
        if (response?.toLowerCase() == 'case updated successfully') {        
          this.getSFCaseList(this.globalStartTime, this.globalEndTime);
          this.messageService.add({ severity: 'success', detail: 'SF case initiated successfully' });
        } 
        else if (response != undefined) {
          this.messageService.add({ severity: 'error', detail: 'Error while updating data, please try again!' });
        }
        setTimeout(() => {
          this.messageService.clear();
        }, 4000);
      });
    }
  
    filterGlobalTableByDate() {
      const startTime = this.sfInitiationGridForm.value.globalCalendar[0]?.split("/").reverse().join("-");
      const endTime = this.sfInitiationGridForm.value.globalCalendar[1]?.split("/").reverse().join("-");
      if(this.globalEndTime) {
        this.globalStartTime = startTime;
        this.globalEndTime = undefined;
      } else {
        this.globalStartTime = startTime;
        this.globalEndTime = endTime;  
      }
      if(this.globalStartTime && this.globalEndTime) {
        this.globalCalendar.overlayVisible = false;
        
        this.getSFCaseList(this.globalStartTime, this.globalEndTime);
      }
    }

    onClearGlobalDate() {
      if(this.globalStartTime && this.globalEndTime) {
        this.getSFCaseList();
      }
      this.globalEndTime = undefined;
      this.globalStartTime = undefined;
      this.sfInitiationGridForm.patchValue({globalCalendar : null});
    }

    onBackClick() {
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }

    // method to get grid data and display on UI
    getSFCaseList(startDate? : string, endDate?: string) {
      this.gridLoader = true;
      let bdSpocUserId = this.encyDyc.decrypt(localStorage.getItem('empid'));
      let department = null;
      // if(this.departmentName === "DEP36"){
      //   bdSpocUserId = this.encyDyc.decrypt(localStorage.getItem('empid'));
      //   department = "LCG";
      // } else {
      //   department = "MCG";
      // }

      let getSfOppr$ = this.initiationStatusList.length === 0 ? this.pagesService.getCaseInitiationStatus().pipe(switchMap((initiationStatusList : any[]) => {
        if(initiationStatusList.length > 0) {
          initiationStatusList.map(status => {
            this.initiationStatusList.push({label : status.name, value: status.name, id: status.id})
          });
          // 1 - set default initiation status value in dropdown, later filter table as per this value in step 2
          let defaultLabel = this.initiationStatusList.find(item => item.id === this.DEFAULT_SELECTED_STATUS_ID);
          if(defaultLabel)
            this.sfInitiationGridForm.patchValue({initiationFilter : defaultLabel.value});
        }
        return this.pagesService.getSFInitiationGridList(startDate, endDate, bdSpocUserId, this.userRole, department);
      })) : this.pagesService.getSFInitiationGridList(startDate, endDate, bdSpocUserId, this.userRole, department);

      getSfOppr$.subscribe({
        next : (opportunities: Array<any>) => {
          this.reportedDates= [];
          if(opportunities) {
            this.sfCaseOpprList = opportunities.map(opportunity => {
              const reportedDate = opportunity?.businessReportedDate?.split("-").reverse().join("-");
              let opprStatus = opportunity.initiationStatus == null ? this.initiationStatusList.find(item => item.id === 1): this.initiationStatusList.find(item => item.id === opportunity.initiationStatus);
              opprStatus = opprStatus?.label;
              return { 
                ...opportunity ,
                businessReportedDate :reportedDate || null,
                initiationStatus: opprStatus,
                initiationComment : opportunity.initiationComment || 'Blank',
              }
            }
            );
            
            if(this.sfInitiationGridForm.get('initiationFilter').value !== null)  {  // 2 - default table filter on load of screen
              this.mainGrid.first.filter(this.sfInitiationGridForm.get('initiationFilter').value, 'initiationStatus', 'equals');
            }
            if(!this.initData)
              this.initData = this.sfCaseOpprList;
            this.getReportedDates();
          }
          this.gridLoader = false;
          this.apiFail = false;
          // this.patchBdSpoc();
        },
        error : err => {
          console.error("sf-init-grid error while fetching records :: ", err);
          this.gridLoader = false;
          this.apiFail = true;
        }
      })
    }

    patchBdSpoc(){
    if(this.departmentName == "DEP36"){
      this.disableFilter = true;
      this.mainGrid.first.filter(this.employeeName, 'opportunitybdSpoc', 'equals');
    }
  }

    // method to get updated reportDate list for multi-select
    getReportedDates() {
      let reportedDateSet = new Set();
      this.reportedDates = [];
      if(this.sfCaseOpprList) {
        this.sfCaseOpprList.map(opportunity => {
          const reportedDate = opportunity?.businessReportedDate;
          reportedDateSet.add(reportedDate);
        });
        reportedDateSet.forEach(date => {
          this.reportedDates.push({label : date || '', value: date || null});
        });
      }
    }

}