import { Location } from '@angular/common';
import { Component, OnInit, ViewChildren, AfterViewInit, ViewChild, Renderer2, ChangeDetectorRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { Table, TableCheckbox } from 'primeng/table';
import { PagesService } from '../pages.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { SfPushPullDetailsComponent } from '../sf-push-pull-details/sf-push-pull-details.component';
import { MultiSelect } from 'primeng/multiselect';

@Component({
  selector: 'backoffice-sf-push-pull-grid',
  templateUrl: './sf-push-pull-grid.component.html',
  styleUrls: ['./sf-push-pull-grid.component.scss'],
  providers: [DialogService, MessageService]
})
export class SfPushPullGridComponent implements OnInit, AfterViewInit {
  @ViewChildren('mainGrid') mainGrid;
  @ViewChild('mainGrid') mainGrid1 :Table ;
  @ViewChild('checkboxRef', { static: false }) checkboxRef!: TableCheckbox;
  @ViewChild('globalCalendar') globalCalendar: any;

  detailsPopUp: DynamicDialogRef = new DynamicDialogRef;
  ccprimebreadcrumbList = [
    { label: `SF Invoices Listing`, routerLink: ['/pages/invoices/sf'] },
  ];

  homeiconccprimebreadcrumb = { icon: `pi pi-home` };
  loadingStatus = false; 
  pendingCasesCount: any = 0;
  pendingInvoiceCount: any = 0;
  pendingCNCount: any = 0;
  casesSelected: any = 0;
  filterDisplay: boolean = true;
  showLoader: boolean = false;
  invoiceDataList: any[] = [];

  clientTypeList :any;
  noOfAgreementsList = [];
  invoiceStatusList = [];
  transcationTypeList :any;
  invoiceNoList = [];
  originalBilledAmountList = [];
  proposedBilledAmountList = [];
  amountRealizedList = [];
  cnNoList = [];
  errorStatusList = [];
  sfBillIdList = [];
  revisionReasons :any;
  isChecked: boolean = true;

  sfPullPushForm = this.fb.group({
    invoiceStatusFilter: ['Pending', []],
    companyNameSearchValue: [null, []],
    globalCalendar: [null as any, []],
    clientTypeFilter: [null as any, []],
    transactionTypeFilter: [null as any, []],
    revisionReasonFilter: [null as any, []],
  });
  disableProcessBtn: boolean = true;
  sfBillDataList = [];
  selectSFBillIdList = [];
  gridLoader: boolean = false;
  apiFail: boolean = false;
  querysfBillId: any;
  showCross: boolean = false;
  showEntityLoader: boolean = false;
  companyNameList: any[];
  emptyMsgFlag: boolean = false;
  emptyMsgVal: string = 'No Company Found';
  minGlobalStartTime = new Date();
  maxGlobalEndTime = new Date();

  globalStartTime = null;
  globalEndTime = null;
  cnOfRevisionNotProcessed: boolean = false;
  filteredListRevision: any[];
  unfilteredListRevision: any[];
  cnOfRevisionNotProcessedMsg:string="";
  selectAllClientType:boolean = false;
  @ViewChild('oppClientType') oppClientType : MultiSelect;
  clientTypeIDList :any;
  selectAllTransactionType:boolean = false;
  @ViewChild('oppTransactionType') oppTransactionType : MultiSelect;
  transcationTypeIDList :any;
  selectAllRevisionReason:boolean = false;
  @ViewChild('oppRevisionReason') oppRevisionReason : MultiSelect;
  revisionReasonsIDList :any;

  constructor(private _location: Location, private fb: FormBuilder, private pagesService: PagesService, private dialogService: DialogService,private renderer: Renderer2,private cd: ChangeDetectorRef) { }
  ngAfterViewInit(): void {
    if (this.sfPullPushForm.get('invoiceStatusFilter').value !== null) {  // 2 - default table filter on load of screen
      this.mainGrid.first.filter("Pending", 'status', 'equals');
    }
  }

  ngOnInit(): void {

    this.getPushGridList();
  }

  homeBreadcrumbClicked(event) {
    if (event.item?.icon == 'pi pi-home') {
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}/#/nexera/dashboard/home`;
    }
  }

  showFilter(table: Table) {
    this.filterDisplay = !this.filterDisplay;
    this.cd.detectChanges();
    if (this.filterDisplay === false) {
      table?.reset();

      if (this.mainGrid?.first?.filters) { // reset all filters
        Object.values(this.mainGrid.first.filters).map(item => {
          (<any>item).value = null;
          return item;
        });
      }
      this.sfPullPushForm.patchValue({invoiceStatusFilter:null});
      this.sfPullPushForm.patchValue({companyNameSearchValue:null});
      this.sfPullPushForm.get('clientTypeFilter').reset();
      this.sfPullPushForm.patchValue({ clientTypeFilter: [] });
      this.sfPullPushForm.get('transactionTypeFilter').reset();
      this.sfPullPushForm.patchValue({ transactionTypeFilter: [] });
      this.sfPullPushForm.get('revisionReasonFilter').reset();
      this.sfPullPushForm.patchValue({ revisionReasonFilter: [] });
      this.getPushGridList();
    }
    
    this.checkClientTypeAllSelection(this.sfPullPushForm.get('clientTypeFilter')?.value);
    this.checkTransactionTypeAllSelection(this.sfPullPushForm.get('transactionTypeFilter')?.value);
    this.checkRevisionReasonAllSelection(this.sfPullPushForm.get('revisionReasonFilter')?.value);
  }

  onBackClick() {
    this._location.back();
  }

  setListData(sfCaseDetails: Array<any>) {
    // logic to put Revision entries in WIP, if its CN is unprocessed, and revision in PENDING state
    // if(sfCaseDetails?.length > 0) {
    //   sfCaseDetails = sfCaseDetails.map(item => {
    //     if((item?.status === "Pending") && item?.sfBillId?.includes("_R"))
    //       item.status = "WIP";
    //     return item;
    //   });
    // }
    // end of logic block
    this.invoiceDataList = <[]>sfCaseDetails;
    this.clientTypeList = new Set();
    let noOfAgreementsSet = new Set();
    let invoiceStatusSet = new Set();
    this.transcationTypeList = new Set();
    let invoiceNoSet = new Set();
    let originalBilledAmountSet = new Set();
    let proposedBilledAmountSet = new Set();
    let amountRealizedSet = new Set();
    let cnNoSet = new Set();
    let errorStatusSet = new Set();
    this.revisionReasons = new Set();

    if (this.invoiceDataList) {
      this.invoiceDataList.forEach(invoice => {
        this.clientTypeList.add(invoice?.clientType);
        noOfAgreementsSet.add(invoice?.noOfAgreement);
        invoiceStatusSet.add(invoice?.status);
        this.transcationTypeList.add(invoice?.transactionType);
        invoiceNoSet.add(invoice?.invoiceNo);
        originalBilledAmountSet.add(invoice?.originalBilledAmount);
        proposedBilledAmountSet.add(invoice?.proposedBilledAmount);
        amountRealizedSet.add(invoice?.amountRealized);
        cnNoSet.add(invoice?.cnNo);
        errorStatusSet.add(invoice?.errorStatus);
        this.revisionReasons.add(invoice?.revisionReason);
      });

      this.clientTypeIDList = [...this.clientTypeList];
      this.clientTypeList = Array.from(this.clientTypeList).map(clientType => {
        return { label: clientType || '', value: clientType || '' };
      });

      this.noOfAgreementsList = Array.from(noOfAgreementsSet).map(noOfAgreement => {
        return { label: noOfAgreement || '', value: noOfAgreement || null };
      });

      this.invoiceStatusList = Array.from(invoiceStatusSet).map(status => {
        return { label: status || '', value: status || null };
      });

      this.transcationTypeIDList = [...this.transcationTypeList];
      this.transcationTypeList = Array.from(this.transcationTypeList).map(transactionType => {
        return { label: transactionType || '', value: transactionType || null };
      });

      this.invoiceNoList = Array.from(invoiceNoSet).map(invoiceNo => {
        return { label: invoiceNo || '', value: invoiceNo || null };
      });

      this.originalBilledAmountList = Array.from(originalBilledAmountSet).map(originalBilledAmount => {
        return { label: originalBilledAmount || '', value: originalBilledAmount || null };
      });

      this.proposedBilledAmountList = Array.from(proposedBilledAmountSet).map(proposedBilledAmount => {
        return { label: proposedBilledAmount || '', value: proposedBilledAmount || null };
      });
      
      this.amountRealizedList = Array.from(amountRealizedSet).map(amountRealized => {
        return { label: amountRealized || '', value: amountRealized || null };
      });
      
      this.cnNoList = Array.from(cnNoSet).map(cnNo => {
        return { label: cnNo || '', value: cnNo || null };
      });
      
      this.errorStatusList = Array.from(errorStatusSet).map(errorStatus => {
        return { label: errorStatus || '', value: errorStatus || null };
      });
      
      this.revisionReasonsIDList = [...this.revisionReasons];
      this.revisionReasons = Array.from(this.revisionReasons).map(revisionReason => {
        return { label: revisionReason || '', value: revisionReason || null };
      });
      
    }
  }

  addSfBillData(sfBillData: any) {
    let sfBillStatus = sfBillData.status;
    if (sfBillStatus == 'Pending' || sfBillStatus == 'Error' || sfBillStatus == 'WIP') {
      const index = this.sfBillIdList.indexOf(sfBillData);
      if (index === -1) {

        // sfBillId is not in the selectedIds array, add it
        this.sfBillIdList.push(sfBillData);
        this.casesSelected += 1;
      } else {
        // sfBillId is already in the selectedIds array, remove it
        this.sfBillIdList.splice(index, 1);
        this.casesSelected -= 1;
      }
      if (this.sfBillIdList != null && this.sfBillIdList.length != 0 && this.sfBillIdList != undefined) {
        this.disableProcessBtn = false;
      } else {
        this.disableProcessBtn = true;
      }
    }

  }

  processInvoice() {
    this.disableProcessBtn = true;
    let processInvoiceData = [];
    let processCNData = [];
    let processRevisionData = [];
    let sfInvoiceSet = new Set(this.invoiceDataList?.map(data_ => data_?.sfBillId));
    this.gridLoader = true;
    
    this.selectSFBillIdList = this.sfBillIdList;
    const unprocessedRevision = [];
    this.sfBillIdList.forEach(data => {
      const sfBillId = data.sfBillId;
      if(sfBillId.includes("_C")){
        let invoiceData = {
          "selectedInvoiceId": data.sfBillId
        }
        processCNData.push(invoiceData);
        let correspondingRevInvoice = sfBillId?.replace("C", "R"); 
        if(correspondingRevInvoice != null && sfInvoiceSet.has(correspondingRevInvoice) && data.status === "Error") {
          processRevisionData.push({"selectedInvoiceId" : correspondingRevInvoice});
        }
      } else if(sfBillId.includes("_R")){
        const selectedBillIDTypeNumber = sfBillId.replace('_R','_C');
        const filteredData = this.unfilteredListRevision.find(item => item.sfBillId === selectedBillIDTypeNumber && item.status === "Processed")
        if(filteredData != null || filteredData != undefined){
          console.log("status to check: Processed");
          let invoiceData = {
            "selectedInvoiceId": data.sfBillId
          }
          processRevisionData.push(invoiceData);
        }else{
          unprocessedRevision.push(sfBillId);
          if(unprocessedRevision.length <= 10){
            this.cnOfRevisionNotProcessed = true;
            this.cnOfRevisionNotProcessedMsg = `Invoice with SF Bill ID ${unprocessedRevision.length == 1 ? unprocessedRevision[0] : unprocessedRevision.join(', ')} cannot be pushed, as CN (Credit Note) is still not processed.`;
          }
        }
      } else{
        let invoiceData = {
          "selectedInvoiceId": data.sfBillId
        }
        processInvoiceData.push(invoiceData);
      }
      
    });

    

    if(processInvoiceData?.length>0){
      let payload = {
        "ofaPushRequestListIds": processInvoiceData
      }

      this.pagesService.pushInvoiceToFusion(payload).subscribe((results: any) => {
        this.gridLoader = false;
        this.createInvoiceDataList(this.selectSFBillIdList);
      },(_error) => {
        this.gridLoader = false;
        this.apiFail = true;
      });
    }

    if(processCNData?.length>0){
      let payload = {
        "ofaPushRequestListIds": processCNData
      }

      this.pagesService.pushCNToFusion(payload).subscribe((results: any) => {
        this.gridLoader = false;
        this.createInvoiceDataList(this.selectSFBillIdList);
      },(_error) => {
        this.gridLoader = false;
        this.apiFail = true;
      });
    }

    if(processRevisionData?.length>0){
      let payload = {
        "ofaPushRequestListIds": processRevisionData
      }

      this.pagesService.pushRevisionToFusion(payload).subscribe((results: any) => {
        this.gridLoader = false;
        this.createInvoiceDataList(this.selectSFBillIdList);
      },(_error) => {
        this.gridLoader = false;
        this.apiFail = true;
      });
    }
    this.unSelectHeaderCheckbox();
    
    this.disableProcessBtn = true;
  }

  selectAllRows() {
    if (this.sfBillDataList != null && this.sfBillDataList.length != 0 && this.sfBillDataList != undefined) {
      this.sfBillIdList = this.sfBillDataList;
      this.casesSelected = this.sfBillDataList.length;
    } else {
      this.sfBillIdList = [];
      this.casesSelected = 0;
    }
    if (this.sfBillIdList != null && this.sfBillIdList.length != 0 && this.sfBillIdList != undefined) {
      this.disableProcessBtn = false;
    } else {
      this.disableProcessBtn = true;
    }
  }

  unSelectHeaderCheckbox() {
    this.invoiceDataList.forEach(data => {
      let querySelectorData = "#checkboxRef_" + data?.sfBillId + " " + `input[type="checkbox"]`
      let checkboxEle = <HTMLInputElement>document.querySelector(querySelectorData);

      if (checkboxEle) {
        let selectAllCheckbox = checkboxEle.closest('.p-checkbox').querySelector('.p-checkbox-box') as HTMLElement;
        let selectAllCheckboxIcon = checkboxEle.closest('.p-checkbox').querySelector('.p-checkbox-box span');

        if (checkboxEle.checked == true) {
          selectAllCheckbox.click();
        }
      }
    });
    this.sfBillIdList = [];
    this.casesSelected = 0;

  }

  getPushGridList(startDate?: string, endDate?: string) {
    this.gridLoader = true;
    this.pagesService.getSFPushGridList(startDate, endDate).subscribe({
      next: (sfCaseDetails: Array<any>) => {
        this.setListData(sfCaseDetails["gridList"]);
        this.unfilteredListRevision = sfCaseDetails["gridList"];
        this.pendingCasesCount = sfCaseDetails["pendingCases"];
        let countByTransactionType = sfCaseDetails["countByTransactionType"];
        this.pendingCNCount = countByTransactionType["CN"] == null ? 0 : countByTransactionType["CN"];
        this.pendingInvoiceCount = countByTransactionType["INVOICE"] == null ? 0 : countByTransactionType["INVOICE"];
        this.gridLoader = false;
        this.apiFail = false;
      },
      error: (err) => {
        this.gridLoader = false;
        this.apiFail = true;
      }
    })
  }

  onClearSearch(): void {
    this.getPushGridList();
  }

  onChangeCompanySelect(e): void {
    console.log(e);
    
    if (e?.value?.companyName) {
      this.querysfBillId = e?.value?.companyName;
      this.gridLoader = true;
      this.pagesService.getCompanyBySfBillId(this.querysfBillId).subscribe({
        next: (opportunities: Array<any>) => {
          this.setListData(opportunities);
          this.gridLoader = false;
          this.apiFail = false;
          this.sfPullPushForm.patchValue({ globalCalendar: null });
          this.globalStartTime = null;
          this.globalEndTime = null;
        },
        error: (err) => {
          this.gridLoader = false;
          this.apiFail = true;
        }
      })
    }
    this.showCross = true;
  }

  loadCompanyList(event: any): any {
    if (event.query.length >= 3) {
      this.showEntityLoader = true;
      this.pagesService.getPushCompanySearchResults(event.query).subscribe(
        (results: any) => {
          const responseData = results;
          this.showEntityLoader = false;
          this.companyNameList = [];
          if (responseData && responseData.length > 0) {
            this.companyNameList = responseData;
          } else {
            this.companyNameList = [];
            this.emptyMsgFlag = true;
          }
        }, (_err) => {
          this.companyNameList = []
          this.showEntityLoader = false;
          this.emptyMsgFlag = true;
        });
    } else {
      this.companyNameList = [];
    }
  }

  clearCompanySearch() {
    this.showCross = false;
    this.onClearSearch();
    this.querysfBillId = null;
    this.sfPullPushForm.get('companyNameSearchValue').setValue(null);
  }

  filterGlobalTableByDate() {
    const startTime = this.sfPullPushForm.value.globalCalendar[0]?.split("/").reverse().join("-");
    const endTime = this.sfPullPushForm.value.globalCalendar[1]?.split("/").reverse().join("-");
    if (this.globalEndTime) {
      this.globalStartTime = startTime;
      this.globalEndTime = undefined;
    } else {
      this.globalStartTime = startTime;
      this.globalEndTime = endTime;
    }
    if (this.globalStartTime && this.globalEndTime) {
      this.globalCalendar.overlayVisible = false;
      this.sfPullPushForm.patchValue({ companyNameSearchValue: null });
      this.querysfBillId = null;
      this.showCross = false;
      this.showEntityLoader = false;
      this.getPushGridList(this.globalStartTime, this.globalEndTime);
    }
  }

  onClearGlobalDate() {
    if (this.globalStartTime && this.globalEndTime) {
      this.getPushGridList();
    }
    this.globalEndTime = undefined;
    this.globalStartTime = undefined;
    this.sfPullPushForm.patchValue({ globalCalendar: null });
  }

  openDetailsPopup(rowData: any) {
    this.detailsPopUp = this.dialogService.open(SfPushPullDetailsComponent, {
      contentStyle: { "height": "65vh", "overflow": "hidden", "padding-left": "15px" },
      closeOnEscape: false,
      closable: true,
      data: rowData,
      width: '90%',
      header: rowData.companyName
    });
    this.detailsPopUp.onClose.subscribe((response: any) => {
      let selectedCase = response;

      if (selectedCase) {

        let querySelectorData = "#checkboxRef_" + selectedCase?.sfBillId + " " + `input[type="checkbox"]`
        let checkboxEle = <HTMLInputElement>document.querySelector(querySelectorData);
        if (checkboxEle) {
          let selectAllCheckbox = checkboxEle.closest('.p-checkbox').querySelector('.p-checkbox-box') as HTMLElement;
          let selectAllCheckboxIcon = checkboxEle.closest('.p-checkbox').querySelector('.p-checkbox-box span');

          if (checkboxEle.checked == false) {
            selectAllCheckbox.click();
          }
        }
      }
    });
  }

  createInvoiceDataList(selectSFBillIdList:any[]){
    this.invoiceDataList.forEach((invoice) => {
      let index = selectSFBillIdList.findIndex(s => s.sfBillId == invoice.sfBillId);
      if(index != -1){
        invoice.status = "WIP";
      }
    });
    this.setListData(this.invoiceDataList);
    if (this.sfPullPushForm.get('invoiceStatusFilter').value !== null) {  // 2 - default table filter on load of screen
      this.mainGrid.first.filter(this.sfPullPushForm.get('invoiceStatusFilter').value, 'status', 'equals');
    }
  }

  showErrorMsg(data:any){
    if(data?.errorStatus != null && data?.errorStatus != '' && data?.errorStatus != undefined){
      let invoiceDetailsId = "tooltipOpp_"+data.sfBillId;
      document.getElementById(invoiceDetailsId)?.setAttribute('data-tooltip', data?.errorStatus);
      this.renderer.addClass(document.body, 'overflow-scroll');
      this.renderer.removeClass(document.body, 'overflow-hidden');
    }
    
  }

  applyFilterClientType(event: any) {
    console.log("event", event);
    if (event.checked) {
      this.selectAllClientType = event.checked;
      this.sfPullPushForm.get('clientTypeFilter').reset();
      this.sfPullPushForm.patchValue({ clientTypeFilter: this.clientTypeIDList });
      if (this.mainGrid1) {
        this.mainGrid1.filter(this.sfPullPushForm.get('clientTypeFilter')?.value, 'clientType', 'in');
      }
    } else {
      this.selectAllClientType = event.checked;
      this.sfPullPushForm.get('clientTypeFilter').reset();
      this.sfPullPushForm.patchValue({ clientTypeFilter: [] });
      this.oppClientType.onChange.emit({ originalEvent: null, value: [], itemValue: null });
    }
  }

  checkClientTypeAllSelection(eValue) {
    console.log("eValue", eValue);
    console.log("this.clientTypeIDList", this.clientTypeIDList);

    if(eValue !== null || eValue !== undefined){
      this.selectAllClientType = false;
    }else if (eValue.length == this.clientTypeIDList.length) {
      this.selectAllClientType = true;
    } else {
      this.selectAllClientType = false;
    }
  }

  applyFilterTransactionType(event: any) {
    console.log("event", event);
    if (event.checked) {
      this.selectAllTransactionType = event.checked;
      this.sfPullPushForm.get('transactionTypeFilter').reset();
      this.sfPullPushForm.patchValue({ transactionTypeFilter: this.transcationTypeIDList });
      if (this.mainGrid1) {
        this.mainGrid1.filter(this.sfPullPushForm.get('transactionTypeFilter')?.value, 'transactionType', 'in');
      }
    } else {
      this.selectAllTransactionType = event.checked;
      this.sfPullPushForm.get('transactionTypeFilter').reset();
      this.sfPullPushForm.patchValue({ transactionTypeFilter: [] });
      this.oppTransactionType.onChange.emit({ originalEvent: null, value: [], itemValue: null });
    }
  }

  checkTransactionTypeAllSelection(eValue) {
    console.log("eValue", eValue);
    console.log("this.transcationTypeIDList", this.transcationTypeIDList);

    if(eValue !== null || eValue !== undefined){
      this.selectAllTransactionType = false;
    }else if (eValue.length == this.transcationTypeIDList.length) {
      this.selectAllTransactionType = true;
    } else {
      this.selectAllTransactionType = false;
    }
  }

  applyFilterRevisionReason(event: any) {
    console.log("event", event);
    if (event.checked) {
      this.selectAllRevisionReason = event.checked;
      this.sfPullPushForm.get('revisionReasonFilter').reset();
      this.sfPullPushForm.patchValue({ revisionReasonFilter: this.revisionReasonsIDList });
      if (this.mainGrid1) {
        this.mainGrid1.filter(this.sfPullPushForm.get('revisionReasonFilter')?.value, 'revisionReason', 'in');
      }
    } else {
      this.selectAllRevisionReason = event.checked;
      this.sfPullPushForm.get('revisionReasonFilter').reset();
      this.sfPullPushForm.patchValue({ revisionReasonFilter: [] });
      this.oppRevisionReason.onChange.emit({ originalEvent: null, value: [], itemValue: null });
    }
  }

  checkRevisionReasonAllSelection(eValue) {
    console.log("eValue", eValue);
    console.log("this.revisionReasonsIDList", this.revisionReasonsIDList);

    if(eValue !== null || eValue !== undefined){
      this.selectAllRevisionReason = false;
    }else if (eValue.length == this.revisionReasonsIDList.length) {
      this.selectAllRevisionReason = true;
    } else {
      this.selectAllRevisionReason = false;
    }
  }

}
