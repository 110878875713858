
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SfBillingService } from '../sf-billing.service';
import { Router } from '@angular/router';
import { MultiSelect } from 'primeng/multiselect';

@Component({
  selector: 'backoffice-sf-bill-tracking-advance-filter',
  templateUrl: './sf-bill-tracking-advance-filter.component.html',
  styleUrls: ['./sf-bill-tracking-advance-filter.component.scss'],
  providers: [DialogService]
})
export class SfBillTrackingAdvanceFilterComponent implements OnInit {
  // [x: string]: any;

  @ViewChild('bdOwnerNameDropdown')
  bdOwnerNameDropdown: MultiSelect;

  @HostListener('window:popstate', ['$event'])
  onPopState(_event) {
    this.ref.close();
  }

  financialYear;
  entityStatus;
  clientType;
  sfBillStatus;
  assignmentFamily;
  bdOwnerName;
  infoMsg: any;
  financialYearList;
  entityStatusList;
  sfBillStatusList;
  clientTypeNameAPIList;
  clientTypeList;
  lotNumber = null;
  assignmentFamilyListUnfiltered;
  assignmentFamilyList;
  lotNumberList: any[];
  clearAllDisabled: boolean = true;
  saveButtonClicked: boolean = false;

  currDate = new Date();
  currentMonth = this.currDate.getMonth();
  currentFinancialYear = this.currentMonth >= 3 ? this.currDate.getFullYear() + '-' + (this.currDate.getFullYear() + 1).toString().substring(2) : (this.currDate.getFullYear() - 1) + '-' + (this.currDate.getFullYear()).toString().substring(2);
  defaultValues = {
    financialYear: this.currentFinancialYear,
    entityStatus: 'Cooperative',
    clientType: 'All',
    assignmentFamily: 'All',
    sfBillStatus: 0,
    lotNumber: null,
  }


  disableFields: boolean = false;
  selectAll: boolean = true;
  bdOwnerNameList: any;
  gridLoader: boolean;
  apiFail: boolean;
  searchedEditList: any[];
  financialYearListUnfiltered: any;
  disableExport: boolean;

  constructor(public ref: DynamicDialogRef, private sfBillingService: SfBillingService, private router: Router,) {
    const navigation = this.router.getCurrentNavigation();
    this.infoMsg = navigation?.extras?.state?.msg;
  }

  ngOnInit(): void {
    this.getDropdownValuesApis();
    this.lotNumber = null;
    this.financialYear = this.defaultValues.financialYear;
    this.entityStatus = this.defaultValues.entityStatus;
    this.clientType = this.defaultValues.clientType;
    this.sfBillStatus = this.defaultValues.sfBillStatus;
    console.log("this.sfbillstatuslist dropdown",this.sfBillStatus);
  }
  onFilter(event: any) {
    const query: string = event.filter;
    if (query?.length >= 3) {
      this.sfBillingService.getBDOwnerNames(query).subscribe((data: any) => {

        if (this.bdOwnerName == null || this.bdOwnerName.length == 0) {
          this.bdOwnerNameList = data;
        } else {
          this.bdOwnerNameList = [...this.bdOwnerName, ...data];
          this.bdOwnerNameDropdown.hide();
          this.bdOwnerNameDropdown.show();
        }

      });
    }
  }
  onCancel(): void {
    this.ref.close();

  }

  onApply(): void {
    this.gridLoader = true;
    this.apiFail = false;
    if (this.sfBillStatus == 0) {
      this.sfBillStatus = null;
    }
    let assignmentFamilySelected;
    assignmentFamilySelected = this.assignmentFamily.map(item => item.value);
    if (this.assignmentFamily?.length == this.assignmentFamilyList.length) {
      assignmentFamilySelected = null;
    }

    let employeeIdList;
    if (this.bdOwnerName != null && this.bdOwnerName != undefined) {
      employeeIdList = this.bdOwnerName.map(item => item.employeeId);
    }

    let bdId;
    if (employeeIdList && employeeIdList.length > 0) {
      bdId = employeeIdList;
    } else {
      bdId = null;
    }
    let payload = {

      "financialYear": this.financialYear,
      "entityStatus": this.entityStatus == "All"?null:this.entityStatus,
      "clientType": this.clientType == "All" ? null : this.clientType,
      "assignmentFamily": assignmentFamilySelected == undefined ? null : assignmentFamilySelected,
      "billStatus": this.sfBillStatus == undefined ? null : this.sfBillStatus == this.defaultValues.sfBillStatus ? null : this.sfBillStatus,
      "lotNumber": this.lotNumber == undefined ? null : this.lotNumber,
      "bdOwnerIds": bdId
    }

    this.sfBillingService.sfBillTrackingGridApi(payload).subscribe({
      next: (sfCaseDetails: Array<any>) => {
        this.searchedEditList = sfCaseDetails;
        this.gridLoader = false;
        this.apiFail = false;
        setTimeout(() => {
          this.ref.close(sfCaseDetails);
        }, 1000);
      },
      error: (err) => {
        this.gridLoader = false;
        this.apiFail = true;
        setTimeout(() => {
          this.ref.close();
        }, 1000);
      }
    })

  }

  chipRemoved(){
    this.clearAllDisabled = false;
    this.selectAll = false;
  }

  getDropdownValuesApis() {
    //Financial Year
    this.sfBillingService.getFeeCapFinancialYear().subscribe({
      next: (data: any) => {
        this.financialYearListUnfiltered = data;
        const transformedDataFY = this.financialYearListUnfiltered.map(item => { return { id: item.id, label: item.financialYearDescription, value: item.financialYearDescription }; });
        this.financialYearList = transformedDataFY;
        this.getLotNum(this.financialYear);
      },
      error: (_err: any) => {
      }
    });

    // sf_bill_status
    this.sfBillingService.getBillStatusMaster().subscribe({
      next: (data: any) => {
        let allval = {
          id: 0,
          name: "SF Bill Status",
          property: "All"
        }
        data.unshift(allval);
        this.sfBillStatusList = data;
        this.sfBillStatus = this.defaultValues.sfBillStatus;
        console.log("this.sfbillstatuslist dropdown",this.sfBillStatusList);
        console.log("this.sfbillstatuslist dropdown",this.sfBillStatus);
        
      },
      error: (_err: any) => {
      }
    });
    // Status
    this.sfBillingService.getSfEntityStatus().subscribe({
      next: (data: any) => {
        let allvalEntityStatus = {
          id: 0,
          name: "SF Entity Status",
          property:"All",
        }
        data.unshift(allvalEntityStatus);
        this.entityStatus = this.defaultValues.entityStatus;
        this.entityStatusList = data
      },
      error: (_err: any) => {
      }
    });
    //clientType
    this.sfBillingService.getClientType().subscribe({
      next: (data: any) => {
        this.clientTypeNameAPIList = data.SUCCESS;
        const transformedData = [];
        const uniqueLabels = [];

        transformedData.push({ label: 'All', value: 'All' });
        this.clientTypeNameAPIList.forEach(item => {
          let label, value;
          if (item.clientTypeName.startsWith('LCG')) {
            label = 'LCG';
            value = 'LCG'
          } else {
            label = item.clientTypeName;
            value = item.clientTypeName
          }
          //removing duplicates of LCG type
          if (!uniqueLabels[label]) {
            transformedData.push({ label, value })
            uniqueLabels[label] = true;
          }
        });
        this.clientTypeList = transformedData;
      },
      error: (_err: any) => {
      }
    });
    //Assignment Family
    this.sfBillingService.getAssignmentFamily().subscribe({
      next: (data: any) => {
        this.assignmentFamilyListUnfiltered = data;
        const transformedData = this.assignmentFamilyListUnfiltered.map(item => { return { label: item.name, value: item.id }; });
        this.assignmentFamilyList = transformedData;
        this.assignmentFamily = this.assignmentFamilyList;
      },
      error: (_err: any) => {
      }
    });
  }
  resetFinancialYearDropdown() {
    this.financialYear = this.financialYearList[1].label;
  }

  resetForm() {
    this.financialYear = this.financialYearListUnfiltered[1].financialYearDescription;
    this.entityStatus = 'Cooperative';
    this.clientType = 'All';
    this.assignmentFamily = this.assignmentFamilyList;
    this.sfBillStatus = 0;
    this.bdOwnerName = [];
    this.bdOwnerNameList = null;
    this.lotNumber = null;
    this.clearAllDisabled = true;
    this.selectAll = true;
  }
  
  selectOnChangeForBdOwner(e) {
    if (this.bdOwnerName.length >= 1) {
      this.clearAllDisabled = false;
    } else {
      this.clearAllDisabled = true;
    }
  }
  selectOnChange() {
    this.getLotNum(this.financialYear);
    if (this.financialYear != this.defaultValues.financialYear || this.entityStatus != this.defaultValues.entityStatus || this.clientType != this.defaultValues.clientType || this.assignmentFamily[0].label !== "All" || this.assignmentFamily.length > 1 || this.lotNumber != this.defaultValues.lotNumber) {
      this.clearAllDisabled = false;
    } else {
      this.clearAllDisabled = true;
    }

    if(this.assignmentFamily.length < this.assignmentFamilyList.length && this.assignmentFamily.length != 0){
      this.selectAll = false;
    }else{
      this.selectAll = true;
    }
  }
  changeAssignmentFamilyToEmpty() {
    this.assignmentFamily = [];
  }
  handleChipRemoval(event: any) {
    let index = this.assignmentFamily.findIndex(item => item.label === event.label);
    if (index !== -1) {
      this.assignmentFamily.splice(index, 1);
    }

    if (this.assignmentFamily.length == 0) {
      this.disableExport = true;
    }

  }

  getLotNum(fy) {
    this.sfBillingService.getLotNumOnFinancialYear(fy).subscribe({
      next: (data: any) => {
        this.lotNumberList = data;
      },
      error: (_err: any) => {
      }
    });
  }

  onSelectAllChange(e){
    console.log("before",this.assignmentFamily);
    if(e.checked){
      this.assignmentFamily = this.assignmentFamilyList;
      this.clearAllDisabled = true;
      this.selectAll = true;
    }else{
      this.changeAssignmentFamilyToEmpty();
      this.clearAllDisabled = false;
      this.selectAll = false;
    }
  }
}
