import { ChangeDetectorRef, Component, OnInit, ViewChildren } from '@angular/core';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { QcDetailsComponent } from '../qc-details/qc-details.component';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { FormBuilder } from '@angular/forms';
import { Table } from 'primeng/table/table';
import { QcService } from '../qc.service';

@Component({
  selector: 'backoffice-qc-listing',
  templateUrl: './qc-listing.component.html',
  styleUrls: ['./qc-listing.component.scss'],
  providers: [DialogService, MessageService]
})
export class QcListingComponent implements OnInit {

  qcDetailsDialog: DynamicDialogRef = new DynamicDialogRef;

  // breadcrumb variables - start
  ccprimebreadcrumbList = [
    { label: `Quality Control`, routerLink: ['/qc/listing'] },
  ];
  homeiconccprimebreadcrumb = { icon: `pi pi-home` };
  // breadcrumb variables - end

  // grid related variables - start
  @ViewChildren('mainGrid') mainGrid;

  qcForm = this.fb.group({
    entityNameFilter: [null, []],
    nexeraUserFilter: [null, []],
    qcStatus: [null, []],
    clientTypeFilter:[null,[]],
    opportunityStatusFilter: [null as any,[]],
    businessTypeFilter:[null,[]],
    productFilter:[null,[]],
    crrProductFilter:[null,[]],
    quantumFilter:[null,[]],
    applicableAmountFilter:[null,[]],
    actualIrfFeeFilter:[null,[]],
    bdUserFilter:[null,[]],
    bdManagerFilter:[null,[]],
    opsInitiatorFilter:[null,[]],
    entityFilter:[null,[]]
  });
  // grid related variables - end

  // loader variables
  firstLoad = false;
  apiFail = false;
  gridLoader = false;
  loadingStatus = false; 

  // grid related variables - end

  gridDataList:any[] = [];
  clientTypeList: any;
  qcStatusList: any[] = [];
  opportunityStatusList: any[] = [{name:"Initiated"},{name:"Case Closed"}];
  businessTypeList: any;
  productList: any;
  crrProductList:any;
  quantumList:any;
  applicableAmountList:any;
  actualIrfFeeList:any;
  bdUserList:any;
  bdManagerList:any;
  opsInitiatorList:any;


  // search company popup -
  // auto complete company name related variables
  searchCompanyPopUp = false;
  showCross: boolean = false;
  showEntityLoader: boolean = false;
  emptyMsgVal: string = 'No Company Found';
  emptyMsgFlag: boolean = false;
  companyNameList = [];
  companyNameSearchValue : any;
  filterDisplay = true;
  filterDisplayRow = true;
  highPriorityChecked:boolean;
  invoiceBeforePaymentChecked:boolean;
  qcStatus:any;
  opportunityStatus:any;
  priorityForQc:any = null;
  invoiceBeforePayment = "No";

  showFailureModel: boolean = false;
  errorMessage: string = "";
  companyName:any = null;

  entitySearchTimeout: any;

  constructor(private dialogService: DialogService, private messageService: MessageService, private encyDyc: EncryptDecryptInterceptor, private fb: FormBuilder,private cd: ChangeDetectorRef,private qcService:QcService) { }

  ngOnInit(): void {
    this.getQcStatusList();
  }

  // breadrumb related method
  homeBreadcrumbClicked(event) {
    if(event.item?.icon == 'pi pi-home'){
      sessionStorage.removeItem('urlRedirectedTo');
      sessionStorage.setItem('dashboard', 'true');
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }
  }

  showSearchCompanyPopUp() {
    if(!this.gridLoader && !this.loadingStatus)
      this.searchCompanyPopUp = true;
  }

  searchCompanyPopupClosed() {
    this.searchCompanyPopUp = false;
  }

  onChangeCompanySelect(event) {
    console.log("Event :: ", event);
    
  }

  resetCompanySearch() {
    this.companyName = null;
    this.getGridList(this.qcStatus,this.priorityForQc,this.opportunityStatus,this.companyName,this.invoiceBeforePayment);
    this.companyNameSearchValue = undefined;
    this.searchCompanyPopUp = false;
  }

  applyCompanySearch() {
    console.log("company name search data... ",this.companyNameSearchValue);
    this.companyName = this.companyNameSearchValue?.entityName;
    this.qcForm.patchValue({qcStatus:null});
    this.qcStatus = null;
    this.qcForm.patchValue({opportunityStatusFilter:null});
    this.opportunityStatus = null;
    this.getGridList(this.qcStatus,this.priorityForQc,this.opportunityStatus,this.companyName,this.invoiceBeforePayment);
    this.searchCompanyPopUp = false;
  }

  onQcBackBtn(){
    sessionStorage.removeItem('urlRedirectedTo');
    sessionStorage.setItem('dashboard', 'true');
    window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
  }

  getQcStatus(event){
    this.qcStatus = event;
    console.log("QC Status is: ",event);
    this.getGridList(this.qcStatus,this.priorityForQc,this.opportunityStatus,this.companyName,this.invoiceBeforePayment);
  }

  getQcDetails(qcDetails : any) {
    this.qcDetailsDialog = this.dialogService.open(QcDetailsComponent, {
      contentStyle: { "height": "100vh", "overflow": "hidden", "padding-top" : "0" },
      closeOnEscape: false,
      closable: true,
      data: {qcDetails},
      width: '96%',
      header: qcDetails.entityName,
    });

    this.qcDetailsDialog.onClose.subscribe((closeResponse: any) => {
      console.log("closeRes :: ", closeResponse);
      if(closeResponse == "error") {
        this.messageService.add({ severity: 'error', detail: 'Unable to save data, please try again!'});
      } else if(closeResponse?.msg == "success"){
          // refresh grid on succession
          this.messageService.add({ severity: 'success', summary: `Case is ${closeResponse?.caseStatus || "saved"} successfully`});
          this.getGridList(this.qcStatus,this.priorityForQc,this.opportunityStatus,this.companyName,this.invoiceBeforePayment);
      } else if(closeResponse?.msg == "rowSuccess"){
        this.messageService.add({severity:'success', summary: closeResponse?.caseStatus})
      } else if(closeResponse?.msg == "rowError"){
        this.messageService.add({severity:'error', detail: closeResponse?.caseStatus})
      }
      setTimeout(() => {
        this.messageService.clear();
      }, 3000);
    
    });
  }

  formatDate(initiatedDate: string){
    let data = initiatedDate.split(' ');
    let days = data[0].split("-")?.reverse()?.join("-");
    let tempTime = data[1].split(":");
    let time = `${tempTime[0]}:${tempTime[1]}`;
    return `${days} ${time}`;
  }

  setGridItem(item) {

    this.gridDataList = <[]>item;
    this.clientTypeList = new Set();
    this.businessTypeList = new Set();
    this.productList = new Set();
    this.crrProductList = new Set();
    this.quantumList = new Set();
    this.applicableAmountList = new Set();
    this.actualIrfFeeList = new Set();
    this.bdUserList = new Set();
    this.bdManagerList = new Set();
    this.opsInitiatorList = new Set();

    this.gridDataList.forEach(item => {
      if(item.clientClassification){
        this.clientTypeList.add(item.clientClassification);
        item.clientTypeByList = { name: item.clientClassification };
      }
      item.initiatedDate = this.formatDate(item.initiatedDate);
      if(item.businessType){
        this.businessTypeList.add(item.businessType);
        item.businessTypeByList = { name: item.businessType };
      }
      
      if(item.product){
        this.productList.add(item.product);
        item.productByList = { name: item.product };
      }
      
      if(item.crrProduct){
        this.crrProductList.add(item.crrProduct);
        item.crrProductByList = { name: item.crrProduct };
      }
      
      if(item.quantum){
        this.quantumList.add(item.quantum);
        item.quantumByList = { name: item.quantum };
      }
      
      if(item.applicableAmount){
        this.applicableAmountList.add(item.applicableAmount);
        item.applicableAmountByList = { name: item.applicableAmount };
      }

      if(item.actualIrfFee){
        this.actualIrfFeeList.add(item.actualIrfFee);
        item.actualIrfFeeByList = { name: item.actualIrfFee };
      }
      
      if(item.bdUser){
        this.bdUserList.add(item.bdUser);
        item.bdUserByList = { name: item.bdUser };
      }
      
      if(item.bdManager){
        this.bdManagerList.add(item.bdManager);
        item.bdManagerByList = { name: item.bdManager };
      }
      
      if(item.initiatedBy){
        this.opsInitiatorList.add(item.initiatedBy);
        item.opsInitiatorByList = { name: item.initiatedBy };
      }
      
    });

    this.clientTypeList = Array.from(this.clientTypeList).map(item => ({name: item}));
    this.businessTypeList = Array.from(this.businessTypeList).map(item => ({name: item}));
    this.productList = Array.from(this.productList).map(item => ({name: item}));
    this.crrProductList = Array.from(this.crrProductList).map(item => ({name: item}));
    this.quantumList = Array.from(this.quantumList).map(item => ({name: item}));
    this.applicableAmountList = Array.from(this.applicableAmountList).map(item => ({name: item}));
    this.actualIrfFeeList = Array.from(this.actualIrfFeeList).map(item => ({name: item}));
    this.bdUserList = Array.from(this.bdUserList).map(item => ({name: item}));
    this.bdManagerList = Array.from(this.bdManagerList).map(item => ({name: item}));
    this.opsInitiatorList = Array.from(this.opsInitiatorList).map(item => ({name: item}));
    
    let gridData1 = [];
    this.gridDataList.forEach((gridData)=>{
      let qcStatusData = this.qcStatusList.filter((item) => item.id == gridData.qcStatus)[0].property;
      gridData.qcStatus = qcStatusData;
      gridData1.push(gridData);
    });

    this.gridDataList = gridData1;
    this.gridLoader = false;
    this.loadingStatus = false;
    this.firstLoad = true;
  }

  clear(table : Table) {
    table.reset();
    this.cd.detectChanges();
    if(this.mainGrid?.first?.filters) { // reset all filters
      Object.values(this.mainGrid.first.filters).map(item => {
        (<any>item).value = null;
        return item;
      });
    }
    this.filterDisplayRow = false;
    this.filterDisplay = false;
    this.companyNameSearchValue = undefined;
    this.companyName = null;
    this.qcForm.patchValue({entityFilter:null});
    this.getGridList(null,null,null,null,null);
    this.cd.detectChanges();   
  }

  showFilter(){
    this.filterDisplayRow = true;
    this.filterDisplay = true;
    this.getGridList(this.qcStatus,this.priorityForQc,this.opportunityStatus,this.companyName,this.invoiceBeforePayment);
    this.cd.detectChanges();
  }

  showHighPriorityCases(event){
    this.highPriorityChecked = event.checked;
    if(this.highPriorityChecked){
      this.priorityForQc = "High";
      this.qcStatusList = [
        {
          "id": 133,
          "name": "QC Status",
          "property": "QC Pending"
        },
        {
          "id": 135,
          "name": "QC Status",
          "property": "Resubmitted for QC"
        }
      ];
      this.qcForm.patchValue({qcStatus:this.qcStatusList[0].id});
      this.qcStatus = this.qcStatusList[0].id;
      this.opportunityStatus = "Initiated";
      let opportunityStatus = {name:"Initiated"};
      this.qcForm.patchValue({opportunityStatusFilter:opportunityStatus.name});
      this.getGridList(this.qcStatus,this.priorityForQc,this.opportunityStatus,this.companyName,this.invoiceBeforePayment);
    }

    if(!this.highPriorityChecked){
      this.priorityForQc = null;
      this.getQcStatusList();
      this.getGridList(this.qcStatus,this.priorityForQc,this.opportunityStatus,this.companyName,this.invoiceBeforePayment);
    }
  }

  showInvoiceBeforePaymentCases(event){
    this.invoiceBeforePaymentChecked = event.checked;
    if(this.invoiceBeforePaymentChecked){
      this.invoiceBeforePayment = "Yes";
      this.getGridList(this.qcStatus,this.priorityForQc,this.opportunityStatus,this.companyName,this.invoiceBeforePayment);
    }

    if(!this.invoiceBeforePaymentChecked){
      this.invoiceBeforePayment = "No";
      this.getGridList(this.qcStatus,this.priorityForQc,this.opportunityStatus,this.companyName,this.invoiceBeforePayment);
    }
  }

  entitySearchFilter(event){
    if(this.entitySearchTimeout) {
      clearTimeout(this.entitySearchTimeout);
    }
    this.entitySearchTimeout = setTimeout(()=> {
      if(event){
        this.qcForm.patchValue({qcStatus:null});
        this.qcStatus = null;
        this.qcForm.patchValue({opportunityStatusFilter:null});
        this.opportunityStatus = null;
        this.getGridList(this.qcStatus,this.priorityForQc,this.opportunityStatus,this.companyName,this.invoiceBeforePayment);
        console.log("Event Dataa: ",event);
      } else{
        this.qcForm.patchValue({qcStatus:this.qcStatusList[1].id});
        this.qcStatus = this.qcStatusList[1].id;
        this.opportunityStatus = "Initiated";
        let opportunityStatus = {name:"Initiated"};
        this.qcForm.patchValue({opportunityStatusFilter:opportunityStatus.name});
        this.getGridList(this.qcStatus,this.priorityForQc,this.opportunityStatus,this.companyName,this.invoiceBeforePayment);
      }
  
      if(event == null || event == '' || event == undefined){
        this.qcForm.patchValue({qcStatus:this.qcStatusList[1].id});
        this.qcStatus = this.qcStatusList[1].id;
        this.opportunityStatus = "Initiated";
        let opportunityStatus = {name:"Initiated"};
        this.qcForm.patchValue({opportunityStatusFilter:opportunityStatus.name});
        this.getGridList(this.qcStatus,this.priorityForQc,this.opportunityStatus,this.companyName,this.invoiceBeforePayment);
      }

      if(this.entitySearchTimeout) {
        clearTimeout(this.entitySearchTimeout);
      }
    }, 1000);
    
  }

  getGridList(status:any,priority:any,oppStatus:any,companyName:any,invoiceBeforePayment:any) {
    let payload = {"status":status,"priority":priority,"oppStatus":oppStatus,"companyName":companyName,"invoiceBeforePayment":invoiceBeforePayment};
    if(this.companyName){
      this.qcService.getCompanySearchResults(payload).subscribe((res)=>{
        this.setGridItem(res);
      });
    } else{
      this.qcService.findAllQcData(payload).subscribe((res)=>{
        this.setGridItem(res);
      });
    }
    
  }

  getQcStatusList(){
    this.qcService.getQcStatus().subscribe((res)=>{
      if(res.length>0){
        this.qcStatusList = res;
        console.log("this.qcStatusList: ", this.qcStatusList);
        this.qcForm.patchValue({qcStatus:this.qcStatusList[1].id});
        this.qcStatus = this.qcStatusList[1].id;
        this.opportunityStatus = "Initiated";
        let opportunityStatus = {name:"Initiated"};
        this.qcForm.patchValue({opportunityStatusFilter:opportunityStatus.name});
        this.getGridList(this.qcStatus,this.priorityForQc,this.opportunityStatus,this.companyName,this.invoiceBeforePayment);
      }
    });
  }

  opportunityStatusFilter(event){
    this.opportunityStatus = event;
    this.getGridList(this.qcStatus,this.priorityForQc,this.opportunityStatus,this.companyName,this.invoiceBeforePayment);
    console.log("Opporuntiy Filter Selected: ",event);
  }

  loadCompanyList(event){
    if(event?.query?.length >= 4){
      let payload = {"status":null,"priority":null,"oppStatus":null,"companyName":event?.query};
      this.qcService.getCompanySearchResults(payload).subscribe((results:any)=>{
        const responseData = results;
        if (responseData && responseData.length > 0) {
          this.companyNameList = responseData;
        } else {
          this.companyNameList = [];
        }
      });
    }
  }

}
