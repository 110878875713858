<div class="layout-content layout-content-noscroll">
    <p-toast class="custom-toast" [life]="6000"></p-toast>
    <div class="p-formgrid p-grid">
        <div class="p-col-12 p-p-0">
            <div class="ui-fluid p-formgrid p-grid p-ai-center">
                <div class="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-pl-0 p-text-left">
                    <div class=" p-fluid p-field  p-mb-0  ">
                        <p-breadcrumb [model]="breadCrumbList" [home]="homeIconBreadCrumb"
                            (onItemClick)="homeIconClicked($event)">
                        </p-breadcrumb>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container p-mt-4">
        <div class="p-col-12 p-p-0 p-d-flex p-jc-between">
            <h1 class="p-medium display-2">Import Data</h1>
            <h1 class="p-medium display-2"><a (click)="exportExcel()"><i class="pi pi-file-excel"></i>Export</a></h1>
        </div>
        <div class="p-col-12 p-p-0">
            <p-card>
                <div class="p-col-12 p-p-0 p-grid" style="height: 50vh;">
                    <div class="p-col-4 p-mt-5">
                        <div class="import-excel p-d-flex p-flex-column p-ai-center">
                            <div class="p-medium display-3">Import Data <span class='invalid-feedback-astrik'>*</span>
                            </div>
                            <p-fileUpload *ngIf="!excelUpload" customUpload="true" id="fileUpload1" name="file"
                                accept=".xlsx" mode="basic" [showUploadButton]="false"
                                [showCancelButton]="false" #excelFileUpload (onSelect)="fileUploaded($event, 'xlsx')"
                                chooseLabel="Choose File" [disabled]="readScope">
                            </p-fileUpload>
                            <p-card *ngIf="excelUpload" class="name-card p-mt-2">
                                <div class="p-col-12 p-pl-0 p-p-2 p-d-flex p-ai-center p-flex-wrap">
                                    <div class="p-col-9 p-pl-0 p-p-0 p-d-flex p-ai-center p-flex-wrap">
                                        <div class="p-col-2 p-p-0 p-ml-4">
                                            <svg width="24" height="32" viewBox="0 0 24 32" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_1779_24618)">
                                                    <path d="M18.8944 0H0V32H24V5.33267L18.8944 0Z" fill="#F4F5F6" />
                                                    <path
                                                        d="M5.82828 25.2306L6.5404 26.5866L7.2702 25.2306H8.37374L7.25 27.17L8.40404 29.1094H7.30556L6.5404 27.7685L5.78788 29.1094H4.67677L5.82828 27.17L4.71717 25.2306H5.82828ZM10.0884 23.9377V29.1094H9.01263V23.9377H10.0884ZM14.1774 26.3366L13.1926 26.3973C13.1757 26.3131 13.1395 26.2373 13.084 26.17C13.0284 26.101 12.9552 26.0462 12.8643 26.0058C12.775 25.9638 12.6681 25.9427 12.5436 25.9427C12.3769 25.9427 12.2363 25.9781 12.1218 26.0488C12.0074 26.1178 11.9501 26.2104 11.9501 26.3265C11.9501 26.4191 11.9872 26.4974 12.0612 26.5614C12.1353 26.6254 12.2624 26.6767 12.4426 26.7154L13.1446 26.8568C13.5217 26.9343 13.8028 27.0589 13.988 27.2306C14.1732 27.4023 14.2658 27.6279 14.2658 27.9074C14.2658 28.1616 14.1909 28.3846 14.041 28.5765C13.8929 28.7685 13.6892 28.9183 13.4299 29.026C13.1723 29.1321 12.8752 29.1851 12.5385 29.1851C12.025 29.1851 11.616 29.0782 11.3112 28.8644C11.0082 28.6489 10.8306 28.356 10.7784 27.9856L11.8365 27.9301C11.8685 28.0866 11.9459 28.2062 12.0688 28.2887C12.1917 28.3695 12.3491 28.4099 12.541 28.4099C12.7296 28.4099 12.8811 28.3737 12.9956 28.3013C13.1117 28.2272 13.1707 28.1321 13.1723 28.0159C13.1707 27.9183 13.1294 27.8383 13.0486 27.776C12.9678 27.7121 12.8432 27.6632 12.6749 27.6296L12.0032 27.4957C11.6244 27.42 11.3424 27.2887 11.1572 27.1018C10.9737 26.9149 10.8819 26.6767 10.8819 26.3872C10.8819 26.138 10.9493 25.9233 11.084 25.7432C11.2203 25.5631 11.4114 25.4242 11.6572 25.3265C11.9047 25.2289 12.1942 25.1801 12.5259 25.1801C13.0158 25.1801 13.4013 25.2836 13.6824 25.4907C13.9653 25.6978 14.1303 25.9797 14.1774 26.3366ZM15.8422 25.2306L16.5543 26.5866L17.2841 25.2306H18.3876L17.2639 27.17L18.4179 29.1094H17.3194L16.5543 27.7685L15.8018 29.1094H14.6907L15.8422 27.17L14.7311 25.2306H15.8422Z"
                                                        fill="#A7ABB7" />
                                                    <path d="M6.44444 19.1111V8.44444H16.6984V19.1111H6.44444Z"
                                                        stroke="#298E51" stroke-width="0.888889" />
                                                    <path
                                                        d="M9 16.8839L10.8635 13.7651L9.15659 10.6641H10.5273L11.4586 12.6498C11.5447 12.8377 11.6071 12.9774 11.6353 13.0706H11.6479C11.7087 12.9196 11.7733 12.7743 11.8408 12.6321L12.8367 10.6673H14.0983L12.3477 13.7507L14.1429 16.8863H12.8004L11.7243 14.7094C11.6743 14.6158 11.6319 14.5178 11.5974 14.4163H11.5796C11.5482 14.5152 11.5064 14.6098 11.4549 14.6982L10.3469 16.8839H9Z"
                                                        fill="#298E51" />
                                                    <path d="M19.7143 2.66667H0V0H18.923L19.7143 2.66667Z"
                                                        fill="#298E51" />
                                                    <path d="M18.9438 5.33267H24L18.9438 0V5.33267Z" fill="#D3D5DB" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1779_24618">
                                                        <rect width="24" height="32" rx="1" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div class="p-col-8 p-p-0 p-ml-2 p-d-flex p-flex-column p-flex-wrap">
                                            <div class="limit-text-length">
                                                {{excelFile.name}} </div>
                                        </div>
                                    </div>
                                    <div class="p-col-2 p-pr-0 p-d-flex p-jc-end">
                                        <i class="pi pi-trash cursor-pointer" aria-hidden="true"
                                            (click)="deleteFile('xlsx')"></i>
                                    </div>
                                </div>
                            </p-card>
                            <div class="labelText">File supported: xlsx
                                &nbsp;&nbsp; Max size: 5MB</div>
                        </div>
                    </div>
                    <div class="p-col-4 p-mt-5">
                        <div class="attachment p-d-flex p-flex-column p-ai-center">
                            <div class="p-medium display-3">Attachment <span class='invalid-feedback-astrik'>*</span>
                            </div>
                            <p-fileUpload *ngIf="!attachmenUpload" customUpload="true" id="fileUpload1" name="file"
                                accept="image/*, .pdf, .jpeg, .xlsx, .jpg, .png, .doc, .docx, .msg"
                                mode="basic" [showUploadButton]="false" [showCancelButton]="false" #attachmentUpload
                                (onSelect)="fileUploaded($event, 'all')" chooseLabel="Choose File" [disabled]="readScope">
                            </p-fileUpload>
                            <p-card *ngIf="attachmenUpload" class="name-card p-mt-2">
                                <div class="p-col-12 p-pl-0 p-p-2 p-d-flex p-ai-center p-flex-wrap">
                                    <div class="p-col-9 p-pl-0 p-p-0 p-d-flex p-ai-center p-flex-wrap"
                                        style="cursor: pointer;" (click)="attachmentPreview()">
                                        <div class="p-col-2 p-p-0 p-ml-4">
                                            <img [src]="thumbNail" alt="">
                                        </div>
                                        <div class="p-col-8 p-p-0 p-ml-2 p-d-flex p-flex-column p-flex-wrap">
                                            <div class="limit-text-length">
                                                {{attachmentFile.name}} </div>
                                        </div>
                                    </div>
                                    <div class="p-col-2 p-pr-0 p-d-flex p-jc-end">
                                        <i class="pi pi-trash cursor-pointer" aria-hidden="true"
                                            (click)="deleteFile('all')"></i>
                                    </div>
                                </div>
                            </p-card>
                            <div class="labelText">File supported: All
                                &nbsp;&nbsp; Max size: 20MB</div>
                        </div>
                    </div>
                    <div class="p-col-4 p-mt-5">
                        <div class="reason-dropdown p-fluid p-field">
                            <label for="rejectReason">Reason for Revision<span
                                    class='invalid-feedback-astrik'>*</span></label>
                            <p-dropdown [options]="rejectReasonList" [(ngModel)]="revisionReason" [disabled]="readScope"
                                optionLabel="reason_description" [showClear]="true" (onChange)="reasonSelected()" optionValue="reason_description">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
        <div class="p-col-12 p-pl-0 p-pr-0 p-d-flex p-jc-end p-mt-3">
            <button pButton class="p-button-brand2 p-button p-component p-mr-3" label="Cancel" (click)="resetPage()"></button>
            <button pButton class="p-button-primary p-button p-component p-mr-0" label="Process" [disabled]="readScope || disableProcess" (debounceClick)="processCn()" appDebounceClick></button>
        </div>
    </div>
    <div class="no_data_div" *ngIf="pageLoader">
        <div class="app-loading" id="loader">
            <div class="loadbg"></div>
            <div class="bdload">
                <div class="spinner">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                    <span>Loading...</span>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- image cropper -->
<p-dialog header="Crop Image" [(visible)]="displayImageCropper" [modal]="true" [style]="{width: '60vw', height: '80vh'}"
    [draggable]="true" [resizable]="false" maskStyleClass="negativemask">
    <div class="p-col-12 p-text-center popupAttachmentBodyScrollContainer">
        <image-cropper styleClass="imageCropper" class="crop-content" [imageChangedEvent]="imgChangeEvt"
            [canvasRotation]="canvasRotation" [imageFile]="imageChangedEventfile" [maintainAspectRatio]="false"
            format="jpeg" id="image_cropper" (imageCropped)="imageCroppedfileUpload($event)">
        </image-cropper>
        <i class="pi pi-replay p-mr-3 p-pt-2" style="cursor:pointer;" (click)="rotateLeft()" aria-hidden="true"></i>
        <i class="pi pi-refresh p-pt-2" style="cursor:pointer;" (click)="rotateRight()" aria-hidden="true"></i>
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" (click)="displayImageCropper=false" label="Cancel"
            class="p-button-brand2"></button>
        <button pButton type="button" label="Upload" class="p-button-primary" (click)="croppedBtn()"></button>
    </ng-template>
</p-dialog>

<!-- preview model -->
<p-dialog header="Preview" [(visible)]="displayPreview" class="docPreview previewModal" [modal]="true"
    [style]="{width: '95vw', height: '100vh'}" [draggable]="false" [resizable]="false">
    <div class="no_data_div tableLoader" *ngIf="dataLoader">
        <div class="app-loading " id="loader">
            <div class="loadbg"></div>
            <div class="bdload">
                <div class="spinner">
                    <div class="rect1"></div>
                    <div class="rect2"></div>
                    <div class="rect3"></div>
                    <div class="rect4"></div>
                    <div class="rect5"></div>
                    <span>Loading...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="p-mb-2 p-d-flex p-flex-row zoomContainer">
        <label>Zoom : </label>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-search-plus" (click)="zoom(1)"></button>
        <button type="button" (click)="zoom(-1)" pButton icon="pi pi-search-minus"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" (click)="rotateFile(1)" pButton icon="pi pi-refresh"
            class="p-button-brand2 p-ml-1"></button>
        <button type="button" pButton class="p-button-brand2" icon="pi pi-replay" (click)="rotateFile(-1)"></button>
    </div>
    <div class="imagePreviewContainer">
        <img src="{{previewUrl}}" id="imagePrev" alt="img">
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" (click)="displayPreview=false" class="p-button-brand2"></button>
        <button pButton type="button" label="Download" (click)="downloadFile('null', 'null', 'image')" class="p-button-secondary"></button>
    </ng-template>
</p-dialog>