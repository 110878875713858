<div class="layout-content layout-content-noscroll">
  <div class="p-formgrid p-grid">
    <div class=" p-col-12 p-p-0">
      <div class="ui-fluid p-formgrid p-grid p-ai-center">
        <div class="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-pl-0 p-text-left">
          <div class=" p-fluid p-field  p-mb-0  ">
            <p-breadcrumb [model]="breadcrumbList" [home]="homeicon" (onItemClick)="homeClicked($event)">
            </p-breadcrumb>
          </div>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="billForm" autocomplete="off" novalidate>
    <div class="p-formgrid p-grid p-m-0">
      <div class=" p-col-12 p-p-0">
        <div class="ui-fluid p-formgrid p-grid">
          <div
            class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-jc-between p-ai-center p-d-flex p-flex-column p-flex-md-row  p-p-0">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-d-flex p-jc-end p-mt-0 p-mr-0">
              <!-- <p-calendar #globalCalendar [showIcon]="true" id="global-calendar" class="p-px-2" name="global-calendar"
                placeholder="From-To" yearRange="2000:2030" dataType="string"
                selectionMode="range" [readonlyInput]="true" rangeSeparator="to" dateFormat="dd/mm/yy"
                [showButtonBar]="true" [hideOnDateTimeSelect]="true" dataType="string" [stepHour]="1" [stepMinute]="1"
                [showButtonBar]="true" [minDate]="minGlobalStartTime" [maxDate]="maxGlobalEndTime"
                (onSelect)="filterGlobalDate()" (onClearClick)="onClearGlobalDate()">
              </p-calendar> -->
              <div class=" p-fluid   p-field  p-d-flex p-jc-end p-flex-row p-pl-0 p-pr-0 p-mb-2">
                <!-- <div pTooltip="Global entity name search" tooltipPosition="left" (click)="showSearchCompanyPopUp()"
                  class="p-col-2 p-button-brand2 p-mr-3 p-d-flex p-ai-center" style="width : 36px; cursor: pointer">
                  <backoffice-svg [name]="'global-search-light'"></backoffice-svg>
                </div> -->

                <button pButton class="p-col-2 p-button-brand2" icon="pi pi-filter" *ngIf="!filterDisplay"
                  pTooltip="Filter" tooltipPosition="bottom" (click)="showFilter()">
                </button>

                <button pButton class="p-col-2 p-button-brand2" icon="pi pi-filter-slash" *ngIf="filterDisplay"
                  pTooltip="Clear Filter" tooltipPosition="bottom" (click)="clearFilter(mainGrid)">
                </button>
              </div>
            </div>
          </div>

          <div class="p-col-12 p-sm-12 p-p-0 reportingScreenGridTable">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-col-12 p-p-0">
                <div class="ui-fluid p-formgrid p-grid">
                  <div class="p-col-12 p-sm-12 p-p-0 p-right">
                    <div class="p-field">
                      <div [ngClass]="{'content-section implementation': true}">
                        <p-table #mainGrid name="businessWonDataGrid"
                          styleClass="p-datatable-gridlines backoffice-cust-scroll" [value]="gridDataList"
                          [paginator]="true" [showCurrentPageReport]="true" [rows]="10" sortMode="single"
                          [scrollable]="true" scrollHeight="55vh" scrollDirection="both"
                          [rowsPerPageOptions]="[10, 25, 50]"
                          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                          [loading]="loadingStatus" (onFilter)="loadingStatus = false;">
                          <ng-template pTemplate="header">
                            <tr>
                              <th pSortableColumn='companyName' scope="col" style="max-width:220px;min-width:220px;">
                                Client Name
                                <p-sortIcon field='companyName' ariaLabel="Activate to sort"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                              </th>

                              <th pSortableColumn='billingDate' scope="col" style="max-width:160px;min-width:160px;">
                                Bill Date
                                <p-sortIcon field='billingDate' ariaLabel="Activate to sort"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                              </th>

                              <th pSortableColumn='bdManager' scope="col" style="max-width:160px;min-width:160px;">
                                BD Manager
                                <p-sortIcon field='bdManager' ariaLabel="Activate to sort"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                              </th>

                              <th pSortableColumn='costCenter' scope="col" style="max-width:160px;min-width:160px;">
                                Cost Center
                                <p-sortIcon field='costCenter' ariaLabel="Activate to sort"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                              </th>

                              <th pSortableColumn='status' scope="col" style="max-width:160px;min-width:160px;">
                                Status
                                <p-sortIcon field='status' ariaLabel="Activate to sort"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                              </th>

                              <th pSortableColumn='billType' scope="col" style="max-width:160px;min-width:160px;">
                                Bill Type
                                <p-sortIcon field='billType' ariaLabel="Activate to sort"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                              </th>

                              <th pSortableColumn='billingAmount' scope="col" style="max-width:160px;min-width:160px;">
                                Bill Amount
                                <p-sortIcon field='billingAmount' ariaLabel="Activate to sort"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                              </th>

                              <th pSortableColumn='narration' scope="col" style="max-width:320px;min-width:320px;">
                                Narration
                                <p-sortIcon field='narration' ariaLabel="Activate to sort"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                              </th>

                              <th pSortableColumn='offlineBillMandateAttachment' scope="col" style="max-width:160px;min-width:160px;">
                                Attachment
                                <p-sortIcon field='offlineBillMandateAttachment' ariaLabel="Activate to sort"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                              </th>
                            </tr>
                            <tr *ngIf="filterDisplayRow">
                              <th scope="col" class="no-bg" style="max-width:220px;min-width:220px;">
                                <input pInputText type="text"
                                  (input)="mainGrid.filter($event.target.value, 'companyName', 'contains')"
                                  [value]="mainGrid.filters['companyName']?.value" placeholder="Search"
                                  class="p-column-filter">
                              </th>

                              <th scope="col" class="no-bg" style="max-width:160px;min-width:160px;">
                                <input pInputText type="text"
                                  (input)="mainGrid.filter($event.target.value, 'billingDate', 'contains')"
                                  [value]="mainGrid.filters['billingDate']?.value" placeholder="Search"
                                  class="p-column-filter">
                              </th>

                              <th scope="col" class="no-bg" style="max-width:160px;min-width:160px;">
                                <p-columnFilter class="p_columnFilter" field="bdManager" matchMode="in"
                                  [showMenu]="false">
                                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-multiSelect [options]="bdManagerList" placeholder="Select"
                                      (onChange)="filter($event.value)" id="bdManager"
                                      [disabled]="disableFilter" optionLabel="name" optionValue="name" #oppBd
                                      [showClear]="true" class="p_multiSelect" formControlName="bdManagerFilter" resetFilterOnHide="true">
                                      <ng-template let-rowData pTemplate="item">
                                        <div class="">
                                          <span class="p-ml-1">{{ rowData.name }}</span>
                                        </div>
                                      </ng-template>
                                    </p-multiSelect>
                                  </ng-template>
                                </p-columnFilter>
                              </th>

                              <th scope="col" class="no-bg" style="max-width:160px;min-width:160px;">
                                <p-columnFilter class="p_columnFilter" field="costCenter" matchMode="in"
                                  [showMenu]="false">
                                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-multiSelect [options]="costCenterList" placeholder="Select"
                                      (onChange)="filter($event.value)" id="costCenter"
                                      [disabled]="disableFilter" optionLabel="name" optionValue="name" #oppBd
                                      [showClear]="true" class="p_multiSelect" formControlName="costCenterFilter" resetFilterOnHide="true">
                                      <ng-template let-rowData pTemplate="item">
                                        <div class="">
                                          <span class="p-ml-1">{{ rowData.name }}</span>
                                        </div>
                                      </ng-template>
                                    </p-multiSelect>
                                  </ng-template>
                                </p-columnFilter>
                              </th>

                              <th scope="col" class="no-bg" style="max-width:160px;min-width:160px;">
                                <p-columnFilter class="p_columnFilter" field="status" matchMode="equals"
                                  [showMenu]="false">
                                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-dropdown [options]="statusList" placeholder="Select"
                                      (onChange)="statusChange($event.value)" id="status"
                                      [disabled]="disableFilter" optionLabel="name" optionValue="name" #oppBd
                                      class="p_multiSelect" formControlName="statusFilter"
                                      [panelStyle]="{ 'min-width': 'min(100vw, 10px)', 'max-height' : '20vh' }">
                                      <ng-template let-rowData pTemplate="item">
                                        <div class="">
                                          <span class="p-ml-1">{{ rowData.name }}</span>
                                        </div>
                                      </ng-template>
                                    </p-dropdown>
                                  </ng-template>
                                </p-columnFilter>
                              </th>

                              <th scope="col" class="no-bg" style="max-width:160px;min-width:160px;">
                                <p-columnFilter class="p_columnFilter" field="billType" matchMode="in"
                                  [showMenu]="false">
                                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-multiSelect [options]="billTypeList" placeholder="Select"
                                      (onChange)="filter($event.value)" id="billType" formControlName="billTypeFilter"
                                       optionLabel="name" optionValue="name" #oppBd
                                      [showClear]="true" [disabled]="disableFilter" class="p_multiSelect" resetFilterOnHide="true">
                                      <ng-template let-rowData pTemplate="item">
                                        <div class="">
                                          <span class="p-ml-1">{{ rowData.name }}</span>
                                        </div>
                                      </ng-template>
                                    </p-multiSelect>
                                  </ng-template>
                                </p-columnFilter>
                              </th>

                              <th scope="col" class="no-bg" style="max-width:160px;min-width:160px;">
                                <input pInputText type="text"
                                  (input)="mainGrid.filter($event.target.value, 'billingAmount', 'contains')"
                                  [value]="mainGrid.filters['billingAmount']?.value" placeholder="Search"
                                  class="p-column-filter">
                              </th>

                              <th scope="col" class="no-bg" style="max-width:320px;min-width:320px;">
                                <input pInputText type="text"
                                  (input)="mainGrid.filter($event.target.value, 'narration', 'contains')"
                                  [value]="mainGrid.filters['narration']?.value" placeholder="Search"
                                  class="p-column-filter">
                              </th>

                              <th scope="col" class="no-bg" style="max-width:160px;min-width:160px;">
                                <input pInputText type="text"
                                  (input)="mainGrid.filter($event.target.value, 'offlineBillMandateAttachment', 'contains')"
                                  [value]="mainGrid.filters['offlineBillMandateAttachment']?.value" placeholder="Search"
                                  class="p-column-filter">
                              </th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-rowData>
                            <tr style="cursor: pointer;">
                              <td class="text-transform-capitalize p-text-nowrap p-text-truncate" style="max-width:220px;min-width:220px;">
                                <div
                                  style="max-width:200px;min-width:200px;white-space: nowrap; text-overflow: ellipsis; overflow: hidden;" (click)="openBillform(rowData)">
                                  <a>{{rowData.companyName}}</a>
                                </div>
                              </td>
                              <td style="max-width:160px;min-width:160px;">
                                {{rowData.billingDate}}
                              </td>
                              <td style="max-width:160px;min-width:160px;">
                                {{rowData.bdManager}}
                              </td>
                              <td style="max-width:160px;min-width:160px;">
                                {{rowData.costCenter}}
                              </td>
                              <td style="max-width:160px;min-width:160px;">
                                {{rowData.status}}
                              </td>
                              <td style="max-width:160px;min-width:160px;">
                                {{rowData.billType}}
                              </td>
                              <td style="max-width:160px;min-width:160px;">
                                {{rowData.billingAmount}}
                              </td>
                              <td style="max-width:320px;min-width:320px;">
                                {{rowData.narration}}
                              </td>
                              <td style="max-width:160px;min-width:160px;">
                                {{rowData.offlineBillMandateAttachment}}
                              </td>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage">
                            <tr>
                              <td colspan="9" style="background: white;">
                                <ng-container>
                                  <div class="empmess" style="text-align: center; color: rgba(41, 41, 41, 0.6);">
                                    <div class="p-mb-3 p-mt-5 p-d-flex p-jc-center">
                                      <backoffice-svg [name]="'empty-state-light'"></backoffice-svg>
                                      <backoffice-svg [name]="'empty-state-dark'"></backoffice-svg>
                                    </div>
                                    <p>No data found for the selected filter criteria</p>
                                  </div>
                                </ng-container>
                              </td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="p-grid p-ml-0 p-mr-0 p-col-12 p-jc-between p-button-container">
  <div class="p-col-5 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-pl-0 left">
    <div class="btnwrap">
      <button pButton class="p-button-brand2" type="button" (click)="backBtn()" label="Back">
      </button>
    </div>
  </div>
</div>
<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="apiFail" [modal]="true"
  [style]="{width: '55vw', height: '20vh'}" [draggable]="false" [resizable]="false">
  <p>Failed to connect with the server, Sorry for the inconvenience</p>
</p-dialog>
<!-- search company name -->
<p-dialog header="Search Company" [(visible)]="searchCompanyPopUp" [modal]="true" [style]="{width: '31vw'}"
  [draggable]="false" [resizable]="false" [closable]="true" class="search-company">
  <div class="btnwrap p-col-12 p-px-0">
    <div class="p-fluid p-w-100">
      <span class="p-float-label p-input-icon-left">
        <p-autoComplete id="search1" name="search1" placeholder="Search for Company" [suggestions]="companyNameList"
          field="entityName" optionLabel="entityName" [(ngModel)]="companyNameSearchValue"
          [ngClass]="{'show': showEntityLoader}" [showEmptyMessage]="emptyMsgFlag" [emptyMessage]="emptyMsgVal">
        </p-autoComplete>
        <i class="pi pi-search" aria-hidden="true"></i>

      </span>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button [disabled]="!companyNameSearchValue" pButton (click)="resetCompanySearch()" label="Reset"
      class="p-button-info"></button>
    <button [disabled]="!companyNameSearchValue" pButton label="Apply" (click)="applyCompanySearch()"
      class="p-button-primary"></button>
  </ng-template>
</p-dialog>