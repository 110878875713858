<div *ngIf="totalListItems?.length > 0" class="p-d-flex p-jc-end p-mr-1 p-mb-2">
    <button pButton class="p-button-secondary p-button-sm" label="Approve All" [disabled]="!isApproveAllEnabled"
        (click)="approveAllPayments()">
    </button>

</div>

<ng-container *ngIf="listItems?.length > 0">
    <!-- <p>Primary Opportunity Payments</p> -->
    <!-- <p>Opportunity Name: </p>
    <h1 class="p-medium display-4">{{primaryOpportunityName}}</h1> -->

    <div class="p-col-10 p-d-flex p-my-2">
        <label class="label_light">Opportunity Name: </label>
        <h1 class="p-medium display-4">{{primaryOpportunityName}}</h1>
    </div>
    <ng-container *ngFor="let item of listItems;let i=index; trackBy: trackChange">
        <!-- payment item -->
        <form class="main-payment p-ai-center p-m-1" [formGroup]="item">
            <h1 class="p-medium display-4 p-px-3">Payment {{i + 1}}</h1>
            <div class="p-grid p-mx-2">
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">Payment Type : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.paymentType}}</h1>
                </div>
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">Payment Mode : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.paymentMode}}</h1>
                </div>
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">NEFT / Cheque No : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.chequeNo}}</h1>
                </div>

            </div>

            <div class="p-grid p-mx-2">
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">Bank : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.bank}}</h1>
                </div>
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">Payment Date : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.paymentDate}}</h1>
                </div>
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">TDS Type : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.tdsType}}</h1>
                </div>
            </div>

            <div class="p-grid p-mx-2">
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">TDS Amount : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.tdsAmount}}</h1>
                </div>
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">Amount Collected : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.amount}}</h1>
                </div>
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">Gross Amount : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.grossAmount}}</h1>
                </div>
            </div>

            <!-- payment approve/rej button -->
            <div class="p-grid p-mx-2 p-my-2">
                <div class="p-col-4">
                    <div class="p-d-flex">
                        <div
                            [ngClass]="{'p-formgroup-inline approve-radio' : true, 'active' :  item.get('paymentApprove' + i).value === 'Approved'}">
                            <div class="field-radiobutton p-radiobutton-xs">
                                <p-radioButton [disabled]="!!item.get('isDisabled')?.value" class="p-mr-1"
                                    [formControlName]="'paymentApprove' + i" value="Approved"
                                    (onClick)="onApprove(true, i, 'paymentApprove' + i, 'paymentComment')"
                                    [inputId]="i"></p-radioButton>
                                <label class="cursor-pointer p-ml-1 p-mb-0 label_light" [for]="i">Approve</label>

                            </div>
                        </div>


                        <div
                            [ngClass]="{'p-formgroup-inline reject-radio p-ml-3' : true, 'active' :  item.get('paymentApprove' + i).value === 'Rejected'}">
                            <div class="field-radiobutton p-radiobutton-xs">
                                <p-radioButton [disabled]="!!item.get('isDisabled')?.value"
                                    class="p-mr-1 p-radioButton-sm" [formControlName]="'paymentApprove' + i"
                                    value="Rejected"
                                    (onClick)="onApprove(false, i, 'paymentApprove' + i, 'paymentComment')"
                                    [inputId]="i +'identity'"></p-radioButton>
                                <label class="p-ml-1 p-mb-0 cursor-pointer label_light"
                                    [for]="i +'identity'">Reject</label>
                            </div>
                        </div>
                    </div>
                    <div class="invalid-feedback"
                        *ngIf="item.get('paymentApprove' + i)?.touched && item.get('paymentApprove' + i)?.errors?.required && item.get('paymentApprove' + i)?.invalid">
                        This field is required
                    </div>
                </div>


                <div class="p-col-8 p-d-flex">
                    <div class="p-col-12 p-p-0">
                        <textarea [attr.disabled]="!!item.get('isDisabled')?.value?true:null"
                            [ngClass]="{'error':(item.get('paymentComment')?.touched && (item.get('paymentComment')?.errors?.required || item.get('paymentComment')?.errors?.minlength) && item.get('paymentComment')?.invalid)}"
                            pInputTextarea id="comment" maxlength="500" formControlName="paymentComment" rows="1"
                            cols="3" placeholder="Enter your comment here"></textarea>


                        <div class="invalid-feedback"
                            *ngIf="item.get('paymentComment')?.touched && item.get('paymentComment')?.errors?.required && item.get('paymentComment')?.invalid">
                            Comment is required
                        </div>

                        <div class="invalid-feedback"
                            *ngIf="item.get('paymentComment')?.touched && !item.get('paymentComment')?.errors?.required && item.get('paymentComment')?.errors?.minlength && item.get('paymentComment')?.invalid">
                            Please enter atleast 3 characters
                        </div>
                    </div>
                </div>

            </div>

            <!-- divider -->
            <div style="border-top: 1px solid #cfcdcd;" class="p-mx-3 p-my-3"></div>

            <!-- cheque attachment -->
            <div class="p-grid p-mx-2 p-ai-center">
                <div class="p-col-5">
                    <label class="label_light">Cheque Attachment : </label>
                </div>
                <div class="p-col-3">
                    <div class="p-d-flex">
                        <div
                            [ngClass]="{'p-formgroup-inline approve-radio' : true, 'active' : item.get('chequeApprove' + i).value === 'Approved'}">
                            <div class="field-radiobutton p-radiobutton-xs">
                                <p-radioButton [disabled]="!!item.get('isDisabled')?.value" class="p-mr-1"
                                    [formControlName]="'chequeApprove' + i" value="Approved"
                                    (onClick)="onApprove(true, i, 'chequeApprove' + i, 'chequeAttachmentComment')"
                                    [inputId]="i + 'cheque'"></p-radioButton>
                                <label class="cursor-pointer p-ml-1 p-mb-0 label_light"
                                    [for]="i + 'cheque'">Approve</label>

                            </div>
                        </div>


                        <div
                            [ngClass]="{'p-formgroup-inline reject-radio p-ml-3' : true, 'active' : item.get('chequeApprove' + i)?.value === 'Rejected'}">
                            <div class="field-radiobutton p-radiobutton-xs">
                                <p-radioButton [disabled]="!!item.get('isDisabled')?.value"
                                    class="p-mr-1 p-radioButton-sm" [formControlName]="'chequeApprove' + i"
                                    value="Rejected"
                                    (onClick)="onApprove(false, i, 'chequeApprove' + i, 'chequeAttachmentComment')"
                                    [inputId]="i +'chequeReject'"></p-radioButton>
                                <label class="p-ml-1 p-mb-0 cursor-pointer label_light"
                                    [for]="i +'chequeReject'">Reject</label>
                            </div>
                        </div>
                    </div>

                    <div class="invalid-feedback"
                        *ngIf="item.get('chequeApprove' + i)?.touched && item.get('chequeApprove' + i)?.errors?.required && item.get('chequeApprove' + i)?.invalid">
                        This field is required
                    </div>
                </div>


                <div class="p-col-4 p-d-flex">
                    <div class="p-col-12 p-p-0">
                        <textarea [attr.disabled]="!!item.get('isDisabled')?.value ? true : null"
                            [ngClass]="{'error':(item.get('chequeAttachmentComment')?.touched && (item.get('chequeAttachmentComment')?.errors?.required || item.get('chequeAttachmentComment')?.errors?.minlength) && item.get('chequeAttachmentComment')?.invalid)}"
                            pInputTextarea id="comment" formControlName="chequeAttachmentComment" rows="1" cols="3"
                            placeholder="Enter your comment here" maxlength="500"></textarea>


                        <div class="invalid-feedback"
                            *ngIf="item.get('chequeAttachmentComment')?.touched && item.get('chequeAttachmentComment')?.errors?.required && item.get('chequeAttachmentComment')?.invalid">
                            Comment is required
                        </div>

                        <div class="invalid-feedback"
                            *ngIf="item.get('chequeAttachmentComment')?.touched && !item.get('chequeAttachmentComment')?.errors?.required && item.get('chequeAttachmentComment')?.errors?.minlength && item.get('chequeAttachmentComment')?.invalid">
                            Please enter atleast 3 characters
                        </div>
                    </div>
                </div>

            </div>

            <!-- deposit slip -->
            <div class="p-grid p-mx-2 p-ai-center p-my-1">
                <div class="p-col-5">
                    <label class="label_light">Deposit Slip : </label>
                </div>
                <div class="p-col-3">
                    <div class="p-d-flex">
                        <div
                            [ngClass]="{'p-formgroup-inline approve-radio' : true, 'active' : item.get('depositApprove' + i).value === 'Approved'}">
                            <div class="field-radiobutton p-radiobutton-xs">
                                <p-radioButton [disabled]="!!item.get('isDisabled')?.value" class="p-mr-1"
                                    [formControlName]="'depositApprove' + i" value="Approved"
                                    (onClick)="onApprove(true, i, 'depositApprove' + i, 'depositSlipComment')"
                                    [inputId]="i + 'deposit'"></p-radioButton>
                                <label class="cursor-pointer p-ml-1 p-mb-0 label_light"
                                    [for]="i + 'deposit'">Approve</label>

                            </div>
                        </div>


                        <div
                            [ngClass]="{'p-formgroup-inline reject-radio p-ml-3' : true, 'active' : item.get('depositApprove' + i)?.value === 'Rejected'}">
                            <div class="field-radiobutton p-radiobutton-xs">
                                <p-radioButton [disabled]="!!item.get('isDisabled')?.value"
                                    class="p-mr-1 p-radioButton-sm" [formControlName]="'depositApprove' + i"
                                    value="Rejected"
                                    (onClick)="onApprove(false, i, 'depositApprove' + i, 'depositSlipComment')"
                                    [inputId]="i + 'depositRej'"></p-radioButton>
                                <label class="p-ml-1 p-mb-0 cursor-pointer label_light"
                                    [for]="i + 'depositRej'">Reject</label>
                            </div>
                        </div>
                    </div>
                    <div class="invalid-feedback"
                        *ngIf="item.get('depositApprove' + i)?.touched && item.get('depositApprove' + i)?.errors?.required && item.get('depositApprove' + i)?.invalid">
                        This field is required
                    </div>
                </div>


                <div class="p-col-4 p-d-flex">
                    <div class="p-col-12 p-p-0">
                        <textarea [attr.disabled]="!!item.get('isDisabled')?.value ? true : null"
                            [ngClass]="{'error':(item.get('depositSlipComment')?.touched && (item.get('depositSlipComment')?.errors?.required || item.get('depositSlipComment')?.errors?.minlength) && item.get('depositSlipComment')?.invalid)}"
                            pInputTextarea id="comment" formControlName="depositSlipComment" rows="1" cols="3"
                            placeholder="Enter your comment here" maxlength="500"></textarea>


                        <div class="invalid-feedback"
                            *ngIf="item.get('depositSlipComment')?.touched && item.get('depositSlipComment')?.errors?.required && item.get('depositSlipComment')?.invalid">
                            Comment is required
                        </div>

                        <div class="invalid-feedback"
                            *ngIf="item.get('depositSlipComment')?.touched && !item.get('depositSlipComment')?.errors?.required && item.get('depositSlipComment')?.errors?.minlength && item.get('depositSlipComment')?.invalid">
                            Please enter atleast 3 characters
                        </div>
                    </div>
                </div>

            </div>


        </form>
    </ng-container>
</ng-container>
<!-- payment-item -->

<!-- combo payment item start -->

<ng-container *ngIf="comboListItems?.length > 0">
    <!-- <p>Secondary Opportunity Payments</p> -->
    <div class="p-col-10 p-d-flex p-my-3">
        <label class="label_light">Opportunity Name: </label>
        <h1 class="p-medium display-4">{{secondaryOpportunityName}}</h1>
    </div>

    <ng-container *ngFor="let item of comboListItems;let i=index; trackBy: trackChange">
        <!-- payment item -->
        <form class="main-payment p-ai-center p-m-1" [formGroup]="item">
            <h1 class="p-medium display-4 p-px-3">Payment {{i + 1}}</h1>
            <div class="p-grid p-mx-2">
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">Payment Type : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.paymentType}}</h1>
                </div>
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">Payment Mode : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.paymentMode}}</h1>
                </div>
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">NEFT / Cheque No : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.chequeNo}}</h1>
                </div>

            </div>

            <div class="p-grid p-mx-2">
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">Bank : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.bank}}</h1>
                </div>
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">Payment Date : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.paymentDate}}</h1>
                </div>
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">TDS Type : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.tdsType}}</h1>
                </div>
            </div>

            <div class="p-grid p-mx-2">
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">TDS Amount : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.tdsAmount}}</h1>
                </div>
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">Amount Collected : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.amount}}</h1>
                </div>
                <div class="p-col-4 p-d-flex p-my-2">
                    <label class="label_light">Gross Amount : </label>
                    <h1 class="p-medium display-4">{{item?.value?.data?.grossAmount}}</h1>
                </div>
            </div>

            <!-- payment approve/rej button -->
            <div class="p-grid p-mx-2 p-my-2">
                <div class="p-col-4">
                    <div class="p-d-flex">
                        <div
                            [ngClass]="{'p-formgroup-inline approve-radio' : true, 'active' :  item.get('paymentApprove0' + i).value === 'Approved'}">
                            <div class="field-radiobutton p-radiobutton-xs">
                                <p-radioButton [disabled]="!!item.get('isDisabled')?.value" class="p-mr-1"
                                    [formControlName]="'paymentApprove0' + i" value="Approved"
                                    (onClick)="onApproveCombo(true, i, 'paymentApprove0' + i, 'paymentComment0')"
                                    [inputId]="'paymentApprove0' + i"></p-radioButton>
                                <label class="cursor-pointer p-ml-1 p-mb-0 label_light" [for]="'paymentApprove0' + i">Approve</label>

                            </div>
                        </div>


                        <div
                            [ngClass]="{'p-formgroup-inline reject-radio p-ml-3' : true, 'active' :  item.get('paymentApprove0' + i).value === 'Rejected'}">
                            <div class="field-radiobutton p-radiobutton-xs">
                                <p-radioButton [disabled]="!!item.get('isDisabled')?.value"
                                    class="p-mr-1 p-radioButton-sm" [formControlName]="'paymentApprove0' + i"
                                    value="Rejected"
                                    (onClick)="onApproveCombo(false, i, 'paymentApprove0' + i, 'paymentComment0')"
                                    [inputId]="'paymentApprove0' + i +'identity'"></p-radioButton>
                                <label class="p-ml-1 p-mb-0 cursor-pointer label_light"
                                    [for]="'paymentApprove0' + i +'identity'">Reject</label>
                            </div>
                        </div>
                    </div>
                    <div class="invalid-feedback"
                        *ngIf="item.get('paymentApprove0' + i)?.touched && item.get('paymentApprove0' + i)?.errors?.required && item.get('paymentApprove0' + i)?.invalid">
                        This field is required
                    </div>
                </div>


                <div class="p-col-8 p-d-flex">
                    <div class="p-col-12 p-p-0">
                        <textarea [attr.disabled]="!!item.get('isDisabled')?.value?true:null"
                            [ngClass]="{'error':(item.get('paymentComment0')?.touched && (item.get('paymentComment0')?.errors?.required || item.get('paymentComment0')?.errors?.minlength) && item.get('paymentComment0')?.invalid)}"
                            pInputTextarea id="comment" maxlength="500" formControlName="paymentComment0" rows="1"
                            cols="3" placeholder="Enter your comment here"></textarea>


                        <div class="invalid-feedback"
                            *ngIf="item.get('paymentComment0')?.touched && item.get('paymentComment0')?.errors?.required && item.get('paymentComment0')?.invalid">
                            Comment is required
                        </div>

                        <div class="invalid-feedback"
                            *ngIf="item.get('paymentComment0')?.touched && !item.get('paymentComment0')?.errors?.required && item.get('paymentComment0')?.errors?.minlength && item.get('paymentComment0')?.invalid">
                            Please enter atleast 3 characters
                        </div>
                    </div>
                </div>

            </div>

            <!-- divider -->
            <div style="border-top: 1px solid #cfcdcd;" class="p-mx-3 p-my-3"></div>

            <!-- cheque attachment -->
            <div class="p-grid p-mx-2 p-ai-center">
                <div class="p-col-5">
                    <label class="label_light">Cheque Attachment : </label>
                </div>
                <div class="p-col-3">
                    <div class="p-d-flex">
                        <div
                            [ngClass]="{'p-formgroup-inline approve-radio' : true, 'active' : item.get('chequeApprove0' + i).value === 'Approved'}">
                            <div class="field-radiobutton p-radiobutton-xs">
                                <p-radioButton [disabled]="!!item.get('isDisabled')?.value" class="p-mr-1"
                                    [formControlName]="'chequeApprove0' + i" value="Approved"
                                    (onClick)="onApproveCombo(true, i, 'chequeApprove0' + i, 'chequeAttachmentComment0')"
                                    [inputId]="i + 'cheque0'"></p-radioButton>
                                <label class="cursor-pointer p-ml-1 p-mb-0 label_light"
                                    [for]="i + 'cheque0'">Approve</label>

                            </div>
                        </div>


                        <div
                            [ngClass]="{'p-formgroup-inline reject-radio p-ml-3' : true, 'active' : item.get('chequeApprove0' + i)?.value === 'Rejected'}">
                            <div class="field-radiobutton p-radiobutton-xs">
                                <p-radioButton [disabled]="!!item.get('isDisabled')?.value"
                                    class="p-mr-1 p-radioButton-sm" [formControlName]="'chequeApprove0' + i"
                                    value="Rejected"
                                    (onClick)="onApproveCombo(false, i, 'chequeApprove0' + i, 'chequeAttachmentComment0')"
                                    [inputId]="i +'chequeReject0'"></p-radioButton>
                                <label class="p-ml-1 p-mb-0 cursor-pointer label_light"
                                    [for]="i +'chequeReject0'">Reject</label>
                            </div>
                        </div>
                    </div>

                    <div class="invalid-feedback"
                        *ngIf="item.get('chequeApprove0' + i)?.touched && item.get('chequeApprove0' + i)?.errors?.required && item.get('chequeApprove0' + i)?.invalid">
                        This field is required
                    </div>
                </div>


                <div class="p-col-4 p-d-flex">
                    <div class="p-col-12 p-p-0">
                        <textarea [attr.disabled]="!!item.get('isDisabled')?.value ? true : null"
                            [ngClass]="{'error':(item.get('chequeAttachmentComment0')?.touched && (item.get('chequeAttachmentComment0')?.errors?.required || item.get('chequeAttachmentComment0')?.errors?.minlength) && item.get('chequeAttachmentComment0')?.invalid)}"
                            pInputTextarea id="comment" formControlName="chequeAttachmentComment0" rows="1" cols="3"
                            placeholder="Enter your comment here" maxlength="500"></textarea>


                        <div class="invalid-feedback"
                            *ngIf="item.get('chequeAttachmentComment0')?.touched && item.get('chequeAttachmentComment0')?.errors?.required && item.get('chequeAttachmentComment0')?.invalid">
                            Comment is required
                        </div>

                        <div class="invalid-feedback"
                            *ngIf="item.get('chequeAttachmentComment0')?.touched && !item.get('chequeAttachmentComment0')?.errors?.required && item.get('chequeAttachmentComment0')?.errors?.minlength && item.get('chequeAttachmentComment0')?.invalid">
                            Please enter atleast 3 characters
                        </div>
                    </div>
                </div>

            </div>

            <!-- deposit slip -->
            <div class="p-grid p-mx-2 p-ai-center p-my-1">
                <div class="p-col-5">
                    <label class="label_light">Deposit Slip : </label>
                </div>
                <div class="p-col-3">
                    <div class="p-d-flex">
                        <div
                            [ngClass]="{'p-formgroup-inline approve-radio' : true, 'active' : item.get('depositApprove0' + i).value === 'Approved'}">
                            <div class="field-radiobutton p-radiobutton-xs">
                                <p-radioButton [disabled]="!!item.get('isDisabled')?.value" class="p-mr-1"
                                    [formControlName]="'depositApprove0' + i" value="Approved"
                                    (onClick)="onApproveCombo(true, i, 'depositApprove0' + i, 'depositSlipComment0')"
                                    [inputId]="i + 'deposit0'"></p-radioButton>
                                <label class="cursor-pointer p-ml-1 p-mb-0 label_light"
                                    [for]="i + 'deposit0'">Approve</label>

                            </div>
                        </div>


                        <div
                            [ngClass]="{'p-formgroup-inline reject-radio p-ml-3' : true, 'active' : item.get('depositApprove0' + i)?.value === 'Rejected'}">
                            <div class="field-radiobutton p-radiobutton-xs">
                                <p-radioButton [disabled]="!!item.get('isDisabled')?.value"
                                    class="p-mr-1 p-radioButton-sm" [formControlName]="'depositApprove0' + i"
                                    value="Rejected"
                                    (onClick)="onApproveCombo(false, i, 'depositApprove0' + i, 'depositSlipComment0')"
                                    [inputId]="i + 'depositRej0'"></p-radioButton>
                                <label class="p-ml-1 p-mb-0 cursor-pointer label_light"
                                    [for]="i + 'depositRej0'">Reject</label>
                            </div>
                        </div>
                    </div>
                    <div class="invalid-feedback"
                        *ngIf="item.get('depositApprove0' + i)?.touched && item.get('depositApprove0' + i)?.errors?.required && item.get('depositApprove0' + i)?.invalid">
                        This field is required
                    </div>
                </div>


                <div class="p-col-4 p-d-flex">
                    <div class="p-col-12 p-p-0">
                        <textarea [attr.disabled]="!!item.get('isDisabled')?.value ? true : null"
                            [ngClass]="{'error':(item.get('depositSlipComment0')?.touched && (item.get('depositSlipComment0')?.errors?.required || item.get('depositSlipComment0')?.errors?.minlength) && item.get('depositSlipComment0')?.invalid)}"
                            pInputTextarea id="comment" formControlName="depositSlipComment0" rows="1" cols="3"
                            placeholder="Enter your comment here" maxlength="500"></textarea>


                        <div class="invalid-feedback"
                            *ngIf="item.get('depositSlipComment0')?.touched && item.get('depositSlipComment0')?.errors?.required && item.get('depositSlipComment0')?.invalid">
                            Comment is required
                        </div>

                        <div class="invalid-feedback"
                            *ngIf="item.get('depositSlipComment0')?.touched && !item.get('depositSlipComment0')?.errors?.required && item.get('depositSlipComment0')?.errors?.minlength && item.get('depositSlipComment0')?.invalid">
                            Please enter atleast 3 characters
                        </div>
                    </div>
                </div>

            </div>


        </form>
    </ng-container>
</ng-container>

<!-- combo payment item end -->
<ng-template #paymentItemTemplate let-ctx>
    
</ng-template>

<ng-container *ngIf="totalListItems?.length === 0">
    <!-- <p-card class="height-100 p-m-2"> -->
        <div class="empmess">
            <div class="p-mt-6 p-mb-3">
                <svg width="143" height="120" viewBox="0 0 143 120"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_3793_6568)">
                        <rect x="14.5" y="28" width="100" height="78" rx="4"
                            fill="white" />
                        <path
                            d="M15 32C15 30.067 16.567 28.5 18.5 28.5H110.5C112.433 28.5 114 30.067 114 32V35.5H15V32Z"
                            fill="white" />
                        <path
                            d="M15 32C15 30.067 16.567 28.5 18.5 28.5H110.5C112.433 28.5 114 30.067 114 32V35.5H15V32Z"
                            stroke="#76A7F9" />
                        <path
                            d="M15 35.5H114V102C114 103.933 112.433 105.5 110.5 105.5H18.5C16.567 105.5 15 103.933 15 102V35.5Z"
                            fill="#EBF3FE" />
                        <path
                            d="M15 35.5H114V102C114 103.933 112.433 105.5 110.5 105.5H18.5C16.567 105.5 15 103.933 15 102V35.5Z"
                            stroke="#76A7F9" />
                        <rect x="18.5" y="95" width="10" height="1" rx="0.5"
                            transform="rotate(45 18.5 95)" fill="#B1CDFB" />
                        <rect x="18.5" y="97.8284" width="6" height="1"
                            rx="0.5" transform="rotate(45 18.5 97.8284)"
                            fill="#B1CDFB" />
                    </g>
                    <rect x="15" y="28.5" width="99" height="77" rx="3.5"
                        stroke="#76A7F9" />
                    <g clip-path="url(#clip1_3793_6568)">
                        <rect x="28.5" y="14" width="100" height="78" rx="4"
                            fill="white" />
                        <path
                            d="M29 18C29 16.067 30.567 14.5 32.5 14.5H124.5C126.433 14.5 128 16.067 128 18V21.5H29V18Z"
                            fill="white" />
                        <path
                            d="M29 18C29 16.067 30.567 14.5 32.5 14.5H124.5C126.433 14.5 128 16.067 128 18V21.5H29V18Z"
                            stroke="#76A7F9" />
                        <path
                            d="M34.2162 19.4098C34.0625 19.4716 33.8981 19.5022 33.7325 19.4999C33.404 19.4953 33.0906 19.3616 32.8599 19.1277C32.6293 18.8938 32.5 18.5785 32.5 18.25C32.5 17.9215 32.6293 17.6062 32.8599 17.3723C33.0906 17.1384 33.404 17.0047 33.7325 17.0001C33.8981 16.9978 34.0625 17.0284 34.2162 17.0902C34.3699 17.152 34.5097 17.2436 34.6277 17.3599C34.7456 17.4762 34.8392 17.6148 34.9032 17.7676C34.9671 17.9204 35 18.0844 35 18.25C35 18.4156 34.9671 18.5796 34.9032 18.7324C34.8392 18.8852 34.7456 19.0238 34.6277 19.1401C34.5097 19.2564 34.3699 19.348 34.2162 19.4098Z"
                            fill="#E7E7E7" />
                        <path
                            d="M39.6888 19.4098C39.5352 19.4716 39.3707 19.5022 39.2051 19.4999C38.8767 19.4953 38.5632 19.3616 38.3326 19.1277C38.102 18.8938 37.9727 18.5785 37.9727 18.25C37.9727 17.9215 38.102 17.6062 38.3326 17.3723C38.5632 17.1384 38.8767 17.0047 39.2051 17.0001C39.3707 16.9978 39.5352 17.0284 39.6888 17.0902C39.8425 17.152 39.9824 17.2436 40.1003 17.3599C40.2182 17.4762 40.3119 17.6148 40.3758 17.7676C40.4397 17.9204 40.4727 18.0844 40.4727 18.25C40.4727 18.4156 40.4397 18.5796 40.3758 18.7324C40.3119 18.8852 40.2182 19.0238 40.1003 19.1401C39.9824 19.2564 39.8425 19.348 39.6888 19.4098Z"
                            fill="#E7E7E7" />
                        <path
                            d="M45.1615 19.4098C45.0078 19.4716 44.8434 19.5022 44.6778 19.4999C44.3493 19.4953 44.0359 19.3616 43.8053 19.1277C43.5746 18.8938 43.4453 18.5785 43.4453 18.25C43.4453 17.9215 43.5746 17.6062 43.8053 17.3723C44.0359 17.1384 44.3493 17.0047 44.6778 17.0001C44.8434 16.9978 45.0078 17.0284 45.1615 17.0902C45.3152 17.152 45.455 17.2436 45.573 17.3599C45.6909 17.4762 45.7846 17.6148 45.8485 17.7676C45.9124 17.9204 45.9453 18.0844 45.9453 18.25C45.9453 18.4156 45.9124 18.5796 45.8485 18.7324C45.7846 18.8852 45.6909 19.0238 45.573 19.1401C45.455 19.2564 45.3152 19.348 45.1615 19.4098Z"
                            fill="#E7E7E7" />
                        <path
                            d="M29 21.5H128V88C128 89.933 126.433 91.5 124.5 91.5H32.5C30.567 91.5 29 89.933 29 88V21.5Z"
                            stroke="#76A7F9" />
                        <rect x="36.9" y="30.4" width="64.2" height="13.2"
                            rx="6.6" stroke="#CECECE" stroke-width="0.8" />
                        <path
                            d="M44.3063 37.7979L46.5 40M44.3225 34.6496C45.1969 35.5155 45.1969 36.9194 44.3225 37.7853C43.4481 38.6512 42.0303 38.6512 41.1559 37.7853C40.2814 36.9194 40.2814 35.5155 41.1559 34.6496C42.0303 33.7835 43.4481 33.7835 44.3225 34.6496Z"
                            stroke="#CECECE" stroke-width="0.8"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        <rect x="106.913" y="30.4133" width="13.1733"
                            height="13.2709" rx="6.58667" stroke="#76A7F9"
                            stroke-width="0.826667" />
                        <path
                            d="M114.207 37.6969L116.625 40.1241M114.225 34.2267C115.189 35.1811 115.189 36.7286 114.225 37.683C113.261 38.6374 111.698 38.6374 110.735 37.683C109.771 36.7286 109.771 35.1811 110.735 34.2267C111.698 33.2721 113.261 33.2721 114.225 34.2267Z"
                            stroke="#76A7F9" stroke-width="0.8"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M113.723 51.1478L110.561 41.5834C110.228 40.5744 111.319 39.6703 112.331 40.1173L121.758 44.2858C122.876 44.7795 122.688 46.3365 121.481 46.5777L117.6 47.3555L116.16 51.1906C115.745 52.2918 114.092 52.2615 113.723 51.1478Z"
                            fill="#76A7F9" />
                        <rect x="36.5" y="54" width="71" height="4" rx="2"
                            fill="#E7E7E7" />
                        <rect x="36.5" y="66" width="58" height="4" rx="2"
                            fill="#E7E7E7" />
                        <rect x="36.5" y="78" width="68" height="4" rx="2"
                            fill="#E7E7E7" />
                    </g>
                    <rect x="29" y="14.5" width="99" height="77" rx="3.5"
                        stroke="#76A7F9" />
                    <defs>
                        <clipPath id="clip0_3793_6568">
                            <rect x="14.5" y="28" width="100" height="78"
                                rx="4" fill="white" />
                        </clipPath>
                        <clipPath id="clip1_3793_6568">
                            <rect x="28.5" y="14" width="100" height="78"
                                rx="4" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
    
            </div>
            <p>Payment is not available.</p>
        </div> 
    <!-- </p-card> -->
</ng-container>
