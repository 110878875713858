import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
import { DatePipe } from '@angular/common';
import { DOMAIN_ROUTE } from 'app/app.constants';
import { ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'backoffice-sf-realization-screen-details',
  templateUrl: './sf-realization-screen-details.component.html',
  styleUrls: ['./sf-realization-screen-details.component.scss'],
  providers: [MessageService]
})
export class SfRealizationScreenDetailsComponent implements OnInit {

  PaymentRealizationForm = this.fb.group({
    finalInvoice:[null,[]],
    bank:[null,[]],
    chequeNo:[null,[]],
    comment:[null,[]],
    companyCode:[null,[]],
    companyName:[null,[]],
    entityId:[null,[]],
    paymentStatusId:[null,[]],
    initiatedOn:[null,[]],
    isPaymentDeleted:[null,[]],
    isclearingdateChanged:[null,[]],
    netAmount:[null,[]],
    opportunityId:[null,[]],
    opportunityType:[null,[]],
    paymentAmount:[null,[]],
    paymentDate:[null,[]],
    paymentId:[null,[]],
    paymentMode:[null,[]],
    paymentType:[null,[]],
    sfBillId:[null,[]],
    rejectedReason:[null as any,[]],
    reason:[null,[Validators.required]],
    otp:[null,[Validators.required]],
    tdsAmount:[null,[]],
    clearingDate:[null as any,[Validators.required]]
  });
  activeIndex: number = 0;
  paymentDetails :any;
  showReasonField:boolean = false;
  showOtpField:boolean = false;
  disableReason: boolean = false;
  disableOtp: boolean = false;
  otpPin: any;
  paymentStatusList:any[];
  disableSaveBtn: boolean = true;
  isFieldsValid: boolean;
  minGlobalStartTime = new Date();
  financialYearEnd = new Date();
  maxGlobalEndTime = new Date();
  otpMessage: string;
  showOtpMessage: boolean = false;
  otpValidate: boolean;
  previousClearingDate: any;
  previousFinanceStatusId: any;
  previousRejectedReason: string = "";
  isSaveButtonClicked: boolean = false;

  attachments:any[] = [];
  pdfThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/pdfThumbnail.svg`;
  wordThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/wordThumbnail.svg`;
  imgThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/jpgThumbnail.svg`;
  mailThumb: any = `${DOMAIN_ROUTE}usermanagement-ui/service/content/images/mail.svg`;

  displayPreview: boolean = false;
  previewFailed: boolean = false;
  openedFile: any;
  zoomValue: number = 1;
  rotate:number = 0;
  dataLoader: boolean = false;
  isPdf: boolean = true;
  urlSafe: any;
  canvasRotation: number = 0;
  transform: ImageTransform = {};

  approverPopup: boolean = false;
  hierarchyList: any = [];
  loggedInUserEmpId: any;
  approverEmployeeId: any;

  balanceAmount:any;

  realizationReadScope: boolean = false;
  scopeList: any;

  otaApproverRoles:string[] = ['BDM-L1', 'BDM-L2', 'BDM-L3', 'AD-L1', 'AD-L2', 'AD-L3', 'D-L1', 'D-L2', 'D-L3'];

  constructor(
    public config : DynamicDialogConfig,private fb: FormBuilder,public ref: DynamicDialogRef,private messageService: MessageService,private pagesService: PagesService,private encryptDecryptInterceptor : EncryptDecryptInterceptor,public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getPaymentStatusList();

    this.loggedInUserEmpId = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid'));
    this.getUserHierarchy();
    
    this.paymentDetails = this.config?.data;
    if(this.paymentDetails.financialYear != null && this.paymentDetails.financialYear != ''){
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentFinancialYear = currentMonth >= 3 ? `${(currentYear).toString().substr(-2)}-${(currentYear + 1).toString().substr(-2)}` : `${(currentYear - 1).toString().substr(-2)}-${currentYear.toString().substr(-2)}`;
      const [financialYearStart, financialYearEnd] = this.getFinancialYearRange(currentFinancialYear);
      this.minGlobalStartTime = new Date(financialYearStart.getFullYear() - 2,3,1);
      this.maxGlobalEndTime = currentDate;
    }
    this.getAttachments(this.paymentDetails.opportunityId);
    this.PaymentRealizationForm.patchValue(this.paymentDetails);
    this.balanceAmount = this.paymentDetails?.balanceAmount;
    if(this.balanceAmount == null || this.balanceAmount == undefined){
      this.balanceAmount = 0;
    } else{
      this.balanceAmount = this.paymentDetails?.balanceAmount;
    }
    this.getOtpValidationData();

    this.scopeList = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('scopes'));
    let scope: any = this.scopeList.split(",");
    if(scope.indexOf('SF_REALIZATION_CREATE') == -1){
      this.realizationReadScope = true;
    } else {
      this.realizationReadScope = false;
    }

    this.setInitialFormFields();

    this.onPaymentRealizationFormValueChange();
  }

  onPaymentRealizationFormValueChange() {
    const initialValue = this.PaymentRealizationForm.value;
    this.PaymentRealizationForm.valueChanges.subscribe((change) => {
      const hasChange = Object.keys(initialValue).some(key => this.PaymentRealizationForm.value[key] != 
        initialValue[key]);
      if(hasChange) {
        this.isSaveButtonClicked = false;
      }
    });
  }

  setInitialFormFields() {
    this.previousFinanceStatusId = this.paymentDetails.paymentStatusId;
    this.previousClearingDate = this.paymentDetails.clearingDate;
    this.previousRejectedReason = this.paymentDetails.rejectedReason;

    if(this.paymentDetails.paymentStatusId == 3 || this.paymentDetails.paymentStatusId == 4) {
      this.PaymentRealizationForm.get("clearingDate").reset();
      this.PaymentRealizationForm.get("clearingDate").disable();
    }

    if(this.PaymentRealizationForm.get("paymentStatusId").value == 4) {
      this.PaymentRealizationForm.get("rejectedReason").disable();
    }
  }

  tabChange(event){
    this.activeIndex = event.index;
  }

  checkStatus(isClearingDateChanged: boolean = false){
    let paymentStatusContoller = this.PaymentRealizationForm.get("paymentStatusId");
    this.PaymentRealizationForm.get("rejectedReason").reset();
    if(this.paymentDetails.paymentStatusId == 2 || paymentStatusContoller.value == 2){
      this.otpValidate = true;
    } else{
      this.otpValidate = false;
    }

    if(paymentStatusContoller.value == 3 || paymentStatusContoller.value == 4) {
      if(this.PaymentRealizationForm.get("clearingDate").value) {
        this.previousClearingDate = this.PaymentRealizationForm.get("clearingDate").value;
      }
      this.PaymentRealizationForm.get("clearingDate").reset();
      this.PaymentRealizationForm.get("clearingDate").disable();
    }
    else {
      this.PaymentRealizationForm.get("clearingDate").enable();
      if(!isClearingDateChanged) {
        this.PaymentRealizationForm.get("clearingDate").setValue(this.previousClearingDate);
      }
      else {
        this.previousClearingDate = this.PaymentRealizationForm.get("clearingDate").value;
      }

    }

    if(paymentStatusContoller.value == 4) {
      if(this.previousRejectedReason) {
        this.PaymentRealizationForm.get("rejectedReason").setValue(this.previousRejectedReason);
      }
      if(this.previousFinanceStatusId!=paymentStatusContoller.value) {
        this.PaymentRealizationForm.get("rejectedReason").enable();
        this.PaymentRealizationForm.get("rejectedReason").addValidators([Validators.required]);
        this.previousRejectedReason = null;
      }
    }
    
    let paymentFormDateParts: any = this.PaymentRealizationForm.controls.clearingDate?.value;
    if(this.PaymentRealizationForm.controls.clearingDate.value!=null&&this.PaymentRealizationForm.controls.clearingDate.value!=''){
      paymentFormDateParts = this.convertToDate(this.PaymentRealizationForm.controls.clearingDate?.value);
    }
    let paymentFormClearingDate = paymentFormDateParts;
    if(paymentFormDateParts == undefined){
      if(this.PaymentRealizationForm.controls.clearingDate.value!=null&&this.PaymentRealizationForm.controls.clearingDate.value!=''){
        paymentFormDateParts = this.PaymentRealizationForm.controls.clearingDate?.value?.split("-");
        paymentFormClearingDate = new Date(Number(paymentFormDateParts[2]), Number(paymentFormDateParts[1]) - 1, Number(paymentFormDateParts[0]));
      }
    }
    const dateParts = this.paymentDetails?.clearingDate?.split("-");
    let isSameDate;
    if((dateParts == null || dateParts == undefined || dateParts == '')&&(paymentFormDateParts == null || paymentFormDateParts == undefined)){
      isSameDate = true;
    } else{
      let paymentDetailClearingDate;
      if(dateParts != null && dateParts != undefined && dateParts != ''){
        paymentDetailClearingDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
      }
      
      
      isSameDate = this.compareDates(paymentDetailClearingDate,paymentFormClearingDate);  
    }
    
    if((this.paymentDetails.paymentStatusId == this.PaymentRealizationForm.controls.paymentStatusId.value)&&(isSameDate)){
      this.disableSaveBtn = true;
      this.showReasonField = false;
      this.showOtpField = false;
    } else{
      if(this.paymentDetails.paymentStatusId == 2 || this.paymentDetails.paymentStatusId == 3 || this.paymentDetails.paymentStatusId == 4){
        this.showReasonField = true;
        this.disableOtp = false;
        this.disableSaveBtn = true;
      } else {
        this.disableSaveBtn = false;
      }
      
    }
  }

  generateOtp(){
    let employeeId = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid'));
    if (this.PaymentRealizationForm.controls.paymentStatusId.value == 2 && (this.PaymentRealizationForm.controls.clearingDate.value == null || this.PaymentRealizationForm.controls.clearingDate.value == undefined || this.PaymentRealizationForm.controls.clearingDate.value == "")) {
      this.PaymentRealizationForm.controls.clearingDate.markAsDirty();
    } else {
      if (this.PaymentRealizationForm.controls.reason.value != '' && this.PaymentRealizationForm.controls.reason.value != null && this.PaymentRealizationForm.controls.reason.value != undefined) {
        this.disableReason = true;
        this.disableOtp = true;
        this.disableSaveBtn = false;
        this.PaymentRealizationForm.get("rejectedReason").disable();
        let clearingDate: any = this.PaymentRealizationForm.controls.clearingDate.value;
        if ((this.PaymentRealizationForm.controls.clearingDate.value != '' && this.PaymentRealizationForm.controls.clearingDate.value != null && this.PaymentRealizationForm.controls.clearingDate.value != undefined) && this.paymentDetails.clearingDate == this.PaymentRealizationForm.controls.clearingDate.value) {
          clearingDate = this.reverseDate(clearingDate);
        }

        if(clearingDate != null){
          clearingDate = new Date(clearingDate);
        }

        let payload = {
          opportunityPaymentId: this.PaymentRealizationForm.controls.paymentId.value,
          sfId: this.PaymentRealizationForm.controls.sfBillId.value,
          paymentStatusId: this.PaymentRealizationForm.controls.paymentStatusId.value,
          oldClearingDate: this.reverseDate(this.paymentDetails.clearingDate),
          newClearingDate: clearingDate,
          createdBy: employeeId,
          otpSent: this.PaymentRealizationForm.controls.otp.value,
          reason: this.PaymentRealizationForm.controls.reason.value,
          rejectedReason: (this.PaymentRealizationForm.get('paymentStatusId').value == 4?(this.PaymentRealizationForm.get('rejectedReason').value):""),
          approverEmployeeId: this.approverEmployeeId
        }

        console.log(payload);
        this.pagesService.generateOtp(payload).subscribe((results: any) => {
          const response = results["responseMsg"];
          this.otpPin = response;
          if (this.otpPin.length > 6) {
            this.disableSaveBtn = true;
            this.messageService.add({ severity: 'error', detail: this.otpPin });
          } else {
            this.showOtpField = true;
            this.messageService.add({ severity: 'success', detail: "OTA Sent Successfully" });
            this.approverPopup=false;
          }

          setTimeout(() => {
            this.messageService.clear();
          }, 4000);
        },
        (error)=> {});

      } else {
        this.PaymentRealizationForm.controls.reason.markAsDirty();
      }
    }
  }

  onPaymentRealizeCancelBtn(): void {
    this.ref.close();
  }

  onPaymentRealizeSaveBtn(): void{
    this.isSaveButtonClicked = true;
    let employeeId = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('empid'));
    if ((this.paymentDetails.paymentStatusId == 2 || this.paymentDetails.paymentStatusId == 3 || this.paymentDetails.paymentStatusId == 4) && this.showOtpField) {
      
      if (this.PaymentRealizationForm.controls.otp.value != '' && this.PaymentRealizationForm.controls.otp.value != null && this.PaymentRealizationForm.controls.otp.value != undefined) {
        if (this.PaymentRealizationForm.controls.otp.value == this.otpPin) {
          let clearingDate: any = this.PaymentRealizationForm.controls.clearingDate.value;
          if(typeof clearingDate === 'string') {
            clearingDate = this.reverseDate(clearingDate);
          }
          
          if (this.PaymentRealizationForm.controls.clearingDate.value != null && this.PaymentRealizationForm.controls.clearingDate.value != ''){
            clearingDate = new Date(clearingDate);
          }
          let payload = {
            opportunityPaymentId: this.PaymentRealizationForm.controls.paymentId.value,
            sfId: this.PaymentRealizationForm.controls.sfBillId.value,
            paymentStatusId: this.PaymentRealizationForm.controls.paymentStatusId.value,
            rejectedReason: (this.PaymentRealizationForm.get('paymentStatusId').value == 4?(this.PaymentRealizationForm.get('rejectedReason').value):""),
            newClearingDate: clearingDate,
            createdBy: employeeId,
            otpSent: this.PaymentRealizationForm.controls.otp.value
          }

          this.pagesService.updateFinanceData(payload).subscribe((results: any) => {
            this.disableSaveBtn = true;
            const response = results["responseMsg"];
            this.ref.close(response);
          },
          (error)=> {});
        } else {
          this.PaymentRealizationForm.controls.otp.markAsDirty();
        }

      } else {
        this.disableSaveBtn = false;
        this.PaymentRealizationForm.controls.otp.markAsDirty();
      }
      
    } else{
      if(this.PaymentRealizationForm.controls.paymentStatusId.value == 2 && (this.PaymentRealizationForm.controls.clearingDate.value == null || this.PaymentRealizationForm.controls.clearingDate.value == undefined || this.PaymentRealizationForm.controls.clearingDate.value == "")){
        this.PaymentRealizationForm.controls.clearingDate.markAsDirty();
      } else{
        let clearingDate: any = this.PaymentRealizationForm.controls.clearingDate.value;
        if(this.PaymentRealizationForm.controls.clearingDate.value!=null&&this.PaymentRealizationForm.controls.clearingDate.value!=''&&this.paymentDetails.clearingDate == this.PaymentRealizationForm.controls.clearingDate.value){
          clearingDate = this.reverseDate(clearingDate);
        }

        if(this.PaymentRealizationForm.controls.clearingDate.value!=null&&this.PaymentRealizationForm.controls.clearingDate.value!=''){
          clearingDate = new Date(clearingDate)
        }
        let payload = {
          opportunityPaymentId: this.PaymentRealizationForm.controls.paymentId.value,
          sfId: this.PaymentRealizationForm.controls.sfBillId.value,
          paymentStatusId: this.PaymentRealizationForm.controls.paymentStatusId.value,
          newClearingDate: clearingDate,
          createdBy:employeeId,
          rejectedReason: (this.PaymentRealizationForm.get('paymentStatusId').value == 4?(this.PaymentRealizationForm.get('rejectedReason').value):"")
        }
        this.pagesService.updateFinanceData(payload).subscribe((results:any)=>{
          this.disableSaveBtn = false;
          const response = results["responseMsg"];
          this.ref.close(response);
        },
        (error)=> {});
      }
      
    }
  }

  getOtpValidationData(): void{
    let sfBillId = this.paymentDetails.sfBillId;
    let opportunityPaymentId = this.paymentDetails.paymentId;

    this.pagesService.validateOtp(sfBillId,opportunityPaymentId).subscribe((results:any)=>{
      const otpDetails = results["responseMsg"];
      if(otpDetails.otpSent != null && otpDetails.otpSent != 0) {
        this.showOtpField = true;
        this.disableReason = true;
        this.disableOtp = true;
        this.showReasonField = true;
        this.otpPin = otpDetails.otpSent;
        this.PaymentRealizationForm.controls.paymentStatusId.setValue(otpDetails.paymentStatusId);
        this.PaymentRealizationForm.controls.clearingDate.setValue(this.reverseDate(otpDetails.newClearingDate));
        this.PaymentRealizationForm.controls.reason.setValue(otpDetails.reason);

        if(this.PaymentRealizationForm.get("paymentStatusId").value == 4) {
          this.PaymentRealizationForm.get("rejectedReason").setValue(otpDetails.rejectedReason);
          this.PaymentRealizationForm.get("rejectedReason").disable();
        }

        this.disableSaveBtn = false;
      } else if(otpDetails.otpSent == 0 && otpDetails.otpReceived == 0) {
        this.showOtpField = false;
        this.disableReason = true;
        this.PaymentRealizationForm.get("rejectedReason").disable();
        this.disableOtp = false;
        this.showReasonField = true;
        this.showOtpMessage = true;
        this.otpMessage = "OTA Expired, Please Resend the OTA";
        this.PaymentRealizationForm.controls.paymentStatusId.setValue(otpDetails.paymentStatusId);
        this.PaymentRealizationForm.controls.clearingDate.setValue(this.reverseDate(otpDetails.newClearingDate));
        this.PaymentRealizationForm.controls.reason.setValue(otpDetails.reason);
      } else {
        this.disableOtp = true;
        this.showReasonField = false;
        this.showOtpField = false;
        this.disableSaveBtn = true;
      }

    },
    (error)=> {});
  }

  getPaymentStatusList(){
    this.pagesService.getPaymentStatusTypes().subscribe(results => {
      this.paymentStatusList = results;
    },
    (error)=> {});
  }

  reverseDate(date : string) {
    console.log("Reversing: \t", date);
    if(date!=null){
      return date.split("-").reverse().join("-");
    } else{
      return null;
    }
    
  }

  getFinancialYearRange(financialYear: string): [Date, Date] {
    const [startYear, endYear] = financialYear.split('-').map(Number);
    const startMonth = 3; // April is the 3rd month (0-indexed)
    const endMonth = 2; // March is the 2nd month (0-indexed)
  
    const startDate = new Date(startYear + 2000 - 1, startMonth, 1);
    const endDate = new Date(endYear + 2000, endMonth, 31);
  
    return [startDate, endDate];
  }

  compareDates(paymentDate: Date, paymentFormDate: Date): boolean {
    // Compare the year, month, and day components
    if((paymentDate != null || paymentDate != undefined)&&(paymentFormDate != null || paymentFormDate != undefined)){
      return (
        paymentDate.getFullYear() === paymentFormDate.getFullYear() &&
        paymentDate.getMonth() === paymentFormDate.getMonth() &&
        paymentDate.getDate() === paymentFormDate.getDate()
      );
    } else{
      return false;
    }
    
  }

  convertToDate(input: string | Date): Date {
    if (typeof input === "string") {
      const dateParts = input.split("-");
      return new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
    } else if (input instanceof Date) {
      return input;
    } else {
      throw new Error("Invalid input type. Expected string or Date.");
    }
  }

  getAttachments(opportunityId) {
    this.pagesService.getAttachmentsByOpportunityIdAndModule(opportunityId, "Opportunity").subscribe((response: Array<any>) => {
      this.attachments = response;
    },
    (error)=> {});
  }

  showAttachmentPreview(item){
    this.openedFile = item;
    let ext = item.fileName?.split('.')[1];
    if(ext.includes('msg')){
      return;
    }
    this.zoomValue = 1;
    this.rotate = 0;

    if(item?.fileId){
      this.displayPreview = true;
      this.dataLoader = true;
      let payload = {
        id: item.fileId
      }
      this.pagesService.findPDF(payload).subscribe((res) => {
        if(res && res?.file){
          let ext = res?.fileName?.split('.');
          let ext1 = ext[ext.length - 1];
          let mime: any;
          if(['png', 'jpg', 'jpeg'].includes(ext1?.toLowerCase())){
            this.isPdf = false;
            mime = 'data:image/png;base64,'
          } else {
            this.isPdf = true;
            mime = 'data:application/pdf;base64,'
          }
          this.urlSafe = `${mime}${res.file}`
          if(!this.isPdf){
            let el = document.getElementById('imagePrev');
            setTimeout(() => {
              el.style.transform = 'rotate(0deg) scale(1)';
            }, 100);
          }
          this.dataLoader = false;
        } else {
          this.displayPreview = false;
          this.previewFailed = true;
        }
      }, (_error) => {
        this.displayPreview = false;
        this.previewFailed = true;
      });
    } else {
      let tempArray: any = [];
      if(item.fileContentType == "application/pdf"){
        this.displayPreview = true;
        this.dataLoader = true;
        let data = '';
        if(item?.file?.startsWith('data:application/pdf;base64')){
          data = item?.file;
        } else {
          data = `data:application/pdf;base64,${item.file}`;
        }
        this.urlSafe = data;
        this.dataLoader = false;
      } else {
        let obj: any = {};
        if (item.fileContentType.startsWith('application/vnd') || item.fileContentType == 'application/msword') {
          obj = {
            fileType: 'doc',
            fileName: item.fileName,
            attachmentType: item.fileTypeName,
            fileData: item.file.split(',')[1]
          }
        } else {
          obj = {
            fileType: item.fileContentType.split('/')[1],
            fileName: item.fileName,
            attachmentType: item.fileTypeName,
            fileData: item.file
          }
        }
        tempArray.push(obj);
        this.displayPreview = true;
        this.dataLoader = true;
        this.pagesService.combineAllPDF(tempArray).subscribe((res) => {
          if(res && res?.fileData){
            this.urlSafe = `data:application/pdf;base64,${res.fileData}`
            this.dataLoader = false;
            this.previewFailed = false;
          } else {
            this.displayPreview = false;
            this.previewFailed = true;
          }
        }, (_error) => {
          this.displayPreview = false;
          this.previewFailed = true;
        });
      }
    }
  }

  rotateFile(x){
    if (x === -1) {
      this.rotate -= 90;
    } else {
      this.rotate += 90;
    }
    this.transformImage();
  }
  
  zoom(x){
    if (x === -1) {
      if(this.zoomValue > 0.1){
        this.zoomValue -= 0.1;
      }
    } else {
      this.zoomValue += 0.1;
    }
    this.transformImage();
  }

  transformImage(){
    let el = document.getElementById('imagePrev');
    el.style.transform = `rotate(${this.rotate}deg) scale(${this.zoomValue})`;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  downloadFile(){
    let linkSource: any;
    if(this.openedFile?.fileContentType?.includes('image')){
      let mime = 'data:image/png;base64';
      linkSource = `${mime},${this.urlSafe?.split(',')[1]}`;
    } else {
      linkSource = this.urlSafe;
    }
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = this?.openedFile?.fileName.split('.')[0];
    downloadLink.click(); 
  }

  getUserHierarchy() {
    this.pagesService.getSfBdHierarchy(this.loggedInUserEmpId).subscribe((res: any) => {
      let data = JSON.parse(res.upwardHierarchyUsers);
      this.hierarchyList = data?.uppwardhierarchy.filter((hierarchyUser) => hierarchyUser.employeeid.toUpperCase() !=this.loggedInUserEmpId.toUpperCase() && this.otaApproverRoles.indexOf(hierarchyUser.levelName)>=0).map((user) => ({...user, empNameWithDesignation: (user.employeeName+" ("+user.levelName+")")}));
      this.hierarchyList.sort((u1, u2) => ((u1.levelid>u2.levelid) ? 1 : ((u1.levelid<u2.levelid) ? -1 : 0)));
    },
    (error)=> {});
  }

  openApproverPopup() {
    this.approverEmployeeId = null;
    this.approverPopup = true;
  }
}
