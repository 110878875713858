import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccountService } from 'app/core/auth/account.service';
import { BACKOFFICE_SERVER_API_URL, FILEUPLOAD_URL, NEXERA_SERVER_API_URL, SERVER_API_INTEGRATION_URL, } from 'app/app.constants';
import { EncryptDecryptInterceptor } from 'app/blocks/interceptor/EncryptDecrypt.interceptor';
@Injectable({
  providedIn: 'root'
})
export class InitiationService {
  userId: any
  apiCallList: any = [];
  
  public empId = this.encDec.decrypt(localStorage.getItem('empid'));
  
  isLoading = new BehaviorSubject<boolean>(true);
  constructor(private accountService: AccountService, private http: HttpClient, private encDec: EncryptDecryptInterceptor) {
    this.accountService.identity().subscribe(account => {
      if (account) {
        this.userId = account.login;
      }
    });
  }

  getIrfInitationDetails(payload): Observable<any> {
    return this.http.post(BACKOFFICE_SERVER_API_URL + 'api/v1/irfinitiation/findByOpportunityId', payload);
  }

  getLimitDetails(companyCode: string, productName: string, masterServiceId: number, subServiceId: number, ratableUnitId: number, agreementId : any = 0, instrumentId : any = 0): Observable<any> {
    this.encDec.encrypt(companyCode);
    //wcxbRKoHmilwHcbSQg3yKA==  - DIOFDS
    //Ma7cmKYhs8pZBMAE3fmJhw==  - BLR
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/ROWIntegration/limits/details?companyCode='+this.encDec.encrypt(companyCode)+'&masterService='+this.encDec.encrypt(masterServiceId)+'&subService='+this.encDec.encrypt(subServiceId)+'&ratableUnit='+this.encDec.encrypt(ratableUnitId)+'&agreemntId='+this.encDec.encrypt(agreementId)+'&instrumentId='+this.encDec.encrypt(instrumentId));
  }

  getCaseDetails(opportunityId: number): Observable<any> {
    this.encDec.encrypt(opportunityId.toString());
    return this.http.get(NEXERA_SERVER_API_URL + 'api/v1/case/details?opportunityId='+this.encDec.encryptWith(opportunityId));
  }

  getAllContacts(payload): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.post(SERVER_API_INTEGRATION_URL + `api/v1/CRMintegration/findAllContactsByCompanyCode`,payload, { headers: headersList });
  }

  getClientType(): Observable<any>{
    return this.http.get(BACKOFFICE_SERVER_API_URL + 'api/v1/irfinitiation/getAllclientType');
  }

  secondaryOppDetails(payload): Observable<any>{
    return this.http.post(NEXERA_SERVER_API_URL + 'api/v1/irf/initiation/findSecondaryOpportunityDetails',payload);
  }


  docList(): Observable<any> {
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/getSingleMasterData/ReportingAttachmentType');
  }

  productServices(payload): Observable<any> {
    const encPayload = this.encDec.encryptWith(payload);
    return this.http.get(SERVER_API_INTEGRATION_URL + `api/v1/integration/findRatableUnitMasterServiceAndSubServiceByProductId?productId=${encPayload}`)
  }

  combineMaster(): Observable<any> {
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/getSingleMasterData/CombineMasters')
  }

  currencyMaster(): Observable<any> {
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/getSingleMasterData/Currency');
  }

  getUserHierarchy(payload): Observable<any> {
    const encPayload = this.encDec.encryptWith(payload);
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/backoffice/hiearachy/findByEmployeeId?employeeId=${encPayload}`)
  }

  getUpdatedUserHierarchy(opprId, opprStatus): Observable<any> {
    const encOppId = this.encDec.encrypt(opprId);
    const encOppStatus = this.encDec.encrypt(opprStatus);
    
    return this.http.get(NEXERA_SERVER_API_URL + `api/v1/entity/findLatestIrfWonMeeting?opportunityId=${encOppId}&opportunityStatus=${encOppStatus}`);
  }

  getAllUserHirearchy(): Observable<any> {
    return this.http.get(BACKOFFICE_SERVER_API_URL + 'api/v1/backoffice/hiearachy/findAllUserList?type=mpzHCFFPa4QBddrRcErfgQ==');
  }

  saveInitiation(payload): Observable<any> {
    return this.http.post(BACKOFFICE_SERVER_API_URL + 'api/v1/irfinitiation/update', payload);
  }

  getQcActions(modules, qcStatus, agreementId): Observable<any>{
    const encAgreement = this.encDec.encryptWith(agreementId)
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/irfqc/actions?agreementId=${encAgreement}&parentModules=${modules}&qcFieldStatus=${qcStatus}`);
  }

  showLoader(api){
    this.apiCallList.push(api);
    if(api == 'clear'){
      this.apiCallList = [];
    }
  }

  getCaseCloseReason(): Observable<any>{
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/findDataByCombineMaster/CaseClose');
  }

  hideLoader(api){
    let index = this.apiCallList.findIndex(a => a == api);
    if(index != -1){
      this.apiCallList.splice(index, 1);
    }
    if(this.apiCallList?.length == 0){
      this.isLoading.next(false)
    }
  }
  
  generateOta(payload): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.post(SERVER_API_INTEGRATION_URL + `api/v1/ota/generate`,payload, { headers: headersList });
  }

  cancelOta(payload): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.post(SERVER_API_INTEGRATION_URL + `api/v1/ota/cancel`,payload, { headers: headersList });
  }

  getBusinessType(): Observable<any>{
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/findDataByCombineMaster/IrfBusinessAction');
  }

  validateOta(payload): Observable<any>{
    const headersList = new HttpHeaders();
    return this.http.post(BACKOFFICE_SERVER_API_URL + `api/v1/irfinitiation/ota/validate`,payload, { headers: headersList });
  }

  getAMCName(companyCode : string) {
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/irfinitiation/getCrmAmcName?companyCode=${this.encDec.encrypt(companyCode)}`);
  }
  
  getMFName(amcCode: string) {
    return this.http.get(BACKOFFICE_SERVER_API_URL + `api/v1/irfinitiation/getMfSchemeName?amcCode=${this.encDec.encrypt(amcCode)}`);
  }

  // revision IRF APIs

  getRevisionById(agreementId, opportunityId) {
    return this.http.post(SERVER_API_INTEGRATION_URL + 'api/v1/revision/irf/findById', {agreementId, opportunityId});
  }

  saveRevision(payload) {
    return this.http.post(SERVER_API_INTEGRATION_URL + 'api/v1/revision/irf/ota/generate', payload);
  }

  cancelRevisionOta(payload) {
    return this.http.post(SERVER_API_INTEGRATION_URL + 'api/v1/revision/irf/ota/cancel', payload);  
  }

  verifyRevisionOta(payload) {
    return this.http.post(SERVER_API_INTEGRATION_URL + 'api/v1/revision/irf/ota/validate', payload);  
  }

  getAllApproverNamesForRevisionOTA() {
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/getSingleMasterData/OtaApprovalEmployeeDetails')
  }

  getBusinessTypeMaster(){
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/findDataByCombineMaster/BusinessType');
  }

  singleFileUpload(payload): Observable<any> {
    return this.http.post(NEXERA_SERVER_API_URL + 'api/v1/attachment/upload', payload);
  }

  deleteFileById(paylaod): Observable<any> {
    const encPayload = this.encDec.encryptWith(paylaod);
    return this.http.get(NEXERA_SERVER_API_URL + `api/v1/attachment/delete?fileId=${encPayload}`);
  }

  deleteFileByModuleIdName(paylaod): Observable<any>{
    return this.http.get(NEXERA_SERVER_API_URL + `api/v1/attachment/delete/by/module?moduleId=ISzprReolxJjcIqUzeIbfg==&moduleName=jkSy/+GS5aICopubUabPsg==`);
  }

  deleteMultipleFiles(payload): Observable<any>{
    return this.http.post(NEXERA_SERVER_API_URL + `api/v1/attachment/delete/multiple`, payload);
  }

  mandateValidity(): Observable<any>{
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/getSingleMasterData/MstSFMandateValidity');
  }
  
  opsReasonMaster(): Observable<any> {
    return this.http.get(SERVER_API_INTEGRATION_URL + 'api/v1/integration/getSingleMasterData/OpsReason');
  }
}
