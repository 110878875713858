<div>
    <form [formGroup]="OtherUSerForm" autocomplete="off" novalidate>
        <p-messages></p-messages>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12  p-p-0">
                <div class="ui-fluid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12  left popupBodyScrollContainer">
                        <div class="p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12  left p-pl-0">
                                <h6> User Info</h6>
                            </div>
                        </div>
                        <div class="p-formgrid p-grid p-d-flex p-ai-start">
                            <!-- Employee ID -->
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-pl-0 left">
                                <div class="p-fluid p-field">
                                    <label>
                                        Employee Id<span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <input type="text" id="employeeId" name="employeeId" formControlName="employeeId" pInputText
                                        placeholder="" class="p-inputtext" (keydown.space)="$event.preventDefault();" pattern="^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$"
                                        [ngClass]="{'error': (OtherUSerForm.get('employeeId')!.invalid && (OtherUSerForm.get('employeeId')!.dirty || OtherUSerForm.get('employeeId')!.touched))}" />
                                    <div class="invalid-feedback"
                                            *ngIf="OtherUSerForm.get('employeeId')!.invalid && (OtherUSerForm.get('employeeId')!.dirty || OtherUSerForm.get('employeeId')!.touched)">
                                        <div *ngIf="OtherUSerForm.get('employeeId')?.errors?.required">
                                            Employee Id is required.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Employee Full Name -->
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-pl-0 left">
                                <div class="p-fluid p-field">
                                    <label>
                                        Full Name<span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <input type="text" id="employeeName" name="employeeName" formControlName="employeeName" pInputText
                                        placeholder="" class="p-inputtext" pattern="^[A-Za-z]+(?:\s[A-Za-z]+)*$"
                                        [ngClass]="{'error': (OtherUSerForm.get('employeeName')!.invalid && (OtherUSerForm.get('employeeName')!.dirty || OtherUSerForm.get('employeeName')!.touched))}" />
                                    <div class="invalid-feedback"
                                            *ngIf="OtherUSerForm.get('employeeName')!.invalid && (OtherUSerForm.get('employeeName')!.dirty || OtherUSerForm.get('employeeName')!.touched)">
                                        <div *ngIf="OtherUSerForm.get('employeeName')?.errors?.required">
                                            Employee Full Name is required.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Employee Date Of Joining -->
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-pl-0 left">
                                <div class="p-fluid p-field">
                                    <label>
                                        Date Of Joining<span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <!-- <input type="text" id="dateOfJoining" name="dateOfJoining" formControlName="dateOfJoining" pInputText
                                        placeholder="" class="p-inputtext"
                                        [ngClass]="{'error': (OtherUSerForm.get('dateOfJoining')!.invalid && (OtherUSerForm.get('dateOfJoining')!.dirty || OtherUSerForm.get('dateOfJoining')!.touched))}" /> -->

                                        <p-calendar dateFormat="dd-mm-yy" id="dateOfJoining" placeholder="Date" formControlName="dateOfJoining"
                                            [hideOnDateTimeSelect]="true"
                                            [ngClass]="{'error': (OtherUSerForm.get('dateOfJoining')!.invalid && (OtherUSerForm.get('dateOfJoining')!.dirty || OtherUSerForm.get('dateOfJoining')!.touched))}"
                                            [showIcon]="true" yearRange="2000:2030">
                                        </p-calendar>
                                    <div class="invalid-feedback"
                                            *ngIf="OtherUSerForm.get('dateOfJoining')!.invalid && (OtherUSerForm.get('dateOfJoining')!.dirty || OtherUSerForm.get('dateOfJoining')!.touched)">
                                        <div *ngIf="OtherUSerForm.get('dateOfJoining')?.errors?.required">
                                            Employee Date Of Joining is required.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Employee Windows Id -->
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-pl-0 left">
                                <div class="p-fluid p-field">
                                    <label>
                                        Windows Id<span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <input type="text" id="windowsId" name="windowsId" formControlName="windowsId" pInputText
                                        placeholder="" class="p-inputtext" (keydown.space)="$event.preventDefault();" pattern="^([a-zA-Z0-9.-/-]+\s)*[a-zA-Z0-9.-/-]+$"
                                        [ngClass]="{'error': (OtherUSerForm.get('windowsId')!.invalid && (OtherUSerForm.get('windowsId')!.dirty || OtherUSerForm.get('windowsId')!.touched))}" />
                                    <div class="invalid-feedback"
                                            *ngIf="OtherUSerForm.get('windowsId')!.invalid && (OtherUSerForm.get('windowsId')!.dirty || OtherUSerForm.get('windowsId')!.touched)">
                                        <div *ngIf="OtherUSerForm.get('windowsId')?.errors?.required">
                                            Employee Windows Id is required.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Employee Email ID -->
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-pl-0 left">
                                <div class="p-fluid p-field">
                                    <label>
                                        Email<span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <input type="text" id="emailId" name="emailId" formControlName="emailId" pInputText
                                        placeholder="" class="p-inputtext" pattern="^\w+[a-zA-Z0-9-._]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-_!@#$%^&)(,+=*]{2,10})+$"
                                        [ngClass]="{'error': (OtherUSerForm.get('emailId')!.invalid && (OtherUSerForm.get('emailId')!.dirty || OtherUSerForm.get('emailId')!.touched))}" />
                                    <div class="invalid-feedback"
                                            *ngIf="OtherUSerForm.get('emailId')!.invalid && (OtherUSerForm.get('emailId')!.dirty || OtherUSerForm.get('emailId')!.touched)">
                                        <div *ngIf="OtherUSerForm.get('emailId')?.errors?.required">
                                            Employee Email Address is required.
                                        </div>
                                        <div *ngIf="OtherUSerForm.get('emailId')?.errors?.pattern">
                                            Email must be a valid email address
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Employee Mobile Number -->
                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-pl-0 left">
                                <div class="p-fluid p-field">
                                    <label>
                                        Mobile Number<span class='invalid-feedback-astrik'>*</span>
                                    </label>
                                    <p-inputMask id="mobileNumber" name="mobileNumber" formControlName="mobileNumber"
                                        [ngClass]="{'error': (OtherUSerForm.get('mobileNumber')!.invalid && (OtherUSerForm.get('mobileNumber')!.dirty || OtherUSerForm.get('mobileNumber')!.touched))}"
                                        mask="9999999999" placeholder="" slotChar="">
                                    </p-inputMask>
                                    <div class="invalid-feedback"
                                            *ngIf="OtherUSerForm.get('mobileNumber')!.invalid && (OtherUSerForm.get('mobileNumber')!.dirty || OtherUSerForm.get('mobileNumber')!.touched)">
                                        <div *ngIf="OtherUSerForm.get('mobileNumber')?.errors?.required">
                                            Employee Mobile Number is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12  left p-p-0 popupFooterBtnContainer">
                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-jc-end p-ai-center  p-left popupFooterBtnContent p-pr-4 p-pt-2 p-mb-3">
                            <div class="btnwrap">
                                <button pButton class="p-button-brand2" type="button" (click)="onCancel(OtherUSerForm);" label="Cancel">
                                </button>
                            </div>
                            <div class="btnwrap">
                                <button pButton class="p-button-primary p-mr-0" type="submit"
                                    [disabled]="!OtherUSerForm.valid" label="Save" (click)="saveOtherDepartmentUser();">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>