import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UserhierarchylistComponent } from './userhierarchylist/userhierarchylist.component';
import { ViewMappedUserComponent } from './ViewMappedUser/ViewMappedUser.component';
import { EditMappedUserComponent } from './EditMappedUser/EditMappedUser.component';
import { UsermappingComponent } from './usermapping/usermapping.component';
import { RouterModule } from '@angular/router';
import { PAGES_ROUTE } from './pages.route';
import { BackofficeSharedModule } from '../shared/shared.module';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ReportingScreenGridComponent } from './ReportingScreenGrid/ReportingScreenGrid.component';
import { ReportingScreenDetailsComponent } from './ReportingScreenDetails/ReportingScreenDetails.component';
import { ReportingValidationComponent } from './reporting-validation/reporting-validation.component';
import { CardModule } from 'primeng/card';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule } from '@angular/common/http';
import { ReportingAttachmentComponent } from './ReportingAttachment/ReportingAttachment.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { DialogModule } from 'primeng/dialog';
import { AccordionModule } from 'primeng/accordion';
import { AllocationComponent } from './allocation/allocation.component';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { TagModule } from 'primeng/tag';
import { ReportingScreenModule } from './reporting-screen/reporting-screen.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ReportsComponent } from './reports/reports.component';
import { SfReportingScreenGridComponent } from './sf-reporting-screen-grid/sf-reporting-screen-grid.component';
import { SfReportingScreenDetailsComponent } from './sf-reporting-screen-details/sf-reporting-screen-details.component';
import { DialogService } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { SfInitiationDetailsComponent } from './sf-initiation-details/sf-initiation-details.component';
import { SfInitiationGridComponent } from './sf-initiation-grid/sf-initiation-grid.component';
import { SfRealizationGridComponent } from './sf-realization-grid/sf-realization-grid.component';
import { SfRealizationScreenDetailsComponent } from './sf-realization-screen-details/sf-realization-screen-details.component';
import { AttachmentModelComponent } from './attachment-model/attachment-model.component';
import { FloatElementDirective } from './directives/float.directive';
import { SfPushPullGridComponent } from './sf-push-pull-grid/sf-push-pull-grid.component';
import { SfPushPullDetailsComponent } from './sf-push-pull-details/sf-push-pull-details.component';
import { DebounceClickDirective } from './directives/DebounceButton.directive';
import { bdHierarchy } from './pipes/showhierarchy';
import { PreviewComponent } from './preview/preview.component';
import { SvgModule } from 'app/backoffice-assets/svg.module';
import { IrfPushPullGridComponent } from './irf-push-pull-grid/irf-push-pull-grid.component';
import { ButtonModule } from 'primeng/button';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { NoPermissionToAccessComponent } from './no-permission-to-access/no-permission-to-access.component';
import { ConfirmationService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';

import { ChipModule } from 'primeng/chip';
import { MultiSelectModule } from 'primeng/multiselect';
import { UserhierarchyotheruserComponent } from './userhierarchyotheruser/userhierarchyotheruser.component';

@NgModule({
  declarations: [
    bdHierarchy,
    UserhierarchylistComponent,
    ViewMappedUserComponent,
    EditMappedUserComponent,
    UsermappingComponent,
    ReportingScreenGridComponent,
    ReportingScreenDetailsComponent,
    ReportingValidationComponent,
    ReportingAttachmentComponent,
    AllocationComponent,
    ReportsComponent,
    SfReportingScreenGridComponent,
    SfReportingScreenDetailsComponent,
    SfInitiationDetailsComponent,
    SfInitiationGridComponent,
    SfRealizationGridComponent,
    SfRealizationScreenDetailsComponent,
    AttachmentModelComponent,
    FloatElementDirective,
    SfPushPullGridComponent,
    SfPushPullDetailsComponent,
    PreviewComponent,
    IrfPushPullGridComponent,
    DebounceClickDirective,
    NoPermissionToAccessComponent,
    UserhierarchyotheruserComponent
  ],
  imports: [
    ReportingScreenModule,
    ReactiveFormsModule,
    BackofficeSharedModule,
    CardModule,
    FileUploadModule,
    HttpClientModule,
    ImageCropperComponent,
    AccordionModule,
    InputSwitchModule,
    StepsModule,
    ToastModule,
    PdfViewerModule,
    InputNumberModule,
    DialogModule,
    TagModule,
    ButtonModule,
    TooltipModule,
    AutoCompleteModule,
    TriStateCheckboxModule,
    CalendarModule,
    RouterModule.forChild(PAGES_ROUTE),
    SvgModule,
    InputTextModule,
    TableModule,
    ChipModule,
    MultiSelectModule
  ],
  providers: [
    DialogModule,
    DialogService,
    ConfirmationService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PagesModule { }
