<div class="layout-content layout-content-noscroll">
  <p-messages class="custom-message"></p-messages>
  <div class="p-formgrid p-grid">
		<div class=" p-col-12 p-p-0">
			<div class="ui-fluid p-formgrid p-grid p-ai-center">
				<div class="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-pl-0 p-text-left">
					<div class=" p-fluid p-field  p-mb-0  ">
						<p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb"
                (onItemClick)="homeBreadcrumbClicked($event)">
              </p-breadcrumb>
					</div>
				</div>
			</div>
		</div>
	</div>
  <form [formGroup]="ReportingScreenGridForm" autocomplete="off" novalidate>
    <div class="p-formgrid p-grid">
      <div class=" p-col-12 p-p-0">
        <div class="ui-fluid p-formgrid p-grid">
          <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-jc-between p-ai-center p-d-flex p-flex-column p-flex-md-row  p-p-0">
            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-p-0 p-mb-2 p-md-mb-0 p-d-flex p-jc-start p-ai-center">
              <label class="p-text-bold p-mb-0">Business Won</label>
              <p-badge [value]="businessWonCount" styleClass="businessWonCount-Badge"></p-badge>
            </div>
            <div class="p-col-12 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-p-0 p-d-flex p-jc-end p-mt-0 p-mr-0">
              <div class=" p-fluid p-field p-mr-2 p-col-4 p-pr-0 p-mb-2">
                <p-calendar #calender [(ngModel)]="rangeDate" [showIcon]="true" id="gridCalender_id"
                  name="gridCalender_name" formControlName="gridCalender" placeholder="From-To" yearRange="2000:2030"
                  dataType="string" selectionMode="range" [readonlyInput]="true" rangeSeparator="to"
                  dateFormat="dd/mm/yy" [showButtonBar]="true" [hideOnDateTimeSelect]="true" dataType="string"
                  [stepHour]="1" [stepMinute]="1" (onSelect)="datePicker()" [showButtonBar]="true" [maxDate]="dateTime"
                  (onClearClick)="onClearClick()" style="width:100%;"></p-calendar>
              </div>
              <div class=" p-fluid   p-field  p-d-flex p-jc-end p-flex-row p-pl-0 p-pr-0 p-mb-2">
                <button pButton class="p-col-2 p-button-brand2 p-mr-2" icon="pi pi-filter" *ngIf="!filterDisplay"
                  pTooltip="Filter" tooltipPosition="bottom" (click)="showFilter()"></button>
                <button pButton class="p-col-2 p-button-brand2 p-mr-2" icon="pi pi-filter-slash"
                  *ngIf="filterDisplay" pTooltip="Clear Filter" tooltipPosition="bottom"
                  (click)="clear(businessWonDataGridid)"></button>
                  <button pButton type="button" class="p-button-secondary p-mr-2" label="Generate Report" (click)="generateExcel()"></button>
                <div class="p-helper-clearfix" style="text-align: left">
                  <button pButton type="button" class="p-button-primary p-mr-0" 
                    label="Export" (click)="exportExcel()" style="float:right"></button>
                </div>
              </div>
            </div>
          </div>
          <div class="p-col-12 p-sm-12 p-p-0">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-col-12 p-p-0">
                <div class="ui-fluid p-formgrid p-grid">
                  <div class="p-col-12 p-sm-12 p-p-0 p-right">
                    <div class="p-field">
                      <div [ngClass]="{'content-section implementation': true}">
                        <p-table #businessWonDataGridid name="businessWonDataGrid" styleClass="p-datatable-gridlines backoffice-cust-scroll"
                          [value]="gridDataList" [paginator]="true" [rows]="10" sortMode="single" [scrollable]="true" scrollHeight="450px" 
                          scrollDirection="both" [globalFilterFields]="['id', 'entityName', 'nexeraUser', 'bdManager', 'bdSpoc', 'opportunityStatus', 'businessWonDate', 'ageing', 'product']" [ngClass]="{'mt-big':filterDisplayRow,'mt-small':!filterDisplayRow}"
                          [showCurrentPageReport]="true"  dataKey="id"
                          [rowsPerPageOptions]="[10, 25, 50]"              
                          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                          [loading]="loadingStatus" (onFilter)="loadingStatus = false;">
                          <ng-template pTemplate="header">
                            <tr>
                              <th pSortableColumn='opportunityId' scope="col" style="max-width:160px;min-width:160px;">
                                Opportunity ID
                                <p-sortIcon field='opportunityId' ariaLabel="Activate to sort"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                              </th>
                              <th pSortableColumn='entityName' scope="col" style="max-width:220px;min-width:220px;">
                                Entity Name
                                <p-sortIcon field='entityName' ariaLabel="Activate to sort"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                              </th>
                              <th pSortableColumn='nexeraUser' scope="col" style="max-width:200px;min-width:200px;">
                                Nexera User
                                <p-sortIcon field='nexeraUser' ariaLabel="Activate to sort"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                              </th>
                              <th pSortableColumn='bdManager' scope="col" style="max-width:200px;min-width:200px;">
                                PA Approver
                                <p-sortIcon field='bdManager' ariaLabel="Activate to sort"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                              </th>
                              <!-- <th pSortableColumn='bdSpoc' scope="col" style="max-width:160px;min-width:160px;">
                                BD Spoc
                                <p-sortIcon field='bdSpoc' ariaLabel="Activate to sort"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                              </th> -->
                              <th pSortableColumn='opportunityStatus' scope="col" style="max-width:180px;min-width:180px;">
                                Opportunity Status
                                <p-sortIcon field='opportunityStatus' ariaLabel="Activate to sort"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                              </th>
                              <th pSortableColumn='businessWonDate' scope="col" style="max-width:140px;min-width:140px;">
                                Date
                                <p-sortIcon field='businessWonDate' ariaLabel="Activate to sort"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                              </th>
                              <th pSortableColumn='ageing' scope="col" style="max-width:130px;min-width:130px;">
                                Ageing
                                <p-sortIcon field='ageing' ariaLabel="Activate to sort"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                              </th>
                              <th pSortableColumn='productName' scope="col" style="max-width:200px;min-width:200px;">
                                Product
                                <p-sortIcon field='productName' ariaLabel="Activate to sort"
                                  ariaLabelDesc="Activate to sort in descending order"
                                  ariaLabelAsc="Activate to sort in ascending order">
                                </p-sortIcon>
                              </th>
                            </tr>
                            <tr *ngIf="filterDisplayRow">
                              <th scope="col" class="no-bg" style="max-width:160px;min-width:160px;">
                                <p-columnFilter class="p_columnFilter" field="oppIdByList" matchMode="in" [showMenu]="false">
                                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-multiSelect class="p_multiSelect" [ngModel]="value" [options]="oppIdList" placeholder="Any"
                                      (onChange)="loadingStatus = true; filter($event.value)" [virtualScroll]="true" itemSize="40" optionLabel="name" formControlName="oppIdFilter"
                                      resetFilterOnHide="true" [showToggleAll]="false" #oppIds scrollHeight="125px">
                                      <ng-template let-rowData pTemplate="item" >
                                        <div class="">
                                          <span class="p-ml-1">{{ rowData.name }}</span>
                                        </div>
                                      </ng-template>
                                    </p-multiSelect>
                                  </ng-template>
                                </p-columnFilter>
                              </th>
                              <th scope="col" class="no-bg" style="max-width:220px;min-width:220px;">
                                <p-columnFilter class="p_columnFilter" field="entityNamebyList" matchMode="in" [showMenu]="false">
                                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-multiSelect class="p_multiSelect" [ngModel]="value" [options]="nameList" placeholder="Any"
                                      (onChange)="loadingStatus = true; filter($event.value)" optionLabel="name" #oppName
                                      formControlName="entityNameFilter" [virtualScroll]="true" itemSize="40" resetFilterOnHide="true" [showToggleAll]="false" scrollHeight="125px">
                                      <ng-template let-rowData pTemplate="item">
                                        <div class="">
                                          <span class="p-ml-1">{{ rowData.name }}</span>
                                        </div>
                                      </ng-template>
                                    </p-multiSelect>
                                  </ng-template>
                                </p-columnFilter>
                              </th>

                              <th scope="col" class="no-bg" style="max-width:200px;min-width:200px;">
                                <p-columnFilter class="p_columnFilter" field="nexeraUserbyList" matchMode="in" [showMenu]="false">
                                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-multiSelect class="p_multiSelect" [ngModel]="value" [options]="nexeraUserList1" placeholder="Any"
                                      (onChange)="filter($event.value)" optionLabel="name" #oppUser
                                      formControlName="nexeraUserFilter" resetFilterOnHide="true" [showToggleAll]="false" scrollHeight="125px">
                                      <ng-template let-rowData pTemplate="item">
                                        <div class="">
                                          <span class="p-ml-1">{{ rowData.name }}</span>
                                        </div>
                                      </ng-template>
                                    </p-multiSelect>
                                  </ng-template>
                                </p-columnFilter>
                              </th>
                              <th scope="col" class="no-bg" style="max-width:200px;min-width:200px;">
                                <input pInputText type="text" (input)="businessWonDataGridid.filter($event.target.value, 'bdManager', 'contains')" [value]="businessWonDataGridid.filters['bdManager']?.value" placeholder="Search" class="p-column-filter">
                                
                              </th>
                              <!-- <th scope="col" class="no-bg" style="max-width:160px;min-width:160px;">
                                <p-columnFilter class="p_columnFilter" field="bdSpocbyList" matchMode="in" [showMenu]="false">
                                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-multiSelect class="p_multiSelect" [ngModel]="value" [options]="uniqbdSpocList" placeholder="Any"
                                      (onChange)="filter($event.value)" optionLabel="name" #oppBd [disabled]="disableFilter"
                                      formControlName="bdSpocFilter" resetFilterOnHide="true" [showToggleAll]="false" scrollHeight="125px">
                                      <ng-template let-rowData pTemplate="item">
                                        <div class="">
                                          <span class="p-ml-1">{{ rowData.name }}</span>
                                        </div>
                                      </ng-template>
                                    </p-multiSelect>
                                  </ng-template>
                                </p-columnFilter>
                              </th> -->
                              <th scope="col" class="no-bg" style="max-width:180px;min-width:180px;">
                                <p-columnFilter class="p_columnFilter" class="p_columnFilter" field="opportunitybyList" matchMode="in" [showMenu]="false">
                                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-multiSelect class="p_multiSelect oppStatusMultiSelect" [ngModel]="value" [options]="opportunityStatusList" placeholder="Any"
                                      (onChange)="filter($event.value)" optionLabel="name" id="oppStatusMultiSelect"
                                      formControlName="opportinutyFilter" resetFilterOnHide="true" #oppStatusMultiSelect scrollHeight="125px" appendTo="businessWonDataGridid">
                                      <ng-template let-rowData pTemplate="item">
                                        <div class="">
                                          <span class="p-ml-1">{{ rowData.name }}</span>
                                        </div>
                                      </ng-template>
                                    </p-multiSelect>
                                  </ng-template>
                                </p-columnFilter>
                              </th>
                              <th scope="col" class="no-bg" style="max-width:140px;min-width:140px;">
                                <input pInputText type="text" (input)="businessWonDataGridid.filter($event.target.value, 'businessWonDate', 'contains')" [value]="businessWonDataGridid.filters['businessWonDate']?.value" placeholder="Search" class="p-column-filter">
                                
                              </th>
                              <th scope="col" class="no-bg" style="max-width:130px;min-width:130px;">
                                <input pInputText type="text" (input)="businessWonDataGridid.filter($event.target.value, 'ageing', 'contains')" [value]="businessWonDataGridid.filters['ageing']?.value" placeholder="Search" class="p-column-filter">
                                
                                
                              </th>
                              <th scope="col" class="no-bg" style="max-width:200px;min-width:200px;">
                                <input pInputText type="text" (input)="businessWonDataGridid.filter($event.target.value, 'productName', 'contains')" [value]="businessWonDataGridid.filters['productName']?.value" placeholder="Search" class="p-column-filter">
                                
                              </th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-rowData>
                            <tr style="cursor: pointer;" (click)="onEntityNAMEdialog(rowData)">
                              <td style="max-width:160px;min-width:160px;">
                                {{rowData.opportunityId}}
                              </td>
                              <td class="text-transform-capitalize p-text-nowrap p-text-truncate" style="max-width:220px;min-width:220px;">
                                <div style="max-width:200px;white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                  <a>{{rowData.entityName}}</a>
                                </div>
                              </td>
                              <td style="max-width:200px;min-width:200px;">

                                {{rowData.nexeraUser}}
                              </td>
                              <td style="max-width:200px;min-width:200px;">

                                {{rowData.bdManager}}
                              </td>
                              <!-- <td style="max-width:160px;min-width:160px;">
                                {{rowData.bdSpoc}}
                              </td> -->
                              <td
                                style="max-width:180px; white-space: nowrap; text-overflow:ellipsis; overflow: hidden; min-width:180px;">
                                {{rowData.opportunityStatus}}
                              </td>
                              <td style="max-width:140px;min-width:140px;">

                                {{rowData.businessWonDate}}
                              </td>
                              <td style="max-width:130px;min-width:130px;">

                                {{rowData.ageing}}
                              </td>
                              <td
                                style="max-width:200px; white-space: nowrap; text-overflow:ellipsis; overflow: hidden; min-width:200px;">

                                {{rowData.productName}}
                              </td>
                            </tr>
                          </ng-template>
                          
                          <ng-template pTemplate="emptymessage">
                            <tr> 
                              <td colspan="8" style="background: white;">
                                <ng-container *ngIf="firstLoad">
                                  <div class="p-my-4" style="width: 97vw;background: white;text-align: center; color: rgba(41, 41, 41, 0.6);">
                                    <div class="p-mb-3 p-d-flex p-jc-center">
                                        <svg width="204" height="124" class="illustrationLight" viewBox="0 0 204 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M194.286 18.2354C198.98 18.2354 202.786 22.0453 202.786 26.7452C202.786 31.445 198.98 35.255 194.286 35.255H145.714C150.409 35.255 154.214 39.0649 154.214 43.7648C154.214 48.4646 150.409 52.2746 145.714 52.2746H172.429C177.123 52.2746 180.929 56.0845 180.929 60.7844C180.929 65.4842 177.123 69.2942 172.429 69.2942H160.075C154.155 69.2942 149.357 73.1041 149.357 77.804C149.357 80.9372 151.786 83.7738 156.643 86.3138C161.337 86.3138 165.143 90.1237 165.143 94.8236C165.143 99.5234 161.337 103.333 156.643 103.333H55.8571C51.1627 103.333 47.3571 99.5234 47.3571 94.8236C47.3571 90.1237 51.1627 86.3138 55.8571 86.3138H8.5C3.80558 86.3138 0 82.5038 0 77.804C0 73.1041 3.80558 69.2942 8.5 69.2942H57.0714C61.7659 69.2942 65.5714 65.4842 65.5714 60.7844C65.5714 56.0845 61.7659 52.2746 57.0714 52.2746H26.7143C22.0199 52.2746 18.2143 48.4646 18.2143 43.7648C18.2143 39.0649 22.0199 35.255 26.7143 35.255H75.2857C70.5913 35.255 66.7857 31.445 66.7857 26.7452C66.7857 22.0453 70.5913 18.2354 75.2857 18.2354H194.286ZM194.286 52.2746C198.98 52.2746 202.786 56.0845 202.786 60.7844C202.786 65.4842 198.98 69.2942 194.286 69.2942C189.591 69.2942 185.786 65.4842 185.786 60.7844C185.786 56.0845 189.591 52.2746 194.286 52.2746Z" fill="#EDEFF6"/>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M129.68 17.02L140.988 99.4955L142.004 107.77C142.331 110.435 140.436 112.862 137.77 113.189L66.5791 121.93C63.9135 122.257 61.4873 120.362 61.16 117.696L50.1996 28.4313C50.036 27.0985 50.9838 25.8854 52.3166 25.7218C52.325 25.7207 52.3334 25.7197 52.3418 25.7188L74.7648 23.0984" fill="white"/>
                                          <path d="M130.884 16.8548C130.793 16.1896 130.18 15.7243 129.515 15.8155C128.849 15.9068 128.384 16.5199 128.475 17.1851L130.884 16.8548ZM140.988 99.4955L142.195 99.3473C142.194 99.3416 142.193 99.336 142.192 99.3303L140.988 99.4955ZM142.004 107.77L143.21 107.622L142.004 107.77ZM137.77 113.189L137.918 114.396L137.77 113.189ZM66.5791 121.93L66.7273 123.137L66.5791 121.93ZM61.16 117.696L62.3666 117.548L61.16 117.696ZM50.1996 28.4313L51.4063 28.2832L50.1996 28.4313ZM52.3418 25.7188L52.4774 26.9269L52.483 26.9263L52.3418 25.7188ZM74.9059 24.3059C75.5727 24.2279 76.0502 23.6241 75.9722 22.9573C75.8943 22.2904 75.2905 21.813 74.6237 21.8909L74.9059 24.3059ZM128.475 17.1851L139.783 99.6606L142.192 99.3303L130.884 16.8548L128.475 17.1851ZM139.781 99.6436L140.797 107.918L143.21 107.622L142.195 99.3473L139.781 99.6436ZM140.797 107.918C141.043 109.917 139.621 111.737 137.622 111.982L137.918 114.396C141.25 113.986 143.62 110.954 143.21 107.622L140.797 107.918ZM137.622 111.982L66.4309 120.723L66.7273 123.137L137.918 114.396L137.622 111.982ZM66.4309 120.723C64.4317 120.969 62.6121 119.547 62.3666 117.548L59.9534 117.844C60.3625 121.176 63.3952 123.546 66.7273 123.137L66.4309 120.723ZM62.3666 117.548L51.4063 28.2832L48.993 28.5795L59.9534 117.844L62.3666 117.548ZM51.4063 28.2832C51.3244 27.6168 51.7983 27.0102 52.4647 26.9284L52.1684 24.5151C50.1692 24.7606 48.7475 26.5803 48.993 28.5795L51.4063 28.2832ZM52.4647 26.9284C52.4689 26.9279 52.4731 26.9274 52.4774 26.9269L52.2063 24.5107C52.1937 24.5121 52.181 24.5136 52.1684 24.5151L52.4647 26.9284ZM52.483 26.9263L74.9059 24.3059L74.6237 21.8909L52.2007 24.5113L52.483 26.9263Z" fill="#9DC0FB"/>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M126.601 22.2093L136.837 96.9542L137.757 104.453C138.054 106.869 136.359 109.064 133.972 109.358L70.2192 117.185C67.8321 117.479 65.6565 115.758 65.3599 113.342L55.7484 35.0623C55.469 32.7871 55.3293 31.6495 55.9494 30.8559C56.5694 30.0623 57.707 29.9226 59.9823 29.6432L65.4559 28.9711" fill="#EBF3FE"/>
                                          <path d="M73.0013 4.86274C73.0013 2.84853 74.6342 1.21569 76.6484 1.21569H132.031C132.998 1.21569 133.925 1.59962 134.609 2.28309L135.469 1.4232L134.609 2.28309L150.952 18.6157C151.636 19.2998 152.021 20.2277 152.021 21.1954V97.2549C152.021 99.2691 150.388 100.902 148.374 100.902H76.6484C74.6342 100.902 73.0013 99.2691 73.0013 97.2549V4.86274Z" fill="white" stroke="#76A7F9" stroke-width="2.43137"/>
                                          <path d="M133.327 2.43115V17.0194C133.327 19.0336 134.96 20.6664 136.974 20.6664H151.353" stroke="#76A7F9" stroke-width="2.43137" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M86.3735 21.8823H117.981" stroke="#9DC0FB" stroke-width="2.43137" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M86.3735 80.2354H117.981" stroke="#9DC0FB" stroke-width="2.43137" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M86.3735 36.4707H138.648" stroke="#9DC0FB" stroke-width="2.43137" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M86.3735 51.0586H138.648" stroke="#9DC0FB" stroke-width="2.43137" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M86.3735 65.647H138.648" stroke="#9DC0FB" stroke-width="2.43137" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M139.793 107.688C149.265 107.688 156.943 99.9097 156.943 90.3147C156.943 80.7197 149.265 72.9414 139.793 72.9414C130.321 72.9414 122.643 80.7197 122.643 90.3147C122.643 99.9097 130.321 107.688 139.793 107.688Z" fill="#F5F9FF" stroke="#76A7F9" stroke-width="1.45882"/>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44" fill="white"/>
                                          <path d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44C130.686 100.631 133.618 103.171 137.045 103.795Z" stroke="#76A7F9" stroke-width="1.45882" stroke-linecap="round"/>
                                          <path d="M152.335 102.501L156.943 107.169" stroke="#76A7F9" stroke-width="1.45882"/>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M156.447 106.666C155.478 107.648 155.478 109.239 156.447 110.221L162.122 115.97C163.091 116.951 164.662 116.951 165.631 115.97C166.6 114.988 166.6 113.397 165.631 112.415L159.956 106.666C158.987 105.685 157.416 105.685 156.447 106.666Z" fill="#EBF3FE" stroke="#76A7F9" stroke-width="1.45882"/>
                                          <path d="M158.991 107.688L164.623 113.393" stroke="white" stroke-width="1.31692" stroke-linecap="round"/>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M136.466 84.3505C136.466 90.3653 141.279 95.2412 147.216 95.2412C148.383 95.2412 149.506 95.053 150.557 94.705C148.841 99.0203 144.668 102.066 139.793 102.066C133.386 102.066 128.192 96.8049 128.192 90.3145C128.192 84.4332 132.457 79.561 138.025 78.6982C137.035 80.3465 136.466 82.2811 136.466 84.3505Z" fill="#EBF3FE"/>
                                          <path d="M136.284 81.4858C132.782 82.9767 130.323 86.4843 130.323 90.5738" stroke="#9DC0FB" stroke-width="1.45882" stroke-linecap="round"/>
                                      </svg>
                                      
                                      <svg width="204" height="124" class="illustrationDark" viewBox="0 0 204 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M194.286 18.2354C198.98 18.2354 202.786 22.0453 202.786 26.7452C202.786 31.445 198.98 35.255 194.286 35.255H145.714C150.409 35.255 154.214 39.0649 154.214 43.7648C154.214 48.4646 150.409 52.2746 145.714 52.2746H172.429C177.123 52.2746 180.929 56.0845 180.929 60.7844C180.929 65.4842 177.123 69.2942 172.429 69.2942H160.075C154.155 69.2942 149.357 73.1041 149.357 77.804C149.357 80.9372 151.786 83.7738 156.643 86.3138C161.337 86.3138 165.143 90.1237 165.143 94.8236C165.143 99.5234 161.337 103.333 156.643 103.333H55.8571C51.1627 103.333 47.3571 99.5234 47.3571 94.8236C47.3571 90.1237 51.1627 86.3138 55.8571 86.3138H8.5C3.80558 86.3138 0 82.5038 0 77.804C0 73.1041 3.80558 69.2942 8.5 69.2942H57.0714C61.7659 69.2942 65.5714 65.4842 65.5714 60.7844C65.5714 56.0845 61.7659 52.2746 57.0714 52.2746H26.7143C22.0199 52.2746 18.2143 48.4646 18.2143 43.7648C18.2143 39.0649 22.0199 35.255 26.7143 35.255H75.2857C70.5913 35.255 66.7857 31.445 66.7857 26.7452C66.7857 22.0453 70.5913 18.2354 75.2857 18.2354H194.286ZM194.286 52.2746C198.98 52.2746 202.786 56.0845 202.786 60.7844C202.786 65.4842 198.98 69.2942 194.286 69.2942C189.591 69.2942 185.786 65.4842 185.786 60.7844C185.786 56.0845 189.591 52.2746 194.286 52.2746Z" fill="#2C2C2C"/>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M129.68 17.02L140.988 99.4955L142.004 107.77C142.331 110.435 140.436 112.862 137.77 113.189L66.5791 121.93C63.9135 122.257 61.4873 120.362 61.16 117.696L50.1996 28.4313C50.036 27.0985 50.9838 25.8854 52.3166 25.7218C52.325 25.7207 52.3334 25.7197 52.3418 25.7188L74.7648 23.0984" fill="#141414"/>
                                          <path d="M130.884 16.8548C130.793 16.1896 130.18 15.7243 129.515 15.8155C128.849 15.9068 128.384 16.5199 128.475 17.1851L130.884 16.8548ZM140.988 99.4955L142.195 99.3473C142.194 99.3416 142.193 99.336 142.192 99.3303L140.988 99.4955ZM142.004 107.77L143.21 107.622L142.004 107.77ZM137.77 113.189L137.918 114.396L137.77 113.189ZM66.5791 121.93L66.7273 123.137L66.5791 121.93ZM61.16 117.696L62.3666 117.548L61.16 117.696ZM52.3418 25.7188L52.4774 26.9269L52.483 26.9263L52.3418 25.7188ZM74.9059 24.3059C75.5727 24.2279 76.0502 23.6241 75.9722 22.9573C75.8943 22.2904 75.2905 21.813 74.6237 21.8909L74.9059 24.3059ZM128.475 17.1851L139.783 99.6606L142.192 99.3303L130.884 16.8548L128.475 17.1851ZM139.781 99.6436L140.797 107.918L143.21 107.622L142.195 99.3473L139.781 99.6436ZM140.797 107.918C141.043 109.917 139.621 111.737 137.622 111.982L137.918 114.396C141.25 113.986 143.62 110.954 143.21 107.622L140.797 107.918ZM137.622 111.982L66.4309 120.723L66.7273 123.137L137.918 114.396L137.622 111.982ZM66.4309 120.723C64.4317 120.969 62.6121 119.547 62.3666 117.548L59.9534 117.844C60.3625 121.176 63.3952 123.546 66.7273 123.137L66.4309 120.723ZM62.3666 117.548L51.4063 28.2832L48.993 28.5795L59.9534 117.844L62.3666 117.548ZM51.4063 28.2832C51.3244 27.6168 51.7983 27.0102 52.4647 26.9284L52.1684 24.5151C50.1692 24.7606 48.7475 26.5803 48.993 28.5795L51.4063 28.2832ZM52.4647 26.9284C52.4689 26.9279 52.4731 26.9274 52.4774 26.9269L52.2063 24.5107C52.1937 24.5121 52.181 24.5136 52.1684 24.5151L52.4647 26.9284ZM52.483 26.9263L74.9059 24.3059L74.6237 21.8909L52.2007 24.5113L52.483 26.9263Z" fill="#9DC0FB"/>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M126.601 22.2093L136.837 96.9542L137.757 104.453C138.054 106.869 136.359 109.064 133.972 109.358L70.2192 117.185C67.8321 117.479 65.6565 115.758 65.3599 113.342L55.7484 35.0623C55.469 32.7871 55.3293 31.6495 55.9494 30.8559C56.5694 30.0623 57.707 29.9226 59.9823 29.6432L65.4559 28.9711" fill="#233655"/>
                                          <path d="M73.0013 4.86274C73.0013 2.84853 74.6342 1.21569 76.6484 1.21569H132.031C132.998 1.21569 133.925 1.59962 134.609 2.28309L135.469 1.4232L134.609 2.28309L150.952 18.6157C151.636 19.2998 152.021 20.2277 152.021 21.1954V97.2549C152.021 99.2691 150.388 100.902 148.374 100.902H76.6484C74.6342 100.902 73.0013 99.2691 73.0013 97.2549V4.86274Z" fill="#141414" stroke="#76A7F9" stroke-width="2.43137"/>
                                          <path d="M133.327 2.43115V17.0194C133.327 19.0336 134.96 20.6664 136.974 20.6664H151.353" stroke="#76A7F9" stroke-width="2.43137" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M86.3735 21.8823H117.981" stroke="#9DC0FB" stroke-width="2.43137" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M86.3735 80.2354H117.981" stroke="#9DC0FB" stroke-width="2.43137" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M86.3735 36.4707H138.648" stroke="#9DC0FB" stroke-width="2.43137" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M86.3735 51.0586H138.648" stroke="#9DC0FB" stroke-width="2.43137" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M86.3735 65.647H138.648" stroke="#9DC0FB" stroke-width="2.43137" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M139.793 107.688C149.265 107.688 156.943 99.9097 156.943 90.3147C156.943 80.7197 149.265 72.9414 139.793 72.9414C130.321 72.9414 122.643 80.7197 122.643 90.3147C122.643 99.9097 130.321 107.688 139.793 107.688Z" fill="#233655" stroke="#76A7F9" stroke-width="1.45882"/>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44" fill="#141414"/>
                                          <path d="M137.045 103.795C137.945 103.964 138.861 104.051 139.793 104.058C147.286 104.058 153.359 97.9049 153.359 90.3148C153.359 82.7247 147.286 76.5718 139.793 76.5718C137.868 76.5718 136.037 76.9778 134.379 77.7099C131.496 78.9822 129.135 81.2392 127.709 84.0614C126.761 85.9372 126.227 88.0628 126.227 90.3148C126.227 92.3581 126.667 94.2973 127.456 96.0403C128.02 97.2846 129.649 99.44 129.649 99.44C130.686 100.631 133.618 103.171 137.045 103.795Z" stroke="#76A7F9" stroke-width="1.45882" stroke-linecap="round"/>
                                          <path d="M152.335 102.501L156.943 107.169" stroke="#76A7F9" stroke-width="1.45882"/>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M156.447 106.666C155.478 107.648 155.478 109.239 156.447 110.221L162.122 115.97C163.091 116.951 164.662 116.951 165.631 115.97C166.6 114.988 166.6 113.397 165.631 112.415L159.956 106.666C158.987 105.685 157.416 105.685 156.447 106.666Z" fill="#233655" stroke="#76A7F9" stroke-width="1.45882"/>
                                          <path d="M158.991 107.688L164.623 113.393" stroke="#141414" stroke-width="1.31692" stroke-linecap="round"/>
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M136.466 84.3505C136.466 90.3653 141.279 95.2412 147.216 95.2412C148.383 95.2412 149.506 95.053 150.557 94.705C148.841 99.0203 144.668 102.066 139.793 102.066C133.386 102.066 128.192 96.8049 128.192 90.3145C128.192 84.4332 132.457 79.561 138.025 78.6982C137.035 80.3465 136.466 82.2811 136.466 84.3505Z" fill="#233655"/>
                                          <path d="M136.284 81.4858C132.782 82.9767 130.323 86.4843 130.323 90.5738" stroke="#9DC0FB" stroke-width="1.45882" stroke-linecap="round"/>
                                      </svg>
                                      </div>
                                    <p>No data found for the selected filter criteria</p>
                                  </div>
                                </ng-container>
                              </td>
                            </tr>
                            </ng-template>
                        </p-table>
                        <!-- <div class="no_data_div"
                          *ngIf="gridDataList.length==0 && gridLoader">
                          <div class="app-loading" id="loader">
                            <div class="loadbg"></div>
                            <div class="bdload">
                              <div class="spinner">
                                <div class="rect1"></div>
                                <div class="rect2"></div>
                                <div class="rect3"></div>
                                <div class="rect4"></div>
                                <div class="rect5"></div>
                                <span>Loading...</span>
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<!-- <div class="no_data_div tableLoader" *ngIf="dataLoader">
  <div class="app-loading " id="loader">
    <div class="loadbg"></div>
    <div class="bdload">
      <div class="spinner">
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
        <div class="rect4"></div>
        <div class="rect5"></div>
        <span>Loading...</span>
      </div>
    </div>
  </div>
</div> -->
<div class="p-grid p-ml-0 p-mr-0 p-col-12 p-jc-between p-button-container">
  <div class="p-col-5 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-pl-0 left">
    <div class="btnwrap">
      <button pButton class="p-button-brand2" type="button"
        (click)="onReportingGridBackBtn()" label="Back">
      </button>
    </div>
  </div>
</div>
<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="apiFail" [modal]="true" [style]="{width: '55vw', height: '20vh'}"
    [draggable]="false" [resizable]="false">
    <p>Failed to connect with the server, Sorry for the inconvenience</p>
</p-dialog>