import { Location } from "@angular/common";
import { Component, OnInit, Renderer2, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { EncryptDecryptInterceptor } from "app/blocks/interceptor/EncryptDecrypt.interceptor";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { DOMAIN_ROUTE } from '../../app.constants'
import { PagesService } from "../pages.service";
import * as FileSaver from 'file-saver';


@Component({
  selector: "backoffice-report",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
  providers: [DialogService, MessageService],
  encapsulation: ViewEncapsulation.None,
})
export class ReportsComponent implements OnInit {
  ccprimebreadcrumbList = [
    { label: `Reports`, routerLink: ["/pages/reports"] },
  ];
  @ViewChild("mainCalendar") mainCalendar: any;
  @ViewChild("archiveCalendar") archiveCalendar: any;

  minArchiveDate = new Date("November 04, 2022");

  billFinancialYearList: any = [];
  clientTypeList: any = [];
  assignmentFamilyList: any = [];
  statusList: any = [];

  ReportForm = this.fb.group({
    meetingType: [null, [Validators.required]],
    financialYear: [null],
    gridCalender: [null as any, [Validators.required]],
    archiveType: [null, [Validators.required]],
    archiveCalendar: [null as any, Validators.required],
  });

  billForm = this.fb.group({
    finYear: [null as any, [Validators.required]],
    status: [null as any, [Validators.required]],
    clientType: [null as any, [Validators.required]],
    assignmentFamily: [null as any, [Validators.required]],
  });

  homeiconccprimebreadcrumb = { icon: "pi pi-home" };
  reportName = null;
  minDateLimit = new Date();

  minMIRDate = new Date("January 01, 2021");
  minIRFBillPayemntDate = new Date("November 04, 2022");

  currDate = new Date();
  maxDateLimit = new Date();
  maxDate = new Date();
  minDate= new Date();
  dateInput : Date|undefined;
  displayProcessingModal = false;
  startDate: any;
  endDate: any;
  meetingTypeSelected: any;
  monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  reportList: any[] = [];
  extractBtn: boolean = true;
  displayArchive = false;
  archiveList = [];
  archiveType: any;
  archiveDownloadDisabled = true;
  recipeintUserId: any;

  financialYearList: any = [];
  financialYear: any;
  selectedFinancialYear: any;
  isTypeAlternate = false;
  isTypeQC = false;
  isTypeQCDocReport = false;
  isTypeMIRCostWF = false;
  isTypeMIRCostFee = false;
  scopeList: any = [];
  scopeListData:any=[];
  billingReportScope: boolean = false;
  reportScope: boolean = false;
  isBankerMeetingScope: boolean = false;
  bankerMeetingShadowPortfolioRead:boolean=false;
  bankerMeetingPortfolioRead:boolean=false;
  bankerMeetingPortfolioReadExport:boolean=false;
  sfBillReportSelected: boolean = false;
  clearAllDisabled: boolean = true;
  todayDate= new Date();

  constructor(
    private renderer: Renderer2,
    private messageService: MessageService,
    private _location: Location,
    private fb: FormBuilder,
    private pagesService: PagesService,
    private encryptDecryptInterceptor: EncryptDecryptInterceptor
  ) {
    this.minDateLimit.setMonth(this.maxDateLimit.getMonth() - 12);
  }

  ngOnInit(): void {
    this.scopeList = this.encryptDecryptInterceptor.decrypt(localStorage.getItem('scopes'));
    console.log("scopeList",this.scopeList);

    this.scopeListData = this.scopeList.split(",");
      
      console.log("Push data",this.scopeListData);


    let scope: any = this.scopeList.split(",");
    if(scope.indexOf('BILLING_REPORT_READ') != -1){
      this.billingReportScope = true;
    } else {
      this.billingReportScope = false;
    }
    if(scope.indexOf('REPORT_CREATE') != -1){
      this.reportScope = true;
    } else {
      this.reportScope = false;
    }
    if(scope.indexOf('BANKER_MEETINGS_PORTFOLIO_READ') != -1 || scope.indexOf('BANKER_MEETINGS_SHADOW_PORTFOLIO_READ') != -1){
      this.bankerMeetingPortfolioRead = true;
    } else {
      this.bankerMeetingPortfolioRead = false;
    }
    if(scope.indexOf('BANKER_MEETINGS_SHADOW_PORTFOLIO_READ') != -1){
      this.bankerMeetingShadowPortfolioRead = true;
    } else {
      this.bankerMeetingShadowPortfolioRead = false;
    }
    if(scope.indexOf('BANKER_MEETINGS_PORTFOLIO_READ') != -1){
      this.bankerMeetingPortfolioReadExport = true;
    } else {
      this.bankerMeetingPortfolioReadExport = false;
    }
    this.combineMaster();
    this.setFinancialYearList();
    this.getMeetingReportTypes();
    this.financialYearMaster();
    this.clientType();
    this.assignmentFamily();
    this.recipeintUserId = this.encryptDecryptInterceptor.decrypt(
      localStorage.getItem("empid")
    );
  }

  setFinancialYearList() {
    let date = new Date();
    this.maxDateLimit = date;
    let prevYear = date.getFullYear();
    let month = date.getMonth();
    if (month >= 1) {
      prevYear += 1;
    }

    let startYear = prevYear + 1;
    for (let i = 0; i < 3; i++) {
      startYear = startYear - 1;
      prevYear = prevYear - 1;
      this.financialYearList.push(prevYear + "-" + startYear);
    }
  }

  yearStatusSelected(financialYear) {
    let tempData = financialYear.split("-");
    let one = tempData[0].split("");
    let two = tempData[1].split("");
    let first = one[2] + one[3];
    let second = two[2] + two[3];
    this.selectedFinancialYear = `${first}-${second}`;
    this.validateExtractBtn();
  }

  getMeetingReportTypes() {
    this.pagesService.getMeetingTypes().subscribe((res: any) => {
      const data = res;
      this.reportList = data;
      if(this.billingReportScope && !this.reportScope){
        let billReport = data?.find(x => x.code == "SF_BILLING_REPORT");
        this.reportList = [];
        this.reportList.push(billReport)
        if(this.bankerMeetingPortfolioRead){
          let billReport = data?.find(x => x.code == "BANKER_REPORT");
          // this.reportList = [];
          this.reportList.push(billReport)
        }
      } else if(!this.billingReportScope && this.reportScope){
        let index = data?.findIndex(x => x.code == "SF_BILLING_REPORT");
        this.reportList.splice(index, 1);
        
        // if(!this.bankerMeetingPortfolioRead){
        //   let billReport = data?.findIndex(x => x.code == "BANKER_REPORT");
        //   // this.reportList = [];
        //   this.reportList.splice(billReport,1)
        // }
      }
    
      if(this.bankerMeetingPortfolioRead && !this.billingReportScope && !this.reportScope){
        let billReport = data?.find(x => x.code == "BANKER_REPORT");
        this.reportList = [];
        this.reportList.push(billReport)
      }

      // populate archiveList dropdown data
      this.reportList.forEach((item_) => {
        if (item_.code === "SF_MEETINGS") {
          this.archiveList.push({ ...item_, name: "SF" });
        } else if (item_.code === "IRF_Total_meetings") {
          this.archiveList.push({ ...item_, name: "IRF" });
        }
      }, []);
    });
  }

  homeBreadcrumbClicked(_event) {
    if (_event?.item?.icon == "pi pi-home") {
      sessionStorage.removeItem("urlRedirectedTo");
      sessionStorage.setItem("dashboard", "true");
      window.location.href = `${DOMAIN_ROUTE}#/nexera/dashboard/home`;
    }
  }
  reportSelected() {
    this.meetingTypeSelected = this.ReportForm.value.meetingType;
    this.sfBillReportSelected = false;
    if (this.meetingTypeSelected === "ALTERNATE_CASES_REPORT") {
      this.isTypeAlternate = true;
      this.isTypeQCDocReport = false;
      this.isTypeQC = false;
      this.isTypeMIRCostFee = false;
      this.isTypeMIRCostWF = false;
    } else if (
      this.meetingTypeSelected === "QC_STATUS_REPORT" ||
      this.meetingTypeSelected === "QC_ADDITIONAL_DOC_REPORT"
    ) {
      this.isTypeQC = true;
      this.isTypeQCDocReport = false;
      this.isTypeAlternate = false;
      this.isTypeMIRCostFee = false;
      this.isTypeMIRCostWF = false;
      if (this.meetingTypeSelected === "QC_ADDITIONAL_DOC_REPORT") {
        this.isTypeQCDocReport = true;
      }
    } else if (
      this.meetingTypeSelected === "COST_CENTER_FEE" ||
      this.meetingTypeSelected === "COST_CENTER_WF"
    ) {
      this.isTypeMIRCostFee = true;
      this.isTypeMIRCostWF = false;
      this.isTypeAlternate = false;
      this.isTypeQCDocReport = false;
      this.isTypeQC = false;
      if (this.meetingTypeSelected === "COST_CENTER_WF") {
        this.isTypeMIRCostWF = true;
      } 
    } else  if (this.meetingTypeSelected === "SF_BILLING_REPORT") {
      this.sfBillReportSelected = true;
    } else {
      this.isTypeAlternate = false;
      this.isTypeQC = false;
      this.isTypeQCDocReport = false;
      this.isTypeMIRCostFee = false;
      this.isTypeMIRCostWF = false;
    }
    if(this.reportName === "BANKER_REPORT" ) {
      this.minDateLimit=new Date();
      this.minDateLimit= new Date(this.todayDate.getFullYear(), this.todayDate.getMonth() - 3,1)
      // this.minDateLimit.setMonth(this.maxDateLimit.getMonth() - 3);
    }
    else {
      this.minDateLimit = new Date();
      this.maxDateLimit = new Date();
      this.minDateLimit.setMonth(this.maxDateLimit.getMonth() - 12);
    }
    this.validateExtractBtn();
  }

  clearDate() {
    this.startDate = null;
    this.endDate = null;
    this.validateExtractBtn();
  }

  extractReport() {
    this.displayProcessingModal = true;

    let reqBody = {};
    if (this.isTypeAlternate) {
      reqBody["endDate"] = this.endDate;
      reqBody["startDate"] = this.startDate;
      this.pagesService.downloadExtractAlternateBilldata(reqBody).subscribe({
        next: (item) => {
          this.downloadData(item, "Alternate Approval data");
        },
        error: (err) => {
          this.showError();
        },
      });
      return;
    }

    if (this.isTypeQC) {
      let fileName = this.reportList.find(
        (res) => res.code === this.ReportForm.value.meetingType
      ).name;
      let name = `${fileName}${this.getDateTime()}${this.getTime()}`;
      reqBody["endDate"] = this.endDate;
      reqBody["startDate"] = this.startDate;
      reqBody["reportName"] = this.meetingTypeSelected;

      this.pagesService.downloadExtractQCReport(reqBody).subscribe(
        (res: any) => {
          this.downloadFileCsv(res.base64, name, "Data Extracted successfully");
        },
        (_error) => {
          this.messageService.add({
            severity: "error",
            summary: "Something went wrong! Please try again",
          });
          this.displayProcessingModal = false;
          this.validateExtractBtn();
        }
      );
      this.closeProcessingPopUp();
      return;
    }

    let meetingTypeAndFlag = this.getMeetingTypeAndFlag(
      this.meetingTypeSelected
    );

    if (this.isTypeMIRCostFee == true || this.isTypeMIRCostWF == true) {
      let fileTypeName = "";
      let name = "";

      if (meetingTypeAndFlag[0] == "CostCenterFee") {
        fileTypeName = "Cost Center_Fee";
        name = `${fileTypeName}${this.getDateTime()}`;
      } else if (meetingTypeAndFlag[0] == "CostCenterWF") {
        fileTypeName = "Cost Center_WF";
        name = `${fileTypeName}${this.getDateTime()}`;
      }
      reqBody["endDate"] = this.endDate;
      reqBody["startDate"] = this.startDate;
      reqBody["reportType"] = meetingTypeAndFlag[0];
      reqBody["exportFlag"] = "extract";

      this.pagesService.getMirCostCenterExtract(reqBody).subscribe(
        (res: any) => {
          this.downloadFile(res.base64, name, "Data Extracted successfully");
        },
        (_error) => {
          this.messageService.add({
            severity: "error",
            summary: "Something went wrong! Please try again",
          });
          this.displayProcessingModal = false;
          this.validateExtractBtn();
        }
      );
      this.closeProcessingPopUp();
      return;
    }
    // Banker Meeting extract for thi
    console.log("meetingTypeAndFlag",meetingTypeAndFlag);
    if (meetingTypeAndFlag && meetingTypeAndFlag.length === 2) {
      if (meetingTypeAndFlag[1] === "total_meetings")
        
     console.log("end Date",this.endDate);
     console.log("start Date",this.startDate);
      
      if(this.reportScope && meetingTypeAndFlag[0]  === "BankerMeeting"){
        reqBody["endDate"] = this.endDate;
        reqBody["startDate"] = this.startDate;
        reqBody["reportFlag"] = meetingTypeAndFlag[1];
        reqBody["reportType"] = meetingTypeAndFlag[0];
        reqBody["exportFlag"] = "extract";
        reqBody["userId"] =null;
        reqBody["isPortfolio"] ="N";
        }else
        {
          reqBody["endDate"] = this.endDate;
          reqBody["startDate"] = this.startDate;
          reqBody["reportFlag"] = meetingTypeAndFlag[1];
          reqBody["reportType"] = meetingTypeAndFlag[0];
          reqBody["exportFlag"] = "extract";
        if(this.bankerMeetingPortfolioReadExport && meetingTypeAndFlag[0]  === "BankerMeeting"){
          reqBody["userId"] =this.recipeintUserId;
          reqBody["isPortfolio"] ="Y";
        }
        else{
          reqBody["isPortfolio"] ="N";
          reqBody["userId"] =this.recipeintUserId;
        }
      }
      if (meetingTypeAndFlag[0] == "SFBillPayment") {
        reqBody["financialYear"] = this.selectedFinancialYear;
      }

    }
    
    if (meetingTypeAndFlag && meetingTypeAndFlag.length === 2) {
      if (meetingTypeAndFlag[1] === "latest_meeting")
        meetingTypeAndFlag[1] += "s";

      reqBody["endDate"] = this.endDate;
      reqBody["startDate"] = this.startDate;
      reqBody["reportFlag"] = meetingTypeAndFlag[1];
      reqBody["reportType"] = meetingTypeAndFlag[0];
      reqBody["exportFlag"] = "extract";

      if (meetingTypeAndFlag[0] == "SFBillPayment") {
        reqBody["financialYear"] = this.selectedFinancialYear;
      }
      console.log("Banker meeting body :",reqBody);
      this.pagesService.getReportData(reqBody).subscribe(
        
        (res: any) => {
          let fileTypeName = "";
          let name = "";
          if (meetingTypeAndFlag[0] == "SFBillPayment") {
            fileTypeName = "SF Bill Payments";
            name = `${fileTypeName} ${this.selectedFinancialYear
              } ${this.getDateTime()}`;
          } else if (meetingTypeAndFlag[0] == "MIRAnalyze") {
            fileTypeName = "MIR For Analytical";
            name = `${fileTypeName}${this.getDateTime()}`;
          } else if (meetingTypeAndFlag[0] == "MIRIncentive") {
            fileTypeName = "MIR For Incentives";
            name = `${fileTypeName}${this.getDateTime()}`;
          } else if (meetingTypeAndFlag[0] == "MIR") {
            fileTypeName = "MIR";
            name = `${fileTypeName}${this.getDateTime()}`;
          } else if (meetingTypeAndFlag[0] == "IRFBillPayment") {
            fileTypeName = "IRF Bill Payment File";
            name = `${fileTypeName}${this.getDateTime()}`;
          } else if (meetingTypeAndFlag[0] == "BankerMeeting") {
            fileTypeName = "Banker Extract";
            name = `${fileTypeName}${this.getDateTime()}`;
          }
          else {
            fileTypeName = meetingTypeAndFlag[1]
              .split("_")
              .map((item) => item[0].toUpperCase() + item.substr(1))
              .join(" "); // capitalize first case of each word in meetingFlag and remove '_'
            name = `${meetingTypeAndFlag[0]
              } - ${fileTypeName}${this.getDateTime()}`;
          }
          if (
            meetingTypeAndFlag[1] == "latest_meetings" &&
            meetingTypeAndFlag[0] == "SF"
          ) {
            this.downloadFileCsv(
              res.base64,
              name,
              "Data Extracted successfully"
            );
          } else {
            this.downloadFile(res.base64, name, "Data Extracted successfully");
          }

          this.displayProcessingModal = false;
          this.startDate = null;
          this.endDate = null;
          this.meetingTypeSelected = null;
          this.ReportForm.controls.meetingType.reset();
          this.ReportForm.controls.gridCalender.reset();
          this.ReportForm.reset();
          this.validateExtractBtn();
        },
        (_error) => {
          this.messageService.add({
            severity: "error",
            summary: "Something went wrong! Please try again",
          });
          this.displayProcessingModal = false;
          this.validateExtractBtn();
        }
      );
    }
  }

  onBackClick() {
    this._location.back();
  }

  datePicker(): any {
    this.startDate = this.ReportForm.value.gridCalender[0]
      ?.split("/")
      .reverse()
      .join("-");
    this.endDate = this.ReportForm.value.gridCalender[1]
      ?.split("/")
      .reverse()
      .join("-");
    if (this.startDate && this.endDate) {
      this.mainCalendar.overlayVisible = false;
    }
    this.validateExtractBtn();
  }

  downloadFile(file, name, successMessage) {
    let src = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${file}`;
    const link = document.createElement("a");
    link.href = src;
    link.download = name;
    link.click();
    this.messageService.add({ severity: "success", detail: successMessage });
  }

  downloadFileCsv(file, name, successMessage) {
    // Convert the base64 data to a Blob
    const byteCharacters = atob(file);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "text/csv" });

    // Save the Blob as a file using FileSaver.js
    FileSaver.saveAs(blob, name);
    this.messageService.add({ severity: "success", detail: successMessage });
  }

  getDateTime() {
    let today = new Date();
    let yy = today.getFullYear();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth()); //January is 0!
    return ` ${dd}-${this.monthNames[mm]}-${yy}`;
  }

  getTime() {
    let time = new Date();
    let hours = time.getHours();
    let minute = time.getMinutes();
    let second = time.getSeconds();
    let currentTime = ` ${hours}_${minute}_${second}`
    return currentTime;
  }

  validateExtractBtn() {
    if (
      (this.meetingTypeSelected != null &&
        this.meetingTypeSelected != "" &&
        this.meetingTypeSelected != undefined &&
        this.meetingTypeSelected == "SF_BILL_PAYMENT" &&
        this.selectedFinancialYear) ||
      (this.meetingTypeSelected != "SF_BILL_PAYMENT" &&
        this.startDate != null &&
        this.startDate != "" &&
        this.startDate != undefined &&
        this.endDate != null &&
        this.endDate != "" &&
        this.endDate != undefined)
    ) {
      this.extractBtn = false;
    } else {
      this.extractBtn = true;
    }
  }

  showArchiveModal(showVal) {
    this.displayArchive = showVal;
    this.ReportForm.controls["archiveType"].reset();
    this.archiveDownloadDisabled = true;
    this.ReportForm.patchValue({ archiveCalendar: null });
  }

  validateDownloadBtn() {
    const archiveStartDate =
      this.ReportForm.value?.archiveCalendar &&
      this.ReportForm.value?.archiveCalendar[0]?.split("/").reverse().join("-");
    const archiveEndDate =
      this.ReportForm.value?.archiveCalendar &&
      this.ReportForm.value?.archiveCalendar[1]?.split("/").reverse().join("-");
    const archiveType = this.ReportForm.value?.archiveType;

    if (archiveStartDate && archiveEndDate && this.archiveCalendar) {
      this.archiveCalendar.overlayVisible = false;
    }

    if (!archiveEndDate || !archiveStartDate || !archiveType) {
      this.archiveDownloadDisabled = true;
    } else {
      this.archiveDownloadDisabled = false;
    }
  }

  clearDateArchive() {
    this.ReportForm.patchValue({ archiveCalendar: null });
    this.validateDownloadBtn();
  }

  generateArchive() {
    this.displayProcessingModal = true;

    let meetingTypeAndFlag = this.getMeetingTypeAndFlag(
      this.ReportForm.value.archiveType
    );

    if (meetingTypeAndFlag && meetingTypeAndFlag.length === 2) {
      let reqBody = {
        startDate: this.ReportForm.value.archiveCalendar[0]
          ?.split("/")
          .reverse()
          .join("-"),
        endDate: this.ReportForm.value.archiveCalendar[1]
          ?.split("/")
          .reverse()
          .join("-"),
        recipientUserId: this.recipeintUserId,

        excelType: `${meetingTypeAndFlag[0]}${meetingTypeAndFlag[1]
          .split("_")
          .map((item_) => item_[0].toUpperCase() + item_.substr(1))
          .join("")
          .replace(/s$/, "")}`,
        // ['SF', 'total_meetings'] => 'SFTotalMeeting'

        excelTypeName: `${meetingTypeAndFlag[0]} - ${meetingTypeAndFlag[1]
          .split("_")
          .map((item_) => item_[0].toUpperCase() + item_.substr(1))
          .join(" ")
          .replace(/s$/, "")}`,
        // ['SF', 'total_meetings'] =>  SF - Total Meeting
      };

      this.pagesService.generateData(reqBody).subscribe(
        (res: any) => {
          this.displayProcessingModal = false;
          this.startDate = null;
          this.endDate = null;
          this.meetingTypeSelected = null;
          this.showArchiveModal(false);
          this.validateExtractBtn();
          this.messageService.add({
            severity: "success",
            summary: "Data generated successfully!",
          });
        },
        (_error) => {
          this.messageService.add({
            severity: "error",
            summary: "Something went wrong! Please try again",
          });
          this.displayProcessingModal = false;
          this.showArchiveModal(false);
          this.validateDownloadBtn();
        }
      );
    }
  }

  // function to convert
  // reportType - eg - SF_COLLECTION & IRF_Total_meetings
  // to
  // ['SF', 'latest_meetings'] & ['IRF', 'total_meetings']
  getMeetingTypeAndFlag(reportType) {
    console.log("reportType",reportType);
    let reportTypeArr = reportType.split("_");
    let meetingTypeSelected = reportTypeArr[0];
    let meetingFlag = reportTypeArr.slice(1).join("_");

    if (meetingTypeSelected === "SF") {
      meetingFlag =
        meetingFlag === "COLLECTION" ? "latest_meetings" : "total_meetings";
    } else {
      meetingFlag = meetingFlag.toLowerCase();
    }

    if (reportType === "MEETINGS_EXTRACT") {
      meetingTypeSelected = "MEETINGSExtract";
      meetingFlag = "total_meetings";
    } else if (reportType === "SF_BILL_PAYMENT") {
      meetingTypeSelected = "SFBillPayment";
      meetingFlag = "total_meetings";
    } else if (reportType === "QC_STATUS_REPORT") {
      meetingTypeSelected = "Quality Control Status Report";
    } else if (reportType === "MIR_REPORT") {
      meetingTypeSelected = "MIR";
      meetingFlag = "total_meetings";
    } else if (reportType === "MIR_REPORT_INCENTIVE") {
      meetingTypeSelected = "MIRIncentive";
      meetingFlag = "total_meetings";
    } else if (reportType === "MIR_REPORT_ANALYTICAL") {
      meetingTypeSelected = "MIRAnalyze";
      meetingFlag = "total_meetings";
    } else if (reportType === "IRF_BILL_PAYMENT") {
      meetingTypeSelected = "IRFBillPayment";
      meetingFlag = "total_meetings";
    } else if (reportType === "COST_CENTER_FEE") {
      meetingTypeSelected = "CostCenterFee";
      meetingFlag = "total_meetings";
    } else if (reportType === "COST_CENTER_WF") {
      meetingTypeSelected = "CostCenterWF";
      meetingFlag = "total_meetings";
    }
    else if (reportType === "BANKER_REPORT") {
      meetingTypeSelected = "BankerMeeting";
      meetingFlag = "total_meetings";
    }
    return [meetingTypeSelected, meetingFlag];
  }

  generateReport() {
    this.displayProcessingModal = true;

    let reqBody = {};
    let meetingTypeAndFlag = this.getMeetingTypeAndFlag(
      this.meetingTypeSelected
    );

    // Genrate report
    console.log("Genrate banker report");
    console.log("meetingTypeAndFlag",meetingTypeAndFlag);
    if (meetingTypeAndFlag && meetingTypeAndFlag.length === 2) {
      if (meetingTypeAndFlag[1] === "total_meetings")
        
     console.log("end Date",this.endDate);
     console.log("start Date",this.startDate);
      
      if(this.reportScope && meetingTypeAndFlag[0]  === "BankerMeeting"){
        reqBody["endDate"] = this.endDate;
        reqBody["startDate"] = this.startDate;
        reqBody["recipientUserId"] =this.recipeintUserId;
        // reqBody["excelTypeName"] = "Banker Extract";
        // reqBody["excelType"] = meetingTypeAndFlag[0];
        // reqBody["exportFlag"] = "extract";
        reqBody["userId"] =null;
        reqBody["isPortfolio"] ="N";
        }else
        {
          reqBody["endDate"] = this.endDate;
        reqBody["startDate"] = this.startDate;
        reqBody["recipientUserId"] =this.recipeintUserId;
        // reqBody["excelTypeName"] = "Banker Extract";
        // reqBody["excelType"] = meetingTypeAndFlag[0];
        // reqBody["exportFlag"] = "extract";
        
        if(this.bankerMeetingPortfolioReadExport && meetingTypeAndFlag[0]  === "BankerMeeting"){
          reqBody["isPortfolio"] ="Y";
          reqBody["userId"] =this.recipeintUserId;
        }
        else{
          reqBody["isPortfolio"] ="N";
          reqBody["userId"] =this.recipeintUserId;
        }
      }
    }
   

    if (meetingTypeAndFlag && meetingTypeAndFlag.length === 2) {
      reqBody["endDate"] = this.endDate;
      reqBody["startDate"] = this.startDate;
      reqBody["recipientUserId"] = this.recipeintUserId;

      if (
        meetingTypeAndFlag[0] == "MEETINGSExtract" &&
        meetingTypeAndFlag[1] == "total_meetings"
      ) {
        reqBody["excelTypeName"] = "MEETINGS - Extract";
        reqBody["excelType"] = meetingTypeAndFlag[0];
        reqBody["exportFlag"] = "extract";
      } else if (meetingTypeAndFlag[0] == "MIR") {
        reqBody["excelTypeName"] = "MIR";
        reqBody["excelType"] = meetingTypeAndFlag[0];
        reqBody["exportFlag"] = "extract";
      } else if (meetingTypeAndFlag[0] == "MIRIncentive") {
        reqBody["excelTypeName"] = "MIR For Incentives";
        reqBody["excelType"] = meetingTypeAndFlag[0];
        reqBody["exportFlag"] = "extract";
      } else if (meetingTypeAndFlag[0] == "MIRAnalyze") {
        reqBody["excelTypeName"] = "MIR For Analytical";
        reqBody["excelType"] = meetingTypeAndFlag[0];
        reqBody["exportFlag"] = "extract";
      } else if (meetingTypeAndFlag[0] == "IRFBillPayment") {
        reqBody["excelTypeName"] = "IRF Bill Payment File";
        reqBody["excelType"] = meetingTypeAndFlag[0];
        reqBody["exportFlag"] = "extract";
      }
      // Banker report name
      else if (meetingTypeAndFlag[0] == "BankerMeeting") {
        reqBody["excelTypeName"] = "Banker Extract";
        reqBody["excelType"] = meetingTypeAndFlag[0];
        reqBody["exportFlag"] = "extract";
      }
      
      else if (meetingTypeAndFlag[0] == "CostCenterFee" || meetingTypeAndFlag[0] == "CostCenterWF") {
        if (meetingTypeAndFlag[0] == "CostCenterFee") {
          reqBody["excelTypeName"] = "Cost Center_Fee";
          reqBody["excelType"] = meetingTypeAndFlag[0];
          reqBody["exportFlag"] = "extract";
        } else if (meetingTypeAndFlag[0] == "CostCenterWF") {
          reqBody["excelTypeName"] = "Cost Center_WF";
          reqBody["excelType"] = meetingTypeAndFlag[0];
          reqBody["exportFlag"] = "extract";
        }

        this.pagesService.getMirCostCenterGenerate(reqBody).subscribe(
          (res: any) => {
            this.closeProcessingPopUp();
            this.messageService.add({
              severity: "success",
              summary: "Data generated successfully!",
            });
          },
          (_error) => {
            this.messageService.add({
              severity: "error",
              summary: "Something went wrong! Please try again",
            });
            this.displayProcessingModal = false;
          }
        );
        return;
      } else if (meetingTypeAndFlag[0] == "SFBillPayment") {
        reqBody[
          "excelTypeName"
        ] = `SF Bill Payments ${this.selectedFinancialYear}`;
        reqBody["excelType"] = meetingTypeAndFlag[0];
        reqBody["financialYear"] = this.selectedFinancialYear;
      } else if (meetingTypeAndFlag[0] == "Quality Control Status Report") {
        reqBody["reportName"] = this.meetingTypeSelected;
        reqBody["reportType"] = meetingTypeAndFlag[0];
        this.pagesService.downloadGenerateQCReport(reqBody).subscribe(
          (res: any) => {
            this.closeProcessingPopUp();
            this.messageService.add({
              severity: "success",
              summary: "Data generated successfully!",
            });
          },
          (_error) => {
            this.messageService.add({
              severity: "error",
              summary: "Something went wrong! Please try again",
            });
            this.displayProcessingModal = false;
          }
        );
        return;
      } else {
        reqBody["excelType"] = `${meetingTypeAndFlag[0]}${meetingTypeAndFlag[1]
          .split("_")
          .map((item_) => item_[0].toUpperCase() + item_.substr(1))
          .join("")
          .replace(/s$/, "")}`;
        // ['SF', 'total_meetings'] => 'SFTotalMeeting'

        reqBody["excelTypeName"] = `${meetingTypeAndFlag[0]
          } - ${meetingTypeAndFlag[1]
            .split("_")
            .map((item_) => item_[0].toUpperCase() + item_.substr(1))
            .join(" ")
            .replace(/s$/, "")}`;
        // ['SF', 'total_meetings'] =>  SF - Total Meeting
      }

      this.pagesService.generateData(reqBody).subscribe(
        (res: any) => {
          this.displayProcessingModal = false;
          this.startDate = null;
          this.endDate = null;
          this.meetingTypeSelected = null;
          this.ReportForm.controls.meetingType.reset();
          this.ReportForm.controls.gridCalender.reset();
          this.validateExtractBtn();
          this.messageService.add({
            severity: "success",
            summary: "Data generated successfully!",
          });
        },
        (_error) => {
          this.messageService.add({
            severity: "error",
            summary: "Something went wrong! Please try again",
          });
          this.displayProcessingModal = false;
        }
      );
    }
  }

  private showError() {
    this.messageService.add({
      severity: "error",
      summary: "Something went wrong! Please try again",
    });
    this.displayProcessingModal = false;
    this.validateExtractBtn();
  }

  private downloadData(res: any, name: string) {
    this.downloadFile(res.base64, name, "Data Extracted successfully");
    this.displayProcessingModal = false;
    this.startDate = null;
    this.endDate = null;
    this.meetingTypeSelected = null;
    this.ReportForm.controls.meetingType.reset();
    this.ReportForm.controls.gridCalender.reset();
    this.ReportForm.reset();
    this.validateExtractBtn();
  }

  private closeProcessingPopUp() {
    this.displayProcessingModal = false;
    this.startDate = null;
    this.endDate = null;
    this.meetingTypeSelected = null;
    this.ReportForm.controls.meetingType.reset();
    this.ReportForm.controls.gridCalender.reset();
    this.ReportForm.reset();
    this.validateExtractBtn();
  }

  financialYearMaster() {
    this.pagesService.getFeeCapFinancialYear().subscribe((res) => {
      this.billFinancialYearList = res;
      const currentFinancialYear = this.getCurrentFinYear();
      this.billForm.patchValue({ finYear: currentFinancialYear });
    });
  }

  getCurrentFinYear(){
    const currDate = new Date();
    const currentMonth = currDate.getMonth();
    const currentFinancialYear = currentMonth >= 3 ? currDate.getFullYear() + '-' + (currDate.getFullYear() + 1).toString().substring(2) : (currDate.getFullYear() - 1) + '-' + (currDate.getFullYear()).toString().substring(2);
    return currentFinancialYear;
  }

  clientType() {
    this.pagesService.clientTypeMaster().subscribe((res) => {
      let clientTypeNameAPIList;
      clientTypeNameAPIList = res?.SUCCESS;
      const transformedData = [];
      const uniqueLabels = [];

      transformedData.push({label: 'All', value: 'All'});
      clientTypeNameAPIList.forEach(item => {
        let label, value;
        if(item.clientTypeName.startsWith('LCG')){
          label='LCG';
          value='LCG'
        }else{
          label =item.clientTypeName;
          value =item.clientTypeName
        }
        //removing duplicates of LCG type
        if(!uniqueLabels[label]){
          transformedData.push({label,value})
          uniqueLabels[label] = true;
        }
      });
      this.clientTypeList = transformedData;
      this.billForm.patchValue({ clientType: 'All' });
    });
  }

  assignmentFamily() {
    this.pagesService.getAssignmentFamily().subscribe((res) => {
      this.assignmentFamilyList = res;
      let data = [];
      res?.forEach((r) => {
        data.push(r.id)
      })
      this.billForm.patchValue({ assignmentFamily: data });
    });
  }

  combineMaster(){
    this.pagesService.getCombineMaster().subscribe((res) => {
      this.statusList = res?.filter((r) => {
        return r.name == 'SF Bill Status';
      });
      this.statusList.unshift({
        name:'SF Bill Status',
        value: 'All',
        id: 0
      });
      this.billForm.patchValue({ status: 0 });
    });
  }

  extractBillReport(){
    let assignmentFamily = this.assignmentFamilyList?.length == this.billForm.value?.assignmentFamily?.length ? null : this.billForm.value?.assignmentFamily;
    let paylaod = {
      "financialYear": this.billForm.value?.finYear, 		
      "billStatus": this.billForm.value?.status == 0 ? null : this.billForm.value?.status,
      "clientType": this.billForm.value?.clientType == 'All' ? null : this.billForm.value?.clientType,
      "assignmentFamily": assignmentFamily
    }
    
    if(this.billForm?.status == 'INVALID'){
      return;
    }

    this.pagesService.sfBillReport(paylaod).subscribe((res) => {
      if(res){
        this.downloadFile(res?.Base64, "SF Billing Report", "Data Extracted successfully");
      }
    }, (_err) => {
      this.messageService.add({ severity: "error", summary: "Something went wrong! Please try again"});
      setTimeout(() => {
        this.messageService.clear();
      }, 3000);
    });
  }

  filterChanged(){
    this.clearAllDisabled = false;
  }

  clearFilters(){
    this.clearAllDisabled = true;
    let data = [];
    this.assignmentFamilyList?.forEach((r) => {
      data.push(r.id)
    });

    const currentFinancialYear = this.getCurrentFinYear();
    this.billForm.patchValue({
      assignmentFamily: data,
      status: 0,
      clientType: 'All',
      finYear: currentFinancialYear
    });
  }
}